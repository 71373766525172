import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { AuthContext } from '../../context/Auth/AuthProvider';

import 'swiper/css';


const HeroSlider = (props) => {

    const {user, login, logout, register} = useContext(AuthContext);

    useEffect(  () => {

    }, []);

   

    const UI = () => {

        return (
            <Fragment>

                <section className="section pb-0 hero-section" id="hero">
                    <div className="bg-overlay bg-overlay-pattern"></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-sm-10">
                                <div className="text-center mt-lg-5 pt-5">
                                    <h1 className="display-6 fw-semibold mb-3 lh-base">The better way to manage your energy efficiency with <span className="text-success coreLogo">CoreDB </span></h1>
                                    <p className="lead text-muted lh-base">CoreDB is the first AI driven "smart" energy efficiency platform built by NMESYS for industry and individuals</p>

                                    <div className="d-flex gap-2 justify-content-center mt-4">
                                        <a className="btn btn-primary" onClick={() => register()} >Get Started <i className="ri-arrow-right-line align-middle ms-1"></i></a>
                                        <a href="#plans" className="btn btn-info">View Plans <i className="ri-eye-line align-middle ms-1"></i></a>
                                    </div>
                                </div>

                                <div className="mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel">
                                    <div className="demo-img-patten-top d-none d-sm-block">
                                        <img src="assets/images/landing/img-pattern.png" className="d-block img-fluid" alt="..." />
                                    </div>
                                    <div className="demo-img-patten-bottom d-none d-sm-block">
                                        <img src="assets/images/landing/img-pattern.png" className="d-block img-fluid" alt="..." />
                                    </div>
                                    <div className="carousel slide carousel-fade" data-bs-ride="carousel">
                                        <div className="carousel-inner shadow-lg p-2 bg-white rounded">
                                            <div className="carousel-item active" data-bs-interval="2000">
                                                <img src="assets/images/demos/default.png" className="d-block w-100" alt="..." />
                                            </div>
                                            <div className="carousel-item" data-bs-interval="2000">
                                                <img src="assets/images/demos/saas.png" className="d-block w-100" alt="..." />
                                            </div>
                                            <div className="carousel-item" data-bs-interval="2000">
                                                <img src="assets/images/demos/material.png" className="d-block w-100" alt="..." />
                                            </div>
                                            <div className="carousel-item" data-bs-interval="2000">
                                                <img src="assets/images/demos/minimal.png" className="d-block w-100" alt="..." />
                                            </div>
                                            <div className="carousel-item" data-bs-interval="2000">
                                                <img src="assets/images/demos/creative.png" className="d-block w-100" alt="..." />
                                            </div>
                                            <div className="carousel-item" data-bs-interval="2000">
                                                <img src="assets/images/demos/modern.png" className="d-block w-100" alt="..." />
                                            </div>
                                            <div className="carousel-item" data-bs-interval="2000">
                                                <img src="assets/images/demos/interactive.png" className="d-block w-100" alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="position-absolute start-0 end-0 bottom-0 hero-shape-svg">
                       
                    </div>
                </section>
        
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default HeroSlider;