import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { LocaleContext } from '../../context/Locale/Locale';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { SocketContext } from '../../context/Socket/SocketProvider';
import LanguageSwitcher  from '../../components/LanguageSwitcher';
import { useRouter } from '../../components/Router/Router'
import {NavLink} from "react-router-dom";
import { Link } from "react-router-dom";
import _ from 'lodash'
import Alerts from './Notifications';
import ICONV from '../iconv';
import {roles, scopes, getPermissions, indexRole} from '@nmesys/permissions'


const Topbar = (props) => {

    const router = useRouter();
    const { lang, locale, l } = useContext(LocaleContext);
    const {user, login, logout} = useContext(AuthContext);
    const {socket} = useContext(SocketContext);
    const [logo, setLogo] = useState(process.env.REACT_APP_LOGO);
    const [logoTitle, setLogoTitle] = useState(process.env.REACT_APP_APP_NAME);

    useEffect(() => {}, [socket]);

    useEffect(() => {

        //if(clientChanged && client?.whitelabel?.logo) setLogo(client.whitelabel.logo);

    }, [lang, locale, user]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const capitalizeFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    const UI = () => {
        if(!lang || !user) return (<Fragment></Fragment>)

        const userRole = _.reduce(user.roles, (highestRole, role) => {
            const currentRank = indexRole(role);
            return currentRank > indexRole(highestRole) ? role : highestRole;
        }, '');

        return (
        
            <Fragment>
                <header id="page-topbar">
                    <div className="layout-width">
                        <div className="navbar-header">
                            <div className="d-flex">
                                <div className="navbar-brand-box horizontal-logo">
                                    <a href="/" className="logo logo-dark">
                                        <span className="logo-sm">
                                            <img src={logo} alt={logoTitle} height="22" />
                                        </span>
                                        <span className="logo-lg coreLogo">
                                            <img src={logo} alt={logoTitle} height="17" />
                                        </span>
                                    </a>

                                    <a href="index.html" className="logo logo-light">
                                        <span className="logo-sm">
                                            <img src={logo} alt={logoTitle} height="22" />
                                        </span>
                                        <span className="logo-lg">
                                            <img src={logo} alt={logoTitle} height="17" />
                                        </span>
                                    </a>
                                </div>

                                <button type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
                                    <span className="hamburger-icon">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                </button>

                            </div>

                            <div className="d-flex align-items-center">

                                <div className="dropdown d-md-none topbar-head-dropdown header-item">
                                    <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="bx bx-search fs-22"></i>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                                    <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <LanguageSwitcher />
{/** 
                                <div className="ms-1 header-item d-none d-sm-flex">
                                    <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" data-toggle="fullscreen">
                                        <i className='bx bx-fullscreen fs-22'></i>
                                    </button>
                                </div>

                                <div className="ms-1 header-item d-none d-sm-flex">
                                    <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                                        <i className='bx bx-moon fs-22'></i>
                                    </button>
                                </div>
*/}
                                <Alerts />

                                <div className="ms-1 header-item d-none d-sm-flex">
                                    <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode fs-20">
                                        <ICONV name={(socket && socket?.id)? 'socket.connected':'socket.disconnected'}/>
                                    </button>
                                </div>

                                <div className="dropdown ms-sm-3 header-item topbar-user">
                                    <button type="button" className="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="d-flex align-items-center">
                                            <img className="rounded-circle header-profile-user" src={(!!user && !!user?.contact?.emails?.[0]?.gravatar)? user.contact.emails[0].gravatar : `https://gravatar.com/avatar/00000000000000000000000000000000.jpg?s=100&d=identicon`} alt="Header Avatar" />
                                            <span className="text-start ms-xl-2">
                                                <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{(!!user)? `${capitalizeFirst(user.contact.first_name)} ${capitalizeFirst(user.contact.last_name)}`: `USER` }</span>
                                                <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{!!user ? user.user_id:''} {!!userRole ? capitalizeFirst(userRole):''}</span>
                                            </span>
                                        </span>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-end">

                                        <h6 className="dropdown-header">{ (!!user)? `${l('navbar.greeting', 'Welcome')} ${capitalizeFirst(user.contact.first_name)}`: l('navbar.greeting', 'Welcome') }</h6>
                                        <Link className="dropdown-item" to="/profile"><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span className="align-middle">{l('navbar.profile', 'Profile')}</span></Link>
                                        <Link className="dropdown-item" to="/chat"><i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">{l('navbar.messages', 'Messages')}</span></Link>
                                        <Link className="dropdown-item" to="/tasks"><i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">{l('navbar.taskboard', 'Taskboard')}</span></Link>
                                        <Link className="dropdown-item" to="/faq"><i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span className="align-middle">{l('navbar.help', 'Help')}</span></Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="/profile/settings"><span className="badge bg-soft-success text-success mt-1 float-end">{l('components.badges.new', 'New')}</span><i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Settings</span></Link>
                                        <a className="dropdown-item" onClick={() => logout()}><i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span className="align-middle" data-key="t-logout">{l('navbar.logout', 'Logout')}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </Fragment>
            
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default Topbar;