/**
 * Socket is at the top of the Provider chain so to prevent multiple connections.
 * To affect other providers like user, notifications or any other
 * we must include constant in the socket for those providers to monitor
 */
import logger from '../../libraries/logger'
import React, { useState, useEffect, createContext, Fragment, useContext, useRef } from 'react';
import { NotificationContext } from '../Notification/NotificationProvider';
import io from 'socket.io-client';
import {
    errorToast,
    successToast,
    COOKIE_PARAMS,
    getCookie,
    setCookie,
    cookieCrypt,
    cookieDecrypt,
    cookieDestroy,
    PROXY_CLIENT
} from '../../libraries/utils';

import { AuthContext } from '../Auth/AuthProvider';

export const SocketContext = createContext();

export const useSocket = () => {
    return useContext(SocketContext);
};

const SocketProvider = ({ children, context }) => {

    const socket = React.useMemo(() => {
        return io(process.env.REACT_APP_SOCKET_NOTIFICATIONS, {
          autoConnect: false,
          forceNew: false,
          timeout: 3000,
          withCredentials: true,
          secure: (process.env.NODE_ENV === 'production')? true:false,
        });
    }, []);

    const {user, logout} = useContext(AuthContext);
    const userChanged = useCompare(user)
    const {notifications, setNotifications} = useContext(NotificationContext);
 

    useEffect(() => {

        if(userChanged && user?.user_id ){
            socket.connect()

            socket.on("connect_error", (err) => {
                logger.log('SOCKET CONNECTION ERROR 33', err)
            })

            socket.on("connected", (data) => {
                logger.log('SOCKET CONNECTED ', data)
            })

            socket.on('notification', (data) => {
                logger.log('NEW NOTIFICATION RECEIVED', data)
                let ex = notifications.filter( n => n.notification_id === data.payload.notification_id)
                if(!ex.length){
                    let refNotifications = [data.payload, ...notifications];
    //                setNotifications(refNotifications)
                }
            })

            socket.on("logout", payload => {
                
            })
        }
  
    }, [user])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }




    const actions = {
        socket
    }

    return (
        <Fragment>
            <SocketContext.Provider value={actions}>
                {children}
            </SocketContext.Provider>
        </Fragment>
    )

}

export default SocketProvider;