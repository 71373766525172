import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/Auth/AuthProvider';
import { setCookie, PROXY_CLIENT } from  '../../libraries/utils';

const LogoutPage = (props) => {

    const { user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(  () => {

        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/auth/logout`)
        .then(response => {
            setUser(null);
            navigate('/auth/login')
            //if(response?.data?.redirect) window.location.href = response.data.redirect
            //else{ navigate('/auth/login') }
        })
        .catch(err => {
            console.log('AUTH PROVIDER: USER AUTHENTICATION ERROR', err)
            setUser(null);
        })

    }, [user]);

    return (
        <Fragment>
            <section  >
                <div className="form-section">
                    <div className="form-inner">

                        <Link to="/auth/login" >
                            <a className="logo">
                                <img src="/auth/assets/logo.png" alt="IRON AUTH" title="IRON AUTH" />
                            </a>
                        </Link>

                        <h3>Logout</h3>
                        
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

LogoutPage.requiredScope = [];
LogoutPage.requireAuth = false;
export default LogoutPage;