import React, {createContext, Fragment, useContext, useEffect, useRef, useState, useMemo} from 'react';
import {useRouter} from "../../components/Router/Router";
import { AuthContext } from '../Auth/AuthProvider';
import Swal from '@nmesys/sweetalert2'
import _ from 'lodash';
import { AccountContext } from '../Account/AccountProvider';
import { BucketContext } from '../Bucket/BucketProvider';

import {
    errorToast,
    PROXY_CLIENT
} from '../../libraries/utils';
import Iconv from '../../components/iconv';

export const GatewayContext = createContext();

const GatewayProvider = ({children}) => {

    const {user} = useContext(AuthContext);
    const {account} = useContext(AccountContext)
    const { bucket, buckets } = useContext(BucketContext);

    const [gateway, setGateway] = useState({});
    const [gateways, setGateways] = useState([]);
    const [insight, setInsight] = useState([])
    const [signalStrength, setSignalStrength] = useState("none");

    const [gatewaysFiltered, setGatewaysFilter] = useState(gateways)
    const [bucketGrpFilter, setBucketGrpFilter] = useState(false);
    const [bucketFilter, setBucketFilter] = useState(false);
    
    const gatewayChanged = useCompare(gateway);
    const gatewaysChanged = useCompare(gateways);
    const accountChanged = useCompare(account);

    const bucketsChanged = useCompare(buckets);
    const bucketChanged = useCompare(bucket);
    const bucketFilterChanged = useCompare(bucketFilter);
    const bucketGrpFilterChanged = useCompare(bucketGrpFilter);

    useEffect( () => {
        if( accountChanged && account?.account_id ) getGateways(); 
    }, [account])

    useEffect( () => {
        if( gatewaysChanged  ){
            if(gateways?.[0]) setGateway(gateways[0]);
            else setGateway({}) 
        }
        setGatewaysFilter(gateways)
    }, [gateways])

    useEffect( () => {
        if( 
            (bucketChanged && gateways.length && !!bucketFilter) ||
            (bucketFilterChanged && !!bucketFilter && gateways.length)
        ) {
            setBucketGrpFilter(false);
            let objs = _.filter(gateways, s => s.bucket_id === bucket.bucket_id);
            setGatewaysFilter(objs);
            setGateway(objs[0])
        }

        if(
            (bucketsChanged && gateways.length && !!bucketGrpFilter) 
            || (bucketChanged && gateways.length && !!bucketGrpFilter)
            || (bucketGrpFilterChanged && !!bucketGrpFilter && gateways.length)
        ){
            setBucketFilter(false);

            let objs = [], bucket_ids, bIds = [];
            if(bucket?.bucket_id && (!bucket.parent_id || bucket.parent_id.length === 0)){ // is parent
                bIds.push(bucket.bucket_id)
                _.map(buckets, bkt => {
                    if(bkt.parent_id === bucket.bucket_id) bIds.push(bkt.bucket_id)
                })
                
            }else{ // child selected
                bIds.push(bucket.parent_id)
                _.map(buckets, bkt => {
                    if(bkt.parent_id === bucket.parent_id) bIds.push(bkt.bucket_id)
                })
            }
            objs = _.filter(gateways, s => _.includes(bIds, s.bucket_id));
            setGatewaysFilter(objs);
            setGateway(objs[0])
        }

        if(
            (bucketFilterChanged && !bucketFilter && !bucketGrpFilter) ||
            (bucketGrpFilterChanged && !bucketGrpFilter && !bucketFilter)
            
        ){
            setGatewaysFilter(gateways)
        }

    }, [bucket, buckets, bucketGrpFilter, bucketFilter])

    useEffect( () => {
        if(gatewayChanged === true && gateway?.gateway_id) {
            if(!!gateway?.rssi){
                let strength = null;
                Object.entries({"weak": 100, "fair": 90, "good": 70, "strong":50}).map( k => {
                    if(gateway.rssi >= k[1] && !strength) strength = k[0];
                })
                if(!!strength) setSignalStrength(strength)
            }
        }
    }, [gateway, signalStrength, insight])


    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const getGateway = async (gateway_uid) => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/gateway/?gateway_uid=${gateway_uid}`)
        .then( response => {
            console.log('GATEWAY RESPONSE', response.data)
            setGateway(response.data)
            return response.data;
        })
        .catch( err => {
            console.log('GATEWAYS FETCHING ERROR 48', err)
            errorToast(err)
        })
    }
    /**
     * Get account level gateways
     */
    const getGateways = async () => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/gateway?account_id=${account.account_id}`)
        .then( response => {
            setGateways(response.data)
            //console.log('GATEWAYS RESPONSE', response.data)
            return;
        })
        .catch( err => {
            console.log('GATEWAYS FETCHING ERROR 70', err)
            errorToast(err)
        })
    }

    const addBulkGatewaysModal = (bucket) => {
        if(!bucket || !bucket?.bucket_id){
            errorToast('A Bucket is required.')
            return;
        }
        Swal.fire({
            title: 'Add Bulk Gateways (s)',
            width: "50vw",
            icon: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Create',
            html: 
                `<p class="me2">You are adding gateways to this Bucket location. To add a gateway or gateways, simply enter the <b>Gateway Serial Number / UID</b>. You can add one single gateway or you can add many. To add many gateways, simply add them delimited by a comma.</p>
                <div class="overflow-hidden">` +
                    '<input class="form-control mb-1" type"text" id="serial_numbers" placeholder="123456, 789012, 345678 ..."/>' +
                `</div>`
            ,
            preConfirm: (inputValue) => {
                const params = {
                    serial_numbers: Swal.getPopup().querySelector('#serial_numbers').value
                }
                for(let item of Object.entries(params)){
                    if(!['serial_numbers'].includes(item[0]) && item[1] === ''){
                        Swal.showValidationMessage(`Bitte das Formular vollständig ausfüllen`)
                    }
                }
                return params.serial_numbers;
            }
        }).then(async results => {
            if(results.isConfirmed) {
                let gatewayArray = results.value.split(',');
                for(let serial_number of gatewayArray){
                    let obj = {
                        account_id: bucket.account_id,
                        bucket_id: bucket.bucket_id,
                        uid: serial_number,
                        active: true
                    }

                    console.log('BULK GATEWAY', obj)
                    await createGateway(obj);
                }
            }
        })
    }

    const createGateway = async (obj) => {
        if(!obj?.account_id || !obj.bucket_id) return;
        PROXY_CLIENT().post(`${process.env.REACT_APP_API}/gateway?account_id=${obj.account_id}`, obj)
        .then( response => {
            getGateways()
            //successToast('Your new Gateway has been created!')
            return;
        })
        .catch( err => {
            console.log('GATEWAY CREATION ERROR 70', err)
            errorToast(err)
        })

    }

    const params = {
        gateway,
        gateways: gatewaysFiltered,
        setGateway,
        setGateways,
        getGateway,
        getGateways,
        createGateway,
        addBulkGatewaysModal,
        bucketGrpFilter, 
        bucketFilter, 
        setBucketGrpFilter,
        setBucketFilter
    }

    return (
        <GatewayContext.Provider value={params}>
            {children}
        </GatewayContext.Provider>
    )
}

export default GatewayProvider;