import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { LocaleContext } from '../../context/Locale/Locale';
import { ContactContext } from '../../context/Contact/ContactProvider';
import { MembersContext } from '../../context/Members/MembersProvider';

import withReactContent from "sweetalert2-react-content";
import _ from 'lodash'
import Iconv from '../iconv';
import { Link, useNavigate } from "react-router-dom";
import Swal from '@nmesys/sweetalert2'

const MemberCard = (props) => {

    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    const { lang, locale } = useContext(LocaleContext);
    const {member, updateRoleModal} = useContext(MembersContext);
    const contact = {}
    const memberChanged = useCompare(member);
    const [filterText, setFilterText] = useState('');

    useEffect(  () => {

    }, [member]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }


    const handleFilterChange = (e) => {
        setFilterText(e.target.value.toLowerCase());
    };

    const capitalizeFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    const getBorderClass = (scope) => {
        if (scope.toLowerCase().includes('create')) return 'success';
        if (scope.toLowerCase().includes('update')) return 'warning';
        if (scope.toLowerCase().includes('view')) return 'primary';
        if (scope.toLowerCase().includes('delete')) return 'danger';
        return 'secondary'; // Default border color if none of the keywords match
    };

    const UI = () => {
        if(!member?.user_id) return (<Fragment></Fragment>);

        const filteredScopes = member?.scopes?.filter(scope =>
            scope.toLowerCase().includes(filterText)
        );

        return (
            <Fragment>
                <div className="card">
                    <div className={`d-flex flex-row-reverse `} >
                        <div className="hstack text-nowrap gap-2 float-right">
                            <button type="button" id="dropdownMenuLink1" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-soft-info"><i className="ri-more-2-fill"></i></button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                <li className="ps-3 dropdown-item text-warning"  >Edit</li>
                                <li className="ps-3 dropdown-item text-danger" title="Remove this member access"><a>Delete</a></li>
                                <hr />
                            
                                <li className="ps-3 dropdown-item text-primary" title="Assign a New Role to this member" onClick={() => updateRoleModal(false)} ><a>Grant Role</a></li>
                                <li className="ps-3 dropdown-item text-danger" title="Remove a Role from this member" onClick={() => updateRoleModal(true)} ><a>Remove Role</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="card-body text-center">
                        <div className="position-relative d-inline-block">
                            <a >
                                <Iconv name="apps.member" size="5" className="text-primary " />
                            </a>
                        </div>
                        <h5 className="mt-4 mb-1">{(member?.contact?.first_name)? `${capitalizeFirst(member.contact.first_name)} ${capitalizeFirst(member.contact.last_name)}` : ''}</h5>
                        <p className="text-muted"></p>
                        
                    </div>

                    <div className="card-body">
                        <div className="card">
                            <div className="card-header" >
                                <div className="card-title d-flex justify-content-between">
                                    <span>
                                        <i className="fa-solid fa-location me-2"></i> <b>Contact</b>
                                    </span>
                                    <button className="btn btn-sm btn-light" ><i className="fa fa-solid fa-pencil"></i></button>
                                </div>
                            </div>

                            <div className="card-body">
                                <table className="table table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>{member?.contact?.addresses?.[0] ? member.contact.addresses[0].street+` `+member.contact.addresses[0].house_number+` `+`${member.contact.addresses[0].street1 ? ' , ME '+member.contact.addresses[0].street1 : ''}`     :``}</div>
                                                <div>{member?.contact?.addresses?.[0] ? member.contact.addresses[0].post_code+` `+ member.contact.addresses[0].city : ''}</div>
                                                <div>{member?.contact?.addresses?.[0] ? member.contact.addresses[0].country : ''}</div>
                                            </td>
                                            <td><button className="btn btn-sm btn-light"><i className="fa fa-solid fa-location-dot"  ></i></button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="card">
                            <div className="card-header" >
                                <div className="card-title d-flex justify-content-between">
                                    <span>
                                        <i className="fa-solid fa-key me-2"></i> <b>Permissions Cloud</b>
                                    </span>
                                </div>
                            </div>

                            <div className="card-body">
                                <input
                                    type="text"
                                    className="form-control mb-3"
                                    placeholder="Search permissions..."
                                    value={filterText}
                                    onChange={handleFilterChange}
                                />
                                {
                                    filteredScopes && filteredScopes.length > 0
                                    ? filteredScopes.map((scope, index) => (
                                        <span key={index} className={`badge bg-${getBorderClass(scope)} me-1`}>
                                        {scope}
                                        </span>
                                    ))
                                    : <p>No matching scopes found.</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
               
            </Fragment>
        )  
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default MemberCard;