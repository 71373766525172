import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";

import { LocaleContext } from '../../context/Locale/Locale';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { AccountContext } from '../../context/Account/AccountProvider';

import { useRouter } from '../../components/Router/Router';
import ICONV from '../iconv';

const AppMenu = (props) => {

    const router = useRouter();
    const { lang, locale } = useContext(LocaleContext);
    const { user, PermissionsWrapper } = useContext(AuthContext);
    const { account } = useContext(AccountContext);
    const navigate = useNavigate();
    
    useEffect(  () => {


    }, [lang]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const UI = () => {
        /**
         * If any wizard is active, we do not want them to use the App Menu
         * Also this gets the menu out of the way. We can rethink this later
         */
        if( !lang ) return (<Fragment></Fragment>)

        return (
        
            <Fragment>
               <div className="app-menu navbar-menu">
                    <div className="navbar-brand-box">
                        <Link to="/">
                            <a className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src="/EnergieWisserPro.png" alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src="/EnergieWisserPro.png" alt="" height="17" />
                                </span>
                            </a>
                        </Link>
                        <Link to="/">
                            <a className="logo logo-light">
                                <span className="logo-sm">
                                    <img src="/EnergieWisserPro.png" alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src="/EnergieWisserPro.png" alt="" height="17" />
                                </span>
                            </a>
                        </Link>
                        <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
                            <i className="ri-record-circle-line"></i>
                        </button>
                    </div>


                    <div id="scrollbar">
                        <div className="container-fluid">

                            <div id="two-column-menu">
                            </div>
                            <ul className="navbar-nav" id="navbar-nav">
                                <li className="menu-title"><span data-key="t-menu">Menu</span></li>
                                
                                <li className="nav-item">
                                    <Link className="nav-link menu-link" to="/dashboards/overview"><a ><ICONV name="apps.dashboard"/> <span data-key="t-dashboards">Dashboard</span></a></Link>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarApps">
                                        <i className="lab la-delicious"></i> <span data-key="t-apps">Apps</span>
                                    </a>



                                    <div className="collapse menu-dropdown" id="sidebarApps">
                                        <ul className="nav nav-sm flex-column">

                                        

                                            <li className="nav-item ms-2">
                                                <Link className="nav-link" to="/apps/databoss"> <ICONV name="apps.databoss"/> DataBoss</Link>
                                            </li>

                                           

                                            <li className="nav-item  ms-2">
                                                <a href="#sidebarCRM" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarCRM" data-key="t-crm"> <ICONV name="apps.crm"/> CRM</a>
                                                <div className="collapse menu-dropdown" id="sidebarCRM">
                                                    <ul className="nav nav-sm flex-column">
                                                        <li className="nav-item ms-4">
                                                            <Link className="nav-link" to="/apps/crm/contacts">Contacts</Link>
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                            </li>

                                            <li className="nav-item ms-2">
                                                <Link to="/apps/shortlinks" className="nav-link"><i className="fa-solid fa-link"></i> ShortLinks <span className="badge badge-pill bg-success" data-key="t-new">New</span></Link>
                                            </li>

                                            <li className="nav-item  ms-2">
                                                <a href="#sidebarInvoices" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarInvoices" data-key="t-gtw"><ICONV name="apps.invoice"/> Invoices</a>
                                                <div className="collapse menu-dropdown" id="sidebarInvoices">
                                                    <ul className="nav nav-sm flex-column">
                                                        <li className="nav-item ms-4">
                                                            <Link className="nav-link" to="/apps/invoices/listview">List</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>



                                        </ul>
                                    </div>
                                </li>

                                <li className="nav-item ms-1">
                                    <a className="nav-link menu-link" href="#sidebarReports" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarReports">
                                        <i className="fa fa-solid fa-file-invoice"></i> <span data-key="t-apps">Reports</span>
                                    </a>
                                    <div className="collapse menu-dropdown" id="sidebarReports">
                                        <ul className="nav nav-sm flex-column">

                                            <li className="nav-item ms-2">
                                                <Link to="/report/builder" className="nav-link"><i className="fa-solid fa-trowel-bricks"></i> Report Builder <span className="badge badge-pill bg-success" data-key="t-new">New</span></Link>
                                            </li>
                                            {/** Here we want to list all custom reports as links */}
                                           

                                        </ul>
                                    </div>
                                </li>

                                <PermissionsWrapper
                                    user={user}
                                    role={['god']}
                                >
                                <li className="nav-item">
                                    <Link to="/apps/blog"> 
                                        <a className="nav-link menu-link" >
                                            <i className="fa-solid fa-blog"></i> <span data-key="t-widgets">Blog</span>
                                        </a>
                                    </Link>
                                </li>
                                </PermissionsWrapper>







                            </ul>
                        </div>
                    </div>

                    <div className="sidebar-background"></div>
                </div>
        
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default AppMenu;