import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { MembersContext } from '../../context/Members/MembersProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import _ from 'lodash'
import {roles, scopes, getPermissions, indexRole} from '@nmesys/permissions'

import de from '../DataTables/datatable.de.json';
const $ = require('jquery')
$.DataTable = require('datatables.net');

const TblMembers = (props) => {

    const {member, members, setMember} = useContext(MembersContext)
    const { lang, locale } = useContext(LocaleContext);
    const memberChanged = useCompare(member)
    const membersChanged = useCompare(members)

    useEffect( () => {

        if(membersChanged === true) initializeDT()

    },[member,members]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
  
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => { ref.current = value });
        return ref.current;
    }

    const capitalizeFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    const formatAddress = (obj) => {
        function hasPropertiesWithNonNullValues(obj, properties) {
            if (typeof obj !== 'object' || obj === null || !Array.isArray(properties)) return false;
            for (let property of properties) {
              if (!obj.hasOwnProperty(property) || obj[property] === null) return false;
            }
            return true;
        }
        if(hasPropertiesWithNonNullValues(obj, ['street', 'house_number', 'city', 'post_code', 'country'])){
            return `${obj.street} ${obj.house_number}, ${ (!!obj.street1) ? obj.street1 + ', ':'' }${obj.city}, ${obj.post_code} ${obj.country.toUpperCase()}`
        } 
        return null;
    }

    const initializeDT = async () => {

        const tableElement = $('#tblMembers');
        const dataTable = $.fn.dataTable.isDataTable(tableElement);
        if (dataTable) {
            tableElement.DataTable().destroy();
            tableElement.empty();
        }
        $(`#tblMembers tbody`).off();

        if (members.length > 0) {
            const table = $(`#tblMembers`).DataTable({
                language: locale,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: members,
                rowId: "user_id",
                pageLength: 50,
                orderCellsTop: false,
                fixedHeader: true,
                order: [
                    [1, "asc"]
                ],
                columns: [
                    {
                        "data": null,
                        "searchable": false,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return data.user_id
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.contact){
                                return `${capitalizeFirst(data.contact.first_name)} ${capitalizeFirst(data.contact.last_name)}`
                            }
                            return '';
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {

                            let memberRole = _.reduce(data.roles, (highestRole, role) => {
                                const currentRank = indexRole(role);
                                return currentRank > indexRole(highestRole) ? role : highestRole;
                            }, '');

                            if(!!memberRole) return capitalizeFirst(memberRole);
                            return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.last_login){
                                return new Date(data.last_login)
                            }
                            return '';
                        }
                    },
                ],
                createdRow: function (row, data, dataIndex) {},
                "fnDrawCallback": function( oSettings ) { }
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $(`#tblMembers tbody tr`).off('mouseenter').on('mouseenter', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });

            $(`#tblMembers tbody tr`).off('mouseleave').on('mouseleave', function () {
                $(this).css('background-color', 'transparent');
            });

            $(`#tblMembers tbody`).on('click', 'tr', function (e) {
                let parent = e.currentTarget.closest('tr');
                let target = members.filter(m => m.user_id === parent.id);
                setMember(target[0])
            });
        }  
    }

    return (
        <Fragment>
            <table id={`tblMembers`} className="table mb-0 row-border hover order-column w-100 table-hover" role="grid">
                <thead>
                    <tr>

                        <th >MemberID</th>
                        <th >Name</th>
                        <th >Role</th>
                        <th >Last Login</th>
                    </tr>
                </thead>
            </table>
        </Fragment> 
    )

}

export default TblMembers;