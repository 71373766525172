import React, {createContext, Fragment, useContext, useEffect, useRef, useState, useMemo} from 'react';
import {useRouter} from "../../components/Router/Router";
import { AuthContext } from '../Auth/AuthProvider';
import { AccountContext } from '../Account/AccountProvider';

import Swal from '@nmesys/sweetalert2'

import {
    errorToast,
    successToast,
    PROXY_CLIENT
} from '../../libraries/utils';
import Iconv from '../../components/iconv';
import _ from 'lodash';
export const TenantContext = createContext();

const TenantProvider = ({children}) => {

    const router = useRouter()
    const {user} = useContext(AuthContext);
    const {account} = useContext(AccountContext)
    
    const [tenant, setTenant] = useState({})
    const [tenants, setTenants] = useState([])
    const [tenantsFiltered, setTenantsFiltered] = useState(tenants)
    
    const [curTenants, setCurTenants] = useState([])
    const accountChanged = useCompare(account);
    const userChanged = useCompare(user);
    const tenantsChanged = useCompare(tenants);


    useEffect( () => {
        if( accountChanged ) getTenants(); 
    }, [account])


    useEffect( () => {
        if(tenantsChanged){
            if(tenants?.[0]) setTenant(tenants[0])
            else setTenant({})
            setTenantsFiltered(tenants);
        }
    }, [tenants])


    useEffect( () => {}, [tenant, curTenants])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const getTenant = async (tenant_id) => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/tenant/?tenant_id=${tenant_id}`)
        .then( response => {
            setTenant(response.data)
            //console.log('TENANT RESPONSE', response.data)
            return;
        })
        .catch( err => {
            console.log('TENANTS FETCHING ERROR 48', err)
            //throw new Error(err)
        })
    }

    const getTenants = async () => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/tenant/all?account_id=${account.account_id}`)
        .then( response => {
            if(!!response.data) setTenants(response.data)
            else setTenants([])
            //console.log('TENANTS RESPONSE', response.data, account.account_id)
            return;
        })
        .catch( err => {
            console.log('TENANTS FETCHING ERROR 48', err)
            errorToast(`Get All TENANTS Fail`);
            return
        })
    }

    const filterTenants = () => {
        
    }
    /**
     * Get the current tenants whom live at a bucket_id
     */
    const getCurTenants = async (bucket_id) => {
        if(!bucket_id || !tenants.length === 0) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/tenant/current/?bucket_id=${bucket_id}`)
        .then( response => {
            setCurTenants(response.data)
            //console.log('CUR TENANTS RESPONSE', response.data)
            return;
        })
        .catch( err => {
            console.log('CUR TENANTS FETCHING ERROR 48', err)
            //throw new Error(err)
        })
    }

    const addressBuilder = (obj, formatted = false) => {
        if(obj?.address){
            if(!formatted) return `${obj.address.street} ${obj.address.house_number} ${ obj?.address?.street1 ? ', ME '+ obj.address.street1 : ''  }, ${obj.address.post_code} ${obj.address.city}, ${obj.address.country.toUpperCase()}`
            else {
                return (
                    `${obj.address.street} ${obj.address.house_number} ${ obj?.address?.street1 ? ', ME '+ obj.address.street1 : ''  }\n ${obj.address.post_code} ${obj.address.city}\n ${obj.address.country.toUpperCase()}`
                    )
                }
        }
        return ''
    }

    const createTenantModal = (bucket) => {
        if(!bucket) return alert('Bucket is required')
        const address = addressBuilder(bucket);
        Swal.fire({
            title: 'Add a new Tenant',
            width: "50vw",
            iconHtml: '<i class="fa-solid fa-house-user"></i>',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Save',
            html: 
                `<div class="overflow-hidden">
                    <div class="text-muted mb-4">${address}</div>
                    <div class="row">
                        <div class="col-6"><input class="form-control mb-1" type"text" id="first_name" placeholder="First Name*"/></div>
                        <div class="col-6"><input class="form-control mb-1" type"text" id="last_name" placeholder="Last Name*"/></div>
                    </div>
                    <div class="row">
                        <div class="col-12"><input class="form-control mb-1" type"text" id="company_name" placeholder="Company Name"/></div>
                    </div>
                    <div class="row mb-4"> 
                        <div class="col-12">
                            <input class="form-control mb-1" type"text" id="email" placeholder="Email*" required />
                            <small >A Tenant email is used to send Energy Consumption Reports and other various required data. NMESYS will not communicate directly with a Tenant.</small>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-6">
                            <label htmlFor="date_move_in">Date Move In*</label>
                            <input class="form-control mb-1" type="date" id="date_move_in" />
                        </div>
                        <div class="col-6">
                            <label htmlFor="date_move_out">Date Move Out*</label>
                            <input class="form-control mb-1" type="date" id="date_move_out" />
                        </div>
                    </div>
                </div>`
            ,
            preConfirm: (inputValue) => {
                const params = {
                    first_name: Swal.getPopup().querySelector('#first_name').value,
                    last_name: Swal.getPopup().querySelector('#last_name').value,
                    company_name: Swal.getPopup().querySelector('#company_name').value,
                    email: Swal.getPopup().querySelector('#email').value,
                    date_move_in: Swal.getPopup().querySelector('#date_move_in').value || undefined,
                    date_move_out: Swal.getPopup().querySelector('#date_move_out').value || undefined,
                }
                for(let item of Object.entries(params)){
                    if(['first_name','last_name', 'date_move_in'].includes(item[0]) && item[1] === ''){
                        Swal.showValidationMessage(`Bitte das Formular vollständig ausfüllen: ${item[0]}`)
                    }
                }
                return params;
            }
        }).then(results => {
            if (results.isConfirmed) {
                const obj = {
                    account_id: bucket.account_id,
                    bucket_id: bucket.bucket_id,
                    address_id: bucket.address_id,
                    first_name: results.value.first_name,
                    last_name: results.value.last_name,
                    company_name: (!results.value?.company_name || results.value.company_name !== '')? undefined : results.value.company_name,
                    email:{
                        type: 'home',
                        address: results.value.email
                    },
                    date_move_in: (results.value.date_move_in) ? new Date(results.value.date_move_in).getTime() : undefined,
                    date_move_out: (results.value.date_move_out) ? new Date(results.value.date_move_out).getTime() : undefined,
                }

                _.map(obj, (o,i) => {
                    if(!o) delete obj[i]
                })

                PROXY_CLIENT().post(`${process.env.REACT_APP_API}/tenant/`, obj)
                .then( response => {
                    getTenants();
                    successToast('Your new Tenant has been created!')
                    return;
                })
                .catch( err => {
                    console.log('TENANT CREATION ERROR 201', err)
                    errorToast(err)
                })
            }
        })
    }


/** 
    const updateTenantModal = () => {
        if(!bucket || !!bucket.address.street1 || bucket.address.street1.length !== 0){
            errorToast('A Bucket of tye "multi" is required.')
            return;
        }
        Swal.fire({
            title: 'Add APT/Office (s)',
            width: "50vw",
            icon: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Create',
            html: 
                `<div class="overflow-hidden">` +
         //           `<input class="form-control mb-1" type"text" id="parent_id" value="${bucket.bucket_id}"/>` +
                    '<input class="form-control mb-1" type"text" id="apts" placeholder="1, 1a, 1b, 2, 2a, 2b..."/>' +
                `</div>`
            ,
            preConfirm: (inputValue) => {
                const params = {
                    apts: Swal.getPopup().querySelector('#apts').value
                }
                for(let item of Object.entries(params)){
                    if(!['apts'].includes(item[0]) && item[1] === ''){
                        Swal.showValidationMessage(`Bitte das Formular vollständig ausfüllen`)
                    }
                }
                return params.apts;
            }
        }).then(results => {
            if (results.isConfirmed) {
                let aptArray = results.value.split(',');
                let promiseArray = [];
                for(let apt of aptArray){
                    let apartment = {...bucket}
                    apartment.address.street1 = apt.trim();
                    console.log(apartment.address)
                    promiseArray.push( PROXY_CLIENT().post(`${process.env.REACT_APP_API}/bucket/`, apartment.address) )
                }
                Promise.all(promiseArray)
                .then(response => {
              //      getBuckets()
                    successToast('Your new Bucket has been created!')
                })
                .catch( err => {
                    console.log('PREMISE CREATION ERROR 70', err)
                    errorToast(err)
                })
            }
        })
    }
**/

/** 
    const deleteTenantModal = () => {
        if(!bucket) return
        let html = `<p>You are about to permanently delete the bucket located at:</p>` +
        `<b>${bucket.address.street} ${bucket.address.house_number} ${ (!!bucket.address.street1)? ', '+ bucket.address.street1 : '' }</b>` +
        `<p>Are you sure you want to proceed?</p>`;

        if(bucket.type === 'multi'){
            html = `<p>You are about to permanently delete the bucket located at:</p>` +
            `<b>${bucket.address.street} ${bucket.address.house_number} ${ (!!bucket.address.street1)? ', '+ bucket.address.street1 : '' }</b>` +
            `<p><span class="text-danger">AND every apartment, office or space inside this Bucket.</span> Are you sure you want to proceed?</p>`;
        }

        Swal.fire({
            title: 'Delete this Bucket?',
            width: "50vw",
            icon: 'question',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Submit',
            html
        }).then(results => {
            if (results.isConfirmed) {
                PROXY_CLIENT().delete(`${process.env.REACT_APP_API}/bucket?bucket_id=${bucket.bucket_id}`)
                .then( response => {
           //         getBuckets()
                    successToast('Your new Bucket has been deleted!')
                    return;
                })
                .catch( err => {
                    console.log('PREMISE DELETION ERROR 238', err)
                    errorToast(err)
                })
            }
        })
    }

**/

    const params = {
        tenant,
        tenants: tenantsFiltered,
        getTenant,
        getTenants,
        getCurTenants,
        createTenantModal,
        setTenant,
        curTenants, 
        setCurTenants
  //      updateTenantModal,
  //      deleteTenantModal
       
    }

    return (
        <TenantContext.Provider value={params}>
            {children}
        </TenantContext.Provider>
    )
}

export default TenantProvider;