import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import {SensorContext} from "../../context/Sensor/SensorProvider";
import uniqid from 'uniqid';
import Iconv from '../iconv';
import _ from 'lodash';

const Chart = React.lazy(() => import('react-apexcharts'), { ssr: false });

const SensorConsumptionChart = (props) => {

    const [data, setData] = useState(null);
    const { sensor } = useContext(SensorContext);
    const [height, setHeight] = useState( props.height ? props.height : 250)
    const sensorChanged = useCompare(sensor)


    useEffect( () => {
        if(sensorChanged === true && sensor?.medium_code) chartBuilder()
    },[ sensor ]);

    useEffect( () => {},[ props, height, data ]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
  
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => { ref.current = value });
        return ref.current;
    }

    const chartBuilder = async () => {

        if(!sensor?.aggregate || !sensor.aggregate?.monthlyAggregate) return
        let charts = [];
        let year = new Date().getFullYear();
        /**
         * Loop through the sensor data and show the last 2 years in charts
         * where the max value of ITR is the number of years to show
         */
        for(let itr = 0; itr < 3; itr ++){ // itr = iteration
            //year = year - itr; 
               // let sdata = _.map(consumption[year]['m'], (obj) => _.get(obj, 'kwh', 0))
              //  sdata = _.map(sdata, d => d.toFixed(2))
            let data = _.filter(sensor.aggregate.monthlyAggregate, blk => new Date(blk.date).getUTCFullYear() === Number(year));
            let uomData = _.map(data, 'consumptionR');
            uomData = _.map(uomData, num => parseFloat(num.toFixed(1)));
            let id = uniqid();
            let series = [
                {
                    name: "Energie",
                    type: "line",
                    data: uomData
                }
            ]
            let options = {
                chart: {
                    height: height,
                    type: 'line',
                    sparkline: {
                        show: true
                    },
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                colors: ['#ff0000'],
                title: {
                    text: '',
                    align: 'left'
                },
                xaxis: {
                    categories: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                }
            }

            if(!sensor?.medium_code) sensor.medium_code = '0x0';
            switch(sensor.medium_code){
                case'0x8':
                    options.title.text = `HEIZUNGSVERBRAUCH IN KWH ${year}`;
                    options.colors = ['#dc3545'];
                    options.icon =  <Iconv name="sensors.0x8.standard" />
                //    monthVals = consumption
                    break;
                case'0x6':
                    options.title.text = `WARMWASSERVERBRAUCH IN KWH ${year}`;
                    options.colors = ['#ffc107'];
                    options.icon = <Iconv name="sensors.0x6.standard" />
                    //   monthVals = value[1]
                    break;
                case'0x7':
                    options.title.text = `KALTWASSERVERBRAUCH IN M3 ${year}`;
                    options.colors = ['#007bff'];
                    options.icon = <Iconv name="sensors.0x7.standard" />
                //    monthVals = value[1]
                    break;
                case'0x16':
                    options.title.text = `KALTWASSERVERBRAUCH IN M3 ${year}`;
                    options.colors = ['#007bff'];
                    options.icon = <Iconv name="sensors.0x16.standard" />
                //    monthVals = value[1]
                    break;
                case'0x4':
                    options.title.text = `HEIZUNGSVERBRAUCH IN KWH ${year}`;
                    options.colors = ['#dc3545'];
                    options.icon = <Iconv name="sensors.0x4.standard" />
                //    monthVals = value[1]
                    break;
                case'0x3':
                    options.title.text = `GAS IN M3 ${year}`;
                    options.colors = ['#dc3545'];
                    options.icon = <Iconv name="sensors.0x3.standard" /> 
                    //   monthVals = value[1]
                    break;
                case'0xa':
                    options.title.text = `KLIMA (KALT) IN KWH ${year}`;
                    options.colors = ['#03c5fe'];
                    options.icon = <Iconv name="sensors.0xa.standard" />
                //    monthVals = value[1]
                    break;
                case'0xb':
                    options.title.text = `KLIMA (KALT) IN KWH ${year}`;
                    options.colors = ['#03c5fe'];
                    options.icon = <Iconv name="sensors.0xb.standard" />
                //    monthVals = value[1]
                    break;
                case'0xd':
                    options.title.text = `COMBI (WÄRME/KALT) IN KWH ${year}`;
                    options.colors = ['#800080'];
                    options.icon = <Iconv name="sensors.0xd.standard" />
                //    monthVals = value[1]
                    break;
                    
                default :
                    options.title.text = `UNKNOWN ${year}`;
                    options.colors = ['#800080'];
                    options.icon = <Iconv name="sensors.0xd.standard" />
                    break;
            }
            if(data.length > 0) charts.push({id, series, options})
            year --
        }
        setData(charts)
        return
    }

    const UI = () => {
        if(!data) return (<Fragment></Fragment>)
        return (
            <Fragment>
                {
                    data.map(obj => {
                        return (
                            <div className="card " key={uniqid()}>
                                <div className="card-body">
                                    <div id={`${obj.id}`}>
                                        <Chart 
                                            options={obj.options} 
                                            series={obj.series}
                                            type={'line'}
                                            width={'100%'} 
                                            height={height} 
                                        />
                                    </div>
                                </div>
                            </div>
                       
                        )
                    })
                }
            </Fragment>
        )
    };

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default SensorConsumptionChart;