import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from './AuthProvider';
import Loading from '../../components/Loaders/Loading';

const RenderUI = React.memo(({ authorized, children }) => {
  if (authorized === true) {
    return <>{children}</>;
  }
  if (authorized === false) {
    // Need to return a 401 page here instead
    return <h1>401</h1>;
  }
  return null;
});

const AuthGuard = ({children}) => {
    const [authorized, setAuthorized] = useState(false);
    const [childrenChecked, setChildrenChecked] = useState(false);
    const { user, authenticateUser, login } = useContext(AuthContext);
    const navigate = useNavigate();
    /**
     * Here we compare the user permissions such as roles and scopes to the page requirements.
     * If the page states the user must possess certain scopes then this method will determine
     * whether to present the page or not. It is only a small issue if a user is shown a page
     * which should not view as the backend will still deny the data resource.
     */
    const checkAuth = (children) => {
        return new Promise((resolve) => {
            //console.log('CHECKING AUTHORIZATION', user)
            //Pass for GOD no matter what
            if (user.roles.includes('god')) return resolve(true);
           
            const child = React.Children.toArray(children.length >= 1 ? children[1] : children);
            let isAuthorized = false;
            if (child[0]?.type) {
                const { requiredRole, requiredScope,  requireAuth} = child[0].type;
                //console.log('CHILD', requiredRole, requiredScope)
                //console.log('CHILD USER', user)
                /**
                 * If the page has requireAuth set to true then evaluate
                 * else let user is authroized to view page
                 */
                if(requireAuth){
                    /**
                     * If page has requiredRole then evaluate
                     * User must have at least one of any in the array
                     */
                    if (
                        requiredRole && 
                        requiredRole.some((role) => user.roles.includes(role))
                    ) {
                        isAuthorized = true;
                    }
                    /**
                     * If page has requiredScope then evaluate
                     * User must have at least one of any in the array
                     */
                    if (requiredScope && requiredScope.length === 0) {
                        isAuthorized = true;
                    } else {
                        _.forEach(requiredScope, (scope) => {
                        if (user.scopes.includes(scope)) {
                            isAuthorized = true;
                        }
                        });
                    }
                    /**
                     * Finally it is no matter if the user has any of the above, if the user is 
                     * not an "authenticatedUser" then they are not authorized.
                     */
                    if(!user.roles.includes('authenticatedUser')) isAuthorized = false;
                }else{
                    isAuthorized = true; 
                }
            }
            return resolve(isAuthorized);
            
        });
    };

    useEffect(() => {  
        if (user) {
            //console.log('AUTH GUARD CHECKING AUTHORIZATION') 
            checkAuth(children).then((result) => {
                setAuthorized(result);
                setChildrenChecked(true);
            });
        }else{
            if(authorized) setAuthorized(false);
        }
    }, [user, authorized]);

    return <RenderUI authorized={authorized} >{children}</RenderUI>;
    
};

export default AuthGuard;