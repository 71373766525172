import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/Auth/AuthProvider';
import { AccountContext } from '../../context/Account/AccountProvider';

import { LocaleContext } from '../../context/Locale/Locale';

import {useRouter} from "../../components/Router/Router";
import Loading from '../../components/Loaders/Loading';
import Iconv from '../../components/iconv';

import TblAccounts from '../../components/DataTables/accounts';
import AccountCard from '../../components/Cards/accountCard';
import _ from 'lodash'

import { 
    PROXY_CLIENT,
    capitalizeFirst
} from '../../libraries/utils';

const Accounts = (props) => {
    
    const navigate = useNavigate();
    const { lang, locale } = useContext(LocaleContext);
    const {user} = useContext(AuthContext);

    const {
        accounts,
        account,
        setAccount,
        createAccount,
        creatAccountModal
    } = useContext(AccountContext)

    const [mapIsOpen, setMapIsOpen] = useState(false)

    const accountChanged = useCompare(account);


    useEffect(  () => {


    }, [accounts, account]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        const isProcessing = () => {
            if (!accounts || !accounts.length ) {
                return (<Fragment><Loading/></Fragment>)
            }
        }
        return (
            <Fragment>
{/**
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">CUSTOMERS</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item ">Apps</li>
                                    <li className="breadcrumb-item ">Customers</li>
                                    <li className="breadcrumb-item active"><b>List</b></li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>
**/}
                
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex align-items-center flex-wrap gap-2">
                                    <div className="flex-grow-1">
                                        <button className="btn btn-primary add-btn" onClick={() => creatAccountModal('standard') } ><i className="ri-add-fill me-1 align-bottom"></i> Add Account</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`col-${(account?.account_id)? '8':'12'}`}>
                        <div className="card" id="contactList">
                            <div className="card-body">
                                 <TblAccounts />
                                {isProcessing()}
                            </div>
                        </div>
                    </div>

                    <div className={`${(account?.account_id)? 'col-4':'hidden'}`}>
                        <AccountCard 
                            account={account}
                        />
                    </div>
                </div>

                

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

Accounts.requiredScope = ['account.view.own','account.view.any'];
Accounts.requireAuth = true;
export default Accounts;