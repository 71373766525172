import React, {Fragment, useState, useEffect, useContext, createContext, useRef } from 'react';
//import { PreferenceContext } from '../Preferences/Preference';
import da from './translations/da';
import fr from './translations/fr';
import it from './translations/it';
import en from './translations/en';
import de from './translations/de';
import { 
    getCookie,
    setCookie
 } from '../../libraries/utils';
import Cookie from "js-cookie";
export const LocaleContext = createContext();

const LocaleProvider = ({children}) => {

    const [lang, setTranslations] = useState(de); // This object holds the site translations
    const [locale, setLocale] = useState('de');
    const [localeAvailable, setLocaleAvailable] = useState(
        {
            da: {
                name: "Danich",
                flag: "dk"
            },
            
            de: {
                name: "Deutsch",
                flag: "de"
            },
        
            en: {
                name: "English",
                flag: "gb"
            },
        
            fr: {
                name: "Français",
                flag: "fr"
            },
            it: {
                name: "Italiano",
                flag: "it"
            },
        }
    );
    const localeChanged = useCompare(locale)
    const cache = new Map();
    /**
     * On first load
     */
    useEffect( () => {
        let cookieLang = getCookie('lang')
        //console.log('LOCALECONTEXT COOKIE', cookieLang)
        if(cookieLang) setLocale(cookieLang)
        else{
            setCookie('lang', 'de')
            setLocale('de')
        }
    }, [])
    /**
     * after first load only refresh if locale changed
     */
    useEffect( () => {
        //console.log('LOCALE CHANGED', locale)
        if(localeChanged) setTranslationObject();
    }, [locale])

   
    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const setTranslationObject =  async () => {
        let translationObject = await import(`./translations/${locale}`);
        setTranslations(translationObject);
        return;
    }
    /**
     * Method for any language switchers in the UI
     */
    const setLang = async(l) => {
        if(localeAvailable[l]){
            Cookie.set('lang', l, { "httpOnly": false })
            setLocale(l);
        }
    }
    /**
     * <h4>{l('greeting', 'Hello, {name}!', { name: userName })}</h4>
     */
    const l = (path, fallback = '', replacements = {}) => {

        function replacePlaceholders(str, replacements) {
            return Object.keys(replacements).reduce(
              (result, placeholder) => result.replace(`{${placeholder}}`, replacements[placeholder]),
              str
            );
        }
        
        if (cache.has(path)) {
            const cachedValue = cache.get(path);
            return cachedValue ? replacePlaceholders(cachedValue, replacements) : fallback;
        }

        const value = path.split('.').reduce((obj, keyPart) => obj?.[keyPart], lang);
        cache.set(path, value);
        return value ? replacePlaceholders(value, replacements) : fallback;
    }

    const params = {
        locale,
        localeAvailable,
        lang,
        setLang,
        l
    }

    return (
        <LocaleContext.Provider value={params}>
            {children}
        </LocaleContext.Provider>
    )
}

export default LocaleProvider;