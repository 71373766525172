const { HeatmapLayer } = require("@react-google-maps/api");

const lang = {
    login: {
        common: {
            forgot_password: 'Forgot password?',
            login: 'Login',
            no_account: "Don't have an account?",
            no_account2: 'Register here',
            password: 'Password'
        },
        login: {
            title: 'Sign Into Your Account',
            submit: 'Login'
        },
        pwl_login: {
            title: 'Sign Into Your Account',
            submit: 'Request One Time Use Password (OTP)'
        },
        pwl_passwd: {
            title: "Enter your OTP",
            description: "A one time use password was sent to your registered email address or to your chat application.",
            submit: 'Login'
        },
        twofa: {
            title: 'This account requires Two Factor Authentication',
            submit: 'Verify Token'
        },
        /**
         * Error Pages
         */
        error: {
            "404": {
                h1: "Oh mannn..",
                message: "This page page not exist !",
                goback: `...but no worries, just go <a class="text-primary" href="/">back to home</a> and try again.`
            },
            "401": {
                h1: "Oh mannn..",
                message: "You are probably not authorized to do what you are trying to do.",
                goback: `...but no worries, just go <a class="text-primary" href="/">back to home</a> and not try again.`
            },
        },
        /**
         * Error messages for UI feedback
         */
        errorMessages: {
            invalidCredentials: 'INVALID USER CREDENTIALS',
            error304: 'ERROR: 304',
            emailVerificationSent: 'An email verification code has been sent to your registered email address. Once you verify your email address, your account will be active.',
            emailUnverified: 'Your email was not verified. Please try again.',
            emailVerified: 'Your email has been verified. You may now use it to login.',
            emailExists: 'An account using that email address already exists. Please try to login instead.',
            registrationNotAllowed: `This client does not allow user registration.`,
            somethingWrong: 'SOMETHING WENT WRONG'
        },

        home: {
            header: {
                home: "Home",
                features: "Features",
                about: "About"
            },
            hero: {
                subtitle: "Get instant User Authentication and Identification without setup",
                login: "login"
            },
            features: {
                f1: {
                    "title": "EU-Based Data Compliance",
                    "body": "Experience the peace of mind with our GDPR-compliant services, powered by servers strategically located in Germany for optimal data protection and privacy."
                },
                f2: {
                    "title": "Advanced Encryption Standards",
                    "body": "Secure every bit of personal data with our state-of-the-art encryption, safeguarding information both in transit and at rest, enhanced with the latest password hashing technologies for unrivaled security."
                },
                f3: {
                    "title": "Flexible Multifactor Authentication",
                    "body": "Empower your security with customizable Multifactor Authentication (MFA). Choose between optional or mandatory MFA settings to balance convenience and protection perfectly."
                },
                f4: {
                    "title": "Versatile Login Options",
                    "body": "Offer your users the convenience of traditional username/password login or the simplicity of password-free, OTP (One-Time Password) login for a seamless authentication experience."
                },
                f5: {
                    "title": "Stringent Password Policies",
                    "body": "Elevate security with our robust password policies: enforce zero password reuse, implement rigorous password requirements, and easily manage password resets, eliminating the 'Forgot Password' vulnerability."
                },
                f6: {
                    "title": "Controlled Remote Persistence",
                    "body": "Gain complete control over authentication persistence. Manage user sessions effortlessly with local cookies and remote authorization cancellation capabilities for enhanced security."
                },
                f7: {
                    "title": "Flexible Integration: API or Relay",
                    "body": "Integrate seamlessly using our standard, relayable login interface compatible with Whitelabel solutions, or customize your login experience with our versatile API."
                },
                f8: {
                    "title": "Cutting-Edge OAUTH2 with PKCE",
                    "body": "Leverage the latest in OAuth2 standards with PKCE methods, ensuring the utmost security. Whether using client secrets or PKCE, trust in our rigorous verification processes."
                },
                f9: {
                    "title": "Scalable Solutions",
                    "body": "Grow without limits with IRONAUTH3. Our scalable solution adapts to your expanding customer base effortlessly, ensuring no limits to your growth at no additional cost."
                }
            },
            "about": {
                "ab1": {
                    "title": "Unwavering Commitment to Data Privacy",
                    "body": "At the heart of our service is a staunch commitment to your data sovereignty. We neither sell nor monitor your user information, ensuring you retain exclusive control. Plus, we empower you with the ability to download your entire user database, facilitating effortless data transfers when needed."
                },
                "ab2": {
                    "title": "Device-Agnostic Workflow Integration",
                    "body": "IronAuth seamlessly integrates with all modern devices, offering a user-friendly setup and execution process. Tailor your user experience with our adaptable API or utilize our efficient built-in workflow to suit your unique requirements."
                }
            }  
        }
    },
    currency: {
        symbol: "€",
        label: "EUR"
    },
    lang: {
        language: "Language",
        english: "English",
        german: "German"
    },
    user: {
        profile: "Account",
    },
    navbar: {
        greeting: 'Welcome',
        profile: 'Profile',
        messages: 'Messages',
        taskboard: 'Taskboard',
        help: 'Help',
        login: "Login",
        logout: "Logout",
        dashboard: "Dashboard",
        register: "Register"
    },
    dataTables: {
        loading: "Loading ..."
    },
    swal: {
        titles:{
            "Are you sure": "Are you sure?"
        },
        texts: {
            shortLinkDelete: "Do you really want to delete short link: "
        },
        buttons: {
            confirmButtonText1: "Yes",
            cancelButtonText1: "Cancel"
        }
    },
    context: {},
    components: {
        badges: {
            new: 'New'
        },
        widgets: {
            showWidget: 'show',
            accountSwitcher:{
                placeholder1: 'Choose an account...',
                placeholder2: 'Create an account ->->->'
            },
            accounts: {
                title: 'Accounts',
                link: 'Accounts Manager'
            },
            premises: {
                title: 'Premises',
                link: 'Premise Manager'
            },
            sensors: {
                title: 'Sensors',
                link: 'Sensor Manager'
            },
            gateways: {
                title: 'Gateways',
                link: 'Gateway Manager'
            },
            tenants: {
                title: 'Tenants',
                link: 'Tenant Manager'
            }
        }
    },
    pages: {
        overview: {
            pageTitle: 'Overview',
            tabs:{
                accounts: 'Accounts',
                premises: 'Premises',
                tenants: 'Tenants',
                sensors: 'Sensors',
                gateways: 'Gateways',
                members: 'Members',
                manager: 'Manager', //used in the widgets
            }
        },
        accountWizard: {
            title: "Choose the plan that's right for you",
            subtitle: `Simple pricing. <b className="text-primary">No hidden fees.</b> Advanced features for your business.`,
            howItWorks_p: `How It Works:`,
            howItWorks_ul: `
                <li>You may have as many accounts as you like.</li>
                <li>Each account is billed independently.</li>
                <li>Billing occurs at the end of each 30-day period, and the first 30 days are complimentary!</li>
                <li>Billing is based on the number of accounts.</li>
                <li><span className="text-primary">No Contracts</span> or obligations required.</li>
                <li>If our service doesn't meet your needs, simply refrain from payment, and your data will expire (data recovery is not available once it expires).</li>
                <li>Account members will have full "client premissions" access to all account data in the Client Application. For example: If you make an account for "Lucky Immobilien GmbH" and later add Frau Schmetterling as a user, this user will see all sensors, gateways, bucket and tenant data in addition to access to all reports.</li>
            `,
            unlimited: 'Unlimited'
        }
    },
    

    
}

module.exports = lang;
