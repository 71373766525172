import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, useLocation } from "react-router-dom";


import { AuthContext } from '../../context/Auth/AuthProvider';
import { AccountContext } from '../../context/Account/AccountProvider';
import { SensorContext } from '../../context/Sensor/SensorProvider';

import { LocaleContext } from '../../context/Locale/Locale';
import { TenantContext } from '../../context/Tenant/TenantProvider';
import { BucketContext } from '../../context/Bucket/BucketProvider';

import Loading from '../../components/Loaders/Loading';
import Iconv from '../../components/iconv';
import ConsumptionChart from '../../components/Charts/consumptionChart';
import TblBuckets from '../../components/DataTables/buckets';
import TblSensors from '../../components/DataTables/sensors';
import BucketMap from '../../components/Maps/bucket';
import Modal from 'react-modal';
import _ from 'lodash'

import { 
    PROXY_CLIENT,
    capitalizeFirst
 } from '../../libraries/utils';
import Swal from '@nmesys/sweetalert2'

import de from '../../components/DataTables/datatable.de.json';
const $ = require('jquery')
$.DataTable = require('datatables.net');


const BucketDetails = (props) => {

    const navigate = useNavigate()
    const { lang, locale } = useContext(LocaleContext);
    const {
        bucket, 
        buckets, 
        setBucket, 
        addNewBucketModal, 
        addApartmentsModal,
        deleteBucketModal
    } = useContext(BucketContext);

    const {sensors, setSensor, sensor} = useContext(SensorContext);

    const [bucketSensors, setBucketSensors] = useState([]);
    const [bucketGateways, setBucketGateways] = useState([]);
    const [childBuckets, setChildBuckets] = useState([]);
    const [bucketTenants, setBucketTenants] = useState([]);
    const [curTenants, setCurTenants] = useState([]);


    const [mapIsOpen, setMapIsOpen] = useState(false);

    const {tenant, tenants, getTenant} = useContext(TenantContext);
        
    const bucketsChanged = useCompare(buckets);
    const bucketChanged = useCompare(bucket);
    const bucketSensorsChanged = useCompare(bucketSensors);
    const childBucketsChanged = useCompare(childBuckets);

    useEffect(  () => {

        if(bucketChanged && !!bucket && !!bucket.bucket_id){ console.log('PREMISE DETAILS', bucket); resolveData()}
        if(bucketSensorsChanged) initializeSensorDT();
        if(childBucketsChanged) initializeApartmentsDT();

    }, [bucketsChanged, bucketChanged, bucket, buckets, bucketSensors, bucketGateways, childBuckets, bucketTenants]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const resolveData = () => {
        if(!bucket || !bucket.bucket_id) return;

        const pSensors = _.filter(sensors, s => s.bucket_id === bucket.bucket_id)
        setBucketSensors(pSensors);

        const pGateways = []
        setBucketGateways(pGateways);

        let pTenants = [];
        setBucketTenants(pTenants);

        const pChildren = _.filter(buckets, p => !!p.parent_id && p.parent_id === bucket.bucket_id)
        setChildBuckets(pChildren)
         
    }

    const showMap = () => {
        setMapIsOpen(true)
    }
    const closeMap = () => {
        setMapIsOpen(false)
    }


    const initializeSensorDT = async () => { 
        let table;
        if ($.fn.dataTable.isDataTable(`#tblBucketSensors`)) {
            try{
                $(`#tblBucketSensors`).DataTable().destroy();
                // remove the eventhandler to prevent multiple events 
                $(`#tblBucketSensors tbody`).off('click')
                $(`#tblBucketSensors tbody`).off('mouseenter')
                $(`#tblBucketSensors tbody`).off('mouseleave')
                initializeSensorDT()
            }catch(err){console.log(err)}
        } else {
            table = $(`#tblBucketSensors`).DataTable({
                language: locale,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: bucketSensors,
                rowId: "serial_number",
                pageLength: 100,
                orderCellsTop: false,
                fixedHeader: true,
                order: [
                    [3, "asc"]
                ],
                columns: [
                    
                    {
                        "data": null,
                        "searchable": false,
                        "visible": true,
                        "ordering": false,
                        render: function (data, type, row) {
                            /**
                             * MUST be identical to ICONV Icons
                             */
                            const icons = {
                                "0x4": {
                                    standard: "text-warning fa-solid fa-fire",
                                    wsr: "fa-solid fa-droplet text-danger",
                                    hzg: "fa-solid fa-droplet text-fire",
                                },
                                "0x7": {
                                    standard: "fa-solid fa-droplet text-primary"
                                },
                                "0x8": { 
                                    standard: "fa-solid fa-fire text-primary"
                                },
                                "0x6": { 
                                    standard: "fa-solid fa-droplet text-warning"
                                },
                                "0xc": {
                                    standard: "text-warning fa-solid fa-fire",
                                    wsr: "fa-solid fa-droplet text-danger",
                                    hzg: "fa-solid fa-droplet text-fire",
                                }
                            }
                            let iconType = "standard";
                            if(data.attributes.includes('wsr')) iconType = 'wsr';
                            if(data.attributes.includes('hzg')) iconType = 'hzg';
                            let icon = icons[data.medium_code][iconType];
                            let html = `<i class="ps-2 ${icon}"></i>`
                            return html
                            
                        }
                    },
                    {
                        "data": "attributes",
                        "searchable": true,
                        "visible": false,
                        "ordering": false,
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return data.serial_number
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return data.type
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(!!data.state && !!data.state.stand){
                                return new Date(data.state.date).toLocaleDateString('de-DE', { year:"numeric", month:"short", day:"numeric"})
                            }
                            return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(!!data.state && !!data.state.stand){
                                return data.state.stand[data.unit_of_measure.toLowerCase()].toFixed(2)
                            }
                            return ''
                        }
                    }
                ],
                createdRow: function (row, data, dataIndex) {},
                createdCell:function (td, cellData, rowData, row, col) {},
                "fnDrawCallback": function( oSettings ) { }
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $(`#tblBucketSensors tbody tr`).off('mouseenter').on('mouseenter', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });

            $(`#tblBucketSensors tbody tr`).off('mouseleave').on('mouseleave', function () {
                $(this).css('background-color', 'transparent');
            });

            $(`#tblBucketSensors tbody`).on('click', 'tr', function (e) {
                let parent = e.currentTarget.closest('tr');
                let target = bucketSensors.filter(s => s.serial_number === parent.id);
                setSensor(target[0])
            });
        }  
    }

    const initializeApartmentsDT = async () => {
        
        let table;
        if ($.fn.dataTable.isDataTable(`#tblChildBuckets`)) {
            try{
                $(`#tblChildBuckets`).DataTable().destroy();
                // remove the eventhandler to prevent multiple events 
                $(`#tblChildBuckets tbody`).off('click')
                $(`#tblChildBuckets tbody`).off('mouseenter')
                $(`#tblChildBuckets tbody`).off('mouseleave')
                initializeApartmentsDT()
            }catch(err){console.log(err)}
        } else {
            table = $(`#tblChildBuckets`).DataTable({
                language: locale,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: childBuckets,
                rowId: "bucket_id",
                pageLength: 100,
                orderCellsTop: false,
                fixedHeader: true,
                order: [
                    [5, "asc"]
                ],
                columns: [
                    {
                        data: "bucket_id",
                        searchable: true,
                        visible: false
                    },
                    {
                        data: null,
                        searchable: true,
                        visible: false,
                        render: function (data, type, row) {
                            if(!!data.parent_id) return  data.parent_id;
                            return '';
                        }
                    },
                    {
                        "data": null,
                        "searchable": false,
                        "visible": true,
                        "ordering": false,
                        render: function (data, type, row) {
                            if(!!data.type && data.type === 'multi') return ( '<i title="Multi Bucket" class="fa-solid fa-building text-primary" />' );
                            else return ( '<i title="Multi Bucket" class="fa-solid fa-house text-info" />' );
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return data.address.street
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return data.address.house_number
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return `${(data.address.street1)? data.address.street1: ''}`
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return `${(data.address.city)? data.address.city: ''}`
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return `${(data.address.province)? data.address.province: ''}`
                        }
                    },{
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return `${(data.address.post_code)? data.address.post_code: ''}`
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": false,
                        "ordering": true,
                        render: function (data, type, row) {
                            return `${(data.address.country)? data.address.country: ''}`
                        }
                    },
                    {
                        data: "type",
                        searchable: true,
                        visible: false
                    },
                ],
                createdRow: function (row, data, dataIndex) {},
                "fnDrawCallback": function( oSettings ) { }
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            

            $(`#tblChildBuckets tbody tr`).off('mouseenter').on('mouseenter', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });

            $(`#tblChildBuckets tbody tr`).off('mouseleave').on('mouseleave', function () {
                $(this).css('background-color', 'transparent');
            });

            $(`#tblChildBuckets tbody`).on('click', 'tr', function (e) {
                let parent = e.currentTarget.closest('tr');
                let target = buckets.filter(p => p.bucket_id === parent.id);
                setBucket(target[0])
            });
        }  
    }



    const UI = () => {
        if(!lang || !bucket) return (<Fragment><Loading/></Fragment>)
        
        return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">PREMISE Details {!!bucket ? `(${bucket.bucket_id})`:'' }</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/dashboards/overview">Dashboard</Link></li>
                                    <li className="breadcrumb-item active"><b>Premise Details</b></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                {/** BEGIN HEADER SECTION */}
                                <section className="header">
                                    <div className="row gx-lg-5">
                                        <div className="col-xl-3 col-md-8 mx-auto ">
                                            <div className="d-flex justify-content-center mt-4">
                                                {
                                                    (!bucket?.parent_id || bucket.parent_id.length === 0)?
                                                        <Iconv name={`bucket.multi`} classList="text-primary" size="10"/>
                                                        :
                                                        <Iconv name="bucket.single" classList="text-primary" size="10"/>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </section>
                                {/** END HEADER SECTION */}




                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={mapIsOpen}
                    onRequestClose={closeMap}
                    style={{
                        content: {
                          top: '50%',
                          left: '50%',
                          right: 'auto',
                          bottom: 'auto',
                          marginRight: '-50%',
                          transform: 'translate(-50%, -50%)',
                          width: "50vw",
                          height: "50vh"
                        }
                    }}
                >
                    <BucketMap />
                </Modal>
            </Fragment>
            
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

BucketDetails.requiredScope = ['bucket.view.any'];
BucketDetails.requireAuth = true;
export default BucketDetails;