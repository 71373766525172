const lang = {
    login: {
        common: {
            forgot_password: 'Mot de passe oublié ?',
            login: 'Connexion',
            no_account: "Vous n'avez pas de compte ?",
            no_account2: 'Inscrivez-vous ici',
            password: 'Mot de passe'
        },
        login: {
            title: 'Connectez-vous à votre compte',
            submit: 'Connexion'
        },
        pwl_login: {
            title: 'Connectez-vous à votre compte',
            submit: 'Demander un mot de passe à usage unique (OTP)'
        },
        pwl_passwd: {
            title: "Entrez votre OTP",
            description: "Un mot de passe à usage unique a été envoyé à votre adresse e-mail enregistrée ou à votre application de chat.",
            submit: 'Connexion'
        },
        twofa: {
            title: 'Ce compte nécessite une authentification à deux facteurs',
            submit: 'Vérifier le jeton'
        },
        /**
         * Pages d'erreur
         */
        error: {
            "404": {
                h1: "Oh là là..",
                message: "Cette page n'existe pas !",
                goback: `...mais ne vous inquiétez pas, retournez simplement <a class="text-primary" href="/">à l'accueil</a> et essayez à nouveau.`
            },
            "401": {
                h1: "Oh là là..",
                message: "Vous n'êtes probablement pas autorisé à faire ce que vous essayez de faire.",
                goback: `...mais ne vous inquiétez pas, retournez simplement <a class="text-primary" href="/">à l'accueil</a> et n'essayez pas à nouveau.`
            },
        },
        /**
         * Messages d'erreur pour les retours visuels
         */
        errorMessages: {
            invalidCredentials: 'IDENTIFIANTS INVALIDES',
            error304: 'ERREUR : 304',
            emailVerificationSent: 'Un code de vérification par courrier électronique a été envoyé à votre adresse e-mail enregistrée. Une fois votre adresse e-mail vérifiée, votre compte sera actif.',
            emailUnverified: 'Votre adresse e-mail n\'a pas été vérifiée. Veuillez réessayer.',
            emailVerified: "Votre adresse e-mail a été vérifiée. Vous pouvez maintenant l'utiliser pour vous connecter.",
            emailExists: 'Un compte utilisant cette adresse e-mail existe déjà. Veuillez essayer de vous connecter à la place.',
            registrationNotAllowed: 'Ce client n\'autorise pas l\'inscription des utilisateurs.',
            somethingWrong: 'QUELQUE CHOSE A MAL TOURNÉ'
        },
    
        home: {
            header: {
                home: "Accueil",
                features: "Fonctionnalités",
                about: "À propos"
            },
            hero: {
                subtitle: "Obtenez une authentification et une identification instantanées des utilisateurs sans configuration",
                login: "connexion"
            },
            features: {
                f1: {
                    "title": "Conformité aux données basée dans l'UE",
                    "body": "Profitez de la tranquillité d'esprit avec nos services conformes au RGPD, alimentés par des serveurs stratégiquement situés en Allemagne pour une protection et une confidentialité optimales des données."
                },
                f2: {
                    "title": "Normes de cryptage avancées",
                    "body": "Sécurisez chaque donnée personnelle avec notre cryptage de pointe, protégeant les informations à la fois en transit et au repos, renforcé par les dernières technologies de hachage de mots de passe pour une sécurité inégalée."
                },
                f3: {
                    "title": "Authentification multifactorielle flexible",
                    "body": "Renforcez votre sécurité avec une authentification multifactorielle (MFA) personnalisable. Choisissez entre des paramètres MFA optionnels ou obligatoires pour équilibrer parfaitement commodité et protection."
                },
                f4: {
                    "title": "Options de connexion polyvalentes",
                    "body": "Offrez à vos utilisateurs la commodité d'une connexion traditionnelle avec nom d'utilisateur/mot de passe ou la simplicité d'une connexion sans mot de passe, OTP (mot de passe à usage unique) pour une expérience d'authentification fluide."
                },
                f5: {
                    "title": "Politiques de mot de passe strictes",
                    "body": "Rehaussez la sécurité avec nos politiques de mot de passe robustes : appliquez une interdiction totale de réutilisation des mots de passe, mettez en place des exigences rigoureuses pour les mots de passe, et gérez facilement les réinitialisations de mot de passe, éliminant la vulnérabilité du 'Mot de passe oublié'."
                },
                f6: {
                    "title": "Persistance à distance contrôlée",
                    "body": "Prenez un contrôle complet sur la persistance de l'authentification. Gérez les sessions utilisateurs sans effort avec des cookies locaux et des capacités d'annulation d'autorisation à distance pour une sécurité renforcée."
                },
                f7: {
                    "title": "Intégration flexible : API ou Relais",
                    "body": "Intégrez-vous facilement en utilisant notre interface de connexion standard, relayable, compatible avec les solutions Whitelabel, ou personnalisez votre expérience de connexion avec notre API polyvalente."
                },
                f8: {
                    "title": "OAUTH2 de pointe avec PKCE",
                    "body": "Tirez parti des dernières normes OAuth2 avec les méthodes PKCE, garantissant une sécurité maximale. Que vous utilisiez des secrets clients ou PKCE, faites confiance à nos processus de vérification rigoureux."
                },
                f9: {
                    "title": "Solutions évolutives",
                    "body": "Développez-vous sans limites avec IRONAUTH3. Notre solution évolutive s'adapte sans effort à votre base de clients en expansion, assurant aucune limite à votre croissance sans coût supplémentaire."
                }
            },
            "about": {
                "ab1": {
                    "title": "Engagement inébranlable envers la confidentialité des données",
                    "body": "Au cœur de notre service se trouve un engagement ferme envers votre souveraineté des données. Nous ne vendons ni ne surveillons vos informations utilisateur, vous assurant un contrôle exclusif. De plus, nous vous donnons la possibilité de télécharger l'intégralité de votre base de données utilisateur, facilitant les transferts de données sans effort lorsque nécessaire."
                },
                "ab2": {
                    "title": "Intégration de flux de travail indépendante du dispositif",
                    "body": "IronAuth s'intègre de manière transparente avec tous les appareils modernes, offrant un processus de configuration et d'exécution convivial. Adaptez votre expérience utilisateur avec notre API adaptable ou utilisez notre flux de travail intégré efficace pour répondre à vos besoins uniques."
                }
            }  
        }
    },
    currency: {
        symbol: "€",
        label: "EUR"
    },
    lang: {
        language: "Language",
        english: "English",
        german: "German"
    },
    user: {
        profile: "Account",
    },
    navbar: {
        login: "Login",
        logout: "Logout",
        dashboard: "Dashboard",
        register: "Register"
    },
    dataTables: {
        loading: "Loading ..."
    },
    swal: {
        titles:{
            "Are you sure": "Are you sure?"
        },
        texts: {
            shortLinkDelete: "Do you really want to delete short link: "
        },
        buttons: {
            confirmButtonText1: "Yes",
            cancelButtonText1: "Cancel"
        }
    }

    
}

module.exports = lang;
