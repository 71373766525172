import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation } from "react-router-dom";
import { uniqueId } from 'lodash';
import { LocaleContext } from '../../../context/Locale/Locale';
import { GatewayContext } from '../../../context/Gateway/GatewayProvider';
import { BucketContext } from '../../../context/Bucket/BucketProvider';

import Iconv from '../../iconv';
//import { PreferenceContext } from '../../../context/Preferences/Preference';
import _ from 'lodash'


const CountGatewaysWidget = (props) => {

    const { lang, locale, l } = useContext(LocaleContext);
//    const {preferences, hideWidget} = useContext(PreferenceContext);
    const {gateways} = useContext(GatewayContext);

    useEffect(  () => {

    }, [gateways, lang, locale]);

    const UI = () => {
        if(!lang) return <Fragment></Fragment>
        return (
            <Fragment>
                <div className={`col-xl-${(props?.block?.width)? props.block.width:'3'} col-md-6`}>
                    <div className="card card-animate">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{l('components.widgets.gateways.title', 'Gateways')}</p>
                                </div>
                                <div className="flex-shrink-0">
                                    <span className="dropdown text-secondary  mb-0">
                                        <a className="dropdown-btn fa fa-solid fa-ellipsis-vertical fs-13 align-middle ps-2 pe-2" data-bs-toggle="dropdown" aria-expanded="false"></a>
                                        <div className="dropdown-menu dropdown-menu-end " data-popper-placement="bottom-end" style={{zIndex: 5000}}>
                                            <a className="dropdown-item">
                                                <div className="form-check">
                                                    <input type="checkbox" id={props?.block?.component? props.block.component:'countGatewaysWidget'} className="form-check-input" checked onChange={(e) => {}}/>
                                                    <label className="form-check-label" >{l('components.widgets.showWidget', 'Show')}</label>
                                                </div>
                                            </a>
                                        </div>
                                    </span>
                                    
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                        <span className="counter-value" >
                                            <span title="Account Gateways">{(!!gateways)? gateways.length : 0}</span>
                                        </span>
                                    </h4>
                                    <a className="text-decoration-underline text-muted" onClick={() => document.getElementById('tabGateways').click()} >{l('components.widgets.gateways.link', 'Gateway Manager')}</a>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className={`avatar-title bg-soft-${(gateways.length > 0)?'success':'danger'} rounded fs-3`}>
                                        <Iconv name="apps.gateway" classList={`text-${(gateways.length > 0)?'success':'danger'} `}/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default CountGatewaysWidget;