import React, {createContext, Fragment, useContext, useEffect, useRef, useState, useMemo} from 'react';
import { AccountContext } from '../Account/AccountProvider';
import Swal from '@nmesys/sweetalert2'
import {
    errorToast,
    successToast,
    PROXY_CLIENT
} from '../../libraries/utils';
import _ from 'lodash';
import { AddressContext } from '../Address/AddressProvider';

export const BucketContext = createContext();

const BucketProvider = ({children}) => {

    const {account} = useContext(AccountContext);
    const [bucket, setBucket] = useState({});
    const [buckets, setBuckets] = useState([]);
    const [bucketsFiltered, setBucketFilter] = useState(buckets);

    const {initialLoadComplete, addresses, address} = useContext(AddressContext);
    
    const accountChanged = useCompare(account);
    const bucketsChanged = useCompare(buckets);
    const filterChanged = useCompare(bucketsFiltered);
    const initialLoadCompleteChanged = useCompare(initialLoadComplete)
    const addressesChanged = useCompare(addresses)

 
    useEffect( () => {
        if( accountChanged && account?.account_id ) getBuckets()
    }, [account])

    useEffect( () => {
        if( initialLoadCompleteChanged || addressesChanged) getBuckets()
    }, [initialLoadComplete, addresses])

    useEffect( () => {
        if(bucketsChanged === true) {
            if(buckets?.[0]){
                setBucket(buckets[0])
            }
            else setBucket({});
        
            setBucketFilter(buckets);
        }
    }, [buckets])

    useEffect( () => {
        if(filterChanged === true && bucketsFiltered.length){
            let multi = _.find(bucketsFiltered, {"type": "multi"})
            if(multi?.bucket_id) setBucket(multi);
        }
    }, [bucketsFiltered])

    useEffect( () => {}, [bucket])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    /**
     * When buckets are retrieved recursively these are built as parent child
     * relationships.  For best use, we want to flatten that array in to a single array,
     * while allowing us to use the bucket.depth and the parent_id to visually
     * show heirachy.
     */
    const flattenBuckets = (recursiveBuckets) => {
        if(!recursiveBuckets || recursiveBuckets.length === 0) return 
        const flatArray = [];
        const flatten = (obj) => {
            let objWithoutChildren = {...obj}
            delete objWithoutChildren.children;
            flatArray.push(objWithoutChildren);
            if (obj.children && obj.children.length > 0) {
                obj.children.forEach(child => flatten(child));
            }
        };

        for(let obj of recursiveBuckets){
            flatten(obj)
        }
        
        for(const obj of flatArray){
            if(obj.address_id){
                let address = _.find(addresses, {address_id:obj.address_id});
                if(!!address) obj.address = address
            }
        }
        return flatArray;
    };

    const getBucket = async (bucket_id) => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/bucket/?bucket_id=${bucket_id}`)
        .then( response => {
            let obj = response.data;
            setBucket(obj)
            return obj;
        })
        .catch( err => {
            console.log('BUCKET FETCHING ERROR 48', err)
         //   throw new Error(err)
        })
    }

    const getBuckets = async () => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/bucket/all?account_id=${account.account_id}&recursive=true`)
        .then( response => {
            if(response.data.length > 0) {
                const flatArray = flattenBuckets(response.data)
                setBuckets(flatArray)
            }else{
                setBuckets([])
            }
            
        })
        .catch( err => {
            console.log('BUCKETS FETCHING ERROR 70', err)
        //    throw new Error(err)
        })
    }

    const addNewBucketModal = () => {
        Swal.fire({
            title: 'Add a new Bucket',
            width: "50vw",
            icon: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Save',
            html: 
                `<div class="overflow-hidden">` +
                    '<input class="form-control mb-1" type"text" id="external_reference_id" placeholder="Reference ID"/>' +
                    `<div class="row">
                        <div class="col-6"><input class="form-control mb-1" type"text" id="street" placeholder="Street*" required/></div>
                        <div class="col-3"><input class="form-control mb-1" type"text" id="house_number" placeholder="House Number*" required/></div>
                        <div class="col-3"><input class="form-control mb-1" type"text" id="street1" placeholder="Apt Number"/></div>
                    </div>` +
                    `<div class="row">
                        <div class="col-6"><input class="form-control mb-1" type"text" id="city" placeholder="City*" required /></div>
                        <div class="col-6"><input class="form-control mb-1" type"text" id="province" placeholder="Province*" required /></div>
                    </div>` +
                    '<input class="form-control mb-1" type"text" id="post_code" placeholder="Post Code*" required />' +
                    `
                    <select class="form-select mb-1" id="country" aria-label="Address Country">
                        <option selected value="de">Deutschland</option>
                        <option value="dk">Denmark</option>
                        <option value="fr">France</option>
                        <option value="it">Italy</option>
                        <option value="gb">United Kingdom</option>
                    </select>
                    ` +
                    `<div class="row">
                        <div class="col-6"><input class="form-control mb-1" type"number" id="year_built" placeholder="Build Year" min="1900" /></div>
                        <div class="col-6"><input class="form-control mb-1" type"number" id="living_space" placeholder="SQM"/></div>
                    </div>` +
                `</div>`
            ,
            preConfirm: (inputValue) => {
                const params = {
                    account_id: account.account_id,
                    external_reference_id: Swal.getPopup().querySelector('#external_reference_id').value,
                    address: {
                        street: Swal.getPopup().querySelector('#street').value,
                        house_number: Swal.getPopup().querySelector('#house_number').value,
                        street1: Swal.getPopup().querySelector('#street1').value,
                        city: Swal.getPopup().querySelector('#city').value,
                        province: Swal.getPopup().querySelector('#province').value,
                        post_code: Swal.getPopup().querySelector('#post_code').value,
                        country: Swal.getPopup().querySelector('#country').value
                    },
                    year_built: Swal.getPopup().querySelector('#year_built').value || undefined,
                    living_space: Swal.getPopup().querySelector('#living_space').value || undefined
                }
                /** 
                for(let item of Object.entries(params)){
                    if(!['external_reference_id','year_built', 'living_space', 'street1'].includes(item[0]) && item[1] === ''){
                        Swal.showValidationMessage(`Bitte das Formular vollständig ausfüllen`)
                    }
                }
                **/
                return params;
            }
        }).then(results => {
            if (results.isConfirmed) {
                PROXY_CLIENT().post(`${process.env.REACT_APP_API}/bucket/`, results.value)
                .then( response => {
                    getBuckets()
                    successToast('Your new Bucket has been created!')
                    return;
                })
                .catch( err => {
                    console.log('BUCKET CREATION ERROR 70', err)
                    errorToast(err)
                })
            }
        })
    }

    const addApartmentsModal = () => {
        if(!bucket || (!!bucket.address.street1 && bucket.address.street1.length !== 0)){
            errorToast('A Bucket of type "multi" is required.')
            return;
        }
        Swal.fire({
            title: 'Add APT/Office (s)',
            width: "50vw",
            icon: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Create',
            html: 
                `<div class="overflow-hidden">` +
                    `<input class="form-control mb-1 hidden" type"text" id="parent_id" value="${bucket.bucket_id}" readonly />` +
                    '<input class="form-control mb-1" type"text" id="apts" placeholder="1, 1a, 1b, 2, 2a, 2b..."/>' +
                `</div>`
            ,
            preConfirm: (inputValue) => {
                const params = {
                    apts: Swal.getPopup().querySelector('#apts').value
                }
                for(let item of Object.entries(params)){
                    if(!['apts'].includes(item[0]) && item[1] === ''){
                        Swal.showValidationMessage(`Bitte das Formular vollständig ausfüllen`)
                    }
                }
                return params.apts;
            }
        }).then(results => {
            if (results.isConfirmed) {
                let aptArray = results.value.split(',');
                let promiseArray = [];
                for(let apt of aptArray){
                    let apartment = {
                        account_id: account.account_id,
                        type: 'single',
                        address: JSON.parse(JSON.stringify(bucket.address))
                    }
                    apartment.address.street1 = apt.trim();
                   
                    console.log('APARTMENT', apartment)
                    promiseArray.push( PROXY_CLIENT().post(`${process.env.REACT_APP_API}/bucket/`, apartment) )
                }
                Promise.all(promiseArray)
                .then(response => {
                    getBuckets()
                    successToast('Your new Bucket has been created!')
                })
                .catch( err => {
                    console.log('BUCKET CREATION ERROR 70', err)
                    errorToast(err)
                })
            }
        })
    }

    const deleteBucketModal = () => {
        if(!bucket) return
        let html = `<p>You are about to permanently delete the bucket located at:</p>` +
        `<b>${bucket.address.street} ${bucket.address.house_number} ${ (!!bucket.address.street1)? ', '+ bucket.address.street1 : '' }</b>` +
        `<p>Are you sure you want to proceed?</p>`;

        if(bucket.type === 'multi'){
            html = `<p>You are about to permanently delete the bucket located at:</p>` +
            `<b>${bucket.address.street} ${bucket.address.house_number} ${ (!!bucket.address.street1)? ', '+ bucket.address.street1 : '' }</b>` +
            `<p><span class="text-danger">AND every apartment, office or space inside this Bucket.</span> Are you sure you want to proceed?</p>`;
        }

        Swal.fire({
            title: 'Delete this Bucket?',
            width: "50vw",
            icon: 'question',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Submit',
            html
        }).then(results => {
            if (results.isConfirmed) {
                PROXY_CLIENT().delete(`${process.env.REACT_APP_API}/bucket?bucket_id=${bucket.bucket_id}`)
                .then( response => {
                    getBuckets()
                    successToast('Your new Bucket has been deleted!')
                    return;
                })
                .catch( err => {
                    console.log('BUCKET DELETION ERROR 238', err)
                    errorToast(err)
                })
            }
        })
    }

    const addressBuilder = (obj, formatted = false) => {
        if(obj?.address){
            if(!formatted) return `${obj.address.street} ${obj.address.house_number} ${ obj.address?.street1 ? ', ME '+ obj.address.street1 : ''  }, ${obj.address.post_code} ${obj.address.city}, ${obj.address.country.toUpperCase()}`
            else {
                return (
                    `${obj.address.street} ${obj.address.house_number} ${ obj.address?.street1 ? ', ME '+ obj.address.street1 : ''  }\n ${obj.address.post_code} ${obj.address.city}\n ${obj.address.country.toUpperCase()}`
                    )
                }
        }
        return ''
    }
    /**
     * Filter buckets by group where the parent is the multi
     */
    const filterBucketByGroup = (bucket_id) => {
        if(!bucket_id || bucket_id === "") return setBucketFilter(buckets)
        const data = _.filter(buckets, p => (p.bucket_id === bucket_id || p.parent_id === bucket_id) )
        setBucketFilter(data)
    }

 

    const params = {
        bucket,
        setBucket,
        buckets: bucketsFiltered,
        getBucket,
        getBuckets,
        addNewBucketModal,
        addApartmentsModal,
        deleteBucketModal,
        addressBuilder,
    }

    return (
        <BucketContext.Provider value={params}>
            {children}
        </BucketContext.Provider>
    )
}

export default BucketProvider;