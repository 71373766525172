import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import Swal from '@nmesys/sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Iconv from '../../components/iconv';
import { GoogleMap, LoadScript, Marker, useLoadScript } from '@react-google-maps/api';

const BucketMap = (props) => {
  
  const { bucket, buckets, addressBuilder } = useContext(BucketContext);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
  });


  useEffect(() => {}, [props])

  useEffect(() => {
    if (bucket && bucket.address && bucket.address.geolocation && bucket.address.geolocation.lat) {
      setCenter({ lat: bucket.address.geolocation.lat, lng: bucket.address.geolocation.lng });
    }
  }, [bucket]);

  const getBucketPosition = (bucket) => {
    if (bucket?.address?.geolocation?.lat) {
        return { lat: bucket.address.geolocation.lat, lng: bucket.address.geolocation.lng }
    }

  }

  const showMapModal = () => {
        if (!isLoaded) return;
        const MapSwal = withReactContent(Swal);
        MapSwal.fire({
            title: `<strong>${addressBuilder(bucket)}</strong>`,
            html: '<div id="map" class="bucket-map" style="width: 60vw; height: 60vh"></div>',
            showCloseButton: true,
            showConfirmButton: false,
            allowOutsideClick: true,
            width: 'auto',
            didOpen: () => {
            const mapDiv = document.getElementById('map');
            if (mapDiv) {
                const root = createRoot(mapDiv);
                root.render(
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        center={center}
                        zoom={19}
                        mapTypeId="satellite"
                    >
                    <Marker position={getBucketPosition(bucket)} />
                    </GoogleMap>
                );
            }
            }
        });
  };

  return (
    <Fragment>
      <a className="avatar-title bg-soft-primary text-primary fs-15 rounded" id="btnShowMapModal" onClick={showMapModal}>
        <Iconv name="bucket.location" size="2" classList="text-primary" />
      </a>
    </Fragment>
  );
};

export default BucketMap;