import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link } from "react-router-dom";
import { AuthContext } from '../../context/Auth/AuthProvider';
import { AddressContext } from '../../context/Address/AddressProvider';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import { TenantContext } from '../../context/Tenant/TenantProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import { SensorContext } from '../../context/Sensor/SensorProvider';
import { GatewayContext } from '../../context/Gateway/GatewayProvider';

import AddBucketModal from '../../components/Modals/addBucketModal';
import {useRouter} from "../../components/Router/Router";
import Loading from '../../components/Loaders/Loading';
import TblBuckets from '../../components/DataTables/buckets';
import _ from 'lodash'

import BucketCard from '../../components/Cards/bucketCard';
const $ = require('jquery')
$.DataTable = require('datatables.net');


const Buckets = (props) => {
    
    const router = useRouter();
    const { lang, locale } = useContext(LocaleContext);
    const {user} = useContext(AuthContext);
    const {
        bucket, 
        buckets, 
        addNewBucketModal, 
        addApartmentsModal,
        deleteBucketModal,
        addressBuilder
    } = useContext(BucketContext);
    const [showAddBucketModal, setModalShow] = useState(false)

    const {addresses} = useContext(AddressContext);

    const {
        tenant,
        tenants,
        createTenantModal,
        setTenant,
        curTenants, 
        setCurTenants
    } = useContext(TenantContext);

    const {addBulkSensorsModal, sensors} = useContext(SensorContext);
    const {addBulkGatewaysModal, gateways} = useContext(GatewayContext);

    const [bucketSensors, setBucketSensors] = useState(0)
    const [bucketGateways, setBucketGateways] = useState(0)
    const [bucketTenants, setBucketTenants] = useState(0)

    const [mapIsOpen, setMapIsOpen] = useState(false)
    const bucketsChanged = useCompare(buckets);
    const bucketChanged = useCompare(bucket);

    useEffect(  () => {}, [buckets])
    useEffect(  () => {
        if(bucketChanged && bucket?.bucket_id) {
            getCurTenants();
        }
    }, [bucket]);

    useEffect(  () => {}, [tenants, curTenants]);

    useEffect(  () => {}, [showAddBucketModal]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const getCurTenants = async () => {
        if(!bucket || !tenants.length === 0) return;
        const date = new Date().getTime();
        let objs = []
        const filterTenants = (bucket_id) => {
            let current = _.chain(tenants)
                .filter(obj => (obj.bucket_id === bucket_id ))
                .filter(obj => (!!obj.date_move_in && obj.date_move_in < date) )
                .filter(obj => (!obj.date_move_out || obj.date_move_out > date))
                .value();
            objs = objs.concat(current);
        }
       _.chain(buckets)
        .filter(obj => (obj.bucket_id === bucket.bucket_id || obj.parent_id === bucket.bucket_id))
        .map(obj => filterTenants(obj.bucket_id))
        .value();
        setCurTenants(objs)
    }
    
    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        const isProcessing = () => {
            if (!buckets || buckets.length === 0) {
                return (<Fragment><Loading/></Fragment>)
            }
        }
        return (
            <Fragment>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex align-items-center flex-wrap gap-2">
                                    <div className="flex-grow-1">
                                        <button className="btn btn-primary add-btn" onClick={ () => setModalShow(true) }><i className="ri-add-fill me-1 align-bottom"></i> Add Premise</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`col-xxl-${(!!buckets && buckets.length)? '8':'12'}`}>
                        <div className="card" >
                            <div className="card-body">
                                <div>
                                    <div className="table-responsive table-card mb-3">
                                        <TblBuckets /> 
                                    </div>
                                    {isProcessing()}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={`${(!!buckets && buckets.length > 0)? 'col-xxl-4':'hidden'}`}>
                        <BucketCard bucket={bucket}/>
                    </div>
                </div>

                <AddBucketModal 
                    showModal={showAddBucketModal}
                    closeModal={() => setModalShow(false)}
                    openModal={() => setModalShow(true)}
                />

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

Buckets.requiredScope = ['bucket.view.own','bucket.view.any'];
Buckets.requireAuth = true;
export default Buckets;