import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../context/Auth/AuthProvider';



const MembershipPlans = (props) => {
    
    const {user, login, logout, register} = useContext(AuthContext);
   
    useEffect(  () => {

    }, []);

   

    const UI = () => {

        return (
            <Fragment>

                <section className="section bg-light" id="plans">
                    <div className="bg-overlay bg-overlay-pattern"></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="text-center mb-5">
                                    <h3 className="mb-3 fw-semibold">Choose the plan that's right for you</h3>
                                    <p className="text-muted mb-4">Simple pricing. Transparency. No hidden fees. Advanced features for you business.</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="row gy-4">


                            <div className="col-lg-4">
                                <div className="card plan-box mb-0">
                                    <div className="card-body p-4 m-2">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h5 className="mb-1 fw-semibold">Basic Plan</h5>
                                                <p className="text-muted mb-0">Best For Startup or individual hand worker.</p>
                                            </div>
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-light rounded-circle text-primary">
                                                    <i className="ri-book-mark-line fs-20"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-4 text-center">
                                            <h1 className="month"><sup><small>€</small></sup><span className="ff-secondary fw-bold">50</span> <span className="fs-13 text-muted">/Month</span></h1>
                                            <h1 className="annual"><sup><small>€</small></sup><span className="ff-secondary fw-bold">550</span> <span className="fs-13 text-muted">/Year</span></h1>
                                        </div>
                                        <div>

                                            <ul className="list-unstyled text-muted vstack gap-3 ff-secondary">
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Up to <b>3</b> Kunden
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Up to <b>100</b> Buckets
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Up to <b>600</b> Zähler
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Up to <b>20</b> Gateways
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Up to <b>1</b> DB Admins
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Unlimited Sensor Alerts
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Unlimited Emails
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Unlimited Reports
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-danger me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>White Label </b>Dashboard
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-danger me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>White Label </b>Domain (Custom Domain)
                                                        </div>
                                                    </div>
                                                </li>


                                            </ul>

                                            <small>* Cancel anytime to the end of the month (free). If you paid ahead by the year, then we will refund you the difference of the months not used.</small>

                                            <div className="mt-4">
                                                <a className="btn btn-soft-success w-100" onClick={() => register()} >Get Started</a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4">
                                <div className="card plan-box mb-0 ribbon-box right">
                                    <div className="card-body p-4 m-2">
                                        <div className="ribbon-two ribbon-two-danger"><span>Popular</span></div>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h5 className="mb-1 fw-semibold">Pro Business</h5>
                                                <p className="text-muted mb-0">Best for Meßtech firms and Immo Managers</p>
                                            </div>
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-light rounded-circle text-primary">
                                                    <i className="ri-medal-fill fs-20"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-4 text-center">
                                            <h1 className="month"><sup><small>€</small></sup><span className="ff-secondary fw-bold">500</span> <span className="fs-13 text-muted">/Month</span></h1>
                                            <h1 className="annual"><sup><small>€</small></sup><span className="ff-secondary fw-bold">5.500</span> <span className="fs-13 text-muted">/Year</span></h1>
                                        </div>
                                        <div>

                                            <ul className="list-unstyled text-muted vstack gap-3 ff-secondary">
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                           <b>Unlimited</b> Kunden
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>Unlimited</b> Buckets
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>Unlimited</b> Zähler
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>Unlimited</b> Gateways
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Up to <b>5</b> DB Admins
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Unlimited Sensor Alerts
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Unlimited Emails
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Unlimited Reports
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-danger me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>White Label </b>Dashboard
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-danger me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>White Label </b>Domain (Custom Domain)
                                                        </div>
                                                    </div>
                                                </li>


                                            </ul>

                                            <small>* Cancel anytime to the end of the month (free). If you paid ahead by the year, then we will refund you the difference of the months not used.</small>

                                            <div className="mt-4">
                                                <a className="btn btn-soft-success w-100" onClick={() => register()} >Get Started</a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4">
                                <div className="card plan-box mb-0">
                                    <div className="card-body p-4 m-2">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h5 className="mb-1 fw-semibold">Platinum Plan</h5>
                                                <p className="text-muted mb-0">You are a professional Meßtech firm and want your own software platform.</p>
                                            </div>
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-light rounded-circle text-primary">
                                                    <i className="ri-stack-fill fs-20"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-4 text-center">
                                            <h1 className="month"><sup><small>€</small></sup><span className="ff-secondary fw-bold">1.000</span> <span className="fs-13 text-muted">/Month</span></h1>
                                            <h1 className="annual"><sup><small>€</small></sup><span className="ff-secondary fw-bold">11.000</span> <span className="fs-13 text-muted">/Year</span></h1>
                                        </div>

                                        <div>

                                            <ul className="list-unstyled text-muted vstack gap-3 ff-secondary">
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>Unlimited</b> Kunden
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>Unlimited</b> Buckets
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>Unlimited</b> Zähler
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>Unlimited</b> Gateways
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Up to <b>5</b> DB Admins
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Unlimited Sensor Alerts
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Unlimited Emails
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Unlimited Reports
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>White Label </b>Dashboard
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>White Label </b>Domain (Custom Domain)
                                                        </div>
                                                    </div>
                                                </li>


                                            </ul>

                                            <small>* Cancel anytime to the end of the month (free). If you paid ahead by the year, then we will refund you the difference of the months not used.</small>

                                            <div className="mt-4">
                                                <a className="btn btn-soft-success w-100" onClick={() => register()} >Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default MembershipPlans;