import React, {createContext, Fragment, useContext, useEffect, useRef, useState, useMemo} from 'react';
import {useRouter} from "../../components/Router/Router";
import { AuthContext } from '../Auth/AuthProvider';
import { AccountContext } from '../Account/AccountProvider';
import { BucketContext } from '../Bucket/BucketProvider';
import Swal from '@nmesys/sweetalert2'
import _ from 'lodash';

import {
    errorToast,
    successToast,
    PROXY_CLIENT
} from '../../libraries/utils';
import Iconv from '../../components/iconv';

export const SensorContext = createContext();

const SensorProvider = ({children}) => {

    const router = useRouter();
    const {user} = useContext(AuthContext);
    const { bucket, buckets } = useContext(BucketContext);
    const {account} = useContext(AccountContext)

    const [sensor, setSensor] = useState({});
    const [sensorEvents, setSensorEvents] = useState([]);

    const [sensors, setSensors] = useState([]);
    const [sensor_ids, setSensorIds] = useState([])
    const [sensorsFiltered, setSensorsFilter] = useState(sensors)

    const [alarms, setAlarms] = useState([]);
    const [alarmsPending, setAlarmsPending] = useState([]);
    const [measurements, setMeasurements] = useState(null);
    const [telegrams, setTelegrams] = useState(null)

    const [sensorSignalStrength, setSensorSignalStrength] = useState('none');
    const [bucketGrpFilter, setBucketGrpFilter] = useState(false);
    const [bucketFilter, setBucketFilter] = useState(false);

    const sensorChanged = useCompare(sensor);
    const sensorsChanged = useCompare(sensors);
    const bucketsChanged = useCompare(buckets);
    const bucketChanged = useCompare(bucket);
    const accountChanged = useCompare(account);

    const bucketFilterChanged = useCompare(bucketFilter);
    const bucketGrpFilterChanged = useCompare(bucketGrpFilter);


    useEffect( () => {
        if( accountChanged && account?.account_id ) getSensors(); 
    }, [account])

    useEffect( () => {
        if(sensorsChanged === true){
            if(sensors?.[0]) setSensor(sensors[0]);
            else setSensor({})
            let ids = []
            for(const obj of sensors){
                ids.push(obj.sensor_id)
            }
            setSensorIds(ids)
            setSensorsFilter(sensors)
        }
    }, [sensors, sensor_ids])

    useEffect( () => {
        if( 
            (bucketChanged && sensors.length && !!bucketFilter) ||
            (bucketFilterChanged && !!bucketFilter && sensors.length)
        ) {
            setBucketGrpFilter(false);
            let objs = _.filter(sensors, s => s.bucket_id === bucket.bucket_id);
            setSensorsFilter(objs);
            setSensor(objs[0])
        }

        if(
            (bucketsChanged && sensors.length && !!bucketGrpFilter) 
            || (bucketChanged && sensors.length && !!bucketGrpFilter)
            || (bucketGrpFilterChanged && !!bucketGrpFilter && sensors.length)
        ){
            setBucketFilter(false);

            let objs = [], bucket_ids, bIds = [];
            if(bucket?.bucket_id && (!bucket.parent_id || bucket.parent_id.length === 0)){ // is parent
                bIds.push(bucket.bucket_id)
                _.map(buckets, bkt => {
                    if(bkt.parent_id === bucket.bucket_id) bIds.push(bkt.bucket_id)
                })
                
            }else{ // child selected
                bIds.push(bucket.parent_id)
                _.map(buckets, bkt => {
                    if(bkt.parent_id === bucket.parent_id) bIds.push(bkt.bucket_id)
                })
            }
            objs = _.filter(sensors, s => _.includes(bIds, s.bucket_id));
            setSensorsFilter(objs);
            setSensor(objs[0])
        }

        if(
            (bucketFilterChanged && !bucketFilter && !bucketGrpFilter) ||
            (bucketGrpFilterChanged && !bucketGrpFilter && !bucketFilter)
            
        ){
            setSensorsFilter(sensors)
        }
    }, [bucket, buckets, bucketGrpFilter, bucketFilter])



     
    useEffect( () => {
        if(sensorChanged && sensor?.serial_number){
            getEvents({days: 3000});
        //    getAggregate();
        }
    }, [sensor])
    
   
    useEffect( () => {

        
    }, [sensorSignalStrength, sensorEvents])


    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const getSensor = async (serial_number) => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/sensor/?serial_number=${serial_number}`)
        .then( response => {
            setSensor(response.data)
            return response.data;
        })
        .catch( err => {
            console.log('SENSOR FETCHING ERROR 48', err)
            errorToast(err)
        })
    }

    const getEvents = async ({serial_number, end_date, start_date, days = 30} = {days: 90}) => {
        if(!sensor || !sensor?.serial_number) return;
        if(!serial_number) serial_number = sensor.serial_number;
        const date = new Date().getTime();
        /**
         * Instead of paginating the dates, lets just start off with getting the last 90 days instead.
         * This may need to be adjusted for sensors which have a higher frequency of data such as 5 min data
         */
        if(!end_date) end_date = date;
        if(!start_date) start_date = new Date(date - (days * 24 * 60 * 60 * 1000)).getTime();

        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/sensor/events?serial_number=${serial_number}&start_date=${start_date}&end_date=${end_date}`)
        .then( response => {
            //console.log('SENSOR EVENTS', response.data)
            if(!!response.data) setSensorEvents(response.data)
            return response.data;
        })
        .catch( err => {
            console.log('SENSOR FETCHING ERROR 48', err)
            errorToast(err)
        })
    }

    const getAggregate = async (serial_number = null) => {
        if(!sensor || !sensor?.serial_number) return;
        if(!serial_number) serial_number = sensor.serial_number;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/sensor/aggregate?serial_number=${serial_number}`)
        .then( response => {
          //  if(!!response.data) setSensorAggregate(response.data)
            return response.data;
        })
        .catch( err => {
            console.log('SENSOR FETCHING ERROR 48', err)
            errorToast(err)
        })
    }
    /**
     * Get account level sensors
     */
    const getSensors = async () => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/sensor/all?account_id=${account.account_id}&aggregate=true`)
        .then( response => {
            //console.log('SENSORS ALL RESPONSE', response.data)
            if(!!response.data) setSensors(response.data)
            else setSensors([])
            return;
        })
        .catch( err => {
            console.log('SENSORS FETCHING ERROR 70', err)
            errorToast(err)
        })
    }

    const addBulkSensorsModal = (bucket) => {
        if(!bucket || !bucket?.bucket_id){
            errorToast('A Bucket is required.')
            return;
        }
        Swal.fire({
            title: 'Add Bulk Sensor (s)',
            width: "50vw",
            icon: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Create',
            html: 
                `<p class="me2">You are adding sensors to this Bucket location. To add a sensor or sensors, simply enter the <b>Sensor Serial Number</b>. You can add one single sensor or you can add many. To add many sensors, simply add them delimited by a comma.</p>
                <div class="overflow-hidden">` +
                    '<input class="form-control mb-1" type"text" id="serial_numbers" placeholder="123456, 789012, 345678 ..."/>' +
                `</div>`
            ,
            preConfirm: (inputValue) => {
                const params = {
                    serial_numbers: Swal.getPopup().querySelector('#serial_numbers').value
                }
                for(let item of Object.entries(params)){
                    if(!['serial_numbers'].includes(item[0]) && item[1] === ''){
                        Swal.showValidationMessage(`Bitte das Formular vollständig ausfüllen`)
                    }
                }
                return params.serial_numbers;
            }
        }).then(async results => {
            if(results.isConfirmed) {
                let sensorArray = results.value.split(',');
                for(let serial_number of sensorArray){
                    let obj = {
                        account_id: bucket.account_id,
                        bucket_id: bucket.bucket_id,
                        serial_number,
                        active: true
                    }

                    console.log('BULK SENSOR', obj)
                    await createSensor(obj);
                }
            }
        })
    }

    const createSensor = async (obj) => {
        if(!obj?.account_id || !obj.bucket_id) return;
        PROXY_CLIENT().post(`${process.env.REACT_APP_API}/sensor/`, obj)
        .then( response => {
            getSensors()
            //successToast('Your new Sensor has been created!')
            return;
        })
        .catch( err => {
            console.log('SENSOR CREATION ERROR 70', err)
            errorToast(err)
        })

    }

    const addNewSensorModal = async () => {
        Swal.fire({
            title: 'Add a new Bucket',
            width: "50vw",
            icon: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Save',
            html: 
                `<div class="overflow-hidden">` +
                    '<input class="form-control mb-1" type"text" id="external_reference_id" placeholder="Reference ID"/>' +
                    `<div class="row">
                        <div class="col-6"><input class="form-control mb-1" type"text" id="street" placeholder="Street*" required/></div>
                        <div class="col-3"><input class="form-control mb-1" type"text" id="house_number" placeholder="House Number*" required/></div>
                        <div class="col-3"><input class="form-control mb-1" type"text" id="street1" placeholder="Apt Number"/></div>
                    </div>` +
                    `<div class="row">
                        <div class="col-6"><input class="form-control mb-1" type"text" id="city" placeholder="City*" required /></div>
                        <div class="col-6"><input class="form-control mb-1" type"text" id="province" placeholder="Provice*" required /></div>
                    </div>` +
                    '<input class="form-control mb-1" type"text" id="post_code" placeholder="Post Code*" required />' +
                    '<input class="form-control mb-1" type"text" id="country" placeholder="Country*" required />' +
                    `<div class="row">
                        <div class="col-6"><input class="form-control mb-1" type"number" id="year_built" placeholder="Build Year" min="1900" /></div>
                        <div class="col-6"><input class="form-control mb-1" type"number" id="living_space" placeholder="SQM"/></div>
                    </div>` +
                `</div>`
            ,
            preConfirm: (inputValue) => {
                const params = {
                    external_reference_id: Swal.getPopup().querySelector('#external_reference_id').value,
                    street: Swal.getPopup().querySelector('#street').value,
                    house_number: Swal.getPopup().querySelector('#house_number').value,
                    street1: Swal.getPopup().querySelector('#street1').value,
                    city: Swal.getPopup().querySelector('#city').value,
                    province: Swal.getPopup().querySelector('#province').value,
                    post_code: Swal.getPopup().querySelector('#post_code').value,
                    country: Swal.getPopup().querySelector('#country').value,
                    year_built: Swal.getPopup().querySelector('#year_built').value,
                    living_space: Swal.getPopup().querySelector('#living_space').value
                }
                for(let item of Object.entries(params)){
                    if(!['external_reference_id','year_built', 'living_space', 'street1'].includes(item[0]) && item[1] === ''){
                        Swal.showValidationMessage(`Bitte das Formular vollständig ausfüllen`)
                    }
                }
                return params;
            }
        }).then(results => {
            if (results.isConfirmed) {
                PROXY_CLIENT().post(`${process.env.REACT_APP_API}/bucket/`, results.value)
                .then( response => {
                    getSensors()
                    successToast('Your new Bucket has been created!')
                    return;
                })
                .catch( err => {
                    console.log('PREMISE CREATION ERROR 70', err)
                    errorToast(err)
                })
            }
        })
    }

    const deleteSensorModal = async () => {
        if(!bucket || !bucket?.bucket_id) return
        let html = `<p>You are about to permanently delete the bucket located at:</p>` +
        `<b>${bucket.address.street} ${bucket.address.house_number} ${ (!!bucket.address.street1)? ', '+ bucket.address.street1 : '' }</b>` +
        `<p>Are you sure you want to proceed?</p>`;

        if(bucket.type === 'multi'){
            html = `<p>You are about to permanently delete the bucket located at:</p>` +
            `<b>${bucket.address.street} ${bucket.address.house_number} ${ (!!bucket.address.street1)? ', '+ bucket.address.street1 : '' }</b>` +
            `<p><span class="text-danger">AND every apartment, office or space inside this Bucket.</span> Are you sure you want to proceed?</p>`;
        }

        Swal.fire({
            title: 'Delete this Bucket?',
            width: "50vw",
            icon: 'question',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Submit',
            html
        }).then(results => {
            if (results.isConfirmed) {
                PROXY_CLIENT().delete(`${process.env.REACT_APP_API}/bucket?bucket_id=${bucket.bucket_id}`)
                .then( response => {
                    getSensors()
                    successToast('Your new Bucket has been deleted!')
                    return;
                })
                .catch( err => {
                    console.log('PREMISE DELETION ERROR 238', err)
                    errorToast(err)
                })
            }
        })
    }

    const getAlarms = async () => {
        if(!sensor || !sensor?.serial_number) return;
        console.log('GETTING ALARMS')
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/alarm/all?topic_id=${sensor.serial_number}`)
        .then(response => {
            console.log(' ALARMS RESPONSE', response.data)
            setAlarms(response.data)
        })
        .catch(err => {
            //console.log(err)
        })
    }

    const deleteAlarm = async (alarm_id) => {
        PROXY_CLIENT().delete(`${process.env.REACT_APP_API}/alarm?topic_id=${sensor.serial_number}&alarm_id=${alarm_id}`)
        .then(response => {
            successToast('Success')
            getAlarms()
        })
        .catch(err => {
            console.log(err)
        })
    }

    const saveAlarms = async () => {
        if(alarmsPending && alarmsPending.length === 0) return;
        let origAlarms = [...alarmsPending];
        await Promise.all(
            _.map(alarmsPending, async (alarm, index) => {
                try{ 
                    let uAlarms = [...origAlarms]
                    let res = await PROXY_CLIENT().post(`${process.env.REACT_APP_API}/alarm`, alarm)
                    if(res.status === 200) successToast('Success !')
                    uAlarms.splice(index,1)
                    origAlarms = uAlarms
                }catch(err){
                    errorToast(err)
                }
            })
        )
        setAlarmsPending(origAlarms)
        getAlarms()
    }

    const getMeasurements = async (serial_number) => {
        if(!serial_number ) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/sensor/measurements?serial_number=${serial_number}`)
        .then( response => {
            console.log('MEASUREMENTS RESPONSE', response.data)
            if(response?.data?.data) setMeasurements(response.data.data)
            return;
        })
        .catch( err => {
            console.log('SENSOR MEASUREMENTS FETCHING ERROR 48', err)
            errorToast(err)
        })
    }

    const getTelegrams = async ({serial_number, from_date, to_date}) => {
        if(!serial_number ) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/sensor/telegrams?serial_number=${serial_number}&from_date=${from_date}&to_date=${to_date}`)
        .then( response => {
            //console.log('TELEGRAMS RESPONSE', response.data)
            if(response?.data) setTelegrams(response.data)
            return;
        })
        .catch( err => {
            console.log('SENSOR TELEGRAMS FETCHING ERROR 48', err)
            errorToast(err)
        })
    }

    const signalStrength = (rssi = null) => {
        if(!rssi) return 'none';
        if(rssi > 0) rssi = rssi * -1;
        if (rssi >= -50) {
            return 'strong';
        } else if (rssi >= -60) {
            return 'good';
        } else if (rssi >= -70) {
            return 'fair';
        } else {
            return 'weak';
        }
    }

    const params = {
        sensor,
        sensors: sensorsFiltered,
        sensorSignalStrength,
        alarms,
        setAlarms,
        getAlarms,
        deleteAlarm,
        alarmsPending, 
        setAlarmsPending,
        saveAlarms,
        setSensor,
        setSensors,
        getSensor,
        getSensors,
        addBulkSensorsModal,
        addNewSensorModal,
        deleteSensorModal,
        getMeasurements,
        measurements,
        getTelegrams,
        telegrams,
        sensor_ids,
        signalStrength,
        sensorEvents,
        bucketGrpFilter,
        bucketFilter,
        setBucketGrpFilter,
        setBucketFilter
        
    }

    return (
        <SensorContext.Provider value={params}>
            {children}
        </SensorContext.Provider>
    )
}

export default SensorProvider;