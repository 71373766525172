import React, {createContext, Fragment, useContext, useEffect, useRef, useState, useMemo} from 'react';
import {useRouter} from "../../components/Router/Router";
import { AuthContext } from '../Auth/AuthProvider';
import Swal from '@nmesys/sweetalert2'
import {roles, scopes, getPermissions, indexRole} from '@nmesys/permissions'
import _ from 'lodash';
import { AccountContext } from '../Account/AccountProvider';

import {
    successToast,
    errorToast,
    PROXY_CLIENT
} from '../../libraries/utils';
import Iconv from '../../components/iconv';

export const MembersContext = createContext();

const MembersProvider = ({children}) => {

    const {user} = useContext(AuthContext);
    const {account} = useContext(AccountContext)
    
    const [member, setMember] = useState({});
    const [members, setMembers] = useState([]);
   
    const membersChanged = useCompare(members);
    const memberChanged = useCompare(member);
    const accountChanged = useCompare(account)

    useEffect( () => {
        if( accountChanged && account?.account_id ) getMembers(); 
    }, [account])

    useEffect( () => {
        if( membersChanged  ){
            if(members?.[0]) setMember(members[0]);
            else setMember({}) 
        }
    }, [members])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const getMembers = async () => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/member?account_id=${account.account_id}`)
        .then( response => {
            if(!!response.data) setMembers(response.data)
            else setMembers([])
            return;
        })
        .catch( err => {
            console.log('MEMBERS FETCHING ERROR 70', err)
            errorToast(err)
        })
    }

    const createMember = async (obj) => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().post(`${process.env.REACT_APP_API}/member`, obj)
        .then( response => {
            if(!!response?.data?.user_id){
                let objs = JSON.parse(JSON.stringify(members))
                objs.push(response.data)
                setMembers(objs)
            }
            successToast('Your new Account Member has been created!')
            return;
        })
        .catch( err => {
            console.log('MEMBER CREATION ERROR 75', err)
            errorToast('Something went wrong, your new member could not be created.')
        })
    }

    const updateMember = async () => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().put(`${process.env.REACT_APP_API}/member`, member)
        .then( response => {
            getMembers()
            successToast('Your Account Member has been updated!')
            return;
        })
        .catch( err => {
            console.log('MEMBER UPDATE ERROR 75', err)
            errorToast('Something went wrong, your new member could not be created.')
        })
    }

    const deleteMember = async () => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().delete(`${process.env.REACT_APP_API}/member?member_id=${member.member_id}`)
        .then( response => {
            getMembers()
            successToast('Your Account Member has been deleted!')
            return;
        })
        .catch( err => {
            console.log('MEMBER DELETION ERROR 75', err)
            errorToast('Something went wrong, your new member could not be deleted.')
        })
    }

    const addNewMemberModal = () => {
        Swal.fire({
            title: 'Add a new Member',
            width: "50vw",
            icon: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Save',
            html: 
                `<p>Add a new member account for the purpose of this person to be able to log in and manage your account assets. All fields are required.</p><small class="mb-2">NOTE: If the email address is not correct, this user will not be able to log in.</small>`+
                `<div class="overflow-hidden mt-4">` +
                    `<div class="form-section">
                        <div class="form-inner">
                        <form>
                            <div class="row mb-2">
                                <div class="col-6">
                                    <div class="form-group form-box">
                                        <input id="first_name" type="text" class="form-control" placeholder="First Name" aria-label="First Name" required />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group form-box">
                                        <input id="last_name" type="text" class="form-control" placeholder="Last Name" aria-label="Last Name" required />
                                    </div>
                                </div>
                            </div>
                        
                            <div class="form-group form-box  mb-2">
                                <input id="email" type="email" class="form-control " placeholder="Email Address" aria-label="Email Address" required />
                            </div>

                            <div class="form-group form-box  mb-2">
                                <input id="phone" type="text" class="form-control" placeholder="Contact Phone Number" aria-label="Contact Phone Number" required />
                            </div>

                            <div class="row mb-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <input id="street" type="text" class="form-control" placeholder="Address Street" aria-label="Address Street" required />
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <input id="house_number" type="text" class="form-control" placeholder="Number" aria-label="Address House Number" required/>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <input id="street1" type="text" class="form-control" placeholder="APT" aria-label="Address APT Number"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-6">
                                    <div class="form-group form-box">
                                        <input id="city" type="text" class="form-control" placeholder="City" aria-label="City" required />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group form-box">
                                        <input id="province" type="text" class="form-control" placeholder="Province" aria-label="Province" required />
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-6">
                                    <div class="form-group form-box mb-2">
                                        <input id="post_code" type="text" class="form-control" placeholder="Post Code" aria-label="Post Code" required />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group form-box">
                                        <select class="form-select mb-3" defaultValue="de" id="country" aria-label="Default select example">
                                            <option value="de">Deutschland</option>
                                            <option value="it">Italy</option>
                                            <option value="fr">France</option>
                                            <option value="dk">Denmark</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="d-none form-group form-box clearfix">
                                    <input id="ambot" type="text" class="form-control" placeholder="Name" aria-label="Name" />
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>` +
                `</div>`
            ,
            preConfirm: (inputValue) => {
                const params = {
                    account_id: account.account_id,
                    first_name: Swal.getPopup().querySelector('#first_name').value,
                    last_name: Swal.getPopup().querySelector('#last_name').value,
                    email: Swal.getPopup().querySelector('#email').value,
                    phone: Swal.getPopup().querySelector('#phone').value,
                    address: {
                        street: Swal.getPopup().querySelector('#street').value,
                        house_number: Swal.getPopup().querySelector('#house_number').value,
                        street1: Swal.getPopup().querySelector('#street1').value,
                        city: Swal.getPopup().querySelector('#city').value,
                        province: Swal.getPopup().querySelector('#province').value,
                        post_code: Swal.getPopup().querySelector('#post_code').value,
                        country: Swal.getPopup().querySelector('#country').value
                    }
                }
                return params;
            }
        }).then(async results => {
            if (results.isConfirmed) {
                console.log(results)
                await createMember(results.value)
            }
        })
    }

    const updateRole = (role, remove = false) => {
        if(!member || !member?.user_id) return;
        PROXY_CLIENT().post(`${process.env.REACT_APP_API}/member/role`, {
            member_id: member.user_id,
            account_id: member.account_id,
            role,
            remove
        })
        .then( response => {
            getMembers()
            successToast('Member has been updated!')
            return;
        })
        .catch( err => {
            console.log('MEMBER ROLE UPDATE ERROR 249', err)
            errorToast('Something went wrong, your new member could not be updated.')
        })
    }

    const capitalizeFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    const updateRoleModal = (remove = false) => {

        //Get users highest Role
        let userRoleIndex = 0
        _.forEach(user.roles, role => {
            let index = indexRole(role)
            if(index > userRoleIndex ) userRoleIndex = index
        })

        const excluded = ['god', 'authenticatedUser','anonymous']
        let rolesAvailable = [] 
        if(!remove){
            rolesAvailable = Object.keys(roles)
            .map((role,index) => ({
                index,
                value: role,
                label: `${capitalizeFirst(role)}: Rank ${index}`
            }))
            .filter(roleObj => (!excluded.includes(roleObj.value) &&  roleObj.index <= userRoleIndex))
        }else{
            rolesAvailable = member.roles
        }

        if(!rolesAvailable.length){
            errorToast('This member has no roles to remove.');
            return;
        }

        const selectOptions = rolesAvailable.map(option => 
            `<option value="${option.value}">${option.label}</option>`
        ).join('');

        Swal.fire({
            title: `${!!remove ? 'Remove':'Add'} Member Role & Permissions`,
            width: "50vw",
            icon: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Save',
            html: 
                `<p>Update your Member permissions by adjusting their Role.</p>`+
                `<div class="overflow-hidden mt-4">` +
                    `<div class="form-section">
                        <div class="form-inner">
                        <form>
                            <div class="row mb-2">
                                <div class="col-6">
                                    <div class="form-group form-box">
                                        <input id="member_id" type="text" class="form-control" value="${member.user_id}" aria-label="Member ID" readonly />
                                    </div>
                                </div>
                            </div>
                        
                            <div class="form-group form-box  mb-2">
                                <div class="col-12">
                                    <div class="form-group form-box">
                                        <select class="form-select mb-3" defaultValue="de" id="role" aria-label="Default select example">
                                            ${selectOptions}
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </form>
                        </div>
                    </div>` +
                `</div>`
            ,
            preConfirm: (inputValue) => {
                const params = {
                    role: Swal.getPopup().querySelector('#role').value,
                }
                return params;
            }
        }).then(async results => {
            if (results.isConfirmed) {
                console.log(results)
                await updateRole(results.value.role, remove)
            }
        })
    }

    const params = {
        members,
        member,
        createMember,
        deleteMember,
        updateMember,
        addNewMemberModal,
        setMember,
        updateRoleModal,
        updateRole
    }

    return (
        <MembersContext.Provider value={params}>
            {children}
        </MembersContext.Provider>
    )
}

export default MembersProvider;