const lang = {
    login: {
        common: {
            forgot_password: 'Glemt adgangskode?',
            login: 'Log ind',
            no_account: 'Har du ikke en konto?',
            no_account2: 'Registrer her',
            password: 'Adgangskode'
        },
        login: {
            title: 'Log ind på din konto',
            submit: 'Log ind'
        },
        pwl_login: {
            title: 'Log ind på din konto',
            submit: 'Anmod om engangsadgangskode (OTP)'
        },
        pwl_passwd: {
            title: 'Indtast din OTP',
            description: 'En engangsadgangskode er sendt til din registrerede e-mailadresse eller til din chatapplikation.',
            submit: 'Log ind'
        },
        twofa: {
            title: 'Denne konto kræver tofaktorautentifikation',
            submit: 'Bekræft token'
        },
        /**
         * Fejlsider
         */
        error: {
            "404": {
                h1: 'Åh nej..',
                message: 'Denne side eksisterer ikke!',
                goback: `...men ingen bekymringer, gå blot <a class="text-primary" href="/">tilbage til hjem</a> og prøv igen.`
            },
            "401": {
                h1: 'Åh nej..',
                message: 'Du er sandsynligvis ikke autoriseret til at gøre det, du forsøger.',
                goback: `...men ingen bekymringer, gå blot <a class="text-primary" href="/">tilbage til hjem</a> og prøv ikke igen.`
            },
        },
        /**
         * Fejlmeddelelser til brugergrænseflade-feedback
         */
        errorMessages: {
            invalidCredentials: 'UGYLDIGE BRUGERLEGITIMATION',
            error304: 'FEJL: 304',
            emailVerificationSent: 'En e-mail bekræftelseskode er blevet sendt til din registrerede e-mail-adresse. Når du har bekræftet din e-mail-adresse, vil din konto være aktiv.',
            emailUnverified: 'Din e-mail er ikke blevet bekræftet. Prøv venligst igen.',
            emailVerified: 'Din e-mail er blevet bekræftet. Du kan nu bruge den til at logge ind.',
            emailExists: 'Der findes allerede en konto med den e-mail-adresse. Prøv at logge ind i stedet.',
            registrationNotAllowed: 'Denne klient tillader ikke brugerregistrering.',
            somethingWrong: 'NOGET GIK GALT'
        },
    
        home: {
            header: {
                home: 'Hjem',
                features: 'Funktioner',
                about: 'Om'
            },
            hero: {
                subtitle: 'Få øjeblikkelig brugerautentifikation og identifikation uden opsætning',
                login: 'log ind'
            },
            features: {
                f1: {
                    "title": 'EU-baseret databeskyttelse',
                    "body": 'Oplev ro i sindet med vores GDPR-kompatible tjenester, drevet af servere strategisk placeret i Tyskland for optimal databeskyttelse og privatliv.'
                },
                f2: {
                    "title": 'Avancerede krypteringsstandarder',
                    "body": 'Sikr hver eneste personlige data med vores topmoderne kryptering, der beskytter information både under overførsel og i hvile, forstærket med de nyeste password hashing-teknologier for uovertruffen sikkerhed.'
                },
                f3: {
                    "title": 'Fleksibel multifaktorautentifikation',
                    "body": 'Styrk din sikkerhed med tilpasselig multifaktorautentifikation (MFA). Vælg mellem valgfri eller obligatorisk MFA-indstillinger for at balancere bekvemmelighed og beskyttelse perfekt.'
                },
                f4: {
                    "title": 'Alsiddige loginmuligheder',
                    "body": 'Tilbyd dine brugere bekvemmeligheden ved traditionel brugernavn/adgangskode-login eller enkelheden i adgangskode-fri, OTP (engangsadgangskode) login for en problemfri autentifikationsoplevelse.'
                },
                f5: {
                    "title": 'Stramme adgangskodepolitikker',
                    "body": "Hæv sikkerheden med vores robuste adgangskodepolitikker: Gennemfør nul adgangskodegenbrug, implementér strenge adgangskodekrav, og håndter nemt adgangskodereset, og eliminer 'Glemt adgangskode'-sårbarheden."
                },
                f6: {
                    "title": 'Kontrolleret fjernpersistens',
                    "body": 'Få fuld kontrol over autentifikationspersistens. Håndter brugersessioner ubesværet med lokale cookies og fjernautorisationsannulleringskapaciteter for forbedret sikkerhed.'
                },
                f7: {
                    "title": 'Fleksibel integration: API eller relæ',
                    "body": 'Integrer problemfrit ved hjælp af vores standard, videreførbare login-interface kompatibelt med Whitelabel-løsninger, eller tilpas din loginoplevelse med vores alsidige API.'
                },
                f8: {
                    "title": 'Banebrydende OAUTH2 med PKCE',
                    "body": 'Udnyt de nyeste OAuth2-standarder med PKCE-metoder, der sikrer den højeste sikkerhed. Uanset om du bruger klienthemmeligheder eller PKCE, stol på vores grundige verifikationsprocesser.'
                },
                f9: {
                    "title": 'Skalerbare løsninger',
                    "body": 'Vokse uden grænser med IRONAUTH3. Vores skalerbare løsning tilpasser sig ubesværet din ekspanderende kundebase og sikrer ingen grænser for din vækst uden ekstra omkostninger.'
                }
            },
            "about": {
                "ab1": {
                    "title": 'Urokkelig engagement i databeskyttelse',
                    "body": 'I hjertet af vores service er et stærkt engagement for din datasuverænitet. Vi sælger hverken eller overvåger dine brugeroplysninger, hvilket sikrer, at du bevarer eksklusiv kontrol. Derudover giver vi dig mulighed for at downloade hele din brugerdatabase, hvilket letter ubesværet datatransfer når det er nødvendigt.'
                },
                "ab2": {
                    "title": 'Enhedsagnostisk arbejdsintegrering',
                    "body": 'IronAuth integrerer problemfrit med alle moderne enheder og tilbyder en brugervenlig opsætnings- og eksekveringsproces. Tilpas din brugeroplevelse med vores tilpasselige API eller brug vores effektive indbyggede arbejdsproces til at imødekomme dine unikke behov.'
                }
            }  
        }
    },
    currency: {
        symbol: "€",
        label: "EUR"
    },
    lang: {
        language: "Language",
        english: "English",
        german: "German"
    },
    user: {
        profile: "Account",
    },
    navbar: {
        login: "Login",
        logout: "Logout",
        dashboard: "Dashboard",
        register: "Register"
    },
    dataTables: {
        loading: "Loading ..."
    },
    swal: {
        titles:{
            "Are you sure": "Are you sure?"
        },
        texts: {
            shortLinkDelete: "Do you really want to delete short link: "
        },
        buttons: {
            confirmButtonText1: "Yes",
            cancelButtonText1: "Cancel"
        }
    }

    
}

module.exports = lang;
