import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { GatewayContext } from '../../context/Gateway/GatewayProvider';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import _ from 'lodash'

import {useRouter} from "../Router/Router";
import de from '../DataTables/datatable.de.json';
import Iconv from '../iconv';
const $ = require('jquery')
$.DataTable = require('datatables.net');

const TblGateways = (props) => {

    const { gateway, gateways, setGateway } = useContext(GatewayContext);
    const { bucket, buckets } = useContext(BucketContext);
    const { lang, locale } = useContext(LocaleContext);

    const gatewayChanged = useCompare(gateway)
    const gatewaysChanged = useCompare(gateways);

    useEffect( () => {
        if(gatewaysChanged === true) initializeDT()
    }, [gateways]);
    
    
    useEffect( () => {

    },[gateway]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
  
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => { ref.current = value });
        return ref.current;
    }
    
    const initializeDT = async () => {
        const tableElement = $('#tblGateways');
        const dataTable = $.fn.dataTable.isDataTable(tableElement);
        if (dataTable) {
            tableElement.DataTable().destroy();
            tableElement.empty();
        }
        $(`#tblGateways tbody`).off();

        if (gateways.length > 0) {
            const table = $(`#tblGateways`).DataTable({
                language: locale,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: gateways,
                rowId: "gateway_id",
                pageLength: 100,
                orderCellsTop: false,
                fixedHeader: true,
                order: [
                    [1, "asc"]
                ],
                columns: [
                    {
                        "data": null,
                        "searchable": true,
                        "visible": false,
                        "ordering": false,
                        render: function (data, type, row) {
                            if(data?.bucket_id) return data.bucket_id
                            else return ''
                        }
                    },
                    {
                        "data": "serial_number",
                        "searchable": true,
                        "visible": true,
                        "ordering": true
                        
                    },
                    
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(!!data?.last_transmission){
                                return new Date(data.last_transmission).toLocaleDateString('de-DE', { year:"numeric", month:"short", day:"numeric"})
                            }
                            return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return (data?.rssi)? data.rssi:0
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return ''
                        }
                    },

                   
                    
                ],
                createdRow: function (row, data, dataIndex) {},
                createdCell:function (td, cellData, rowData, row, col) {},
                "fnDrawCallback": function( oSettings ) { }
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $(`#tblGateways tbody tr`).off('mouseenter').on('mouseenter', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });

            $(`#tblGateways tbody tr`).off('mouseleave').on('mouseleave', function () {
                $(this).css('background-color', 'transparent');
            });

            $(`#tblGateways tbody`).on('click', 'tr', function (e) {
                let parent = e.currentTarget.closest('tr');
                let target = gateways.filter(g => g.gateway_id === parent.id);
                setGateway(target[0])
            });
        }  
    }

    return (
        
        <table id={`tblGateways`} className="table mb-0 row-border hover order-column w-100 table-hover" role="grid">
            <thead>
                <tr>
                    <th></th>
                    <th >UID</th>
                    <th className="text-right">Date</th>
                    <th>RSSI</th>
                    <th>Battery</th>
                    
                </tr>
            </thead>
        </table>
        
    )

}
export default TblGateways;