import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import { AccountContext } from '../../context/Account/AccountProvider';
import { TenantContext } from '../../context/Tenant/TenantProvider';
import { AddressContext } from '../../context/Address/AddressProvider';
import _ from 'lodash'

import de from '../DataTables/datatable.de.json';
const $ = require('jquery')
$.DataTable = require('datatables.net');

const TblBuckets = (props) => {

    const { bucket, buckets, setBucket } = useContext(BucketContext);
    const {account, accounts } = useContext(AccountContext);
    const { lang, locale } = useContext(LocaleContext);
    const bucketChanged = useCompare(bucket)
    const bucketsChanged = useCompare(buckets);
    const {tenants, tenant} = useContext(TenantContext);

    useEffect( () => {
        if(bucketsChanged === true) initializeDT()
    },[buckets, tenants]);

    useEffect( () => {

    },[bucket]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
  
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => { ref.current = value });
        return ref.current;
    }

    const getCurTenants = (bucket_id) => {
        if(!tenants.length === 0) return [];
        const date = new Date().getTime();
        let objs = []
        
        let current = _.chain(tenants)
            .filter(obj => (obj.bucket_id === bucket_id ))
            .filter(obj => (!!obj.date_move_in && obj.date_move_in < date) )
            .filter(obj => (!obj.date_move_out || obj.date_move_out > date))
            .value();
        objs = objs.concat(current);
        return objs
    }

    const initializeDT = async () => {
        const tableElement = $('#tblBuckets');
        const dataTable = $.fn.dataTable.isDataTable(tableElement);
        if (dataTable) {
            tableElement.DataTable().destroy();
            tableElement.empty();
        }
        $(`#tblBuckets tbody`).off();

        if (buckets.length > 0) {
            const table = $(`#tblBuckets`).DataTable({
                language: locale,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: buckets,
                rowId: "bucket_id",
                pageLength: 100,
                orderCellsTop: false,
                fixedHeader: true,
                order: [
                    [2, "asc"],
                    [3, "asc"],
                    [4, "asc"],
                    [5, "asc"]
                ],
                columns: [
                    {
                        data: "bucket_id",
                        searchable: true,
                        visible: true
                    },
                    {
                        data: null,
                        searchable: true,
                        visible: false,
                        render: function (data, type, row) {
                            if(!!data.parent_id) return  data.parent_id;
                            return '';
                        }
                    },
                    {
                        "data": null,
                        "searchable": false,
                        "visible": true,
                        "ordering": false,
                        render: function (data, type, row) {
                            let iconColor = "danger"
                            let ct = getCurTenants(data.bucket_id);
                            if(ct.length > 0) iconColor = "success"
                            if(!!data.type && data.type === 'structural'){
                                if(!data?.parent_id || data.parent_id.length === 0) return ( '<i title="Parent Bucket" class="fa-solid fa-building text-primary" />' );
                                else return ( `<i title="Child Bucket" class="fa-solid fa-house text-${iconColor}" />` );
                            }
                            else return ( '<i title="Multi Bucket" class="fa-solid fa-house text-info" />' );
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.address) return data.address.street
                            else return '';
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.address) return data.address.house_number
                            else return '';
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.address?.street1) return data.address.street1
                            else return '';
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.address) return data.address.city
                            else return '';
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.address) return data.address.province
                            else return '';
                        }
                    },{
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.address) return data.address.post_code
                            else return '';
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": false,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.address) return data.address.country
                            else return '';
                        }
                    },
                    {
                        data: "type",
                        searchable: true,
                        visible: false
                    },
                ],
                createdRow: function (row, data, dataIndex) {

                    const firstCell = row.querySelector('td:first-child');
                    if (firstCell) {
                        firstCell.classList.add(`level-${data.level}`);
                    }
                },
                "fnDrawCallback": function( oSettings ) { }
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $(`#tblBuckets tbody tr`).off('mouseenter').on('mouseenter', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });

            $(`#tblBuckets tbody tr`).off('mouseleave').on('mouseleave', function () {
                $(this).css('background-color', 'transparent');
            });

            $(`#tblBuckets tbody`).on('click', 'tr', function (e) {
                let parent = e.currentTarget.closest('tr');
                let target = buckets.filter(p => p.bucket_id === parent.id);
                setBucket(target[0])
            });
        }  
    }

    return (
        <Fragment>
            <table id={`tblBuckets`} className="table mb-0 row-border hover order-column w-100 table-hover" role="grid">
                <thead>
                    <tr>
                        <th ></th>
                        <th ></th>
                        <th ></th>
                        <th >Street</th>
                        <th >House</th>
                        <th >Apt</th>
                        <th >City</th>
                        <th >Province</th>
                        <th >Post Code</th>
                        <th >Country</th>
                        <th ></th>
                    </tr>
                </thead>
            </table>
        </Fragment>
        
    )

}
export default TblBuckets;