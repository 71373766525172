import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { LocaleContext } from '../../context/Locale/Locale';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { Link } from "react-router-dom";
import { useRouter } from '../../components/Router/Router';
import Loading from '../../components/Loaders/Loading';
import LanguageSwitcher from "../../components/Auth/languageSwitcher";


const LoginPage = (props) => {

    const { lang, setLang, locale, localeAvailable } = useContext(LocaleContext);
    const { user } = useContext(AuthContext);
    const router = useRouter();
    const [errorMsgs, setErrorMsgs] = useState([])

    useEffect(  () => {

    }, [lang]);


    useEffect(  () => {

    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(e.target.ambot.value) return
        const formData = {
          token: e.target.token.value
        }
    
        const response = await fetch('/api/auth/twofa/verify', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        })
    
        if(response.redirected) window.location.href = response.url
    
        document.getElementById('frmPasswd').reset()
        let error;
        switch(response.status){
          case 401:
            error = {message: 'invalidCredentials', type: 'danger'}
            setErrorMsgs([error]);
            break;
          case 404:
            error = {message: 'somethingWrong', type: 'danger'}
            setErrorMsgs([error]);
            break;
          default:
            break;
        }
    }


    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        return (
            <Fragment>
                <section  >
                    <div className="form-section">
                        <div className="form-inner">

                            <LanguageSwitcher />
                            
                            <Link to="/auth/login" >
                                <a className="logo">
                                    <img src="/auth/assets/logo.png" alt="IRON AUTH" title="IRON AUTH" />
                                </a>
                            </Link>

                            <h3>{lang.login.twofa.title}</h3>
                            <form id="frmPasswd" onSubmit={handleSubmit} >
                                <div className="form-group form-box clearfix">
                                    <input name="token" type="text" className="form-control" placeholder="Code" aria-label="Code" />
                                    <i className="flaticon-key"></i>
                                </div>
                                <div className="d-none">
                                    <input name="ambot" type="text" maxLength="10" className="form-control" placeholder="2FA CODE" aria-label="2FA CODE" />
                                </div>

                                {
                                    (errorMsgs.length) ?
                                        errorMsgs.map((msg,i) => {
                                        return (
                                            <div key={i} className={`errormsg mb-2 text-${ (msg && msg.type)? msg.type:'danger'} `}>
                                                {lang.login.errorMessages[msg.message]}
                                            </div>
                                        )
                                        })
                                        : (<Fragment></Fragment>)
                                }
                                
                                <div className="form-group">
                                    <button className="btn btn-primary btn-lg btn-theme" ><span>{lang.login.twofa.submit}</span></button>
                                </div>
                            </form>
                            <div className="clearfix"></div>


                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

LoginPage.requiredScope = [];
LoginPage.requireAuth = false;
export default LoginPage;