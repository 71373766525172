import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AccountContext } from '../../context/Account/AccountProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import _ from 'lodash'

import de from '../DataTables/datatable.de.json';
const $ = require('jquery')
$.DataTable = require('datatables.net');

const TblAccounts = (props) => {

    const {account, accounts, setAccount} = useContext(AccountContext)
    const { lang, locale } = useContext(LocaleContext);
    const accountChanged = useCompare(account)
    const accountsChanged = useCompare(accounts)

    useEffect( () => {

        if(accountsChanged === true) initializeDT()

    },[account,accounts]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
  
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => { ref.current = value });
        return ref.current;
    }

    const capitalizeFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    const formatAddress = (obj) => {
        function hasPropertiesWithNonNullValues(obj, properties) {
            if (typeof obj !== 'object' || obj === null || !Array.isArray(properties)) return false;
            for (let property of properties) {
              if (!obj.hasOwnProperty(property) || obj[property] === null) return false;
            }
            return true;
        }
        if(hasPropertiesWithNonNullValues(obj, ['street', 'house_number', 'city', 'post_code', 'country'])){
            return `${obj.street} ${obj.house_number}, ${ (!!obj.street1) ? obj.street1 + ', ':'' }${obj.city}, ${obj.post_code} ${obj.country.toUpperCase()}`
        } 
        return null;
    }

    const initializeDT = async () => {

        const tableElement = $('#tblAccounts');
        const dataTable = $.fn.dataTable.isDataTable(tableElement);
        if (dataTable) {
            tableElement.DataTable().destroy();
            tableElement.empty();
        }
        $(`#tblAccounts tbody`).off();

        if (accounts.length > 0) {
            const table = $(`#tblAccounts`).DataTable({
                language: locale,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: accounts,
                rowId: "account_id",
                pageLength: 100,
                orderCellsTop: false,
                fixedHeader: true,
                order: [
                    [1, "asc"]
                ],
                columns: [
                    {
                        data: "account_id",
                        searchable: true,
                        visible: false
                    },
                    {
                        "data": null,
                        "searchable": false,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return data.name
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.contact) return data.contact.first_name + ' ' + data.contact.last_name
                            else return '';
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(!data?.contact?.addresses) return 'na'
                            let formatedAddress = formatAddress(data.contact.address)
                            if(data?.contact?.addresses?.[0]?.geolocation?.formatted_address) return data.contact.addresses[0].geolocation.formatted_address
                            if(formatedAddress) return formatedAddress
                            return 'na'
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.contact?.phones?.[0]?.number) return data.contact.phones[0].number
                            return 'na'
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.contact?.emails?.[0]?.address) return data.contact.emails[0].address
                            return 'na'
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return `<div></div>`
                        }
                    }

                ],
                createdRow: function (row, data, dataIndex) {},
                "fnDrawCallback": function( oSettings ) { }
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $(`#tblAccounts tbody tr`).off('mouseenter').on('mouseenter', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });

            $(`#tblAccounts tbody tr`).off('mouseleave').on('mouseleave', function () {
                $(this).css('background-color', 'transparent');
            });

            $(`#tblAccounts tbody`).on('click', 'tr', function (e) {
                let parent = e.currentTarget.closest('tr');
                let target = accounts.filter(a => a.account_id === parent.id);
                setAccount(target[0])
            });
        }  
    }

    return (
        <Fragment>
            <table id={`tblAccounts`} className="table mb-0 row-border hover order-column w-100 table-hover" role="grid">
                <thead>
                    <tr>
                        <th ></th>
                        <th >Name</th>
                        <th >Contact</th>
                        <th >Address</th>
                        <th >Phone</th>
                        <th >Email</th>
                        <th ></th>
                    </tr>
                </thead>
            </table>
        </Fragment> 
    )

}
export default TblAccounts;