import CryptoJS from "crypto-js";
import Cookie from 'js-cookie';
import CLIENT  from './axios_client';
import Swal from '@nmesys/sweetalert2'
import _ from 'lodash';
import {v1 as uuidv1} from 'uuid';

export const PROXY_CLIENT = () => CLIENT;


export const setSession = () => {
    let session_id = Cookie.get('session')  //= uuidv1();
    if (!session_id) {
        session_id = uuidv1();
        Cookie.set('session', session_id, COOKIE_PARAMS)
    }
    return session_id;
}

export const genKey = () => {
    let KEY = Cookie.get('APP_KEY')
    if(!KEY){
        KEY = generate(32) //For AES encryption, length must be 32
        Cookie.set('APP_KEY', KEY, COOKIE_PARAMS);
        localStorage.setItem('APP_KEY', KEY);
    }
    return KEY;
}

/********************************************
 *          COOKIES
 *******************************************/
export const COOKIE_PARAMS = { "expires": 3, "httpOnly": false  };

export const getCookie = (key) => {
    return Cookie.get(key, COOKIE_PARAMS);
}

export const setCookie = (key, value) => {
    if(typeof value !== 'string') value = JSON.stringify(value)
    Cookie.set(key, value, COOKIE_PARAMS)
}

export const cookieCrypt = async ({cookieName, cookiePayload}) => {
    let payload = cookiePayload;
    const APP_KEY = localStorage.getItem('APP_KEY')
    if(!APP_KEY) throw new Error('COOKIE CRYPT: APP Key is required')
    if(typeof cookiePayload !== 'string' && typeof cookiePayload !== 'String'){
        payload = JSON.stringify(cookiePayload);
    }
    const enc = (obj) => new Promise( resolve => { return resolve(CryptoJS.AES.encrypt( obj, APP_KEY ).toString()) })
    let payloadEncrypted = await enc(payload)//CryptoJS.AES.encrypt( payload, APP_KEY ).toString();
    Cookie.set(cookieName, payloadEncrypted, COOKIE_PARAMS);
    return;
}

export const cookieDecrypt = async ({cookieName}) => {
    const cookieTarget = Cookie.get(cookieName);
    if(!cookieTarget) throw new Error('COOKIE DECRYPT: Cookie did not exist')
    const APP_KEY = Cookie.get('APP_KEY')
    let plaintext = null
    try{ 
        plaintext = CryptoJS.AES.decrypt( cookieTarget, APP_KEY );
        plaintext = plaintext.toString(CryptoJS.enc.Utf8);
        return plaintext;
    }
    catch(err){
        throw new Error('COOKIE DECRYPTION ERROR')
    }
}

export const cookieDestroy = (key) => {
    try{
        Cookie.remove( key, COOKIE_PARAMS);
    }catch(error){
        console.log('Nothing to remove or cookie params not correct')
    }
}

/********************************************
 *          TOASTS
 *******************************************/
export const errorToast = (err) => {
    if(!err) return
    Swal.fire({
        toast: true,
        position: 'bottom-end',
        timer: 5000,
        timerProgressBar: true,
        text: err,
        showConfirmButton: false,
        customClass: {
            popup: "bg-danger text-white"
        }
    })
}

export const successToast = (msg) => {
    if(!msg) return
    Swal.fire({
        toast: true,
        position: 'bottom-end',
        timer: 5000,
        timerProgressBar: true,
        text: msg,
        showConfirmButton: false,
        customClass: {
            popup: "bg-success text-white"
        }
    })
}
/********************************************
 *          Local Storage
 *******************************************/
export const storageCrypt = async ({name, payload}) => {
    const APP_KEY = localStorage.getItem('APP_KEY')
    if(!APP_KEY) throw new Error('STORAGE CRYPT: APP Key is required')
    if(typeof payload !== 'string' && typeof payload !== 'String'){
        payload = JSON.stringify(payload);
    }
    const enc = (obj) => new Promise( resolve => { return resolve(CryptoJS.AES.encrypt( obj, APP_KEY ).toString()) })
    let payloadEncrypted = await enc(payload)//CryptoJS.AES.encrypt( payload, APP_KEY ).toString();
    localStorage.setItem(name, payloadEncrypted)
    return;
}

export const storageDecrypt = async ({name}) => {
    const target = localStorage.getItem(name);
    if(!target) throw new Error('STORAGE DECRYPT: Storage Item did not exist')
    const APP_KEY = localStorage.getItem('APP_KEY');
    let plaintext = null
    try{ 
        plaintext = CryptoJS.AES.decrypt( target, APP_KEY );
        plaintext = plaintext.toString(CryptoJS.enc.Utf8);
        return plaintext;
    }
    catch(err){
        throw new Error('STORAGE DECRYPTION ERROR')
    }
}
/********************************************
 *          Utilities
 *******************************************/
/**
 * Generate a random string of X length
 */
export const generate = (length) => {
    let string = "";
    let stringLength = length !== undefined || null ? length : 64;
    let possible = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789";
    for (let i = 0; i < stringLength; i++){
        string += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return string;
}

export const capitalizeFirst = (string) => {
    return `${string.slice(0,1).toUpperCase()}${string.slice(1, string.length)}`
}

export const setRedirect = (redirect) => {
    setCookie('redirect', redirect)
}

export const getDeepProperty = (obj, keyToFind) => {
    let result = null;
    function search(obj) {
        _.forOwn(obj, (value, key) => {
          if (key === keyToFind) {
            result = value;
            return false;
          }
          if (_.isObject(value)) {
            search(value);
          }
        });
      }
      search(obj);
      return result;
}