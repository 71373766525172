/**
 * This modal is a wizard to help new accounts establish
 * Realm identity
 */
import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-modal';
import Iconv from '../../components/iconv';
import _ from 'lodash';
import MD5 from 'md5';
import { LocaleContext } from '../../context/Locale/Locale';
import { AccountContext } from '../../context/Account/AccountProvider';
import { AuthContext } from '../../context/Auth/AuthProvider';

import {
    errorToast,
    successToast, 
    PROXY_CLIENT,
    capitalizeFirst,
    getDeepProperty
} from '../../libraries/utils';
Modal.setAppElement('#root');

const AddBucketModal = (props) => {

    const { lang, locale } = useContext(LocaleContext);
    const { user, PermissionsWrapper, hasPermission, logout} = useContext(AuthContext);
    const [show, setShow] = useState(false);
    const showModalChanged = useCompare(props.showModal);

    useEffect( () => {

        if(showModalChanged ) setShow(props.showModal)
        
    }, [showModalChanged]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const toggleModal = () => {
        setShow(false)
        if(props.closeModal) props.closeModal();
    }

    // Show remaining message length
    const handleMessageChange = (e) => {
        const elem = document.getElementById('frmEmailMessage');
        const inputText = e.target.value;
      //  if (inputText.length <= messageMaxLength) {
          //  setMessageText(inputText);
    //    } else {
          // setMessageText(inputText.substring(0, messageMaxLength));
    //    }
    }

    const handleEmailFormChange = (event) => {
     //   const { name, value } = event.target;
      //  setEmlFormData(prevState => ({
      //    ...prevState,
      //    [name]: value
     //   }));
    };


    const UI = () => {
        return (
            <Fragment>
            <Modal
                isOpen={show}
                onRequestClose={toggleModal}
                shouldCloseOnOverlayClick={true}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: "80vw",
                        height: "65vh"
                    }
                }}
            >

                <div className="col-xl-12">
                    <div className="d-flex justify-content-between">
                        <h4>{'Contact Us'}</h4>
                        <button className="btn btn-primary" onClick={() => toggleModal()}>X</button>
                    </div>
                    <section className={`mt-4`} id="contactUs">

                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">

                                    <div className="card-body">


                                        <div className="btnBucketWrapper d-flex">
                                            <div className="btnBucketIcon flex-start">
                                                <Iconv name="bucket.structural" size={8} classList="text-primary"/>
                                            </div>
                                            <div className="btnBucketDescription">
                                                THis is a structural bucket
                                            </div>
                                        </div>


                                        <div className="btnBucketWrapper">
                                            <div className="col-2">BTN</div>
                                            <div className="col-8">THis is a polygon bucket</div>
                                        </div>

                                    </div>
                                    
                                       
                              

                                </div>
                            </div>
                        </div>
                    </section>

                </div>
 
            </Modal>

            

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )


}

export default AddBucketModal;