import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { LocaleContext } from '../../context/Locale/Locale';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { Link, useNavigate } from "react-router-dom";
import Loading from '../../components/Loaders/Loading';
import LanguageSwitcher from "../../components/Auth/languageSwitcher";
import { PROXY_CLIENT } from '../../libraries/utils';

const PasswordPage = (props) => {

    const { lang } = useContext(LocaleContext);
    const { setUser } = useContext(AuthContext);
    const [errorMsgs, setErrorMsgs] = useState([])
    const [passwordChars, setPasswordChars] = useState(Array(8).fill(''));
    const passwordInputRefs = useRef(Array(8).fill(null));
    const navigate = useNavigate();

    useEffect(() => {
        if (passwordInputRefs.current[0]) {
            passwordInputRefs.current[0].focus();
        }
    }, []);

    useEffect(() => {
        if(passwordChars.join('').length === passwordInputRefs.current.length) {
            document.getElementById('btnSubmit').disabled = false;
        }
    }, [passwordChars]);

    const handleInputChange = (index) => (event) => {
        const newChars = [...passwordChars];
        newChars[index] = event.target.value;
        setPasswordChars(newChars);
        if (index < passwordChars.length - 1) {
            passwordInputRefs.current[index + 1].focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {password: passwordChars.join('')}
        await PROXY_CLIENT().post('/auth/password', formData)
        .then(response => {
            document.getElementById('frmPasswd').reset()
            if(response?.data?.user){
                setUser(response.data.user);
                navigate('/dashboard')
            }
        })
        .catch(err => {
            document.getElementById('frmPasswd').reset()
            let msg = 'Invalid user credentials...'
            setErrorMsgs([msg])
        })
    }

    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        return (
            <Fragment>

                <section  >
                    <div className="form-section">
                        <div className="form-inner">

                            <LanguageSwitcher />

                            <Link to="/auth/login" >
                                <a className="logo">
                                    <img src="/auth/assets/logo.png" alt="IRON AUTH" title="IRON AUTH" />
                                </a>
                            </Link>

                            <h3>{lang.login.pwl_login.title}</h3>
                            <small className="mb-4">{lang.login.pwl_passwd.description}</small>
                            <div className="clearfix mt-4"></div>
                            <form id="frmPasswd" onSubmit={handleSubmit} >
                                <div className="form-group">
                                    <div className="row justify-content-center">
                                        {
                                            passwordChars.map((char, index) => {
                                                return (
                                                    <div key={index} className="col-2">
                                                        <input 
                                                            className="form-control border-black h2 password-input" 
                                                            maxLength="1" 
                                                            name="password" 
                                                            type="text" 
                                                            placeholder="*" 
                                                            aria-label={`Password Digit ${index + 1}`}
                                                            defaultValue={char}
                                                            onChange={handleInputChange(index)}
                                                            ref={(e) => (passwordInputRefs.current[index] = e)}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                {
                                    (errorMsgs.length) ?
                                        errorMsgs.map(msg => {
                                        return (
                                            <div className={`errormsg mb-2 text-${ (msg && msg.type)? msg.type:'danger'} `}>
                                                {lang.login.errorMessages[msg.message]}
                                            </div>
                                        )
                                        })
                                        : (<Fragment></Fragment>)
                                }

                                <div className="form-group">
                                    <button id="btnSubmit" className="btn btn-primary btn-lg btn-theme" type="submit" disabled ><span>{lang.login.pwl_passwd.submit}</span></button>
                                </div>
                            </form>
                            
                        </div>
                    </div>
                </section>

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

PasswordPage.requiredScope = [];
PasswordPage.requireAuth = false;
export default PasswordPage;