import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';



const DesignFeatures = (props) => {

   
    useEffect(  () => {

    }, []);

   

    const UI = () => {

        return (
            <Fragment>

                <section className="section" id="designFeatures">
                    <div className="container">
                        <div className="row align-items-center gy-4">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="text-muted">
                                    <h5 className="fs-12 text-uppercase text-success">Design</h5>
                                    <h4 className="mb-3">Well Designed Dashboards</h4>
                                    <p className="mb-4 ff-secondary">Quality Dashboards (QD) is a condition-specific, actionable web-based application for quality reporting and population
                                        management that is integrated into the Electronic Health Record (EHR).</p>
                                    <div className="row">
                                        <div className="col-sm-5">
                                            <div className="vstack gap-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-2">
                                                        <div className="avatar-xs icon-effect">
                                                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                                <i className="ri-check-fill"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="fs-14 mb-0">Ecommerce</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-2">
                                                        <div className="avatar-xs icon-effect">
                                                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                                <i className="ri-check-fill"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="fs-14 mb-0">Analytics</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-2">
                                                        <div className="avatar-xs icon-effect">
                                                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                                <i className="ri-check-fill"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="fs-14 mb-0">CRM</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-5">
                                            <div className="vstack gap-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-2">
                                                        <div className="avatar-xs icon-effect">
                                                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                                <i className="ri-check-fill"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="fs-14 mb-0">Crypto</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-2">
                                                        <div className="avatar-xs icon-effect">
                                                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                                <i className="ri-check-fill"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="fs-14 mb-0">Projects</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <a href="index.html" className="btn btn-primary">Learn More <i className="ri-arrow-right-line align-middle ms-1"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-7 col-10 ms-auto order-1 order-lg-2">
                                <div>
                                    <img src="assets/images/landing/features/img-2.png" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center mt-5 pt-lg-5 gy-4">
                            <div className="col-lg-6 col-sm-7 col-10 mx-auto">
                                <div>
                                    <img src="assets/images/landing/features/img-3.png" alt="" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-muted ps-lg-5">
                                    <h5 className="fs-12 text-uppercase text-success">structure</h5>
                                    <h4 className="mb-3">Well Documented</h4>
                                    <p className="mb-4">used to describe something that is known about or known to be true because there are many documents that describe it,
                                        prove it, etc.</p>
                                    <div className="vstack gap-2">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-2">
                                                <div className="avatar-xs icon-effect">
                                                    <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                        <i className="ri-check-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <p className="mb-0">Dynamic Conetnt</p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-2">
                                                <div className="avatar-xs icon-effect">
                                                    <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                        <i className="ri-check-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <p className="mb-0">Setup plugin's information.</p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-2">
                                                <div className="avatar-xs icon-effect">
                                                    <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                        <i className="ri-check-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <p className="mb-0">Themes customization information</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default DesignFeatures;