import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/Auth/AuthProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import { AccountContext } from '../../context/Account/AccountProvider';
import {useRouter} from "../../components/Router/Router";
import Loading from '../../components/Loaders/Loading';
import Iconv from '../../components/iconv';
import _ from 'lodash';
import { SocketContext } from '../../context/Socket/SocketProvider';
import { 
    PROXY_CLIENT,
    capitalizeFirst,
    errorToast,
    successToast
 } from '../../libraries/utils';
 
import Swal from '@nmesys/sweetalert2'
import Dropzone from 'react-dropzone';
import FormData from 'form-data'
import { Buffer } from "buffer";
import LinearProgressBar from '../../components/Progress/progressbar';
import AccountSwitcher from '../../components/AccountSwitcher';

const DataBoss = (props) => {
    
    const navigate = useNavigate();
    const { lang, locale } = useContext(LocaleContext);
    const {account} = useContext(AccountContext);
    const {socket} = useContext(SocketContext);
    const [progress, setProgress] = useState(0);
    const [report, setReport] = useState(null);
    const [validateOnly, setValidateOnly] = useState(true)
    const mimetypes = ['text/csv'];
     
    useEffect(  () => {
        if(socket){
            socket.on('databoss', data => {
                //console.log('SOCKET DATABOSS DATA RECEIVED',data)
                
                const {event, payload} = data;
                switch(event){
                    case 'progress':
                        setProgress(Math.floor(payload.percent));
                        break;
                    case 'complete':
                        setProgress(100);
                        document.getElementById('progressMsg').innerHTML = 'COMPLETE'
                        setReport(payload.message)
                        break;
                    case 'bucket':
                        document.getElementById('progressMsg').innerHTML = payload.message
                        break;
                    default:
                        break;
                }
            })
        }            
    }, [socket]);
    

    useEffect(  () => {

        

    }, [progress, validateOnly]);

   
    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const upload = async (fileAccepted) => {
        if(!account || !account?.account_id) return;
        setProgress(0)
        if(!mimetypes.includes(fileAccepted[0].type)){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Es sind nur CSV-Dateien erlaubt',
            })
            return;
        }

        let formData = new FormData();
        formData.append("file", fileAccepted[0]);
        formData.append("account_id", account.account_id);
        formData.append("socket_id", socket.id);
        if(validateOnly === true) formData.append("validateOnly", true);
        PROXY_CLIENT().post(`${process.env.REACT_APP_API}/databoss/upload`, formData)
        .then( response => {
            
            
        })
        .catch( err => {
            console.log('DATABOSS ERROR 74', err)
            errorToast(err)
        })

    }

    const toggleValidate = () => {
        if(validateOnly) setValidateOnly(false)
        if(!validateOnly) setValidateOnly(true)
        setReport(null)
        setProgress(0)
        document.getElementById('progressMsg').innerHTML = ''
    }

    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        
        return (
            <Fragment>

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">DATABOSS</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><b>Apps</b></li>
                                    <li className="breadcrumb-item active"><b>DataBoss</b></li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex align-items-center flex-wrap gap-2">
                                    <div className="flex-grow-1">
                                     
                                        <div className="col-lg-3 float-right me-2">
                                            <AccountSwitcher /> 
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className={`col-md-12`}>
                        <div className="card">
                            <div className="card-body">
                                <LinearProgressBar
                                    id="upload-progress"
                                    value={progress}
                                    height="2"
                                />
                                <p id="progressMsg" className="mt-2 mb-2"></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <p>
                        Welcome
                    </p>
                </div>
                
                <div className="row">
                    <div className={`col-md-8`}>
                        <div className="card">
                            <div className="card-body">

                                <div className="row mt-2">
                                    <div className="mb-1 col-md-12">
                                        <div className="col-4">
                                            <div className="form-check form-switch form-switch-lg mb-4 form-switch-success " dir="ltr">
                                                <input type="checkbox" className="form-check-input" id="validateToggle" onChange={() => toggleValidate()} checked={validateOnly} />
                                                <label className="form-check-label " htmlFor="validateToggle">Validate ONLY</label>
                                            </div>
                                        </div>
                                        
                                        <div className="dropzone dropzone-area ">
                                            <Dropzone onDrop={acceptedFiles => upload(acceptedFiles)}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className="bg-light-error">
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <p className="dz-message">CSV hier ablegen oder klicken, um eine Datei auszuwählen</p>
                                                    </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                </div>

                                <div className="error-box">
                                    {
                                        report?.errors ?
                                            _.map(report.errors, errMsg => {
                                                return <div className="alert alert-danger alert-dismissible mb-xl-0 fade show" role="alert">{`ROW: ${errMsg.row} | ERROR: ${ (typeof errMsg.error !== 'string') ? errMsg.error.toString() :errMsg.error  }`}</div>
                                            }) 
                                        :''
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={`col-md-4`}>
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="position-relative d-inline-block">
                                    <Iconv name="apps.databoss" size="5" classList={`text-primary `} />
                                    
                                    <p id="progressMsg" className="mt-2 mb-4">Status</p>

                                    <ul className="list-inline mb-0 mt-4">
                                        <li className="list-inline-item avatar-sm">
                                            <a className={`avatar-title bg-soft-${ report?.bucket?.multi && report.bucket.multi > 0 ? 'success':'danger' }  fs-15 rounded`}>
                                                <Iconv name="bucket.multi" size="2" classList={`text-${ report?.bucket?.multi && report.bucket.multi > 0 ? 'success':'danger' }`}/>
                                                <span className={`position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-${ report?.bucket?.multi && report.bucket.multi > 0 ? 'success':'danger' }`} >{report?.bucket ? report.bucket.multi : '0'}</span>
                                            </a>
                                        </li>

                                        <li className="list-inline-item avatar-sm">
                                            <a className={`avatar-title bg-soft-${ report?.bucket?.single && report.bucket.single > 0 ? 'success':'danger' }  fs-15 rounded`}>
                                                <Iconv name="bucket.single" size="2" classList={`text-${ report?.bucket?.multi && report.bucket.multi > 0 ? 'success':'danger' }`} />
                                                <span className={`position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-${ report?.bucket?.single && report.bucket.single > 0 ? 'success':'danger' }`} >{report?.bucket ? report.bucket.single : '0'}</span>
                                            </a>
                                        </li>

                                        <li className="list-inline-item avatar-sm">
                                            <a className={`avatar-title bg-soft-${ report?.sensor && report.sensor > 0 ? 'success':'danger' }  fs-15 rounded`} >
                                                <Iconv name="apps.sensor" size="2" classList={`text-${ report?.sensor && report.sensor > 0 ? 'success':'danger' }`} />
                                                <span className={`position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-${ report?.sensor && report.sensor > 0 ? 'success':'danger' }`} >{report?.sensor ? report.sensor : '0'}</span>
                                            </a>
                                        </li>

                                        <li className="list-inline-item avatar-sm">
                                            <a className={`avatar-title bg-soft-${ report?.gateway && report.gateway > 0 ? 'success':'danger' }  fs-15 rounded`}>
                                                <Iconv name="apps.gateway" size="2" classList={`text-${ report?.gateway && report.gateway > 0 ? 'success':'danger' }`} />
                                                <span className={`position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-${ report?.gateway && report.gateway > 0 ? 'success':'danger' }`} >{report?.gateway ? report.gateway : '0'}</span>
                                            </a>
                                        </li>

                                        <li className="list-inline-item avatar-sm">
                                            <a className={`avatar-title bg-soft-${ report?.errors && report.errors.length < 1 ? 'success':'danger' }  fs-15 rounded`}>
                                                <Iconv name="errors" size="2" classList={`text-${ report?.errors && report.errors.length < 1 ? 'success':'danger' }`} />
                                                <span className={`position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-${ report?.errors && report.errors.length < 1 ? 'success':'danger' }`} >{report?.errors ? report.errors.length : '0'}</span>
                                            </a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>

                   
                </div>

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

DataBoss.requiredScope = ['sensor.create.own','bucket.create.own','gateway.create.own'];
DataBoss.requireAuth = true;
export default DataBoss;