const lang = {
    login: {
        common: {
            forgot_password: 'Hai dimenticato la password?',
            login: 'Accesso',
            no_account: 'Non hai un account?',
            no_account2: 'Registrati qui',
            password: 'Password'
        },
        login: {
            title: 'Accedi al tuo Account',
            submit: 'Accesso'
        },
        pwl_login: {
            title: 'Accedi al tuo Account',
            submit: 'Richiedi Password di Uso Unico (OTP)'
        },
        pwl_passwd: {
            title: 'Inserisci il tuo OTP',
            description: 'Una password di uso unico è stata inviata al tuo indirizzo email registrato o alla tua applicazione di chat.',
            submit: 'Accesso'
        },
        twofa: {
            title: 'Questo account richiede l’Autenticazione a Due Fattori',
            submit: 'Verifica Token'
        },
        /**
         * Pagine di Errore
         */
        error: {
            "404": {
                h1: 'Oh no..',
                message: 'Questa pagina non esiste!',
                goback: `...ma non preoccuparti, torna semplicemente <a class="text-primary" href="/">alla home</a> e riprova.`
            },
            "401": {
                h1: 'Oh no..',
                message: 'Probabilmente non sei autorizzato a fare ciò che stai tentando.',
                goback: `...ma non preoccuparti, torna semplicemente <a class="text-primary" href="/">alla home</a> e non riprovare.`
            },
        },
        /**
         * Messaggi di errore per il feedback dell'interfaccia utente
         */
        errorMessages: {
            invalidCredentials: 'CREDENZIALI NON VALIDE',
            error304: 'ERRORE: 304',
            emailVerificationSent: 'Un codice di verifica e-mail è stato inviato al tuo indirizzo e-mail registrato. Una volta verificato il tuo indirizzo e-mail, il tuo account sarà attivo.',
            emailUnverified: 'Il tuo indirizzo e-mail non è stato verificato. Per favore, riprova.',
            emailVerified: 'Il tuo indirizzo e-mail è stato verificato. Ora puoi utilizzarlo per accedere.',
            emailExists: 'Esiste già un account con quell\'indirizzo e-mail. Per favore, prova ad accedere invece.',
            registrationNotAllowed: 'Questo client non consente la registrazione degli utenti.',
            somethingWrong: 'QUALCOSA È ANDATO STORTO'
        },
    
        home: {
            header: {
                home: 'Home',
                features: 'Funzionalità',
                about: 'Informazioni'
            },
            hero: {
                subtitle: "Ottieni Autenticazione e Identificazione dell’Utente istantanea senza configurazione",
                login: 'accesso'
            },
            features: {
                f1: {
                    "title": "Conformità ai Dati Basata nell’UE",
                    "body": 'Vivi la tranquillità con i nostri servizi conformi al GDPR, alimentati da server strategicamente situati in Germania per una protezione e privacy dei dati ottimali.'
                },
                f2: {
                    "title": 'Standard di Crittografia Avanzati',
                    "body": "Proteggi ogni bit di dati personali con la nostra crittografia all’avanguardia, che salvaguarda le informazioni sia in transito che a riposo, potenziata dalle più recenti tecnologie di hashing delle password per una sicurezza senza pari."
                },
                f3: {
                    "title": 'Autenticazione Multifattoriale Flessibile',
                    "body": 'Potenzia la tua sicurezza con l’Autenticazione Multifattoriale (MFA) personalizzabile. Scegli tra impostazioni MFA opzionali o obbligatorie per bilanciare perfettamente comodità e protezione.'
                },
                f4: {
                    "title": 'Opzioni di Login Versatili',
                    "body": "Offri ai tuoi utenti la comodità del login tradizionale con nome utente/password o la semplicità del login senza password, OTP (Password di Uso Unico) per un'esperienza di autenticazione fluida."
                },
                f5: {
                    "title": 'Politiche di Password Rigide',
                    "body": "Eleva la sicurezza con le nostre rigide politiche di password: applica il divieto di riutilizzo delle password, implementa requisiti di password rigorosi e gestisci facilmente il reset delle password, eliminando la vulnerabilità del 'Password Dimenticata'."
                },
                f6: {
                    "title": 'Persistenza Remota Controllata',
                    "body": "Ottieni il controllo completo sulla persistenza dell’autenticazione. Gestisci le sessioni degli utenti senza sforzo con cookie locali e capacità di annullamento dell'autorizzazione remota per una sicurezza migliorata."
                },
                f7: {
                    "title": 'Integrazione Flessibile: API o Relay',
                    "body": 'Integra senza problemi utilizzando la nostra interfaccia di login standard, relayabile, compatibile con soluzioni Whitelabel, o personalizza la tua esperienza di login con la nostra API versatile.'
                },
                f8: {
                    "title": "OAUTH2 All’Avanguardia con PKCE",
                    "body": 'Sfrutta gli standard OAuth2 più recenti con metodi PKCE, garantendo la massima sicurezza. Che tu utilizzi segreti del cliente o PKCE, fidati dei nostri rigorosi processi di verifica.'
                },
                f9: {
                    "title": 'Soluzioni Scalabili',
                    "body": 'Cresci senza limiti con IRONAUTH3. La nostra soluzione scalabile si adatta senza sforzo alla tua base di clienti in espansione, garantendo nessun limite alla tua crescita senza costi aggiuntivi.'
                }
            },
            "about": {
                "ab1": {
                    "title": 'Impegno Inesorabile per la Privacy dei Dati',
                    "body": "Al centro del nostro servizio c’è un forte impegno per la tua sovranità dei dati. Non vendiamo né monitoriamo le tue informazioni utente, assicurandoti un controllo esclusivo. Inoltre, ti forniamo la possibilità di scaricare l'intero database utenti, facilitando i trasferimenti di dati senza sforzo quando necessario."
                },
                "ab2": {
                    "title": 'Integrazione di Flusso di Lavoro Agnostica del Dispositivo',
                    "body": 'IronAuth si integra senza problemi con tutti i dispositivi moderni, offrendo un processo di configurazione e esecuzione user-friendly. Personalizza la tua esperienza utente con la nostra API adattabile o utilizza il nostro flusso di lavoro integrato efficiente per soddisfare le tue esigenze uniche.'
                }
            }  
        }
    },
    currency: {
        symbol: "€",
        label: "EUR"
    },
    lang: {
        language: "Language",
        english: "English",
        german: "German"
    },
    user: {
        profile: "Account",
    },
    navbar: {
        login: "Login",
        logout: "Logout",
        dashboard: "Dashboard",
        register: "Register"
    },
    dataTables: {
        loading: "Loading ..."
    },
    swal: {
        titles:{
            "Are you sure": "Are you sure?"
        },
        texts: {
            shortLinkDelete: "Do you really want to delete short link: "
        },
        buttons: {
            confirmButtonText1: "Yes",
            cancelButtonText1: "Cancel"
        }
    }

    
}

module.exports = lang;
