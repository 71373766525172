import React, { useState, Fragment, useEffect } from 'react';

const LinearProgressBar = (props) => {
    
    useEffect(()=>{ 

    }, [props])
  
    return (
      <Fragment>
        <div className="progress" style={{height: props.height+"rem"}}>
          <div className={`progress-bar progress-bar-striped progress-bar-animated bg-${ (props.value < 100)?'primary':'success' }`} role="progressbar" style={{width: props.value+'%'}} aria-valuenow={props.value} aria-valuemin="0" aria-valuemax="100">{props.value+"%"}</div>
        </div>
      </Fragment>  
    );
}
  
export default LinearProgressBar;