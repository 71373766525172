import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import { AuthContext } from '../Auth/AuthProvider';
import { useNavigate } from "react-router-dom";
import {
    errorToast,
    successToast,
    PROXY_CLIENT,
    capitalizeFirst
} from '../../libraries/utils';
import _ from 'lodash';
import Swal from '@nmesys/sweetalert2'

export const AccountContext = createContext();

const AccountProvider = ({children}) => {

    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const [account, setAccount] = useState(null); // This is the account the user blongs to
    const [accounts, setAccounts] = useState([]); // A user may have many accoutns which they own

    const userChanged = useCompare(user);
    const accountChanged = useCompare(account);
    const accountsChanged = useCompare(accounts);

    useEffect( () => {
        if(userChanged && user?.user_id) getAccounts();
    }, [user])

    useEffect( () => {
        if(accountsChanged && accounts.length && !account?.account_id) setAccount(accounts[0]);

    }, [accounts])

    useEffect( () => {
        
    }, [account])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    /**
     * Gets the account which the user belongs to however the actual
     * user will not have access to view this account. Only the Account Owner
     * can view their own account.
     */
    const getAccount = async (account_id) => {
        //console.log('GETTING ACCOUNT', account_id)
        if(!account_id) return;
        return new Promise( async (resolve, reject) => {
            PROXY_CLIENT().get(`${process.env.REACT_APP_API}/account/?account_id=${account_id}`)
            .then( response => {
                console.log('ACCOUNT RESPONSE', response.data)
                return resolve(response.data)
            })
            .catch( err => {
                //console.log('ACCOUNT FETCHING ERROR 69', err)
                // If 401, user may not be account owner and thus not authorized to view. Is Ok.
            })
        });
    }

    const getAccounts = async () => {
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/account/all`)
        .then(response => {
            if( response?.data  ) setAccounts(response.data)
        })
        .catch(err => {
            console.log('ACCOUNTS PROVIDER: ERROR', err)
        })
    }

    const createAccount = (obj) => {
        //console.log('CREATING USER ACCOUNT', obj)
        return new Promise( async (resolve, reject) => {
            PROXY_CLIENT().post(`${process.env.REACT_APP_API}/account/`, obj)
            .then( async response => {
                if(response.data.account_id){
                    getAccounts()
                    return resolve(response.data)
                }
            })
            .catch( err => {
                console.log('ACCOUNTS FETCHING ERROR 99', err)
                errorToast('Something went wrong.')
            })
        });
    }

    const update = () => {

    }

    const creatAccountModal = (type = 'standard') => {
        const html = `
            <form id="frmAccount">
                <div class="overflow-hidden">
                    <div class="form-group mb-4">
                        <input class="form-control mb-1" type"text" name="account.name" placeholder="Account Name"/>
                        <small>You should add a name to this account to make it easier to identify. (ex: Lucky Immobilien GmbH)</small>
                    </div> 
                    
                    <section id="frmContact" class={} >
                        <div class="mb-4">
                            <label htmlFor="name" class="form-label">Contact</label>
                            <div class="row mb-4">
                                <div class="col-6">
                                    <input type="text" class="form-control" name="contact.first_name" placeholder='First Name'required/>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-control" name="contact.last_name" placeholder='Last Name'required/>
                                </div>
                            </div>
                            <div class="mb-4">
                                <input type="text" class="form-control" name="contact.company_name" placeholder="Company Name"/>
                            </div>
                            <div class="mb-4">
                                <input type="text" class="form-control" name="contact.job_position" placeholder="Job Position"/>
                            </div>
                        </div>
                    </section>

                    <div class="mb-4">
                        <section id="frmAddress" >
                            <label htmlFor="name" class="form-label">Address</label>
                            <div class="row mb-4">
                                <div class="col-6">
                                    <input type="text" class="form-control" name="address.street" placeholder='Street'required/>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" name="address.house_number" placeholder='Number'required/>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" name="address.street1" placeholder='Apt/Office' />
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-4">
                                    <input type="text" class="form-control" name="address.post_code" placeholder='PostCode'required/>
                                </div>
                                <div class="col-8">
                                    <input type="text" class="form-control" name="address.city" placeholder='City'required/>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-12">
                                    <label htmlFor="country" class="form-label">Country</label>
                                    <select class="form-select" name="address.country" aria-label="country select" defaultValue={'de'}>
                                        <option value="at" >Austria</option>
                                        <option value="dk" >Danmark</option>
                                        <option value="de" selected="true">Deutschland</option>
                                        <option value="fr" >France</option>
                                        <option value="it" >Italia</option>
                                    </select>
                                </div>
                            </div>
                        </section>

                        <hr />

                        <section class="" >
                            <div class="row mb-4" >
                                <div class="col-12">
                                    <input type="text" class="form-control" name="contact.phones.0.number" placeholder='Phone (Work)'/>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-12">
                                    <input type="text" class="form-control" name="contact.emails.0.address" placeholder='Email (Primary)'/>
                                </div>
                            </div>
                        </section>
                        
                        <hr />

                        <div class="row mb-4">
                            <div class="col-6">
                                <input type="text" class="form-control" name="contact.vat" placeholder='VAT Nmr'/>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-12">
                                <input type="text" class="form-control" name="contact.website" placeholder='Website'/>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        
        `;

        Swal.fire({
            title: `Add a new ${capitalizeFirst(type)} Account`,
            icon: 'info',
            width: '60%',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Create this account',
            html,
            preConfirm: (inputValue) => {
                const form = Swal.getPopup().querySelector('#frmAccount');
                const formData = new FormData(form);
                const params = Array.from(formData.entries()).reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {});

                return params;
            }
        }).then(async results => {
            if (results.isConfirmed) {
                console.log('RESULTS', results)

                const nestedObject = {};
                _.forEach(results.value, (value, key) => {
                  _.set(nestedObject, key, value);
                });
                
                let {address, contact, account} = nestedObject;
                const frmAccount = nestedObject.account;

                console.log(nestedObject)

                //const newContact = await createContact( {...contact, address} );

                //const newContact = await PROXY_CLIENT().post(`${process.env.REACT_APP_API}/contact`, {...contact, address})
               //if(!!newContact?.data?.contact_id) frmAccount.contact_id = newContact.data.contact_id
                const accountCreated = await createAccount({...account, ...contact, address: nestedObject.address})
                Swal.close();
                navigate('/dashboard');
            }
        }).catch(err => {
            console.log(err)
            errorToast('Something went wrong with creating your account. Please try again or contact your service admin.');
        })
    }
    
    const params = {
        getAccounts,
        getAccount,
        createAccount,
        setAccount,
        account,
        accounts,
        creatAccountModal
    }

    return (
        <AccountContext.Provider value={params}>
            {children}
        </AccountContext.Provider>
    )
}

export default AccountProvider;