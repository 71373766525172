import React, { Fragment, useState, useEffect } from 'react';
import uniqid from 'uniqid';
import { renderToString } from 'react-dom/server'
import x from 'uniqid';

/**
 * USE:  <ICONV name="apps.dashboard" size=5 classList="text-danger" id="myIcon"/>
 * The icon name may be given as dot notation in reference to the icons constant below.
 * in the abscence of a props.name or if the icon does not exist, you will get the beating question mark.
 * You may also set size and add other class items to this icon
 */
const Iconv = (props) => {
    const icons = {
        default: "text-danger fa-solid fa-question fa-beat-fade",
        errors: "fa-solid fa-flag",
        apps:{
            dashboard: 'fa-solid fa-gauge',
            sensor: "fa-solid fa-microchip",
            gateway: "fa-solid fa-router",
            crm: "fa-solid fa-address-book",
            bucket: "fa-solid fa-building",
            tenant: "fa-solid fa-house-user",
            invoice: "fa-solid fa-file-invoice",
            organization: "fa-solid fa-user-group",
            alert: "fa-solid fa-bell",
            alarm: "fa-solid fa-siren-on",
            databoss: "fa-solid fa-database",
            member: "fa-solid fa-user-tie",
        },
        bucket: {
            structural: "fa-solid fa-building",
            geographical:"fa-solid fa-globe-stand",
            single: "fa-solid fa-house",
            multi: "fa-solid fa-building",
            tenant: "fa-solid fa-house-user",
            location: "fa-solid fa-location-dot",
            city: "fa-solid fa-city",
            children: "fa-solid fa-list-tree"
        },
        sensors: {
            rssi: {
                strong: "fa-solid fa-signal text-success",
                good: "fa-duotone fa-signal-strong text-success",
                fair: "fa-duotone fa-signal-fair text-warning",
                weak: "fa-duotone fa-signal-weak text-danger",
                none: "fa-duotone fa-signal-bars-slash text-danger"
            },
            method:{
                wmbus: 'fa-solid fa-wifi',
                mbus: 'fa-solid fa-swap-arrows',
                lora: 'fa-regular fa-circle-nodes',
                mioty: 'fa-regular fa-chart-network',
                nbiot:'fa-solid fa-diagram-project'
            },
            "0x0": {
                standard: "fa-solid fa-microchip"
            },
            "0x4": {
                standard: "text-warning fa-solid fa-fire",
                wsr: "fa-solid fa-droplet text-danger",
                hzg: "fa-solid fa-fire text-danger",
            },
            "0x7": {
                standard: "fa-solid fa-droplet text-primary"
            },
            "0x8": { 
                standard: "fa-solid fa-fire text-primary"
            },
            "0x6": { 
                standard: "fa-solid fa-droplet text-warning"
            },
            "0xc": {
                standard: "text-warning fa-solid fa-fire",
                wsr: "fa-solid fa-droplet text-danger",
                hzg: "fa-solid fa-droplet text-fire",
            }
        },
        gateways: {
            battery: {
                full: "fa-solid fa-battery-full text-success",
                three_quarter: "fa-solid fa-battery-three-quarters text-success",
                half: "fa-solid fa-battery-half text-primary",
                low: "fa-solid fa-battery-low text-warning",
                empty: "fa-solid fa-battery-empty text-danger",
                unknown: "fa-solid fa-battery-exclamation text-primary",
            },
        },
        dataType: {
            home: "fa-solid fa-home",
            work: "fa-solid fa-building",
            billing: "fa-solid fa-money-bills",
            shipping: "fa-solid fa-truck-fast",
            mobile: "fa-solid fa-mobile",
            fax: "fa-solid fa-fax",
            contact: "fa-solid fa-address-book",
            user: "fa-solid fa-user",
            organization: "fa-solid fa-sitemap"
        },
        socialMedia: {
            website: "fa-regular fa-globe-pointer",
            telegram: "fa-brands fa-telegram text-info",
            facebook: "fa-brands fa-facebook-f",
            instagram: "fa-brands fa-instagram",
            x: "fa-brands fa-x-twitter",
            pinterest: "fa-brands fa-pinterest",
            whatsapp: "fa-brands fa-whatsapp",
            skype: "fa-brands fa-skype",
            zoom: "fa-solid fa-video",
            discord: "fa-brands fa-discord"
        },
        socket: {
            connected: "fa-solid fa-plug-circle-check text-success",
            disconnected: "fa-solid fa-plug-circle-xmark text-danger"
        }
        
    }
    

    const [id, setId] = useState( uniqid() );
    const [size, setSize] = useState( 1 );
    const [classList, setClassList] = useState( '' );
    const [name, setName] = useState( icons['default'] );
    const [title, setTitle] = useState('');
   
    useEffect(  () => {
        if(!!props.id) setId(props.id);
        if(!!props.size) setSize(props.size);
        if(!!props.classList) setClassList(props.classList);
        if(!!props.title) setTitle(props.title);
        if(!!props.name) {
            let icon = props.name.split('.').reduce( (k,v) => k[v], icons)
            if(!!icon) setName( icon )
        }
    }, [props, id, size, classList, name]);

   

    const UI = () => {
        return (
            <i className={`${classList} ${name} fa-${size}x `} id={id} title={title} />
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default Iconv;