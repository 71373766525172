import React, {useEffect} from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'react-bootstrap-typeahead/css/Typeahead.css';

import LocaleProvider from './context/Locale/Locale';
import AuthProvider from './context/Auth/AuthProvider';
import AccountProvider from './context/Account/AccountProvider';
import AuthGuard from './context/Auth/AuthGuard';
import NotificationProvider from './context/Notification/NotificationProvider';
import ContactProvider from './context/Contact/ContactProvider';
import TenantProvider from './context/Tenant/TenantProvider';
import AddressProvider from './context/Address/AddressProvider';
import MembersProvider from './context/Members/MembersProvider';

import BucketProvider from './context/Bucket/BucketProvider';

import SensorProvider from './context/Sensor/SensorProvider';
import GatewayProvider from './context/Gateway/GatewayProvider';
import SocketProvider from './context/Socket/SocketProvider';

import LayoutHandler from "./components/Layout/LayoutHandler";
//import ClientProvider from "./context/Client/ClientContext";

import LoginPage from "./pages/Auth/login";
import RegisterPage from "./pages/Auth/register";
import PasswordPage from "./pages/Auth/password";
import TwoFactorPage from "./pages/Auth/twofa";
import LogoutPage from "./pages/Auth/logout";


import HomePage from "./pages/home";
import AccountWizard from "./pages/Account/account_wizard";
import DashboardOverview from "./pages/Dashboards/overview";
import Tenants from './pages/Apps/tenants';

//import Buckets from './pages/Apps/buckets';
import BucketDetails from './pages/Details/bucketDetails';
import Sensors from './pages/Apps/sensors';
import SensorDetails from './pages/Details/sensorDetails';
import Gateways from './pages/Apps/gateways';
import GatewayDetails from './pages/Details/gatewayDetails';
import DataBoss from './pages/Apps/databoss';

import ReportBuilder from './pages/Reports/reportBuilder';

/** 

import SocketProvider from './context/Socket/SocketProvider';
import NotificationProvider from './context/Notification/NotificationProvider';

import ShortLinks from './pages/Apps/shortlinks';
import Profile from './pages/Apps/profile';
import ProfileSettings from './pages/Apps/profile_settings';
import CrmContacts from './pages/Apps/contacts';
import ReportBuilder from './pages/Reports/reportBuilder';

import BucketDetails from './pages/Details/bucketDetails';

import PaymentDetails from './pages/Payments/details';
import PaymentRedirect from './pages/Payments/redirect';

import InvoicesListView from './pages/Invoice/listView';
import InvoicesDetailView from './pages/Invoice/details';

*/
const App = ({children, props}) => {

    useEffect(() => {}, []);

    return (
        <LocaleProvider>
            <AuthProvider>
                <NotificationProvider>
                <SocketProvider> 
                    <AccountProvider>
                        <AddressProvider>
                        <ContactProvider>
                        <TenantProvider>
                        <BucketProvider>
                        <SensorProvider>
                        <GatewayProvider>
                        <MembersProvider>



                            <LayoutHandler>
                                <Routes>

                                    <Route exact path='/auth/login' element={<LoginPage/>} />
                                    <Route exact path='/auth/register' element={<RegisterPage/>} />
                                    <Route exact path='/auth/password' element={<PasswordPage/>} />
                                    <Route exact path='/auth/twofa' element={<TwoFactorPage/>} />
                                    <Route exact path='/auth/logout' element={<LogoutPage/>} />

                                    <Route exact path='/' element={<HomePage/>} /> 
                                    <Route exact path='/home' element={<HomePage/>} />

                                    <Route exact path='/dashboards/overview' element={<AuthGuard children={children}><DashboardOverview/></AuthGuard>} />
                                    <Route exact path='/dashboard' element={<AuthGuard children={children}><DashboardOverview/></AuthGuard>} />


                                    <Route exact path='/account/wizard' element={<AuthGuard children={children}><AccountWizard/></AuthGuard>} />

                           {/**          <Route exact path='/apps/buckets/list' element={<AuthGuard children={children}><Buckets/></AuthGuard>} />  */}
                                    <Route exact path='/details/bucket' element={<AuthGuard children={children}><BucketDetails/></AuthGuard>} />

                                    <Route exact path='/apps/tenants/list' element={<AuthGuard children={children}><Tenants/></AuthGuard>} />

                                    <Route exact path='/apps/sensors/list' element={<AuthGuard children={children}><Sensors/></AuthGuard>} />
                                    <Route exact path='/details/sensor' element={<AuthGuard children={children}><SensorDetails/></AuthGuard>} />

                                    <Route exact path='/apps/gateways/list' element={<AuthGuard children={children}><Gateways/></AuthGuard>} />
                                    <Route exact path='/details/gateway' element={<AuthGuard children={children}><GatewayDetails/></AuthGuard>} />


                                    <Route exact path='/apps/databoss' element={<AuthGuard children={children}><DataBoss/></AuthGuard>} />

                                    <Route exact path='/report/builder' element={<AuthGuard children={children}><ReportBuilder/></AuthGuard>} />



                                </Routes>
                            </LayoutHandler>

                        </MembersProvider>
                        </GatewayProvider>
                        </SensorProvider>
                        </BucketProvider>
                        </TenantProvider>
                        </ContactProvider>
                        </AddressProvider>
                    </AccountProvider>
                </SocketProvider>  
                </NotificationProvider>
            </AuthProvider>
        </LocaleProvider>  
    )

}

export default App;