import React, {Fragment, useState, useEffect, createContext, useContext, useRef, cloneElement, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setCookie, PROXY_CLIENT } from '../../libraries/utils';
//import { SocketContext } from '../Socket/SocketProvider';
import { debounce } from 'lodash';
import Loading from '../../components/Loaders/Loading';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    const locationChanged = useCompare(location);
    const [user, setUser] = useState(null);
    
    const [isAuthenticated, setAuthenticated] = useState(false)
    const userChanged = useCompare(user);
    // Check the authentication status on every path change

    useEffect( () => {
        authenticateUser()
    }, []) 

    useEffect( () => {
        //authenticateUser()
    }, [location]) 

    useEffect(   () => {
        if(userChanged && !!user) setLoading(false)
    }, [user])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const generate = (length) => {
        let string = "";
        let stringLength = length !== undefined || null ? length : 64;
        let possible = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789";
        for (let i = 0; i < stringLength; i++){
            string += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return string;
    }

    const setRedirect = (redirect) => {
        setCookie('redirect', redirect)
    }

    const genKey = () => {
        let KEY = localStorage.getItem('APP_KEY'); //Cookie.get('APP_KEY')
        if(!KEY){
            KEY = generate(32) //For AES encryption, length must be 32
            localStorage.setItem('APP_KEY', KEY);
            //setCookie('APP_KEY', KEY, COOKIE_PARAMS)
        }
        return KEY;
    }

    const getUser = () => {
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/auth/user`)
        .then(response => {
            if( response.data.user_id ) setUser(response.data)
            
        })
        .catch(err => {
            console.log('AUTH PROVIDER: USER AUTHENTICATION ERROR', err)
            setUser(null);
        })
    }
    
    const authenticateUser = debounce(async () => {
        //console.log('AUTH PROVIDER: AUTHENTICATING USER', `${process.env.REACT_APP_API}/auth/webtoken`, process.env.REACT_APP_TEST_VAR)
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/auth/webtoken`, {
            headers: {
                //PREVENT CACHE OF THE WEBTOKEN RESPONSE
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0',
                'x-appkey': localStorage.getItem('APP_KEY')
            }
        })
        .then(response => {
            if(!response) return navigate('/auth/login')
            if(!response?.data) setUser(null);
            if(
                response.data.isAuthenticated === false
                && location.pathname.search('auth') === -1
            ) navigate('/auth/login')
            
            const rUser = response.data.user;
            if( rUser?.user_id ) setUser(rUser);
            if( !rUser || !rUser?.user_id ) setUser(null);
            setLoading(false);
        })
        .catch(err => {
            console.log(err)
            if(err?.response?.status && err.response.status === 401){
                setUser(null);
                setLoading(false)
                return navigate('/auth/login')
                //return navigate('/')  // Normally go home but as this page is not developed well yet
            }else{
                console.log('AUTH PROVIDER: USER AUTHENTICATION ERROR', err)
            }
        })

    }, 1000)

    const hasPermission = ({ roles = [], scopes = [], user }) => {
        /**
         * You will need a GOD or some SUPER ADMIN role as user number 1. This user
         * will have unrestricted access.
         */
        if(!user) return false;
        if(user.roles.includes('god') ) return true;
    
        let permissionGranted = false;
        let roles_intersection = roles.filter(r => user.roles.includes(r))
        if(roles_intersection.length > 0) permissionGranted = true;
        let scopes_intersection = scopes.filter(s => user.scopes.includes(s))
        if(scopes_intersection.length > 0) permissionGranted = true;
        return permissionGranted;
    
    };

    const PermissionsWrapper = ({
        children,
        scopes = [],
        roles = [],
        user,
        RenderError = () => <></>,
        errorProps = null
    }) => {
        /**
         * If a user object is not provided, then return nothing as we do not
         * know the permissions level nor do we want to render special children yet.
         */
        if(!user) return (<Fragment></Fragment>)
        if( !user?.roles || !user?.scopes ) return <></>;
    
        const permissionGranted = hasPermission({ roles, scopes, user });
        if (!permissionGranted && !errorProps) return <RenderError />;
        if (!permissionGranted && errorProps) return cloneElement(children, { ...errorProps });
    
        return <>{children}</>;
    }

    const login = () => navigate('/auth/login');
    const logout = () => {
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/auth/logout`)
        .then(response => {
            setUser(null);
            return navigate('/')
        })
        .catch(err => {
            console.log('AUTH PROVIDER: USER AUTHENTICATION ERROR', err)
            setUser(null);
            return navigate('/')
        })
        //setUser(null);
        //navigate('/auth/logout');
    }
    const register = () => navigate('/auth/register');

    const auth = useMemo(() => ({
        login,
        logout,
        register,
        user, // Assume this can change and should trigger a recalculation of `auth`
        authenticateUser,
        hasPermission,
        PermissionsWrapper,
        setUser,
        setRedirect,
    }), [user]);

    const UI = () => {
        if(loading && !location.pathname.startsWith('/auth')) return <Loading text="Authenticating"/>
        else return (
            <AuthContext.Provider value={auth}>
                {children}
            </AuthContext.Provider>
        )
    }

    return UI();
}

export default AuthProvider;
