import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { GatewayContext } from '../../context/Gateway/GatewayProvider';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import { SensorContext } from '../../context/Sensor/SensorProvider';
import { uniqueId } from 'lodash';
import _ from 'lodash'

import de from '../DataTables/datatable.de.json';
import Iconv from '../iconv';
const $ = require('jquery')
$.DataTable = require('datatables.net');

const TblInsight = (props) => {

    const { gateway, gateways, setGateway } = useContext(GatewayContext);
    const { bucket, buckets } = useContext(BucketContext);
    const { lang, locale } = useContext(LocaleContext);
    const {sensors, sensor_ids} = useContext(SensorContext); //Full array of account sensor_ids
    const [gatewaySensors, setGatewaySensors] = useState([]);  // Sensor which are listed in insight.history

    const gatewayChanged = useCompare(gateway)
    const gatewaySensorsChanged = useCompare(sensor_ids)


    useEffect( () => {

        console.log('GATEWAY', gateway)

       if( (gatewayChanged && !!gateway?.serial_number) || gatewaySensorsChanged) initializeDT()

    }, [gateway, sensor_ids]);

    
    
    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
  
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => { ref.current = value });
        return ref.current;
    }

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleDateString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
        return formattedDate;
    }

    const initializeDT = async () => {

        if(!gateway?.insight?.history) return
        const tableElement = $('#tblGatewayInsights');
        const dataTable = $.fn.dataTable.isDataTable(tableElement);
        if (dataTable) {
            tableElement.DataTable().destroy();
            tableElement.empty();
        }
        $(`#tblGatewayInsights tbody`).off();

        if (gateway?.insight?.history && gateway.insight.history.length > 0) {
            const table = $(`#tblGatewayInsights`).DataTable({
                language: locale,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: gateway.insight.history,
                rowId: uniqueId(),
                pageLength: 25,
                orderCellsTop: false,
                fixedHeader: true,
                order: [
                    [0, "desc"],
                    [2, "asc"],
                ],
                columns: [
                    {
                        "data": null,
                        "searchable": true,
                        "visible": false,
                        "ordering": true,
                        render: function (data, type, row) {
                            return data.date
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return formatDate(data.date)
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return data.serial_number
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return data.rssi
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return data.device_type
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            let included = sensor_ids.includes(data.serial_number);  // _.some(sensors, { serial_number: data.serial_number });
                            if(included) return (`<i class="fa-solid fa-check text-success" ><span class="d-none">${included}</span></i>`)
                            return (`<i class="fa-solid fa-x text-danger" ><span class="d-none">${included}</span></i>`)
                        }
                    }

                ],
                createdRow: function (row, data, dataIndex) {
                    let included = _.some(sensors, { sensor_id: data.serial_number });
                    if (included) {
                        $(row).addClass('table-success');
                    } else {
                        $(row).removeClass('table-success');
                    }
                },
                createdCell:function (td, cellData, rowData, row, col) {},
            "fnDrawCallback": function( oSettings ) {}
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $(`#tblGatewayInsights tbody tr`).off('mouseenter').on('mouseenter', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });

            $(`#tblGatewayInsights tbody tr`).off('mouseleave').on('mouseleave', function () {
                $(this).css('background-color', 'transparent');
            });

            $(`#tblGatewayInsights tbody`).on('click', 'tr', function (e) {
                let parent = e.currentTarget.closest('tr');
                let target = gateways.filter(g => g.gateway_id === parent.id);
               // setGateway(target[0])
            });
        }  
    }

    return (
        
        <div className="card " >
            <div className="card-header">
                <div className="row">
                    <div className="col-9">
                        <h4>Sensors Insight</h4>
                        <small className="w-50">Insight includes all sensors (registered and non-registered) which are seen by a gateway at a given time. Use it to determin your sensor coverage and to analyze the validity of your serial numbers.</small>
                    </div>
                    
                </div>
                
            </div>
            <div className="card-body">
                <table id={`tblGatewayInsights`} className="table mb-0 row-border hover order-column w-100 table-hover" role="grid">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Date</th>
                            <th>Serial Number</th>
                            <th>RSSI</th>
                            <th>Type</th>
                            <th>Registered?</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    )

}
export default TblInsight;