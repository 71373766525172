import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import { SensorContext } from '../../context/Sensor/SensorProvider';

import uniqid from 'uniqid';
import Iconv from '../iconv';
import _ from 'lodash';

const Chart = React.lazy(() => import('react-apexcharts'), { ssr: false });

const ConsumptionChart = (props) => {

    const [data, setData] = useState([]);
    const [err, setError] = useState(false);

    const { bucket, buckets } = useContext(BucketContext);
    const { sensors } = useContext(SensorContext);

    const [year, setYear] = useState( props.year ? props.year : new Date().getFullYear() - 1)
    const [years, setYears] = useState([]);
    const [height, setHeight] = useState( props.height ? props.height : 250)

    const bucketChanged = useCompare(bucket);
    const yearChanged = useCompare(year);

    useEffect( () => {

        if(bucketChanged === true ) chartBuilder()

    },[ props, height, bucket ]);

    useEffect( () => {
        
    },[ data ]);

    useEffect( () => {
        if(yearChanged === true) chartBuilder()
    },[ year, years ]);


    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
  
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => { ref.current = value });
        return ref.current;
    }

    const renderChartIcon = (icon) => {
        return (<span><Iconv name={icon} /></span>)
    }

    const chartBuilder = async () => {
        if(!bucket || !bucket?.bucket_id) return
        const charts = []
        let sensorObjs = [], sObjs = []
        if(!bucket?.parent_id || bucket.parent_id.length === 0){
            //Then is likely a parent object.
            for(const b of buckets){
                if(b?.parent_id && b.parent_id === bucket.bucket_id){
                    sObjs = _.filter(sensors, sObj => sObj.bucket_id === b.bucket_id);
                    sensorObjs = sensorObjs.concat(sObjs);
                }
            }
        }else{
            // is a child object
            sensorObjs = _.filter(sensors, sObj => sObj.bucket_id === bucket.bucket_id);
        }
        /**
         * Now Group and aggregate the data
         */
        let aggregates = []
        let aggregatedMonthlyData = {}
        sensorObjs.forEach(s => { if(s?.aggregate) aggregates.push(s.aggregate)})
        const groupedByMedium = _.groupBy(aggregates, 'medium_code');
        _.mapValues(groupedByMedium, sensorAggregates => {
            // Collect all monthly aggregates from each sensor
            let medium_code = sensorAggregates[0].medium_code;
            if(!aggregatedMonthlyData?.[medium_code]) {
                aggregatedMonthlyData[medium_code] = {
                    sensor_ids: [],
                    monthlyValues: []
                }
            }
            const summedData = {};
            // Loop through each object in the array.
            sensorAggregates.forEach(obj => {
                aggregatedMonthlyData[medium_code].sensor_ids.push(obj.sensor_id)
                obj.monthlyAggregate.forEach(month => {
                    const monthKey = month.dateISO; // Use dateISO as the unique key for each month.
                    // If the month already exists in the summedData object, add the consumptionR value.
                    if (summedData[monthKey]) {
                        summedData[monthKey].consumptionR += month.consumptionR || 0;
                    } else {
                    // If the month doesn't exist yet, create it with the current consumptionR value.
                    summedData[monthKey] = {
                        date: month.date,
                        dateISO: month.dateISO,
                        consumptionR: month.consumptionR || 0
                    };
                    }
                });
            });
            aggregatedMonthlyData[medium_code].monthlyValues = Object.values(summedData);
        });
        /**
         * Begin chart creation
         */
        _.forEach(aggregatedMonthlyData, (data, mc) => {
            const {monthlyValues, sensor_ids} = data;
            let yearsAvailable = [];
            _.map(monthlyValues, mv => {
                let y = new Date(mv.dateISO).getFullYear();
                if(!yearsAvailable.includes(y)) yearsAvailable.push(y);
            })
            yearsAvailable.sort().reverse();
            setYears(yearsAvailable);

            const mdValues = _.chain(monthlyValues)
                              .filter(mv => {
                                    const date = new Date(mv.dateISO);
                                    date.setHours(date.getHours() - 24);
                                    return date.getFullYear() === year;
                              }) // Account for timezone and javascritp date handling
                              .sortBy("date")
                            //  .reverse()
                              .value();

            const consumptionRArray = []
            _.forEach(mdValues, mv => consumptionRArray.push(Number(mv.consumptionR.toFixed(2))))
            
            let id = `${mc}${year}`;
            let series = []
            let options = {
                chart: {
                    height: height,
                    type: 'line',
                    sparkline: {
                        show: true
                    },
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                colors: ['#ff0000'],
                title: {
                    text: '',
                    align: 'left'
                },
                xaxis: {
                    categories: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            return Math.round(val); // Round the value to the nearest integer
                        }
                    }
                }
            }
            let compareChart = {
                name: "Average",
                type: "bar",
                data: null,
            }

            switch(mc){
                case'0x8':
                    options.title.text = "HEIZUNGSVERBRAUCH IN KWH";
                    options.colors = ['#dc3545'];
                    options.icon =  renderChartIcon("sensors.0x8")
                    break;
                case'0x6':
                    options.title.text = "WARMWASSERVERBRAUCH IN KWH";
                    options.colors = ['#ffc107'];
                    options.icon = renderChartIcon("sensors.0x6")
                    break;
                case'0x7':
                    options.title.text = "KALTWASSERVERBRAUCH IN M3";
                    options.colors = ['#007bff'];
                    options.icon = renderChartIcon("sensors.0x7")
                    break;
                case'0x16':
                    options.title.text = "KALTWASSERVERBRAUCH IN M3";
                    options.colors = ['#007bff'];
                    options.icon = renderChartIcon("sensors.0x16")
                    break;
                case'0x4':
                    options.title.text = "HEIZUNGSVERBRAUCH IN KWH";
                    options.colors = ['#dc3545'];
                    options.icon = renderChartIcon("sensors.0x4")
                    break;
                case'0x3':
                    options.title.text = "GAS IN M3";
                    options.colors = ['#dc3545'];
                    options.icon = renderChartIcon("sensors.0x3")
                    break;
                case'0xa':
                    options.title.text = "KLIMA (KALT) IN KWH";
                    options.colors = ['#03c5fe'];
                    options.icon = renderChartIcon("sensors.0xa")
                    break;
                case'0xb':
                    options.title.text = "KLIMA (KALT) IN KWH";
                    options.colors = ['#03c5fe'];
                    options.icon = renderChartIcon("sensors.0xb")
                    break;
                case'0xd':
                    options.title.text = "COMBI (WÄRME/KALT) IN KWH";
                    options.colors = ['#800080'];
                    options.icon = renderChartIcon("sensors.0xd")
                    break;
                    
                default :
                    break;
            }

  delete options.icon
 
            let chartObj1 = {
                name: "Energie",
                type: "line",
                data: consumptionRArray
            }
            options.colors.push('#D3D3D3')
            series.push(chartObj1);
            if(!!compareChart.data) series.push(compareChart);
            charts.push({id, series, options})
        })

        setData(charts)
        return

    }

    

    const UI = () => {

        data.map( (obj, index) => {
            let iconDiv = document.createElement('div')
            iconDiv.innerHTML = obj.options.icon
            iconDiv.style.position = 'absolute';
            iconDiv.style.left = '1rem';
            iconDiv.style.top = '1.5rem';
            let t1 = document.getElementById(obj.id);
            if(t1) t1.append(iconDiv)
        })
        
        if(data?.length > 0 && !err){
            return (
                <Fragment>
                    <div>
                        <select className="form-select mb-3" aria-label="Year select" onChange={(e) => setYear(parseInt(e.target.value))}>
                            {
                                years.map(y => {
                                    return <option value={y} defaultValue={y === year}>{y}</option>
                                })
                            }
                            
                        </select>
                    </div>
                    {
                        data.map( (obj, index) => {
                            return (
                                <div className="card " key={obj.id}>
                                    <div className="card-body">
                                        <div id={`${obj.id}`}>
                                            <Chart 
                                                options={obj.options} 
                                                series={obj.series}
                                                type={'line'}
                                                width={'100%'} 
                                                height={height} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Fragment>
            )
        }
    };

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default ConsumptionChart;