import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link } from "react-router-dom";
import { AuthContext } from '../../context/Auth/AuthProvider';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import { SensorContext } from '../../context/Sensor/SensorProvider';
import { TenantContext } from '../../context/Tenant/TenantProvider';
import { GatewayContext } from '../../context/Gateway/GatewayProvider';
import { ContactContext } from '../../context/Contact/ContactProvider';

import { LocaleContext } from '../../context/Locale/Locale';

import {useRouter} from "../../components/Router/Router";
import Loading from '../../components/Loaders/Loading';
import Iconv from '../../components/iconv';
import Modal from 'react-modal';
import _ from 'lodash'

import { 
    PROXY_CLIENT,
    capitalizeFirst
 } from '../../libraries/utils';
import Swal from '@nmesys/sweetalert2'
import { uniqueId } from 'lodash';

import de from '../../components/DataTables/datatable.de.json';
const $ = require('jquery')
$.DataTable = require('datatables.net');


const ReportBuilder = (props) => {
    
    const router = useRouter();
    const { lang, locale } = useContext(LocaleContext);
    const {user} = useContext(AuthContext);
    const { buckets } = useContext(BucketContext);
    const { sensors } = useContext(SensorContext);
    const { tenants } = useContext(TenantContext);
    const { gateways } = useContext(GatewayContext);
    const { contacts } = useContext(ContactContext);

    const dataTypes = {
        sensors: {
            icon: 'apps.sensor',
            tags: ['buckets','sensors',]
        },

        buckets: {
            icon: 'bucket.multi',
            tags: ['accounts','buckets']
        },
        
        gateways: {
            icon: 'apps.gateway',
            tags: ['accounts','gateways','buckets']
        },
        
        tenants: {
            icon: 'bucket.tenant',
            tags: ['buckets','tenants']
        },

        contacts: {
            icon: 'bucket.tenant',
            tags: ['tenants','members']
        }
    }

    const dataFetcher = {
        sensors,
        buckets,
        tenants,
        gateways,
        contacts
    }

    const forbiddenHeaders = [
        "symetric_key",
        "__v",
        "_id"
    ]

    const [dataSelected, setDataSelected] = useState(dataTypes)
    
    const [report, setReport] = useState({
        user_id: (user && user.user_id)? user.user_id: null,
        name: 'My Fancy Report',
        type: null,
        headers: [],
        filter: [],
        sortby: '',  // header name
        sort_direction: 'desc'  // asc, desc
    })
    const reportChanged = useCompare(report);

    useEffect(  () => {

        console.log('DF', dataFetcher[report.type])
        if(reportChanged && !!dataFetcher[report.type] && dataFetcher[report.type].length > 0) console.log( 'DATA', Object.keys(dataFetcher[report.type][0]) )

    }, [  report]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    /**
     * On initial selection of the report subject, this shall filter
     * the main subject and all relative subject listed in the tags.
     */
    const filterDataSelected = (e) => {
        e.preventDefault()
        let select = dataSelected[e.currentTarget.dataset.type];
        if(Object.keys(dataSelected).length < 2) return;
        let newDateTypeArray = {}
        _.map(dataSelected, (ds,key) => {
            if( select.tags.includes(key)){
                newDateTypeArray[key] = dataTypes[key]
            } 
        })
        
        let clonedReport = {...report}
        clonedReport.type = e.currentTarget.dataset.type;
        clonedReport.name = `My ${ e.currentTarget.dataset.type.slice(0,1).toUpperCase() }${e.currentTarget.dataset.type.slice(1, e.currentTarget.dataset.type.length )} Report`
        setDataSelected(newDateTypeArray);
        setReport(clonedReport )
    }
    /**
     * Resets the filter back to default
     */
    const resetDataSelected = () => {
        setDataSelected(dataTypes);
    }




    const evalReport = () => {
        let clonedReport = {...report}
        let updateReport = false
        if(!!report.type){
            
            if(!!dataFetcher[report.type] && dataFetcher[report.type].length > 0) clonedReport.headers = Object.keys(dataFetcher[report.type][0]).filter( th => !forbiddenHeaders.includes(th))
        }




        console.log('CLONE: ',clonedReport)
  //      if(updateReport) setReport(clonedReport)
    }




    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        
        return (
            <Fragment>

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">REPORT BUILDER</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item ">Report</li>
                                    <li className="breadcrumb-item active"><b>Builder</b></li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    
                    <div className={`col-xxl-12`}>
                        <div className="card" id="reportBuilderWizard">
                        
                       
                            <div className="card-body">
                                <form action="#" className="form-steps" autocomplete="off">
                                    <div className="text-center pt-3 pb-4 mb-1">
                                        <h5>Report Builder</h5>
                                    </div>
                                    <div id="custom-progress-bar" className="progress-nav mb-4">
                                        <div className="progress" style={{height: "1px"}}>
                                            <div className="progress-bar" role="progressbar" style={{width: "0%"}} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                        <ul className="nav nav-pills progress-bar-tab custom-nav" role="tablist">


                                            
                                            {/** CHOOSE ALL DATA WHICH WILL GO INTO THIS REPORT */}
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-gen-info-tab" data-bs-toggle="pill" data-bs-target="#pills-gen-info" type="button" role="tab" aria-controls="pills-gen-info" aria-selected="false">2</button>
                                            </li>
                                            {/** DESCRIBE YOUR REPORT */}
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-info-desc-tab" data-bs-toggle="pill" data-bs-target="#pills-info-desc" type="button" role="tab" aria-controls="pills-info-desc" aria-selected="false">3</button>
                                            </li>
                                            {/** SCHEDULE SENDING OF YOUR REPORT, FREQUENCY, RECEIVERS, METHODS */}
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-success-tab" data-bs-toggle="pill" data-bs-target="#pills-success" type="button" role="tab" aria-controls="pills-success" aria-selected="false">4</button>
                                            </li>
                                        
                                        
                                        
                                        
                                        
                                        </ul>
                                    </div>

                                    <div className="tab-content">
                                        
                                        {/** CHOOSE A REPORT SUBJECT */}
                                        <div className="tab-pane fade show active" id="pills-gen-info" role="tabpanel" aria-labelledby="pills-gen-info-tab">
                                            <div>
                                                <div className="mb-4">
                                                    <div>
                                                        <h5 className="mb-1">Data Picker</h5>
                                                        <p className="text-muted">What is your report about? Choose the main subject of this report. 
                                                            For example: If you want a report about all Sensors and their values, then select Sensors 
                                                            and you will see all of the data which is available to be in that report.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    {
                                                        _.map(dataSelected, (dt, index) => {
                                                            return (    
                                                                <div className="col-xxl-3" data-type={index} key={_.uniqueId()} onClick={(e) => filterDataSelected(e)}>
                                                                    <div className="card card-primary">
                                                                        <div className="card-body">
                                                                            <Iconv name={dt.icon} size="2" className="text-primary " /> 
                                                                            <span className="ms-2" style={{position:"absolute", top:"30%"}}>{index.slice(0,1).toUpperCase()}{index.slice(1, index.length)}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`card indexHeaders ${ (!report.type)?'hidden':'' }`}>
                                                                        <div className="card-body">
                                                                            <div className="d-flex flex-column justify-content-start">
                                                                                <ul>
                                                                                    { 
                                                                                        (dataFetcher[index] && dataFetcher[index][0]) ? 
                                                                                            _.map( Object.keys( dataFetcher[index][0] ), th => {
                                                                                                if(!forbiddenHeaders.includes(th)) return <li>{th}</li>
                                                                                            }  ) 

                                                                                        : 'false' }
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                
                                            </div>
                                            <div className="d-flex justify-items-between mt-4">
                                                <button type="button" className="btn btn-danger " onClick={() => resetDataSelected()} >Reset</button>
                                                <button type="button" className="btn btn-success btn-label right ms-auto nexttab nexttab" data-nexttab="pills-info-desc-tab"><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Go to more info</button>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="pills-info-desc" role="tabpanel" aria-labelledby="pills-info-desc-tab">
                                            <div>
                                                <div className="text-center">
                                                    <div className="profile-user position-relative d-inline-block mx-auto mb-2">
                                                        <img src="assets/images/users/user-dummy-img.jpg" className="rounded-circle avatar-lg img-thumbnail user-profile-image" alt="user-profile-image" />
                                                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                            <input id="profile-img-file-input" type="file" className="profile-img-file-input" accept="image/png, image/jpeg" />
                                                            <label htmlFor="profile-img-file-input" className="profile-photo-edit avatar-xs">
                                                                <span className="avatar-title rounded-circle bg-light text-body">
                                                                    <i className="ri-camera-fill"></i>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <h5 className="fs-14">Add Image</h5>

                                                </div>
                                                <div>
                                                    <label className="form-label" htmlFor="gen-info-description-input">Description</label>
                                                    <textarea className="form-control" placeholder="Enter Description" id="gen-info-description-input" rows="2" required></textarea>
                                                    <div className="invalid-feedback">Please enter a description</div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start gap-3 mt-4">
                                                <button type="button" className="btn btn-link text-decoration-none btn-label previestab" data-previous="pills-gen-info-tab"><i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back to General</button>
                                                <button type="button" className="btn btn-success btn-label right ms-auto nexttab nexttab" data-nexttab="pills-success-tab"><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Submit</button>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="pills-success" role="tabpanel" aria-labelledby="pills-success-tab">
                                            <div>
                                                <div className="text-center">

                                                    <div className="mb-4">
                                                        <lord-icon src="https://cdn.lordicon.com/lupuorrc.json" trigger="loop" colors="primary:#0ab39c,secondary:#405189" style={{width:"120px",height:"120px"}}></lord-icon>
                                                    </div>
                                                    <h5>Well Done !</h5>
                                                    <p className="text-muted">You have Successfully Signed Up</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>



                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-12 mt-4">
                        <div className="card">
                            <div className="card-header">{report.name}</div>
                            <div className="card-body">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                    
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

ReportBuilder.requiredScope = ['sensor.view.own', 'bucket.view.own'];
ReportBuilder.requireAuth = true;
export default ReportBuilder;