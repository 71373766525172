import React, {createContext, Fragment, useContext, useEffect, useRef, useState} from 'react';
import {useRouter} from "../../components/Router/Router";
import { AuthContext } from '../Auth/AuthProvider';
import { AccountContext } from '../Account/AccountProvider';
//import CreateContactModal from '../../components/Modals/createContactModal';
import Swal from '@nmesys/sweetalert2';
import {
    errorToast,
    successToast,
    PROXY_CLIENT
} from '../../libraries/utils';
import _ from 'lodash';

export const ContactContext = createContext();

const ContactProvider = ({children}) => {

    const router = useRouter()
    const {user} = useContext(AuthContext);

    const { account } = useContext(AccountContext);

    const [contacts, setContacts] = useState([]);
    const [contact, setContact] = useState({});
    const [contactType, setContactType] = useState('contact');

    const userChanged = useCompare(user);
    const accountChanged = useCompare(account);

    const [showCreateContactModal, setShowCreateContactModal] = useState(false) //Toggles Modal Interupt

    useEffect( () => {
        if( accountChanged && account?.account_id ) getContacts();
    }, [account])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const getContacts = async () => {
        if(!account || !account.account_id) return;
        return new Promise( async (resolve, reject) => {
            //console.log('GETTING Contacts') 
            let query = '';
            if(account?.account_id) query = `?account_id=${account.account_id}`;       
            PROXY_CLIENT().get(`${process.env.REACT_APP_API}/contact/all${query}`)
            .then( response => {
                setContacts(response.data)
                //console.log('CONTACTS RESPONSE', response.data)
                return resolve(response.data);
            })
            .catch( err => {
                console.log('Contacts FETCHING ERROR 70', err);
                errorToast(`Get All Contacts Fail`);
                return resolve();
            })
        })
    }

    const getContact = (contact_id) => {
        if(!account || !account.account_id) return;
        return new Promise( async (resolve, reject) => {
            console.log('GETTING Contact')            
            PROXY_CLIENT().get(`${process.env.REACT_APP_API}/contact/?contact_id=${contact_id}`)
            .then( response => {
                setContact(response.data)
                console.log('CONTACT RESPONSE', response.data)
                return resolve(response.data);
            })
            .catch( err => {
                console.log('Contact FETCHING ERROR 87', err);
                errorToast(`Get Contact Fail`);
                return resolve();
            })
        })
    }

    const updateContact = (updatedObj) => {
        return new Promise( async (resolve, reject) => {
            PROXY_CLIENT().put(`${process.env.REACT_APP_API}/crm`, updatedObj)
            .then(async response => {
                successToast(`Contact has been updated`);

                let refContacts = {...contacts}
                _.map(refContacts, (obj, i) => {
                    if(obj.contact_id === response.data.contact_id){
                        refContacts[i] = response.data
                    }
                })
                setContacts(refContacts)
                setContact(response.data)
                console.log('UPDATE CONTACT RESPONSE', response.data);
                return resolve(response.data);
            })
            .catch(err => {
                console.log('UPDATE CONTACT ERROR', err);
                errorToast(`Update Contact Failed.`);
            })
        });
    }

    const deleteContact = (contact_id) => {
        if(!account || !account.account_id) return;
        return new Promise( async (resolve, reject) => {
            console.log('GETTING Contact')            
            PROXY_CLIENT().delete(`${process.env.REACT_APP_API}/crm/?contact_id=${contact_id}`)
            .then( response => {
                successToast(`Success`)
                return resolve();
            })
            .catch( err => {
                console.log('Contact FETCHING ERROR 87', err);
                errorToast(`Delete Contact Fail`);
                return resolve();
            })
        })
    }

    const createContact = async (obj) => {
        if(!account || !account.account_id) return;
        PROXY_CLIENT().post(`${process.env.REACT_APP_API}/contact`, obj)
        .then(async response => {
            successToast(`Contact has been created`);
        //    await getContacts()
        //    setContact(response.data)
            console.log('CREATE CONTACT RESPONSE', response.data);
            return response.data;
        })
        .catch(err => {
            console.log('CREATE CONTACT ERROR', err);
            errorToast(`Create Contact Failed.`);
        })
    }
    
    const createAddress = ({contact_id = null, obj}) => {

    }
    /**
     * An address which has a valid geolocation has already been auto corrected 
     * by Google Maps. The only reason at this point to update an address would be
     * if it were totally incorrect. If the address is only associted with a single
     * content then fine, the ID may be updated. Else it may only be dissacoiated
     * and a new address ID reassociated with this content
     */
    const updateAddress = ({contact_id, obj}) => {

    }
    /**
     * Addresses are not deleted if they are still associated with other
     * content. In the case where an address is not associated with any other
     * then it may be destroyed, else simply disassociated.
     */
    const deleteAddress = ({contact_id, address_id}) => {

    }



    const params = {
        contact,
        contacts,
        setContact,
        deleteContact,
        getContact,
        getContacts,
        setShowCreateContactModal,
        updateContact,
        createContact,
        setContactType
    }

    return (
        <ContactContext.Provider value={params}>
            {/** 
            <CreateContactModal
                contactType={ contactType }
                showModal={ showCreateContactModal }
                closeModal={() => setShowCreateContactModal(false)}
                openModal={() => setShowCreateContactModal(true)}
            />
            */}
            {children}
        </ContactContext.Provider>
    )
}

export default ContactProvider;