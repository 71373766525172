import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { BucketContext } from '../../context/Bucket/BucketProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import { GatewayContext } from '../../context/Gateway/GatewayProvider';
import { SensorContext } from '../../context/Sensor/SensorProvider';

import Iconv from '../../components/iconv';

import _ from 'lodash';

const GatewayCard = (props) => {

    const navigate = useNavigate();
    const { lang, locale } = useContext(LocaleContext);
    const {buckets, setBucket} = useContext(BucketContext);
    const {
        gateway, 
        gateways, 
        setGateway,
        bucketGrpFilter, 
        bucketFilter, 
        setBucketGrpFilter,
        setBucketFilter
    } = useContext(GatewayContext);
    const [countInsight, setCountInsight] = useState(0)
    const {sensors, sensor_ids} = useContext(SensorContext);
    const [gatewayBucket, setGatewayBucket] = useState(null);
    const gatewayChanged = useCompare(gateway);

    useEffect(() => {

        if(gatewayChanged && gateway?.bucket_id){
            if(!!buckets && buckets.length){
                let p = _.filter(buckets, p => p.bucket_id === gateway.bucket_id);
                if(p.length) setGatewayBucket(p[0])
            }
        }
        if(gatewayChanged && gateway?.insight){
            insight();
        }
    
    }, [gateway, gatewayBucket]);

    useEffect(() => {}, [countInsight]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    
    const addressBuilder = (obj, formatted = false) => {
        if(obj?.address){
            if(!formatted) return `${obj.address.street} ${obj.address.house_number} ${ obj.address?.street1 ? ', ME '+ obj.address.street1 : ''  }, ${obj.address.post_code} ${obj.address.city}, ${obj.address.country.toUpperCase()}`
            else {
                return (
                    `${obj.address.street} ${obj.address.house_number} ${ obj.address?.street1 ? ', ME '+ obj.address.street1 : ''  }\n ${obj.address.post_code} ${obj.address.city}\n ${obj.address.country.toUpperCase()}`
                    )
                }
        }
        return ''
    }

    const gotoBucketDetails = () => {
        if(gatewayBucket?.bucket_id) {
            setBucket(gatewayBucket);
            navigate('/details/bucket')
        }else{ return }
    }

    const gotoBucket = () => {
        let objArr = _.filter(buckets, pr => pr.bucket_id === gateway.bucket_id)
        if(objArr?.[0]){
            setBucket(objArr[0]);
            setTimeout(() => {
                navigate('/details/bucket')
            }, 500)
        }
        return;
    }

    const insight = () => {
        if(!gateway?.insight) return;
        let uniqueSerialNumbers = _.uniq(_.flatMap(gateway.insight.history, 'serial_number'));
        let validSensors = _.filter(uniqueSerialNumbers, sid => sensor_ids.includes(sid))
        setCountInsight(validSensors.length);
    }

    const UI = () => {
        if(!gateway?.gateway_id) return <Fragment></Fragment>
        
        return (
            <div className="card" id="contact-view-detail">

                <div className="d-flex flex-row-reverse" >
                    <div className="hstack text-nowrap gap-2 float-right">
                        <button type="button" id="dropdownMenuLinkSensor" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-soft-info"><i className="ri-more-2-fill"></i></button>
                        <ul className="dropdown-menu" aria-labelledby="k">
                            
                            <li className="ps-3 dropdown-item text-warning" >Edit</li>
                            <li className="ps-3 dropdown-item text-danger" title="Destroy this Bucket, but keep sensors and gateways within" ><a>Delete</a></li>
                        </ul>
                    </div>
                </div>

                <div className="card-body text-center">
                    <div className="position-relative d-inline-block">
                        <a onClick={ () => navigate(`/details/gateway`) }>
                            <Iconv name={`apps.gateway`} size="5" className="text-primary "/>
                        </a>
                    </div>
                    
                    <h5 className="mt-4 mb-1" title="Gateway identification number">{ (!!gateway?.serial_number)? gateway.serial_number : '' }</h5>
                    <h5 className="mt-1 mb-1"></h5>

                    <p className="text-muted">{addressBuilder(gatewayBucket)}</p>
                    <ul className="list-inline mb-0">

                        <li className="list-inline-item avatar-sm">
                            <a className="avatar-title bg-soft-primary text-primary fs-15 rounded" onClick={() => gotoBucketDetails()} >
                                <Iconv name="bucket.single" size="2" classList="text-primary" title={`Current gateway location`} />
                            </a>
                        </li>
                        <li className="list-inline-item avatar-sm">
                            <a className="avatar-title bg-soft-success text-success fs-15 rounded">
                                <Iconv name="apps.sensor" size="2" classList={`text-${(countInsight > 0)? 'success':'danger'}`} title="Sensors Insight"/>
                                <span className={`position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-${(countInsight > 0)? 'success':'danger'}`}>{countInsight}</span>
                            </a>
                        </li>
                        <li className="list-inline-item avatar-sm">
                            <a className="avatar-title bg-soft-success text-success fs-15 rounded">
                                <Iconv name="sensors.rssi.strong" size="2" classList="text-success"/>
                            </a>
                        </li>
                        <li className="list-inline-item avatar-sm">
                            <a className="avatar-title bg-soft-success text-success fs-15 rounded">
                                <Iconv name="gateways.battery.unknown" size="2" classList="text-primary"/>
                            </a>
                        </li>

                        
                    </ul>
                </div>

                <div className="card-body">
                    
                    
                    
                </div>
            </div>
        )
    }


    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default GatewayCard;