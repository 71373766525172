import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation } from "react-router-dom";
import {NotificationContext} from '../../../context/Notification/NotificationProvider';
import _ from 'lodash'
import { LocaleContext } from '../../../context/Locale/Locale';
import Iconv from '../../iconv';
//import { PreferenceContext } from '../../../context/Preferences/Preference';


const CountAlertWidget = (props) => {

    const {notifications} = useContext(NotificationContext);
    const notificationsChanged = useCompare(notifications);
    const { lang, locale } = useContext(LocaleContext);
//    const {preferences, hideWidget} = useContext(PreferenceContext);

    useEffect(  () => {
        

    }, [ notifications, lang, locale]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const countUnreadNotifications = () => {
        let count = 0;
        _.map(notifications, n => {
            if(!n.viewed_at) count ++
        })
        return count
    }
   

    const UI = () => {

        return (
            <Fragment>
                <div className={`col-xl-${(props?.block?.width)? props.block.width:'3'} col-md-6`}>
                    <div className="card card-animate">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Alerts</p>
                                </div>
                                <div className="flex-shrink-0">
                                    <span className="dropdown text-secondary  mb-0">
                                        <a className="dropdown-btn fa fa-solid fa-ellipsis-vertical fs-13 align-middle ps-2 pe-2" data-bs-toggle="dropdown" aria-expanded="false"></a>
                                        <div className="dropdown-menu dropdown-menu-end " data-popper-placement="bottom-end" style={{zIndex: 5000}}>
                                            <a className="dropdown-item">
                                                <div className="form-check">
                                                <input type="checkbox" id={props?.block?.component? props.block.component:'countAlertsWidget'} className="form-check-input" checked onChange={(e) => {}}/>
                                                    <label className="form-check-label" >Show</label>
                                                </div>
                                            </a>
                                        </div>
                                    </span>
                                    
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4"><span className="counter-value" >{countUnreadNotifications()}</span></h4>
                                    <a href="" className="text-decoration-underline text-muted">View net earnings</a>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className={`avatar-title bg-soft-${(countUnreadNotifications() > 0)?'danger':'success'} rounded fs-3`}>
                                        <Iconv name="apps.alert" classList={`text-${(countUnreadNotifications() > 0)?'danger':'success'} `}/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default CountAlertWidget;