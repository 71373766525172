import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';



const Faq = (props) => {

   
    useEffect(  () => {

    }, []);

   

    const UI = () => {

        return (
            <Fragment>

                <section className="section" id="faq">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="text-center mb-5">
                                    <h3 className="mb-3 fw-semibold">Frequently Asked Questions</h3>
                                    <p className="text-muted mb-4 ff-secondary">If you can not find answer to your question in our FAQ, you can
                                        always contact us or email us. We will answer you shortly!</p>

                                    <div className="">
                                        <button type="button" className="btn btn-primary btn-label rounded-pill"><i className="ri-mail-line label-icon align-middle rounded-pill fs-16 me-2"></i> Email Us</button>
                                        <button type="button" className="btn btn-info btn-label rounded-pill"><i className="ri-twitter-line label-icon align-middle rounded-pill fs-16 me-2"></i> Send Us Tweet</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-lg-5 g-4">
                            <div className="col-lg-6">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-question-line fs-24 align-middle text-success me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="mb-0 fw-semibold">General Questions</h5>
                                    </div>
                                </div>
                                <div className="accordion custom-accordionwithicon custom-accordion-border accordion-border-box" id="genques-accordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="genques-headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#genques-collapseOne" aria-expanded="true" aria-controls="genques-collapseOne">
                                                What is the purpose of using themes ?
                                            </button>
                                        </h2>
                                        <div id="genques-collapseOne" className="accordion-collapse collapse show" aria-labelledby="genques-headingOne" data-bs-parent="#genques-accordion">
                                            <div className="accordion-body ff-secondary">
                                                A theme is a set of colors, fonts, effects, and more that can be applied to your entire presentation to give it a
                                                consistent, professional look. You've already been using a theme, even if you didn't know it: the default Office theme,
                                                which consists.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="genques-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#genques-collapseTwo" aria-expanded="false" aria-controls="genques-collapseTwo">
                                                Can a theme have more than one theme?
                                            </button>
                                        </h2>
                                        <div id="genques-collapseTwo" className="accordion-collapse collapse" aria-labelledby="genques-headingTwo" data-bs-parent="#genques-accordion">
                                            <div className="accordion-body ff-secondary">
                                                A story can have as many themes as the reader can identify based on recurring patterns and parallels within the story
                                                itself. In looking at ways to separate themes into a hierarchy, we might find it useful to follow the example of a
                                                single book.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="genques-headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#genques-collapseThree" aria-expanded="false" aria-controls="genques-collapseThree">
                                                What are theme features?
                                            </button>
                                        </h2>
                                        <div id="genques-collapseThree" className="accordion-collapse collapse" aria-labelledby="genques-headingThree" data-bs-parent="#genques-accordion">
                                            <div className="accordion-body ff-secondary">
                                                Theme features is a set of specific functionality that may be enabled by theme authors. Themes must register each
                                                individual Theme Feature that the author wishes to support. Theme support functions should be called in the theme's
                                                functions.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="genques-headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#genques-collapseFour" aria-expanded="false" aria-controls="genques-collapseFour">
                                                What is simple theme?
                                            </button>
                                        </h2>
                                        <div id="genques-collapseFour" className="accordion-collapse collapse" aria-labelledby="genques-headingFour" data-bs-parent="#genques-accordion">
                                            <div className="accordion-body ff-secondary">
                                                Simple is a free WordPress theme, by Themify, built exactly what it is named for: simplicity. Immediately upgrade the
                                                quality of your WordPress site with the simple theme To use the built-in Chrome theme editor.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-shield-keyhole-line fs-24 align-middle text-success me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="mb-0 fw-semibold">Privacy &amp; Security</h5>
                                    </div>
                                </div>

                                <div className="accordion custom-accordionwithicon custom-accordion-border accordion-border-box" id="privacy-accordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="privacy-headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#privacy-collapseOne" aria-expanded="false" aria-controls="privacy-collapseOne">
                                                Does Word have night mode?
                                            </button>
                                        </h2>
                                        <div id="privacy-collapseOne" className="accordion-collapse collapse" aria-labelledby="privacy-headingOne" data-bs-parent="#privacy-accordion">
                                            <div className="accordion-body ff-secondary">
                                                You can run Microsoft Word in dark mode, which uses a dark color palette to help reduce eye strain in low light
                                                settings. You can choose to make the document white or black using the Switch Modes button in the ribbon's View tab.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="privacy-headingTwo">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#privacy-collapseTwo" aria-expanded="true" aria-controls="privacy-collapseTwo">
                                                Is theme an opinion?
                                            </button>
                                        </h2>
                                        <div id="privacy-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="privacy-headingTwo" data-bs-parent="#privacy-accordion">
                                            <div className="accordion-body ff-secondary">
                                                A theme is an opinion the author expresses on the subject, for instance, the author's dissatisfaction with the narrow
                                                confines of French bourgeois marriage during that period theme is an idea that a writer repeats.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="privacy-headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#privacy-collapseThree" aria-expanded="false" aria-controls="privacy-collapseThree">
                                                How do you develop a theme?
                                            </button>
                                        </h2>
                                        <div id="privacy-collapseThree" className="accordion-collapse collapse" aria-labelledby="privacy-headingThree" data-bs-parent="#privacy-accordion">
                                            <div className="accordion-body ff-secondary">
                                                A short story, novella, or novel presents a narrative to its reader. Perhaps that narrative involves mystery, terror,
                                                romance, comedy, or all of the above. These works of fiction may also contain memorable characters, vivid
                                                world-building, literary devices.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="privacy-headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#privacy-collapseFour" aria-expanded="false" aria-controls="privacy-collapseFour">
                                                Do stories need themes?
                                            </button>
                                        </h2>
                                        <div id="privacy-collapseFour" className="accordion-collapse collapse" aria-labelledby="privacy-headingFour" data-bs-parent="#privacy-accordion">
                                            <div className="accordion-body ff-secondary">
                                                A story can have as many themes as the reader can identify based on recurring patterns and parallels within the story
                                                itself. In looking at ways to separate themes into a hierarchy, we might find it useful to follow the example of a
                                                single book.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default Faq;