import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { LocaleContext } from '../../context/Locale/Locale';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { Link } from "react-router-dom";
import { useRouter } from '../../components/Router/Router';
import Loading from '../../components/Loaders/Loading';
import LanguageSwitcher from "../../components/Auth/languageSwitcher";
import { PROXY_CLIENT } from '../../libraries/utils';

const RegisterPage = (props) => {

    const { lang, setLang, locale, localeAvailable } = useContext(LocaleContext);
    const { user } = useContext(AuthContext);
    const router = useRouter();
    const [errorMsgs, setErrorMsgs] = useState([])

    useEffect(  () => {}, [lang]);

    useEffect(  () => {}, [user]);

    const handleSubmit = async (e) => {
        try{
            e.preventDefault();
            if(e.target.ambot.value) return
            const formData = {
                first_name: e.target.first_name.value,
                last_name: e.target.last_name.value,
                email: e.target.email.value,
                phone: e.target.phone.value,
                address: {
                    street: e.target.street.value,
                    house_number : e.target.house_number.value,
                    street1: e.target.street1.value,
                    city: e.target.city.value,
                    province: e.target.province.value,
                    country: e.target.country.value,
                    post_code: e.target.post_code.value,
            }
            }

            const response = await PROXY_CLIENT().post('/auth/register', formData)
            if(response?.data?.redirected){
                window.location.href = response.data.redirected
            }

        }catch(err){
            console.log(err)
        }
    }


    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        return (
            <Fragment>
                <section  >
                    <div className="form-section">
                        <div className="form-inner">

                            <LanguageSwitcher />

                            <Link to="/auth/login" >
                                <a className="logo">
                                    <img src="/auth/assets/logo.png" alt="IRON AUTH" title="IRON AUTH" />
                                </a>
                            </Link>
                            
                            <h3>Create An Account</h3>
                            <form  onSubmit={handleSubmit} >
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group form-box">
                                            <input name="first_name" type="text" className="form-control" placeholder="First Name" aria-label="First Name" required />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group form-box">
                                            <input name="last_name" type="text" className="form-control" placeholder="Last Name" aria-label="Last Name" required />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="form-group form-box">
                                    <input name="email" type="email" className="form-control" placeholder="Email Address" aria-label="Email Address" required />
                                </div>

                                <div className="form-group form-box">
                                    <input name="phone" type="text" className="form-control" placeholder="Contact Phone Number" aria-label="Contact Phone Number" required />
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <input name="street" type="text" className="form-control" placeholder="Address Street" aria-label="Address Street" required />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-group">
                                            <input name="house_number" type="text" className="form-control" placeholder="Number" aria-label="Address House Number" required/>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-group">
                                            <input name="street1" type="text" className="form-control" placeholder="APT" aria-label="Address APT Number"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group form-box">
                                        <input name="city" type="text" className="form-control" placeholder="City" aria-label="City" required />
                                    </div>
                                    <div className="form-group form-box">
                                        <input name="province" type="text" className="form-control" placeholder="Province" aria-label="Province" required />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group form-box">
                                            <input name="post_code" type="text" className="form-control" placeholder="Post Code" aria-label="Post Code" required />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group form-box">
                                            <select className="form-select mb-3" defaultValue="de" name="country" aria-label="Default select example">
                                                <option value="de">Deutschland</option>
                                                <option value="it">Italy</option>
                                                <option value="fr">France</option>
                                                <option value="dk">Denmark</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="d-none form-group form-box clearfix">
                                    <input name="ambot" type="text" className="form-control" placeholder="Name" aria-label="Name" />
                                    </div>


                                </div>

                                {
                                    (errorMsgs.length) ?
                                        errorMsgs.map(msg => {
                                        return (
                                            <div className={`errormsg mb-2 text-${ (msg && msg.type)? msg.type:'danger'} `}>
                                                {lang.login.errorMessages[msg.message]}
                                            </div>
                                        )
                                        })
                                        : (<Fragment></Fragment>)
                                }
                                
                                <div className="form-group checkbox form-box clearfix">
                                    <div className="clearfix float-start">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="agb" id="agb" required/>
                                            <label className="form-check-label" htmlFor="agb">
                                                <Link to="/agb" ><a href="/agb">I agree to the terms of service</a></Link>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button type="submit" id="btnRegisterUser" className="btn btn-primary btn-lg btn-theme" ><span>Register</span></button>
                                </div>
                                
                            </form>
                            <div className="clearfix"></div>
                            <p>Already a member?  <Link to="/auth/login" ><a className="thembo"> Login here</a></Link></p>


                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

RegisterPage.requiredScope = [];
RegisterPage.requireAuth = false;
export default RegisterPage;