import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/Auth/AuthProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import { SensorContext } from '../../context/Sensor/SensorProvider';
import { AccountContext } from '../../context/Account/AccountProvider';
import Loading from '../../components/Loaders/Loading';
import Iconv from '../../components/iconv';
import TblSensors from '../../components/DataTables/sensors';
import _ from 'lodash';
import SensorCard from '../../components/Cards/sensorCard';

const $ = require('jquery')
$.DataTable = require('datatables.net');

const Sensors = (props) => {
    
    const navigate = useNavigate();
    const { lang, locale } = useContext(LocaleContext);
    const {user} = useContext(AuthContext);
    const {account} = useContext(AccountContext);
    const {
        sensor, 
        sensors, 
        sensorSignalStrength, 
        bucketGrpFilter, 
        bucketFilter, 
        setBucketGrpFilter,
        setBucketFilter
    } = useContext(SensorContext);
    const [year, setYear] = useState(new Date().getFullYear())

    const sensorChanged = useCompare(sensor);
    const sensorsChanged = useCompare(sensors);

    useEffect(  () => {}, [sensors])
    useEffect(  () => {}, [sensor])
    useEffect(  () => {}, [year])
    useEffect(  () => {}, [bucketGrpFilter, bucketFilter])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const handleBucketGrpFilter = (e) => {
        setBucketGrpFilter(e.target.checked)
    }

    const handleBucketFilter = (e) => {
        setBucketFilter(e.target.checked)
    }

    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        const isProcessing = () => {
            if (!sensors || sensors.length === 0) {
                return (<Fragment><Loading/></Fragment>)
            }
        }

        return (
            <Fragment>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex align-items-center flex-wrap gap-2 ">
                                    <div className="flex-grow-1 ">
                                        {/**<button className="btn btn-primary add-btn" ><i className="ri-add-fill me-1 align-bottom"></i> Add Sensor</button>**/}
                                        <div className="form-check form-switch form-switch-right form-switch-md me-4">
                                            <label htmlFor="popover-showcode" className="form-label " title="Filter this Sensor table by the bucket group selected"><Iconv name="bucket.city" classList="text-primary"/> </label>
                                            <input className="form-check-input code-switcher" type="checkbox" id="popover-bucketGrpFilter" onChange={handleBucketGrpFilter} checked={bucketGrpFilter} />
                                        </div>
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            <label htmlFor="popover-showcode" className="form-label " title="Show only sensors relevant to the bucket selected"><Iconv name="bucket.single" classList="text-success"/></label>
                                            <input className="form-check-input code-switcher" type="checkbox" id="popover-bucketFilter" onChange={handleBucketFilter} checked={bucketFilter} />
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            (!bucketGrpFilter && !bucketFilter)? 'Account Level' : 
                                                (!!bucketGrpFilter) ? "Building Level" : "Premise Level"
                                                
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className={`col-xxl-${(!!sensors && sensors.length > 0)? '8':'12'}`}>
                        <div className="card" id="contactList">
                            <div className="card-body">
                                <div>
                                    <div className="table-responsive table-card mb-3">
                                        <TblSensors />
                                    </div>
                                    {isProcessing()}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={`${(!!sensors && sensors.length)? 'col-xxl-4':'hidden'}`}>
                        <SensorCard />
                    </div>
                </div>

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

Sensors.requiredScope = ['sensor.view.own','sensor.view.any'];
Sensors.requireAuth = true;
export default Sensors;