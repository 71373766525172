import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation } from "react-router-dom";
import { uniqueId } from 'lodash';
import { LocaleContext } from '../../../context/Locale/Locale';
import { BucketContext } from '../../../context/Bucket/BucketProvider';
//import { PreferenceContext } from '../../../context/Preferences/Preference';
import _ from 'lodash'


const CountBucketsWidget = (props) => {

    const { lang, locale, l } = useContext(LocaleContext);
    const {buckets} = useContext(BucketContext)
//    const {preferences, hideWidget} = useContext(PreferenceContext);

    useEffect(  () => {

        
    }, [buckets, lang, locale]);

    const UI = () => {
        if(!lang) return <Fragment></Fragment>
        return (
            <Fragment>
                <div className={`col-xl-${(props?.block?.width)? props.block.width:'3'} col-md-6`}>
                    <div className="card card-animate">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{l('components.widgets.premises.title', 'Premises')}</p>
                                </div>
                                <div className="flex-shrink-0">
                                    <span className="dropdown text-secondary  mb-0">
                                        <a className="dropdown-btn fa fa-solid fa-ellipsis-vertical fs-13 align-middle ps-2 pe-2" data-bs-toggle="dropdown" aria-expanded="false"></a>
                                        <div className="dropdown-menu dropdown-menu-end " data-popper-placement="bottom-end" style={{zIndex: 5000}}>
                                            <a className="dropdown-item">
                                                <div className="form-check">
                                                    <input type="checkbox" id={props?.block?.component? props.block.component:'countBucketsWidget'} className="form-check-input" checked onChange={(e) => {}}/>
                                                    <label className="form-check-label" >{l('components.widgets.showWidget', 'Show')}</label>
                                                </div>
                                            </a>
                                        </div>
                                    </span>
                                    
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4"><span className="counter-value" >{buckets.length}</span></h4>
                                    <a className="text-decoration-underline text-muted" onClick={() => document.getElementById('tabBuckets').click()} >{l('components.widgets.premises.link', 'Premise Manager')}</a>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className={`avatar-title bg-soft-${(buckets.length > 0)?'success':'danger'} rounded fs-3`}>
                                        <i className={`fa fa-solid fa-building text-${(buckets.length > 0)?'success':'danger'}`}></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default CountBucketsWidget;