/**
 * This modal is a wizard to help new accounts establish
 * Realm identity
 */
import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-modal';
import Iconv from '../../components/iconv';
import _ from 'lodash';
import MD5 from 'md5';
import { LocaleContext } from '../../context/Locale/Locale';
import { AccountContext } from '../../context/Account/AccountProvider';
import { AuthContext } from '../../context/Auth/AuthProvider';

import {
    errorToast,
    successToast, 
    PROXY_CLIENT,
    capitalizeFirst,
    getDeepProperty
} from '../../libraries/utils';
Modal.setAppElement('#root');

const ContactUsModal = (props) => {

    const { lang, locale } = useContext(LocaleContext);
    const { user, PermissionsWrapper, hasPermission, logout} = useContext(AuthContext);
    const [show, setShow] = useState(false);
    const [tabActive, setTabActive] = useState('personal-data');
    const [textBased, setTextBased] = useState(props.useText? props.useText:true)

    const textBasedChanged = useCompare(props.useText);

    const [messageText, setMessageText] = useState('');
    const messageMaxLength = 1000;
    const [emlFormData, setEmlFormData] = useState({
        name: (user?.contact) ? user.contact.first_name + ' ' + user.contact.last_name : '',
        email: (user?.contact) ? user.contact.emails[0].address : '',
        subject: '',
        message: '',
        ambot: '',
    });
    
    useEffect( () => {}, [user]);

    useEffect( () => {
        if(textBasedChanged ) setTextBased(props.useText)
    }, [textBasedChanged]);

    useEffect( () => {}, [emlFormData, messageText]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const toggleModal = () => {
        setShow(false)
        if(props.closeModal) props.closeModal();
    }

    const triggerType = () => {
        if(textBased){
            return (
                <a className="nav-link fs-15" onClick={() => setShow(!show)}>Contact Us</a>
            )
        }else{
            return (
                <button className='btn btn-primary' onClick={ () => setShow(!show) }>Contact Us</button>
            )
        }
    }
    // Show remaining message length
    const handleMessageChange = (e) => {
        const elem = document.getElementById('frmEmailMessage');
        const inputText = e.target.value;
        if (inputText.length <= messageMaxLength) {
            setMessageText(inputText);
        } else {
            setMessageText(inputText.substring(0, messageMaxLength));
        }
    }

    const handleEmailFormChange = (event) => {
        const { name, value } = event.target;
        setEmlFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        const { name, email, subject, message, ambot } = emlFormData;
        if (!name || !email || !subject || !message) {
          alert("All fields except are required.");
          return;
        }
        if (ambot.length > 0) return;
        try{
            const response = await PROXY_CLIENT().post(`${process.env.REACT_APP_API}/message/telegram`, emlFormData)
            if(response.status === 200) {
                successToast('Your message has been sent :)')
                setEmlFormData({
                    name: (user?.contact) ? user.contact.first_name + ' ' + user.contact.last_name : '',
                    email: (user?.contact) ? user.contact.emails[0].address : '',
                    subject: '',
                    message: '',
                    ambot: '',
                  });
                  setMessageText('');
                  setShow(false)
            }else{
                errorToast('ERROR 122: Something went wrong with sending your message. It is likely not your fault. We probably do not feed our mice enough :(')
                return
            }
        }catch(err){
            errorToast('ERROR 125: Something went wrong with sending your message. It is likely not your fault. We probably do not feed our mice enough :(')
            return
        }
    }

    const UI = () => {
        return (
            <Fragment>
            <Modal
                isOpen={show}
                onRequestClose={toggleModal}
                shouldCloseOnOverlayClick={false}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: "88vw",
                        height: "65vh"
                    }
                }}
            >

                <div className="col-xl-12">
                    <div className="d-flex justify-content-between">
                        <h4>{'Contact Us'}</h4>
                        <button className="btn btn-primary" onClick={() => toggleModal()}>X</button>
                    </div>
                    <section className={`mt-4`} id="contactUs">

                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        

                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="nav nav-pills flex-column nav-pills-tab custom-verti-nav-pills text-center" role="tablist" aria-orientation="vertical">
                                                    <a className="nav-link active" id="custom-v-pills-home-tab" data-bs-toggle="pill" href="#custom-v-pills-home" role="tab" aria-controls="custom-v-pills-home" aria-selected="false" tabindex="-1">
                                                        <i className="ri-mail-line d-block fs-20 mb-1"></i>
                                                        Email
                                                    </a>
                                                    <a className="nav-link" id="custom-v-pills-profile-tab" data-bs-toggle="pill" href="#custom-v-pills-profile" role="tab" aria-controls="custom-v-pills-profile" aria-selected="false" tabindex="-1">
                                                        <i className="ri-user-2-line d-block fs-20 mb-1"></i>
                                                        Telegram
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-9">
                                                <div className="tab-content text-muted mt-3 mt-lg-0">


                                                    <div className="tab-pane fade active show" id="custom-v-pills-home" role="tabpanel" aria-labelledby="custom-v-pills-home-tab">
                                                        <div>
                                                            <form onChange={handleEmailFormChange}>
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="mb-4">
                                                                            <label htmlFor="name" className="form-label fs-13">Name</label>
                                                                            <input name="name" id="name" type="text" className="form-control bg-light border-light" defaultValue={(user?.contact)? user.contact.first_name+' '+user.contact.last_name : '' } readOnly={user?.contact} required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="mb-4">
                                                                            <label htmlFor="email" className="form-label fs-13">Email</label>
                                                                            <input name="email" id="email" type="email" className="form-control bg-light border-light"  defaultValue={(user?.contact)? user.contact.emails[0].address : '' } readOnly={user?.contact} required/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row d-none">
                                                                    <div className="col-lg-12">
                                                                        <div className="mb-4">
                                                                            <input type="text" className="form-control bg-light border-light " id="ambot" name="ambot" placeholder="You are a bot..." />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="mb-4">
                                                                            <label htmlFor="subject" className="form-label fs-13">Subject</label>
                                                                            <input type="text" className="form-control bg-light border-light" id="subject" name="subject" placeholder="Your Subject.." required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="message" className="form-label fs-13">Message</label>
                                                                            <textarea name="message" id="message" rows="3" className="form-control bg-light border-light" onChange={handleMessageChange} maxLength={1000} defaultValue={messageText} placeholder="Your message..." required ></textarea>
                                                                            <span className="d-flex justify-content-end w-100" >Remaining: {messageMaxLength - messageText.length}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-end">
                                                                        <input type="submit" id="submit" name="send" className="submitBnt btn btn-primary" value="Send Message" onClick={sendEmail}/>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>










                                                    <div className="tab-pane fade" id="custom-v-pills-profile" role="tabpanel" aria-labelledby="custom-v-pills-profile-tab">
                                                        <div className="d-flex mb-4">
                                                            <div className="flex-shrink-0">
                                                                <img src="assets/images/small/img-3.jpg" alt="" width="150" className="rounded"/>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <p className="mb-0">In some designs, you might adjust your tracking to create a certain artistic effect. It can also help you fix fonts that are poorly spaced to begin with. A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1 me-3">
                                                                <p className="mb-0">Each design is a new, unique piece of art birthed into this world, and while you have the opportunity to be creative and make your own style choices. For that very reason, I went on a quest and spoke to many different professional graphic designers.</p>
                                                            </div>
                                                            <div className="flex-shrink-0">
                                                                <img src="assets/images/small/img-6.jpg" alt="" width="150" className="rounded"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                        
                                                   
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
 
            </Modal>

            {triggerType()}

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )


}

export default ContactUsModal;