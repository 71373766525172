const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

if (!isDebugMode) {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.info = () => {};
} else {
  console.log('Debug mode is enabled. Logs will be shown.');
}

export default null;