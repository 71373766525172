import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { TenantContext } from '../../context/Tenant/TenantProvider';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import _ from 'lodash'
import Iconv from '../iconv';

import de from '../DataTables/datatable.de.json';
const $ = require('jquery')
$.DataTable = require('datatables.net');

const TblTenants = (props) => {

    const { 
        tenant,
        tenants,
        curTenants,
        setTenant
    } = useContext(TenantContext);
    const {setBucket, buckets} = useContext(BucketContext)
    const { lang, locale } = useContext(LocaleContext);
    const tenantChanged = useCompare(tenant)
    const tenantsChanged = useCompare(tenants);

    useEffect( () => {
        if(tenantsChanged === true) initializeDT()
    },[tenants]);

    useEffect( () => {

    },[tenant]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
  
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => { ref.current = value });
        return ref.current;
    }

    const addressBuilder = (address, formatted = false) => {
        if(!address) return;
        if(!formatted) return `${address.street} ${address.house_number} ${ address?.street1 ? ', ME '+ address.street1 : ''  }, ${address.post_code} ${address.city}, ${address.country.toUpperCase()}`
        else {
            return (
                `${address.street} ${address.house_number} ${ address?.street1 ? ', ME '+ address.street1 : ''  }\n ${address.post_code} ${address.city}\n ${address.country.toUpperCase()}`
            )
        }
    }

    const initializeDT = async () => { 
        
        const tableElement = $('#tblTenants');
        const dataTable = $.fn.dataTable.isDataTable(tableElement);
        if (dataTable) {
            tableElement.DataTable().destroy();
            tableElement.empty();
        }
        $(`#tblTenants tbody`).off();

        if (tenants.length > 0) {
            const table = $(`#tblTenants`).DataTable({
                language: locale,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: tenants,
                rowId: "tenant_id",
                pageLength: 100,
                orderCellsTop: false,
                fixedHeader: true,
                order: [],
                columns: [
                    {
                        data: "tenant_id",
                        searchable: true,
                        visible: false
                    },
                    {
                        data: "bucket_id",
                        searchable: true,
                        visible: false
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            let status = 'primary' // Future Tenant
                            if(data?.date_move_in){
                                let date = new Date().getTime();
                                if(
                                    data.date_move_in <  date &&
                                    (!data?.date_move_out || data.date_move_out > date)
                                ) status = 'success' // Current Tenant

                                if(data.date_move_out < date) status = 'danger'  //Moved out, past tenant
                            }
                            return `<i class="ps-2 text-${status} fa-solid fa-home-user"></i>`
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return data.contact.first_name
                        }
                    },

                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return data.contact.last_name
                        }
                    },

                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.contact?.addresses?.[0]) return addressBuilder(data.contact.addresses[0])
                            else return '';
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data.date_move_in) return new Date(data.date_move_in).toLocaleDateString('de-DE')
                            else return '';
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data.date_move_out) return new Date(data.date_move_out).toLocaleDateString('de-DE')
                            else return '';
                        }
                    }
                 
                ],
                createdRow: function (row, data, dataIndex) {},
                "fnDrawCallback": function( oSettings ) { }
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $(`#tblTenants tbody tr`).off('mouseenter').on('mouseenter', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });

            $(`#tblTenants tbody tr`).off('mouseleave').on('mouseleave', function () {
                $(this).css('background-color', 'transparent');
            });

            $(`#tblTenants tbody`).on('click', 'tr', function (e) {
                let parent = e.currentTarget.closest('tr');
                let target = tenants.filter(t => t.tenant_id === parent.id);
                let bucket = buckets.filter(bucket => bucket.bucket_id === target[0].bucket_id)[0]
                setTenant(target[0])
                setBucket(bucket)
            });
        }  
    }

    const tblSearch = (val) => {
        if ($.fn.dataTable.isDataTable(`#tblTenants`)){
            let table = $(`#tblTenants`).DataTable()
            table
                .columns(3)
                .search(val ? '^' + val + '$' : '', true, false)
                .draw()
        }
    }

    const tblClearSearch = () => {
        if ($.fn.dataTable.isDataTable(`#tblTenants`)){
            let table = $(`#tblTenants`).DataTable()
            table
                .initializeDT()
        }
    }

    return (
        <Fragment>
            <div className="card-body">
            <table id={`tblTenants`} className="table mb-0 row-border hover order-column w-100 table-hover" role="grid">
                <thead>
                    <tr>
                        <th ></th>
                        <th ></th>
                        <th ></th>
                        <th >First Name</th>
                        <th >Last Name</th>
                        <th >Address</th>
                        <th >In Date</th>
                        <th >Out Date</th>
                    </tr>
                </thead>
            </table>
            </div>
        </Fragment>
        
    )

}
export default TblTenants;