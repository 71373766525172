
import React, { useEffect, useState, useContext, } from "react"
import { LocaleContext } from '../../context/Locale/Locale';
import "/node_modules/flag-icons/css/flag-icons.min.css";

const LanguageSwitcher = () => {

    const { lang, locale, localeAvailable, setLang } = useContext(LocaleContext);

    useEffect(  () => {

      

    }, [lang]);

    return (
        <div className="d-flex justify-content-end languageSelect" >
            <div className="dropdown">
                <button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className={`fi fi-${localeAvailable[locale].flag} me-1`}></span>{localeAvailable[locale].name}
                </button>
                <ul className="dropdown-menu" >
                    {
                        Object.entries(localeAvailable).map(([k,v]) => {
                            return <li key={k} onClick={() => {setLang(k)}}><a className="dropdown-item language-option" ><span className={`fi fi-${v.flag} me-1`}></span>{v.name}</a></li>
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default LanguageSwitcher;