import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { renderToString } from 'react-dom/server'
/**
 * https://medium.com/scalereal/integration-of-google-maps-with-react-part-1-86c075ab452a
 */
const Gmap = (props) => {

    const [geolocation, setGeolocation] = useState(props.geolocation)
    const [height, setHeight] = useState(250)
    const [center, setCenter] = useState({lat: 0, lng: 0});
    const [zoom, setZoom] = useState(19);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY //process.env.REACT_APP_GOOGLE_API_KEY NMESYS Cloud Key,
    });

    const geolocationChanged = useCompare(geolocation)

    useEffect( () => {

        if(props?.geolocation?.lat) setGeolocation(props.geolocation);

    },[ props ]);

    useEffect( () => {

        if(geolocationChanged === true ) resolveMap()

    },[ center, height, geolocation ]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
  
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => { ref.current = value });
        return ref.current;
    }

    const resolveMap = () => {
        console.log('MAP GEO', geolocation)
        if(!geolocation || !geolocation?.lat) return
        setCenter({lat: geolocation.lat, lng: geolocation.lng})
    }

    const UI = () => {
        if(!geolocation || !geolocation?.lat) return
        return (
            <div >
                {!isLoaded ? (
                    <h1>Loading...</h1>
                ) : (
                    <GoogleMap
                        mapContainerClassName="bucket-map"
                        center={center}
                        zoom={zoom}
                        mapTypeId='satellite'
                    >
                    <MarkerF position={center} />
                    </GoogleMap>
                )}
            </div>
        )
    };

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default Gmap;