import React, {createContext, Fragment, useContext, useEffect, useRef, useState} from 'react';
import { AuthContext } from '../Auth/AuthProvider';
import { SocketContext } from '../Socket/SocketProvider';
import {
    PROXY_CLIENT
 } from '../../libraries/utils';
 import _ from 'lodash';

 export const NotificationContext = createContext();

 const NotificationProvider = ({children}) => {

    const {user} = useContext(AuthContext);
    const userChanged = useCompare(user);
    const [notifications, setNotifications] = useState([]);

    useEffect( () => {

        if(userChanged && user?.user_id) getNotifications()
       
    }, [ user ]);

    useEffect( () => {

       
    }, [ notifications]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const getNotifications = () => {
        if(!user || !user?.user_id) return;
        return new Promise( async (resolve, reject) => {
            PROXY_CLIENT().get(`${process.env.REACT_APP_API}/notification/all`)
            .then( response => {
                let newObjs = response.data.filter(n => !n.viewed_at)
                if(newObjs.length){
                    //console.log('PLAYING A LERT SOUND')
                }
                setNotifications(response.data)
                return resolve(response.data);
            })
            .catch( err => {
                console.log('NOTIFICATION FETCHING ERROR 54', err)
                //throw new Error(err)
                return resolve();
            })
        })
    }

    const readNotification = (notification_id) => {
        return new Promise( async (resolve, reject) => {
            let notification = notifications.filter( (n) => n.notification_id === notification_id )[0]
            if(!notification) return
            notification.viewed_at = new Date().getTime();
            
            PROXY_CLIENT().put(`${process.env.REACT_APP_API}/notification/`, notification)
            .then( response => {
                let refNotifications = [...notifications];
                let index = -1
                _.map(refNotifications, (n,i) => {
                    if(n.notification_id === notification_id){
                        index = i
                    }
                })
                refNotifications[index] = notification;
                setNotifications(refNotifications)
                return resolve(refNotifications);
            })
            .catch( err => {
                console.log('NOTIFICATION DELETE ERROR 81', err)
                //throw new Error(err)
                return resolve();
            })
        })
    }

    const deleteNotification = (notification_id) => {
        console.log('DELETING NOTIFICATION', notification_id)
        return new Promise( async (resolve, reject) => {
            PROXY_CLIENT().delete(`${process.env.REACT_APP_API}/notification?notification_id=${notification_id}`)
            .then( response => {
                let refNotifications = notifications.filter((n) => n.notification_id !== notification_id);
                setNotifications(refNotifications)
                return resolve(refNotifications);
            })
            .catch( err => {
                console.log('NOTIFICATION DELETE ERROR 98', err)
                //throw new Error(err)
                return resolve();
            })
        })
    }

    const params = {
        notifications,
        readNotification,
        deleteNotification,
        setNotifications
    }

    return (
        <NotificationContext.Provider value={params}>
            {children}
        </NotificationContext.Provider>
    )
}

export default NotificationProvider;