const lang = {
    login: {
        common: {
            forgot_password: 'Passwort vergessen?',
            login: 'Anmeldung',
            no_account: "Sie haben noch kein Konto?",
            no_account2: 'Hier registrieren',
            password: 'Password'
        },
        login: {
            title: 'Melden Sie sich bei Ihrem Konto an',
            submit: 'Anmeldung'
        },
        pwl_login: {
            title: 'Melden Sie sich bei Ihrem Konto an',
            submit: 'Einmalpasswort (OTP) anfordern'
        },
        pwl_passwd: {
            title: "Geben Sie Ihr OTP ein",
            description: "Ein Einmalpasswort wurde an Ihre registrierte E-Mail-Adresse oder an Ihre Chat-Anwendung gesendet.",
            submit: 'Anmeldung'
        },
        twofa: {
            title: 'Für dieses Konto ist eine Zwei-Faktor-Authentifizierung erforderlich',
            submit: 'Token überprüfen'
        },

        error: {
            "404": {
                h1: "Oh mannn..",
                message: "Diese Seite existiert nicht !",
                goback: `...Aber keine Sorge, geh einfach <a class="text-primary" href="/">zurück nach Hause</a> und versuchen Sie es erneut.`
            },
            "401": {
                h1: "Oh mannn..",
                message: "Sie sind wahrscheinlich nicht berechtigt, das zu tun, was Sie tun möchten.",
                goback: `...Aber keine Sorge, geh einfach <a class="text-primary" href="/">zurück nach Hause</a> und versuchen Sie es nicht noch einmal.`
            },
            
        },

        errorMessages: {
            invalidCredentials: 'UNGÜLTIGE BENUTZERZUGRIFFE',
            error304: 'FEHLER: 304',
            emailVerificationSent: 'Ein Bestätigungscode wurde an Ihre registrierte E-Mail-Adresse gesendet. Sobald Sie Ihre E-Mail-Adresse bestätigt haben, wird Ihr Konto aktiviert.',
            emailUnverified: 'Ihre E-Mail wurde nicht verifiziert. Bitte versuchen Sie es erneut.',
            emailVerified: 'Ihre E-Mail wurde verifiziert. Sie können sie jetzt für die Anmeldung verwenden.',
            emailExists: 'Ein Konto mit dieser E-Mail-Adresse existiert bereits. Bitte versuchen Sie sich stattdessen anzumelden.',
            registrationNotAllowed: 'Dieser Client erlaubt keine Benutzerregistrierung.',
            somethingWrong: 'ETWAS IST SCHIEFGELAUFEN'
        },

        home: {
            header: {
                home: "Startseite",
                features: "Funktionen",
                about: "Über uns"
            },
            hero: {
                subtitle: "Sofortige Benutzerauthentifizierung und -identifikation ohne Einrichtung",
                login: "Anmelden"
            },
            features: {
                f1: {
                    "title": "EU-basierte Datenkonformität",
                    "body": "Erleben Sie die Ruhe mit unseren DSGVO-konformen Diensten, unterstützt durch Server, die strategisch in Deutschland platziert sind, um optimalen Datenschutz und Privatsphäre zu gewährleisten."
                },
                f2: {
                    "title": "Fortgeschrittene Verschlüsselungsstandards",
                    "body": "Sichern Sie jede persönliche Daten mit unserer hochmodernen Verschlüsselung, die Informationen sowohl bei der Übertragung als auch im Ruhezustand schützt, ergänzt durch die neuesten Passwort-Hashing-Technologien für unübertroffene Sicherheit."
                },
                f3: {
                    "title": "Flexible Mehrfaktor-Authentifizierung",
                    "body": "Stärken Sie Ihre Sicherheit mit anpassbarer Mehrfaktor-Authentifizierung (MFA). Wählen Sie zwischen optionalen oder verpflichtenden MFA-Einstellungen, um Bequemlichkeit und Schutz perfekt auszubalancieren."
                },
                f4: {
                    "title": "Vielseitige Anmeldeoptionen",
                    "body": "Bieten Sie Ihren Benutzern die Bequemlichkeit einer traditionellen Benutzername/Passwort-Anmeldung oder die Einfachheit einer passwortfreien, OTP (Einmal-Passwort) Anmeldung für ein nahtloses Authentifizierungserlebnis."
                },
                f5: {
                    "title": "Strenge Passwortrichtlinien",
                    "body": "Erhöhen Sie die Sicherheit mit unseren robusten Passwortrichtlinien: erzwingen Sie keine Passwortwiederverwendung, setzen Sie strenge Passwortanforderungen um und verwalten Sie Passwortrücksetzungen leicht, um die 'Passwort vergessen' Schwachstelle zu eliminieren."
                },
                f6: {
                    "title": "Kontrollierte Fernpersistenz",
                    "body": "Erhalten Sie vollständige Kontrolle über die Authentifizierungspersistenz. Verwalten Sie Benutzersitzungen mühelos mit lokalen Cookies und Fernberechtigungslöschungsfunktionen für erhöhte Sicherheit."
                },
                f7: {
                    "title": "Flexible Integration: API oder Relay",
                    "body": "Integrieren Sie nahtlos mit unserer standardmäßigen, weiterleitbaren Anmeldeschnittstelle, die mit Whitelabel-Lösungen kompatibel ist, oder passen Sie Ihr Anmeldeerlebnis mit unserer vielseitigen API an."
                },
                f8: {
                    "title": "Modernstes OAUTH2 mit PKCE",
                    "body": "Nutzen Sie das Neueste in OAuth2-Standards mit PKCE-Methoden, um höchste Sicherheit zu gewährleisten. Vertrauen Sie auf unsere rigorosen Überprüfungsprozesse, egal ob Sie Client-Geheimnisse oder PKCE verwenden."
                },
                f9: {
                    "title": "Skalierbare Lösung: IRONAUTH3",
                    "body": "Wachsen Sie grenzenlos mit IRONAUTH3. Unsere skalierbare Lösung passt sich mühelos Ihrer wachsenden Kundenbasis an und stellt sicher, dass es keine Grenzen für Ihr Wachstum gibt, ohne zusätzliche Kosten."
                }
            },
            about: {
                "ab1": {
                    "title": "Unerschütterliches Engagement für den Datenschutz",
                    "body": "Im Mittelpunkt unseres Dienstes steht ein entschiedenes Engagement für Ihre Datensouveränität. Wir verkaufen oder überwachen Ihre Benutzerinformationen nicht, was sicherstellt, dass Sie die exklusive Kontrolle behalten. Außerdem ermöglichen wir Ihnen, Ihre gesamte Benutzerdatenbank herunterzuladen, was einen mühelosen Datentransfer bei Bedarf erleichtert."
                },
                "ab2": {
                    "title": "Geräteunabhängige Workflow-Integration",
                    "body": "IronAuth integriert sich nahtlos in alle modernen Geräte und bietet einen benutzerfreundlichen Einrichtungs- und Ausführungsprozess. Passen Sie Ihre Benutzererfahrung mit unserer anpassbaren API an oder nutzen Sie unseren effizienten integrierten Workflow, um Ihre einzigartigen Anforderungen zu erfüllen."
                }
            }
            
        }

    },
    currency: {
        symbol: "€",
        label: "EUR"
    },
    lang: {
        language: "Sprache",
        english: "English",
        german: "Deutsch"
    },
    user: {
        profile: "Konto",
    },











    
    navbar: {
        login: "Anmeldung",
        logout: "Ausmeldung",
        dashboard: "Dashboard",
        register: "Registrieren"
    },
    dataTables: {
        loading: "WIRD GELADEN ..."
    },
    swal: {
        titles:{
            "Are you sure": "Are you sure?"
        },
        texts: {
            shortLinkDelete: "Do you really want to delete short link: "
        },
        buttons: {
            confirmButtonText1: "Yes",
            cancelButtonText1: "Cancel"
        }
    },
    context: {},
    components: {
        widgets: {
            accountSwitcher:{
                placeholder1: 'Choose an account...',
                placeholder2: 'Create an account ->->->'
            }
        }
    },
    pages: {
        overview: {
            pageTitle: 'Overview',
            breadcrumb: ''
        }
    },
    
    
}

module.exports = lang;
