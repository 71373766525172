import React, {createContext, Fragment, useContext, useEffect, useRef, useState} from 'react';
import {useRouter} from "../../components/Router/Router";
import { AuthContext } from '../Auth/AuthProvider';
import CryptoJS from "crypto-js";
import Cookie from 'js-cookie';
import _ from 'lodash'

//import  PROXY_CLIENT  from '../../libraries/axios_client';
import { 
    storageCrypt,
    storageDecrypt,
    COOKIE_PARAMS,
    getCookie,
    setCookie,
    cookieCrypt,
    cookieDecrypt,
    cookieDestroy,
    PROXY_CLIENT
 } from '../../libraries/utils';

let prefsStored = async () => {
    storageDecrypt({name: 'preferences'})
    .then( dencStore => {
        if( !!dencStore && typeof dencStore !== 'object') dencStore = JSON.parse(dencStore)
        return dencStore;
    })
    .catch( err => {
        return null;
    })
}

export const PreferenceContext = createContext();

const PreferenceProvider = ({children}) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const [preferences, setPreferences] = useState( prefsStored() );
    const preferencesChanged = useCompare(preferences);
    const userChanged = useCompare(user);

    const defaultPrefs = {
        app_id: process.env.REACT_APP_APP_ID,
        lang: 'en',
        communication: {},
        dashboard: {
            widgets: {}
        }
    }

    useEffect( () => {

        if(userChanged && user?.user_id) getPrefs();
        
    }, [user, userChanged, preferences])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    /**
     * New users may not have prefences yet, so we create them here
     */
    const createPrefs = async () => {
        const prefs = {
            ...defaultPrefs,
            user_id: user.user_id
        }
        
        PROXY_CLIENT().post(`${process.env.REACT_APP_PROXY}/user/pref`, prefs)
        .then(async response => {
            await storageCrypt({name: 'preferences', payload: response.data});
            setPreferences(response.data)
        })
        .catch(err => {
            console.log('CREATE PREFS ERROR', err)
        })
    }

    const getPrefs = async () => {
        PROXY_CLIENT().get(`${process.env.REACT_APP_PROXY}/user/pref/?user_id=${user.user_id}&app_id=${process.env.REACT_APP_APP_ID}`)
        .then(async response => {
            if(!response.data?.preference_id){
                createPrefs()
            }else{
                await storageCrypt({name: 'preferences', payload: response.data});
                setPreferences(response.data)
            }
        })
        .catch(err => {
            console.log('GET PREFS ERROR', err)
        })
    }
    
    const updatePrefs = (updatedObj) => {
        PROXY_CLIENT().put(`${process.env.REACT_APP_PROXY}/user/pref/`, updatedObj)
        .then(async response => {
            await storageCrypt({name: 'preferences', payload: response.data});
            setPreferences(response.data)
        })
        .catch(err => {
            console.log('GET PREFS ERROR', err)
        })
    }

    /**
     * Remove the widget from the user preferences.
     * They can add it back in the Widget Mananger.
     */
    const hideWidget = (e) => {
        e.preventDefault()
        const id = e.currentTarget.id
        let prefsRef = {...preferences}
        _.map(prefsRef.dashboard.widgets.header, blk => {
            if(blk.component === id) blk.show = false
        })
        updatePrefs(prefsRef)
    }

    const params = {
        preferences,
        getPrefs,
        setPreferences,
        updatePrefs,
        hideWidget
    }

    return (
        <PreferenceContext.Provider value={params}>
            {children}
        </PreferenceContext.Provider>
    )
}

export default PreferenceProvider;