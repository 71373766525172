import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { LocaleContext } from '../../context/Locale/Locale';
import { AuthContext } from '../../context/Auth/AuthProvider';

/** 
import { ClientContext } from '../../context/Client/ClientContext';
import { PreferenceContext } from '../../context/Preferences/Preference';

**/
import LanguageSwitcher  from '../../components/LanguageSwitcher';
import { useRouter } from '../../components/Router/Router'
import {NavLink} from "react-router-dom";
import { Link } from "react-router-dom";
import ContactUsModal from '../Modals/contactUsModal';

const Navbar = (props) => {

    const router = useRouter();
//    const {client} = useContext(ClientContext);
//    const clientChanged = useCompare(client)
    const { lang, locale } = useContext(LocaleContext);
    const {user, login, logout, register} = useContext(AuthContext);

//    const {preferences} = useContext(PreferenceContext);
//    const preferencesChanged = useCompare(preferences);

    const [logo, setLogo] = useState("/assets/images/logo/CoreDB_logo_black_1000.png");
    const [logoTitle, setLogoTitle] = useState("CoreDB - by NMESYS");
    const [showContactUsModal, setModalShow] = useState(false)

    useEffect(  () => {


    }, [lang]);


    useEffect(  () => {


    }, [user]);

    useEffect(  () => {}, [showContactUsModal]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const UI = () => {
        if(!lang) return (<Fragment></Fragment>)

        const btnAuth = () => {
            if(!user){
                return (
                    <Fragment>
                        <button className="btn btn-primary fw-medium" onClick={() => login()} title="Login">{lang.navbar.login}</button>
                    </Fragment>
                )
            }else{
                return (
                    <Fragment>
                        <button className="btn btn-success fw-medium text-decoration-none " onClick={() => router.push('/dashboards/overview')} title="Dashboard">{lang.navbar.dashboard}</button>
                        <button className="btn btn-danger fw-medium text-decoration-none ms-2" onClick={() => logout()} title="Logout">{lang.navbar.logout}</button>
                    </Fragment>
                )
            }
        }
        return (
        
            <Fragment>

                <nav className="navbar navbar-expand-lg navbar-landing fixed-top bg-light" id="navbar">
                    <div className="container">
                        <a className="navbar-brand coreLogo" href="index.html">
                            <img src={logo} className="card-logo card-logo-dark" alt={logoTitle} height="17" />
                        </a>
                        <button className="navbar-toggler py-0 fs-20 text-body" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="mdi mdi-menu"></i>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">
                                <li className="nav-item">
                                    <a className="nav-link fs-15 active" href="#hero">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link fs-15" href="#services">Services</a>
                                </li>
                                {/** 
                                <li className="nav-item">
                                    <a className="nav-link fs-15" href="#features">Features</a>
                                </li>
                                */}
                                <li className="nav-item">
                                    <a className="nav-link fs-15" href="#plans">Plans</a>
                                </li>
                                {/**
                                <li className="nav-item">
                                    <a className="nav-link fs-15" href="#reviews">Reviews</a>
                                </li>
                                */}
                                <li className="nav-item">
                                    <a className="nav-link fs-15" href="#team">Team</a>
                                </li>
                                <li className="nav-item">
                                    <ContactUsModal
                                        useText={true}
                                    />
                                </li>
                               
                            </ul>

                            <div className="btnLinks">
                                <LanguageSwitcher />
                                {btnAuth()}
                            </div>

                        </div>

                    </div>
                </nav>
                <div className="vertical-overlay" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent.show"></div>

                <ContactUsModal />

            </Fragment>
            
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default Navbar;