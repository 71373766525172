import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/Auth/AuthProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import {useRouter} from "../../components/Router/Router";
import Loading from '../../components/Loaders/Loading';
import Iconv from '../../components/iconv';
import _ from 'lodash';

import Swal from '@nmesys/sweetalert2'
import { MembersContext } from '../../context/Members/MembersProvider';
import MemberCard from '../../components/Cards/memberCard';
import TblMembers from '../../components/DataTables/members';

const $ = require('jquery')
$.DataTable = require('datatables.net');

const Members = (props) => {
    
    const router = useRouter();
    const navigate = useNavigate();
    const { lang, locale } = useContext(LocaleContext);
    const {user} = useContext(AuthContext);
    const {member, members, addNewMemberModal} = useContext(MembersContext)

    useEffect(() => {}, [members]);


    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        const isProcessing = () => {
            if (!members || members.length === 0) {
                return (<Fragment><Loading/></Fragment>)
            }
        }

        return (
            <Fragment>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex align-items-center flex-wrap gap-2">
                                    <div className="flex-grow-1">
                                        <button className="btn btn-primary" onClick={ () => addNewMemberModal() }><i className="ri-add-fill me-1 align-bottom"></i> Add New Member</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`col-xxl-${(members.length > 0)? '8':'12'}`}>
                        <div className="card" id="contactList">
                            <div className="card-body">
                                <div className="table-card mb-3 ps-2 pe-2">
                                    <TblMembers />   
                                </div>
                                {isProcessing()}
                            </div>
                        </div>
                    </div>

                    <div className={`${(!!members && members.length)? 'col-xxl-4':'hidden'}`}>
                        <MemberCard /> 
                    </div>
                </div>

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

Members.requiredScope = ['members.view.all', 'members.view.own'];
Members.requireAuth = true;
export default Members;