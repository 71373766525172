import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';



const Services = (props) => {

   
    useEffect(  () => {

    }, []);

   

    const UI = () => {

        return (
            <Fragment>

                <section className="section bg-light mt-4" id="services">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="text-center mb-5">
                                    <h1 className="mb-3 ff-secondary fw-semibold lh-base">A digital energy and property management studio dashboard.</h1>
                                    <p className="text-muted">CoreDB gives you one central software where you can manage oversight of all of your sensors, gateways, properties and more...</p>
                                </div>
                            </div>
                        </div>
                        <div className="row g-3">
                            <div className="col-lg-4">
                                <div className="d-flex p-3">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm icon-effect">
                                            <div className="avatar-title bg-transparent text-success rounded-circle">
                                                <i className="ri-pencil-ruler-2-line fs-36"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-18">First EVER AI assisted platform</h5>
                                        <p className="text-muted my-3 ff-secondary">CoreDB has the first ever Machine Learning based sensor telegram interpreter on the market.</p>
                                        <div>
                                            <a href="#" className="fs-13 fw-medium">Learn More <i className="ri-arrow-right-s-line align-bottom"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex p-3">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm icon-effect">
                                            <div className="avatar-title bg-transparent text-success rounded-circle">
                                                <i className="ri-palette-line fs-36"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-18">Unlimited Data</h5>
                                        <p className="text-muted my-3 ff-secondary">The right plan gets you unlimited data capacity at breakneck speed and power</p>
                                        <div>
                                            <a href="#" className="fs-13 fw-medium">Learn More <i className="ri-arrow-right-s-line align-bottom"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex p-3">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm icon-effect">
                                            <div className="avatar-title bg-transparent text-success rounded-circle">
                                                <i className="ri-lightbulb-flash-line fs-36"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-18">Unlimited Sensor Alarms</h5>
                                        <p className="text-muted my-3 ff-secondary">Setup sensor value alarms to be sent to your email, Telegram or other private notification services.</p>
                                        <div>
                                            <a href="#" className="fs-13 fw-medium">Learn More <i className="ri-arrow-right-s-line align-bottom"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex p-3">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm icon-effect">
                                            <div className="avatar-title bg-transparent text-success rounded-circle">
                                                <i className="ri-customer-service-line fs-36"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-18">Unlimited customer services</h5>
                                        <p className="text-muted my-3 ff-secondary">Your customers can set up sensor alarms, custom reports and more, just like an admin. </p>
                                        <div>
                                            <a href="#" className="fs-13 fw-medium">Learn More <i className="ri-arrow-right-s-line align-bottom"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex p-3">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm icon-effect">
                                            <div className="avatar-title bg-transparent text-success rounded-circle">
                                                <i className="ri-stack-line fs-36"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-18">Customizable Reporting</h5>
                                        <p className="text-muted my-3 ff-secondary">Build your own reports and have them sent to you at custom intervals, as much as you like.</p>
                                        <div>
                                            <a href="#" className="fs-13 fw-medium">Learn More <i className="ri-arrow-right-s-line align-bottom"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex p-3">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm icon-effect">
                                            <div className="avatar-title bg-transparent text-success rounded-circle">
                                                <i className="ri-settings-2-line fs-36"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-18">White Labeling</h5>
                                        <p className="text-muted my-3 ff-secondary">Personalise your logo, name and even use a custom domain with CoreDB. Same platform, better name!</p>
                                        <div>
                                            <a href="#" className="fs-13 fw-medium">Learn More <i className="ri-arrow-right-s-line align-bottom"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="d-flex p-3">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm icon-effect">
                                            <div className="avatar-title bg-transparent text-success rounded-circle">
                                                <i className="ri-slideshow-line fs-36"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-18">Modern Security Access</h5>
                                        <p className="text-muted my-3 ff-secondary">Passwordless Access gives you the chance to forget that password mess while still maintaining controlled access.</p>
                                        <div>
                                            <a href="#" className="fs-13 fw-medium">Learn More <i className="ri-arrow-right-s-line align-bottom"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex p-3">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm icon-effect">
                                            <div className="avatar-title bg-transparent text-success rounded-circle">
                                                <i className="ri-google-fill fs-36"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-18">Government Level Encryption</h5>
                                        <p className="text-muted my-3 ff-secondary">Your data is encrypted at rest and in transit using current governement approved levels of encryption.</p>
                                        <div>
                                            <a href="#" className="fs-13 fw-medium">Learn More <i className="ri-arrow-right-s-line align-bottom"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex p-3">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm icon-effect">
                                            <div className="avatar-title bg-transparent text-success rounded-circle">
                                                <i className="fa-brands fa-telegram fs-36"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-18">Instant communication</h5>
                                        <p className="text-muted my-3 ff-secondary">Forget email. CoreDB can use Telegram to communicate with you instantly. Alarms, Notifications ... all direct to your phone or desktop.</p>
                                        <div>
                                            <a href="#" className="fs-13 fw-medium">Learn More <i className="ri-arrow-right-s-line align-bottom"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default Services;