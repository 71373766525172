
import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { LocaleContext } from '../context/Locale/Locale';
import "/node_modules/flag-icons/css/flag-icons.min.css";


const LanguageSwitcher = (props) => {

    const { 
        locale,
        localeAvailable,
        lang,
        setLang 
    } = useContext(LocaleContext);

    useEffect(  () => {
        
    }, [lang, locale]);


    const UI = () => {
        if(!lang) return (<Fragment></Fragment>)
        return (
            <Fragment>
                
                <div className="dropdown ">
                    <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className={`display-1 fi fi-${ (!!locale)? (locale === 'en')?'gb': locale : locale } fis`}></span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                    {
                        Object.entries(localeAvailable).map(([k,v]) => {
                            return (
                                
                                    <a key={k} className="dropdown-item notify-item language py-2" onClick={() => {setLang(k)}}>
                                        <span className={` fi fi-${ v.flag } fis`}></span>
                                        <span className="ms-1 ">{ v.name }</span>
                                    </a> 
                                
                            )
                        })
                    }
                          
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default LanguageSwitcher;