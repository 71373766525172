import axios from 'axios';
import Cookie from "js-cookie";
import Swal from '@nmesys/sweetalert2';


/********************************************
 *          TOASTS
 *******************************************/
const errorToast = (err) => {
  if(!err) return
  Swal.fire({
      toast: true,
      position: 'bottom-end',
      timer: 5000,
      timerProgressBar: true,
      text: err,
      showConfirmButton: false,
      customClass: {
          popup: "bg-danger text-white"
      }
  })
}

const successToast = (msg) => {
  if(!msg) return
  Swal.fire({
      toast: true,
      position: 'bottom-end',
      timer: 5000,
      timerProgressBar: true,
      text: msg,
      showConfirmButton: false,
      customClass: {
          popup: "bg-success text-white"
      }
  })
}


const PROXY_CLIENT = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API,
  headers:{
//    'Cache-Control': 'no-cache, no-store, must-revalidate',
//    'Pragma': 'no-cache',
//    'Expires': '0',
    'x-appkey': localStorage.getItem('APP_KEY') //Cookie.get('APP_KEY')
  },
  timeout: 10000
})

PROXY_CLIENT.interceptors.request.use(
    (config) => {
      // You can modify the request configuration here if needed
      return config;
    },
    (error) => {
      // Handle request errors here
      return Promise.reject(error);
    }
);

PROXY_CLIENT.interceptors.response.use(
    (response) => response,
    (error) => {
      /**
       * API will provide various errors. Here we map/create custom responses based upon those
       * given error codes
       */
      if (error.response && error.response.status === 401) {
        let errorCode = 'unknown';
        if(error?.response?.data?.error) errorCode = error.response.data.error;
        // If the token has expired, log out and redirect to login page
        switch(errorCode){
            case 'token_expired':
              Swal.fire({
                title: 'Session ist abgelaufen!',
                text: 'Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein :)',
                icon: 'warning',
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: false,
                showConfirmButton: true,
                showCancelButton: false,
                background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.location.href  = '/auth/logout';
              });
              break;

            case 'permission_denied':
              errorToast(`${(error?.response?.data?.message) ? error.response.data.message : 'You do not have the permissions required to conduct that transaction. Please consult with your administrator. Error 40194'}`);
              break;

            case 'user_credentials':
              errorToast(`${(error?.response?.data?.message) ? error.response.data.message : 'User credentials are incorrect'}`);





            default:
              errorToast(`${(error?.response?.data?.message) ? error.response.data.message : 'An uknown error has occurred. Error Code 400127'}`);  // means nothing except for a means to find this code.
              break;


        }
        
        
  
      }
      return Promise.reject(error);
    }
);

export default PROXY_CLIENT;