import React, {createContext, Fragment, useContext, useEffect, useRef, useState, useMemo} from 'react';
import { AccountContext } from '../Account/AccountProvider';
import Swal from '@nmesys/sweetalert2'
import {
    errorToast,
    successToast,
    PROXY_CLIENT
} from '../../libraries/utils';
import _ from 'lodash';

export const AddressContext = createContext();

const AddressProvider = ({children}) => {

    const {account} = useContext(AccountContext);

    const [address, setAddress] = useState({})
    const [addresses, setAddresses] = useState([])
    const [initialLoadComplete, setComplete] = useState(false)
    const accountChanged = useCompare(account);
    
    useEffect( () => {
        if( accountChanged && account?.account_id ) getAddresses()
    }, [account])

    useEffect( () => {
       
    }, [addresses])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const getAddress = async (address_id) => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/address/?address_id=${address_id}`)
        .then( response => {
            setAddress(response.data)
            return response.data;
        })
        .catch( err => {
            console.log('ADDRESS FETCHING ERROR 48', err)
         //   throw new Error(err)
        })
    }

    const getAddresses = async () => {
        if(!account || !account?.account_id) return;
        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/address/all?account_id=${account.account_id}`)
        .then( response => {
            //console.log('ADDRESSES RESPONSE', response.data)
            setAddresses(response.data);
            setComplete(true)
            return response.data;
        })
        .catch( err => {
            console.log('ADDRESS FETCHING ERROR 64', err)
        //    throw new Error(err)
        })
    }

    const params = {
        address,
        addresses,
        initialLoadComplete
    }

    return (
        <AddressContext.Provider value={params}>
            {children}
        </AddressContext.Provider>
    )
}

export default AddressProvider;