import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../context/Auth/AuthProvider';



const CallToActionBanner = (props) => {

    const {user, login, logout, register} = useContext(AuthContext);

   
    useEffect(  () => {

    }, []);

   

    const UI = () => {

        return (
            <Fragment>

                <section className="py-5 bg-primary position-relative" id="callToActionBanner">
                    <div className="bg-overlay bg-overlay-pattern opacity-50"></div>
                    <div className="container">
                        <div className="row align-items-center gy-4">
                            <div className="col-sm">
                                <div>
                                    <h4 className="text-white mb-0 fw-semibold">Start your Core Dashboard Now and get access to your data today!</h4>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <div>
                                    <a className="btn bg-gradient btn-success" onClick={() => register()} >
                                        <i className="ri-shopping-cart-2-line align-middle me-1"></i> Signup Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default CallToActionBanner;