import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { SensorContext } from '../../context/Sensor/SensorProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import _ from 'lodash'

import {useRouter} from "../Router/Router";
import de from '../DataTables/datatable.de.json';
import Iconv from '../iconv';
const $ = require('jquery')
$.DataTable = require('datatables.net');

const TblSensors = (props) => {

    const { sensor, sensors, setSensor } = useContext(SensorContext);
    const { lang, locale } = useContext(LocaleContext);

    const sensorChanged = useCompare(sensor)
    const sensorsChanged = useCompare(sensors);

    useEffect( () => {
        if(sensorsChanged === true) initializeDT()
        
    },[sensors]);


    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
  
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => { ref.current = value });
        return ref.current;
    }
    /**
     * Test the sensor symetric key for validity
     * Key msut be 32 characters
     * Key must not have consecutive characters of the same case
     * Key must not contain the sensor serial number
     */
    const isKeyValid = (input, serial_number) => {
        if (!_.isString(input) || input.length !== 32) {
            return false
        }
        let consecutiveChars = [];
        _.forEach(input, (char, index) => {
            if (index < input.length - 1 && char === input[index + 1]) {
                consecutiveChars.push(char);
            }
        });
        if(consecutiveChars.length > 0) return false;
        if(input.search(serial_number) > -1) return false
        return true;
    }

    const initializeDT = async () => {

        const tableElement = $('#tblSensors');
        const dataTable = $.fn.dataTable.isDataTable(tableElement);
        if (dataTable) {
            tableElement.DataTable().destroy();
            tableElement.empty();
        }
        $(`#tblSensors tbody`).off();

        if (sensors.length > 0) {
            const table = $(`#tblSensors`).DataTable({
                language: locale,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: sensors,
                rowId: "sensor_id",
                pageLength: 25,
                orderCellsTop: false,
                fixedHeader: false,
                order: [
                    [4, "asc"]
                ],
                columns: [
                    
                    {
                        "data": null,
                        "searchable": false,
                        "visible": true,
                        "ordering": false,
                        render: function (data, type, row) {
                            /**
                             * MUST be identical to ICONV Icons
                             */
                            const icons = {
                                "key": {
                                    hasKey: "text-success fa-solid fa-key-skeleton",
                                    noKey: "text-danger fa-solid fa-key-skeleton"
                                },
                                "0x4": {
                                    standard: "text-warning fa-solid fa-fire",
                                    wsr: "fa-solid fa-droplet text-danger",
                                    hzg: "fa-solid fa-fire text-danger",
                                },
                                "0x7": {
                                    standard: "fa-solid fa-droplet text-primary"
                                },
                                "0x8": { 
                                    standard: "fa-solid fa-fire text-primary"
                                },
                                "0x6": { 
                                    standard: "fa-solid fa-droplet text-warning"
                                },
                                "0xc": {
                                    standard: "text-warning fa-solid fa-fire",
                                    wsr: "fa-solid fa-droplet text-danger",
                                    hzg: "fa-solid fa-droplet text-fire",
                                },
                                "0x0": {
                                    standard: "fa-solid fa-microchip",
                                }
                            }
                            let iconType = "standard";
                            if(!data?.medium_code) data.medium_code = "0x0";
                            if(data?.attributes && data.attributes.includes('wsr')) iconType = 'wsr';
                            if(data?.attributes && data.attributes.includes('hzg')) iconType = 'hzg';
                            let icon = icons[data.medium_code][iconType];
                            let html = `<i class="ps-2 ${icon}"></i>`
                            return html
                            
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": false,
                        "ordering": false,
                        render: function (data, type, row) {
                            if(data?.attributes) return data.attributes
                            else return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": false,
                        "ordering": false,
                        render: function (data, type, row) {
                            if(data?.medium_code) return data.medium_code
                            else return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": false,
                        "ordering": false,
                        render: function (data, type, row) {
                            if(data?.bucket_id) return data.bucket_id
                            else return ''
                        }
                    },  
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            return data.serial_number
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.type) return data.type
                            else return '';
                        }
                    },
                    {
                        "data": null,
                        "searchable": false,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            let rssi = data?.state?.rssi
                            if(rssi){
                                if(rssi < 0) rssi = rssi * -1;
                                return rssi
                            }
                            else return '?';
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            const key = {
                                hasKey: "text-success fa-solid fa-key-skeleton",
                                noKey: "text-danger fa-solid fa-key-skeleton",
                                brokenKey: "text-warning fa-solid fa-key-skeleton",
                            }

                            let valid = isKeyValid(data.symetric_key, data.serial_number);
                            let icon = key.noKey, title = "Either there is no VALID Symetric Key or the default manufacturer key is used. A VALID Symetric Key must not be the manufacturer key, must not contain the serial number and must not have any consecutive characters. A VALID symtric key is also 32 characters in length."
                            if(valid === true){
                                icon = key.hasKey;
                                title = 'Key appears to be valid';
                            }
                            let html = `<i class="ps-2 ${icon}" title="${title}"></i>`
                            return html
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (data?.state?.date) {
                                return new Date(data.state.date).toLocaleDateString('de-DE', { year:"numeric", month:"short", day:"numeric"}) 
                            }
                            return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if (data?.state?.values) {
                                let v = '';
                                let index = 0;
                                _.map(data.state.values, (val,uom) => {
                                    v += `<span class=${uom !== data.unit_of_measure ? "text-secondary":""}>${(index > 0 ? '<br />' : '')}${Number(val.toFixed(2))} ${uom}</span>`;
                                    index ++;
                                })
                                return v;



                            }
                            return 'NA'
                        }
                    }
                ],
                createdRow: function (row, data, dataIndex) {},
                createdCell:function (td, cellData, rowData, row, col) {},
                "fnDrawCallback": function( oSettings ) { }
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $(`#tblSensors tbody tr`).off('mouseenter').on('mouseenter', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });

            $(`#tblSensors tbody tr`).off('mouseleave').on('mouseleave', function () {
                $(this).css('background-color', 'transparent');
            });

            $(`#tblSensors tbody`).on('click', 'tr', function (e) {
                let parent = e.currentTarget.closest('tr');
                let target = sensors.filter(s => s.serial_number === parent.id);

                setSensor(target[0])
            });
        }  
    }

    return (
        
        <table id={`tblSensors`} className="table mb-0 row-border hover order-column w-100 table-hover " role="grid">
            <thead>
                <tr>
                    <th ></th>
                    <th ></th>
                    <th ></th>
                    <th ></th>
          
                    <th >Serial Number</th>
                    <th >Type</th>
                    <th > RSSI</th>
                    <th >Key</th>
                    <th className="text-right">Date</th>
                    <th className="text-right">Stand</th>
                </tr>
            </thead>
        </table>
        
    )

}
export default TblSensors;