import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
//import { ClientContext } from '../../context/Client/ClientContext';

import { useRouter } from '../Router/Router'
import {Helmet} from "react-helmet";

import Navbar from "../Home/Navbar"
import Footer from "../Home/Footer";

import Topbar from "../Dashboard/Navbar";
import RemoveNotificationModal from '../Dashboard/RemoveNotificationModal';
import AppMenu from '../Dashboard/AppMenu';
import ThemeSettings from '../Dashboard/ThemeSettings';
import DashFooter from "../Dashboard/Footer";


const LayoutHandler = ({children}) => {
    const [updated, setUpdated] = useState(false);
    const [scrollVisible, setScrollVisible] = useState(false)
    const router = useRouter();

//    const { client } = useContext(ClientContext);
//    const clientChanged = useCompare(client);

    useEffect(() => {
        
        let now = new Date().getTime();
 //       if(clientChanged && client?.whitelabel?.expires_at > now) router.push('/home')

    }, []);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setScrollVisible(true)
        }
        else if (scrolled <= 300){
            setScrollVisible(false)
        }
    };

    const scrollToTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    window.addEventListener('scroll', toggleVisible);

    const UI = () => {
        switch(router.pathname){
            case '/':
            case '/home':    
                return (
                    <Fragment>
                        <Helmet>
                            <title>{(process.env.REACT_APP_META_TITLE)? process.env.REACT_APP_META_TITLE:'EnergieWisser Pro'}</title>
                            <meta charset="utf-8" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <meta name="description" content={(process.env.REACT_APP_META_DESCRIPTION)? process.env.REACT_APP_META_DESCRIPTION:'Premium Multipurpose Energy Efficiency Admin Dashboard'} />
                            <meta name="author" content={(process.env.REACT_APP_META_AUTHOR)? process.env.REACT_APP_META_AUTHOR:'https://nmesys.io | @DevOps'} />
                            <link rel="shortcut icon" href="/assets/images/logo/favicon.ico" />
                            <link rel="apple-touch-icon" sizes="180x180" href="/assets/images/logo/apple-touch-icon.png" />
                            <link rel="icon" type="image/png" sizes="32x32" href="/assets/images/logo/favicon-32x32.png" />
                            <link rel="icon" type="image/png" sizes="16x16" href="/assets/images/logo/favicon-16x16.png" />
                            <link rel="manifest" href="/site.webmanifest" />
                            <script src="/assets/js/layout.js"></script>
                            <link href="/assets/css/icons.min.css" rel="stylesheet" type="text/css" />
                            <link href="/assets/css/app.css" rel="stylesheet" type="text/css" />
                            <link href="/assets/css/custom.css" rel="stylesheet" type="text/css" />

                        </Helmet>
                        <div data-bs-spy="scroll" data-bs-target="#navbar-example">
                            <div className="layout-wrapper landing">
                                <Navbar />
                                {children}
                                <Footer />
                                <button onClick={() => scrollToTop()} className="btn btn-danger btn-icon landing-back-top" id="back-to-top">
                                    <i className="ri-arrow-up-line"></i>
                                </button>
                            </div>
                        </div>
                        <script src="/assets/libs/simplebar/simplebar.min.js"></script>
                        <script src="/assets/libs/node-waves/waves.min.js"></script>
                        <script src="/assets/libs/feather-icons/feather.min.js"></script>
                        <script src="/assets/js/pages/plugins/lord-icon-2.1.0.js"></script>
                        <script src="/assets/js/plugins.js"></script>
                        <script src="/assets/js/pages/form-validation.init.js"></script>
                        <script src="/assets/js/pages/passowrd-create.init.js"></script>
                        <script src="/assets/js/pages/landing.init.js"></script>
                        <script src="/assets/js/pages/dashboard-ecommerce.init.js"></script>
                        <script src="/assets/js/app.js"></script>
                    </Fragment>
                )
            case '/404':
            case '/401':
            case '/500':
                return (
                    <Fragment>
                        {children}
                    </Fragment>
                )
            
            case '/auth/login':
            case '/auth/password':
            case '/auth/twofa':
            case '/auth/logout':
            case '/auth/register':
                return (
                    <Fragment>
                        <Helmet>
                            <meta charSet="UTF-8" />
                            <meta name="viewport" content="width=device-width, initial-scale=1" />
                            <link rel="shortcut icon" href="/auth/assets/logo.png" type="image/x-icon" />
                            <link rel="icon" href="/auth/assets/logo.png" type="image/x-icon" />
                            <link type="text/css" rel="stylesheet" href="/auth/assets/bootstrap/css/bootstrap.css" />
                            <link type="text/css" rel="stylesheet" href="/auth/assets/fonts/font-awesome/css/font-awesome.min.css" />
                            <link type="text/css" rel="stylesheet" href="/auth/assets/fonts/flaticon/font/flaticon.css" />
                            <link rel="shortcut icon" href="/auth/assets/logo.png" type="image/x-icon" />
                            <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800%7CPoppins:400,500,700,800,900%7CRoboto:100,300,400,400i,500,700" />
                            <link href="https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800;900&display=swap" rel="stylesheet" />
                            <link type="text/css" rel="stylesheet" href="/auth/assets/css/style.css" />
                            <link type="text/css" rel="stylesheet" href="/auth/assets/css/custom.css" />
                            <link rel="stylesheet" type="text/css" id="style_sheet" href="/auth/assets/css/skins/default.css" />
                            <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/css/flag-icons.min.css" />
                            <meta name="title" content="NMESYS | TRANSFORMING IDEAS INTO DIGITAL EXCELLENCE" />
                            <title>{(process.env.REACT_APP_META_TITLE)? process.env.REACT_APP_META_TITLE:'EnergieWisser Pro Authentication System'}</title>
                        </Helmet>

                        <div className="login-1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <script src="/auth/assets/js/frmPassword.js" ></script>
                        <script src="/auth/assets/js/jquery.min.js" ></script>
                        <script src="/auth/assets/bootstrap/js/bootstrap.bundle.min.js" ></script>
                        <script src="/auth/assets/js/languageSelect.js" ></script>

                    </Fragment>
                )
            
            default:
                return (
                    <Fragment>
                        <Helmet>
                            <title>{(process.env.REACT_APP_META_TITLE)? process.env.REACT_APP_META_TITLE:'EnergieWisser Pro'}</title>
                            <meta charset="utf-8" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <meta name="description" content={(process.env.REACT_APP_META_DESCRIPTION)? process.env.REACT_APP_META_DESCRIPTION:'Premium Multipurpose Energy Efficiency Admin Dashboard'} />
                            <meta name="author" content={(process.env.REACT_APP_META_AUTHOR)? process.env.REACT_APP_META_AUTHOR:'https://nmesys.io | @DevOps'} />
                            
                            <link rel="shortcut icon" href="/assets/images/logo/favicon.ico" />
                            <link rel="apple-touch-icon" sizes="180x180" href="/assets/images/logo/apple-touch-icon.png" />
                            <link rel="icon" type="image/png" sizes="32x32" href="/assets/images/logo/favicon-32x32.png" />
                            <link rel="icon" type="image/png" sizes="16x16" href="/assets/images/logo/favicon-16x16.png" />
                            <link rel="manifest" href="/site.webmanifest" />

                            <script src="/assets/js/layout.js"></script>
                            <link href="/assets/css/icons.min.css" rel="stylesheet" type="text/css" />
                            <link href="/assets/css/app.css" rel="stylesheet" type="text/css" />
                            <link href="https://cdn.datatables.net/1.13.5/css/dataTables.bootstrap5.min.css" rel="stylesheet" type="text/css" />
                            <link rel="stylesheet" type="text/css" href="/assets/fontawesome/css/all.min.css" />
                            <link href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css" rel="stylesheet" />
                            <link href="/assets/flagicon/css/flag-icons.css" rel="stylesheet" type="text/css" />
                            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.13.18/css/bootstrap-select.min.css" />
                            <link href="/assets/css/custom.css" rel="stylesheet" type="text/css" />
                        </Helmet>
                            <div id="layout-wrapper">
                                <Topbar />
                {/**            <RemoveNotificationModal />**/}
                                <AppMenu />

                
                                <div className="vertical-overlay"></div>
                                <div className="main-content">
                                    <div className="page-content" id="pageContent">
                                        <div className="container-fluid">
                                            {children}
                                        </div>
                                    </div>
                                    <DashFooter /> 
                                </div>
                            </div>
                            <button onClick={() => scrollToTop()} className="btn btn-danger btn-icon" id="back-to-top">
                                <i className="ri-arrow-up-line"></i>
                            </button>
                            <div id="preloader">
                                <div id="status">
                                    <div className="spinner-border text-primary avatar-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>

                            <script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossOrigin="anonymous" />
                            <script src="/assets/libs/simplebar/simplebar.min.js"></script>
                            <script src="/assets/libs/node-waves/waves.min.js"></script>
                            <script src="/assets/libs/feather-icons/feather.min.js"></script>
                            <script src="/assets/js/pages/plugins/lord-icon-2.1.0.js"></script>
                            <script src="/assets/js/plugins.js"></script>
                            <script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>
                            <script src="/assets/js/pages/form-validation.init.js"></script>
                            <script src="/assets/js/pages/passowrd-create.init.js"></script>
                            <script src="/assets/js/pages/select2.init.js"></script>
                            <script src="/assets/js/pages/landing.init.js"></script>
                            <script src="/assets/js/pages/dashboard-ecommerce.init.js"></script>

                            <script src="/assets/js/app.js"></script>


                    </Fragment>





            )


        }
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default LayoutHandler;