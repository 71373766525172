import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { Link } from "react-router-dom";
import { LocaleContext } from '../../context/Locale/Locale';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import Accounts from '../Apps/accounts';
import Buckets from '../Apps/buckets';
import Tenants from '../Apps/tenants';
import Sensors from '../Apps/sensors';
import Gateways from '../Apps/gateways';
import Members from '../Apps/members';

import AccountSwitcher from '../../components/AccountSwitcher';

import Loading from '../../components/Loaders/Loading';
import WidgetPanel from '../../components/Widgets/WidgetPanel';
import { 
    PROXY_CLIENT,
    capitalizeFirst,
    successToast
 } from '../../libraries/utils';

import CountSensorsWidget from '../../components/Widgets/header/sensors';
import CountBucketsWidget from '../../components/Widgets/header/buckets';
import CountGatewaysWidget from '../../components/Widgets/header/gateways';
import CountAlertWidget from '../../components/Widgets/header/alerts';
import CountTenantsWidget from '../../components/Widgets/header/tenants';
import CountAccountWidget from '../../components/Widgets/header/accounts';

const Dashboard = (props) => {

    const { lang, locale, l } = useContext(LocaleContext);
    const {user, PermissionsWrapper} = useContext(AuthContext);
    const userChanged = useCompare(user);

    useEffect(  () => {

    }, [user]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const UI = () => {
        if(!lang || !user?.user_id) return (<Fragment><Loading/></Fragment>)
        return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">{l('pages.overview.pageTitle', 'Overview')}</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">Dashboards</li>
                                    <li className="breadcrumb-item"><Link to="/dashboards/overview">{l('pages.overview.pageTitle', 'Overview')}</Link></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex align-items-center flex-wrap gap-2">
                                        <div className="flex-grow-1">
                                            
                                            <div className="col-lg-3 float-right ms-2">
                                                <AccountSwitcher />
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="row">
                        <CountAccountWidget />
                        <CountBucketsWidget />
                        <CountSensorsWidget />
                        <CountGatewaysWidget />
                        <CountAlertWidget />
                        <CountTenantsWidget />
                    </div>
                </section>

                <section>
                    <div className="row">
                        <div className="col-12">
                            <div className="h-100">


                                


                                <div className="card">
                                    <div className="card-body">
                                        <p className="text-muted"></p>
                                        <ul className="nav nav-pills animation-nav nav-justified gap-2 mb-3" role="tablist">
                                            <li className="nav-item waves-effect waves-light">
                                                <a className="nav-link active" id="tabAccounts" data-bs-toggle="tab" href="#nav-badge-accounts" role="tab" aria-selected="true">
                                                    {l('pages.overview.tabs.accounts', 'Accounts')}
                                                </a>
                                            </li>
                                            <li className="nav-item waves-effect waves-light">
                                                <a className="nav-link align-middle " id="tabBuckets" data-bs-toggle="tab" href="#nav-badge-buckets" role="tab" aria-selected="false">
                                                    {l('pages.overview.tabs.premises', 'Premises')}
                                                </a>
                                            </li>
                                            <li className="nav-item waves-effect waves-light">
                                                <a className="nav-link align-middle" id="tabTenants" data-bs-toggle="tab" href="#nav-badge-tenants" role="tab" aria-selected="false">
                                                    {l('pages.overview.tabs.tenants', 'Tenants')}
                                                </a>
                                            </li>
                                            <li className="nav-item waves-effect waves-light">
                                                <a className="nav-link " id="tabSensors" data-bs-toggle="tab" href="#nav-badge-sensors" role="tab" aria-selected="false">
                                                    {l('pages.overview.tabs.sensors', 'Sensors')}
                                                </a>
                                            </li>
                                            <li className="nav-item waves-effect waves-light">
                                                <a className="nav-link" id="tabGateways" data-bs-toggle="tab" href="#nav-badge-gateways" role="tab" aria-selected="false">
                                                    {l('pages.overview.tabs.gateways', 'Gateways')}
                                                </a>
                                            </li>
                                            <PermissionsWrapper
                                                user={user}
                                                scopes={['user.view.own']}
                                            >
                                                <li className="nav-item waves-effect waves-light">
                                                    <a className="nav-link" id="tabUsers" data-bs-toggle="tab" href="#nav-badge-users" role="tab" aria-selected="false">
                                                        {l('pages.overview.tabs.members', 'Members')}
                                                    </a>
                                                </li>
                                            </PermissionsWrapper>
                                        </ul>
                                        <div className="tab-content text-muted">
                                            
                                            <div className="tab-pane active" id="nav-badge-accounts" role="tabpanel">
                                                <Accounts />
                                            </div>

                                            <div className="tab-pane " id="nav-badge-buckets" role="tabpanel">
                                                <Buckets />
                                            </div>

                                            <div className="tab-pane " id="nav-badge-tenants" role="tabpanel">
                                                <Tenants />
                                            </div>

                                            <div className="tab-pane " id="nav-badge-sensors" role="tabpanel">
                                                <Sensors />
                                            </div>

                                            <div className="tab-pane " id="nav-badge-gateways" role="tabpanel">
                                                <Gateways />
                                            </div>

                                            <div className="tab-pane " id="nav-badge-users" role="tabpanel">
                                                <Members />
                                            </div>

                                        </div>
                                    </div>
                                </div>















                                




                            </div>
                        </div>
                    </div>
                </section>

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

Dashboard.requiredScope = [];
Dashboard.requireAuth = true;
export default Dashboard;