import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { NotificationContext } from '../../context/Notification/NotificationProvider';
import _ from 'lodash'


const Alerts = (props) => {

    const { notifications, deleteNotification, readNotification } = useContext(NotificationContext);
    

    useEffect(  () => {

    }, [notifications]);

    const timeAgo = (timestamp) => {
        let minutes = Math.floor((Math.abs(  new Date().getTime() - timestamp  )) / (1000 * 60));
        if(minutes < 2) return `1 min ago`
        if(minutes <= 60) return `${minutes} min ago`
        if(minutes > 60 && minutes < 1440) return `${ Math.floor(minutes / 60)} hr ago`;
        if(minutes >= 1440 ) return `${ Math.floor(minutes / 1440)} days ago`;
    }

    const countUnreadNotifications = () => {
        let count = 0;
        _.map(notifications, n => {
            if(!n.viewed_at) count ++
        })
        return count
    }

    const countUnreadAlerts = () => {
        let count = 0;
        _.map(notifications, n => {
            if(
                (n.type === 'alert' || n.type === 'notification') && !n.viewed_at) count ++
        })
        return count
    }

    const countUnreadMessages = () => {
        let count = 0;
        _.map(notifications, n => {
            if(
                (n.type === 'message') && !n.viewed_at) count ++
        })
        return count
    }

    const UI = () => {

        return (
            <Fragment>
                <div className="dropdown topbar-head-dropdown ms-1 header-item" id="notificationDropdown">
                    <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                        <i className='bx bx-bell fs-22'></i>
                        <span className={`position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger ${(countUnreadNotifications() < 1)?'hidden':''}`}>{countUnreadNotifications()}<span className="visually-hidden">unread messages</span></span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">

                        <div className="dropdown-head bg-primary bg-pattern rounded-top">
                            <div className="p-3">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                    </div>
                                    <div className="col-auto dropdown-tabs">
                                        <span className="badge badge-soft-light fs-13"> { countUnreadNotifications() + ' New' }</span>
                                    </div>
                                </div>
                            </div>

                            <div className="px-2 pt-2">
                                <ul className="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true" id="notificationItemsTab" role="tablist">
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link active" data-bs-toggle="tab" href="#all-noti-tab" role="tab" aria-selected="true">
                                            All ({countUnreadNotifications()})
                                        </a>
                                    </li>
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link" data-bs-toggle="tab" href="#messages-tab" role="tab" aria-selected="false">
                                            Messages
                                        </a>
                                    </li>
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link" data-bs-toggle="tab" href="#alerts-tab" role="tab" aria-selected="false">
                                            Alerts
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div className="tab-content position-relative" id="notificationItemsTabContent">
                            <div className="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
                                <div data-simplebar style={{"maxHeight": "300px", overflowY:"auto"}} className="pe-2">
                                    
                                    {
                                        (notifications)?
                                            notifications.map(n => {
                                                if( !!n )
                                                return (
                                                    <div className={`text-reset notification-item d-block dropdown-item position-relative ${(!n.viewed_at)?'bg-soft-success':'' } `} id={n.notification_id} key={n.notification_id} onClick={() => readNotification(n.notification_id)}>
                                                        <div className="d-flex">
                                                            
                                                            <div className="avatar-xs me-3">
                                                                <span className={`avatar-title bg-soft-${n.severity} text-${n.severity} rounded-circle fs-16`}>
                                                                    <i className={ (n.type === 'alert' || n.type === 'notification')? 'fa fa-solid fa-bell': 'fa fa-solid fa-message'}></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-1" >
                                                                <a href="#!" className="stretched-link">
                                                                    <h6 className="mt-0 mb-2 fs-13 lh-base">{n.message}</h6>
                                                                </a>
                                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                    <span><i className="mdi mdi-clock-outline"></i> {timeAgo(n.created_at)}</span>
                                                                </p>
                                                            </div>
                                                            <div className="px-2 fs-15">
                                                                <div className="form-check notification-check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="all-notification-check01" onChange={() => deleteNotification(n.notification_id)} />
                                                                    <label className="form-check-label" htmlFor="all-notification-check01"></label>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        :
                                            <Fragment></Fragment>
                                    }
                                </div>

                            </div>

                            <div className="tab-pane fade py-2 ps-2" id="messages-tab" role="tabpanel" aria-labelledby="messages-tab">
                                <div data-simplebar style={{"maxHeight": "300px"}} className="pe-2">
                                    {
                                        (notifications)?
                                            notifications.map(n => {
                                                if( !!n && n.type === 'message' )
                                                return (
                                                    <div className={`text-reset notification-item d-block dropdown-item position-relative ${(!n.viewed_at)?'bg-soft-success':'' } `} id={n.notification_id} key={n.notification_id} >
                                                        <div className="d-flex">
                                                            <div className="avatar-xs me-3">
                                                                <span className={`avatar-title bg-soft-${n.severity} text-${n.severity} rounded-circle fs-16`}>
                                                                    <i className={ (n.type === 'alert' || n.type === 'notification')? 'fa fa-solid fa-bell': 'fa fa-solid fa-message'}></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-1" onClick={() => readNotification(n.notification_id)}>
                                                                <a href="#!" className="stretched-link">
                                                                    <h6 className="mt-0 mb-2 fs-13 lh-base">{n.message}</h6>
                                                                </a>
                                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                    <span><i className="mdi mdi-clock-outline"></i> {timeAgo(n.created_at)}</span>
                                                                </p>
                                                            </div>
                                                            <div className="px-2 fs-12">
                                                                <div className="form-check notification-check">
                                                                    <i className="bg-soft-danger p-1 text-danger fa fa-solid fa-x" onClick={() => deleteNotification(n.notification_id)}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        :
                                            <Fragment></Fragment>
                                    }
                                    
                                </div>
                            </div>
                            <div className="tab-pane fade py-2 ps-2" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
                                <div data-simplebar style={{"maxHeight": "300px"}} className="pe-2">
                                    {
                                        (notifications)?
                                            notifications.map(n => {
                                                if(
                                                    (!!n) && 
                                                    (n.type === 'alert' || n.type === 'notification')
                                                )
                                                return (
                                                    <div className={`text-reset notification-item d-block dropdown-item position-relative ${(!n.viewed_at)?'bg-soft-success':'' } `} id={n.notification_id} key={n.notification_id} >
                                                        <div className="d-flex">
                                                            <div className="avatar-xs me-3">
                                                                <span className={`avatar-title bg-soft-${n.severity} text-${n.severity} rounded-circle fs-16`}>
                                                                    <i className={ (n.type === 'alert' || n.type === 'notification')? 'fa fa-solid fa-bell': 'fa fa-solid fa-message'}></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-1" onClick={() => readNotification(n.notification_id)}>
                                                                <a href="#!" className="stretched-link">
                                                                    <h6 className="mt-0 mb-2 fs-13 lh-base">{n.message}</h6>
                                                                </a>
                                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                    <span><i className="mdi mdi-clock-outline"></i> {timeAgo(n.created_at)}</span>
                                                                </p>
                                                            </div>
                                                            <div className="px-2 fs-12">
                                                                <div className="form-check notification-check">
                                                                    <i className="bg-soft-danger p-1 text-danger fa fa-solid fa-x" onClick={() => deleteNotification(n.notification_id)}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        :
                                            <Fragment></Fragment>
                                    }
                                </div>
                            </div>

                            <div className="notification-actions" id="notification-actions">
                                <div className="d-flex text-muted justify-content-center">
                                    Select <div id="select-content" className="text-body fw-semibold px-1">0</div> Result <button type="button" className="btn btn-link link-danger p-0 ms-3" data-bs-toggle="modal" data-bs-target="#removeNotificationModal">Remove</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
        
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default Alerts;