import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/Auth/AuthProvider';
import { TenantContext } from '../../context/Tenant/TenantProvider';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import {useRouter} from "../../components/Router/Router";
import Loading from '../../components/Loaders/Loading';
import Iconv from '../../components/iconv';
import TblTenants from '../../components/DataTables/tenants';

import Modal from 'react-modal';
import _ from 'lodash'

import { 
    PROXY_CLIENT,
    capitalizeFirst
 } from '../../libraries/utils';
import Swal from '@nmesys/sweetalert2'

const $ = require('jquery')
$.DataTable = require('datatables.net');

const Tenants = (props) => {
    
    const router = useRouter();
    const navigate = useNavigate();
    const { lang, locale } = useContext(LocaleContext);
    const {user} = useContext(AuthContext);
    const {
        tenant,
        tenants,
        curTenants,
        createTenantModal
    } = useContext(TenantContext);
    const {bucket, buckets} = useContext(BucketContext);
    const tenantChanged = useCompare(tenant);
    const [tenantBucket, setTenantBucket] = useState(null);

    useEffect(  () => {
        if(tenantChanged && tenant?.bucket_id){
            if(!!buckets && buckets.length){
                let p = _.filter(buckets, p => p.bucket_id === tenant.bucket_id);
                if(p.length) setTenantBucket(p[0])
            }
        }       
    }, [tenant, tenants]);

    useEffect(  () => {

    }, [tenantBucket]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const addressBuilder = (obj, formatted = false) => {
        if(!obj?.address) return '';
        const {address} = obj
        if(!formatted) return `${address.street} ${address.house_number} ${ address?.street1 ? ', ME '+ address.street1 : ''  }, ${address.post_code} ${address.city}, ${address.country.toUpperCase()}`
        else {
            return (
                `${address.street} ${address.house_number} ${ address?.street1 ? ', ME '+ address.street1 : ''  }\n ${address.post_code} ${address.city}\n ${address.country.toUpperCase()}`
            )
        }
    }

    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        const isProcessing = () => {
            if (!tenants || tenants.length === 0) {
                return (<Fragment><Loading/></Fragment>)
            }
        }
        return (
            <Fragment>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex align-items-center flex-wrap gap-2">
                                    <div className="flex-grow-1">
                                        {/**<button className="btn btn-primary add-btn" onClick={ () => createTenantModal() }><i className="ri-add-fill me-1 align-bottom"></i> Add Tenant</button>**/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`col-xxl-${(!!tenants && tenants.length > 0)? '8':'12'}`}>
                        <div className="card" >
                            <div className="card-body">
                                <div>
                                    <div className="table-responsive table-card mb-3">
                                        <TblTenants />
                                    </div>
                                    {isProcessing()}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={`${(tenant?.tenant_id)? 'col-xxl-4':'hidden'}`}>
                        <div className="card" id="contact-view-detail">

                            <div className="d-flex flex-row-reverse" >
                                <div className="hstack text-nowrap gap-2 float-right">
                                    <button type="button" id="dropdownMenuLink1" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-soft-info"><i className="ri-more-2-fill"></i></button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                        <li className="ps-3 dropdown-item text-warning" >Edit</li>
                                        <li className="ps-3 dropdown-item" title="Transfer ownership of all assets to another Account">Transfer to Account</li>
                                        <li className="ps-3 dropdown-item text-danger" title="Destroy this Bucket, but keep sensors and gateways within" onClick={() => {}}><a>Delete</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="card-body text-center">
                                <div className="position-relative d-inline-block">
                                    <Iconv name="bucket.tenant" size="5" className="text-info "/>
                                </div>
                                
                                <h5 className="mt-4 mb-1">{!!tenant?.tenant_id ?  tenant.contact.first_name + ' ' + tenant.contact.last_name : ''}</h5>
                                <p className="text-muted">{!!tenant?.tenant_id ? addressBuilder(tenantBucket) : ''}</p>

                                <ul className="list-inline mb-0">

                                    <li className="list-inline-item avatar-sm" onClick={() => navigate('/details/bucket')}>
                                        <a className="avatar-title bg-soft-primary text-primary fs-15 rounded">
                                            <Iconv name="bucket.single" size="2" classList="text-primary" />
                                            <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-primary"></span>
                                        </a>
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

               
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

Tenants.requiredScope = ['tenant.view.own','tenant.view.any'];
Tenants.requireAuth = true;
export default Tenants;