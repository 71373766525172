import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { LocaleContext } from '../../context/Locale/Locale';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { Link, useNavigate } from "react-router-dom";
import { useRouter } from '../../components/Router/Router';
import Loading from '../../components/Loaders/Loading';
import LanguageSwitcher from "../../components/Auth/languageSwitcher";
import { PROXY_CLIENT } from '../../libraries/utils';
import axios from 'axios'

const LoginPage = (props) => {

    const { lang, setLang, locale, localeAvailable } = useContext(LocaleContext);
    const { user, authenticateUser } = useContext(AuthContext);
    const router = useRouter();
    const [errorMsgs, setErrorMsgs] = useState([])
    const navigate = useNavigate();

    /**
     * Check if user is authenticated already.
     */
    useEffect(  () => {

        PROXY_CLIENT().get(`${process.env.REACT_APP_API}/auth/webtoken`)
        .then(response => {
            //console.log('AUTH LOGIN',response.data, response?.data?.isAuthenticated, response.data.isAuthenticated)
            if(
                response?.data?.isAuthenticated && 
                response.data.isAuthenticated
            ) {
                navigate('/dashboard');
            }
        })
        .catch(err => {})
        
    }, []);

    useEffect(  () => {
        
    }, [lang]);

    useEffect(  () => {
        
    }, [errorMsgs]);


    useEffect(  () => {

    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(e.target.ambot.value) return
        const formData = {
          email: e.target.email.value,
        }
 
        PROXY_CLIENT().post('/auth/login', formData)
        .then(response => {
            if(response?.data?.redirected) {
                navigate(response.data.redirected)
            }
            
        })
        .catch(err => {
            let msg = 'Invalid user credentials...'
            setErrorMsgs([msg])
        })

      }


    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        return (
            <Fragment>

                <section  >
                    <div className="form-section">
                        <div className="form-inner">

                            <LanguageSwitcher />

                            <Link to="/auth/login" >
                                <a className="logo">
                                    <img src="/auth/assets/logo.png" alt="IRON AUTH" title="IRON AUTH" />
                                </a>
                            </Link>

                            <h3>{lang.login.login.title}</h3>
                            <form onSubmit={handleSubmit} >

                                <div className="form-group form-box clearfix">
                                <input name="email" type="email" className="form-control" placeholder="Email Address" aria-label="Email Address" />
                                <i className="flaticon-mail-2"></i>
                                </div>
                                <div className="d-none form-group form-box clearfix">
                                    <input name="ambot" type="text" className="form-control" placeholder="Name" aria-label="Name" />
                                </div>

                                {
                                    (errorMsgs.length) ?
                                        errorMsgs.map((msg,i) => {
                                        return (
                                            <div key={i} className={`errormsg mb-2 text-${ (msg && msg.type)? msg.type:'danger'} `}>
                                                {lang.login.errorMessages[msg.message]}
                                            </div>
                                        )
                                        })
                                        : (<Fragment></Fragment>)
                                }
                                
                                <div className="form-group">
                                    <button className="btn btn-primary btn-lg btn-theme" type="submit" ><span>{lang.login.login.submit}</span></button>
                                </div>

                            </form>
                            <div className="clearfix"></div>

                            <p>{lang.login.common.no_account} <Link to="/auth/register"><a className="thembo"> {lang.login.common.no_account2} </a></Link></p>

                        </div>
                    </div>
                </section>
                
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

LoginPage.requiredScope = [];
LoginPage.requireAuth = false;
export default LoginPage;