import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import './Loader.css';

const Loading = (props) => {

    const [dots, setDots] = useState(null)
    const [loaderActive, setLoaderActive] = useState(true)
   
    useEffect(  () => {
        if(!dots){
            let $ = (e) => document.querySelector(e);
            let xdots = $(".dots");
            setDots(xdots)
        }
        
        if(dots){
            setLoaderActive(true)
            animate(dots, "animate");
        }

    }, [dots]);

   const animate = (element, className) => {
        if(!loaderActive) return;
        let dotTimeout, animateTimeout;
        element.classList.add(className);
        dotTimeout = setTimeout(() => {
            element.classList.remove(className);
            animateTimeout = setTimeout(() => {
                animate(element, className);
            }, 500);
        }, 2500);
        let found = document.querySelector(".dots")
        if(!found){
            setLoaderActive(false)
            clearTimeout(dotTimeout)
            clearTimeout(animateTimeout)
        }
        //console.log(found)
    }

    const UI = () => {

        return (
            <Fragment>
                
                <div className='pageLoader'>
                    <body>
                    <h1>{ (props.text)?props.text: 'Loading'  }
                        <div className="dots">
                            <span className="dot z"></span>
                            <span className="dot f"></span>
                            <span className="dot s"></span>
                            <span className="dot t">
                                <span className="dot l"></span>
                            </span>
                            
                        </div>
                    </h1>
                    </body>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


export default Loading;