import React, {Fragment, useEffect, useContext, useState, useRef } from 'react';
import { AccountContext } from '../context/Account/AccountProvider';
import _ from 'lodash'; 
import { useNavigate } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import { LocaleContext } from '../context/Locale/Locale';

const AccountSwitcher = (props) => {

    const navigate = useNavigate();

    const { 
        accounts,
        account,
        setAccount,
        creatAccountModal
    } = useContext(AccountContext);
    const { lang, locale, l } = useContext(LocaleContext);
    const [selected, setSelected] = useState([]);
    const [options, setOptions] = useState([]);
    const accountsChanged = useCompare(accounts)
    const accountChanged = useCompare(account)

    useEffect(  () => {}, [lang])

    useEffect(  () => {
        if(accountsChanged && accounts.length){
            let opts = []
            for(const a of accounts){
                opts.push({
                    label: `${a.name} (${a.account_id})`,
                    id: a.account_id
                })
            }
            setOptions(opts);
        }
    }, [accounts]);

    useEffect(  () => {
        if(accountChanged && account?.account_id && selected.id !== account.account_id) setSelected([{
            id: account.account_id,
            label: account.name
        }])
    }, [account]);

    useEffect(  () => {}, [options]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const handleSelectChange = (item) => {
        if(!item.length){
            setSelected([]);
            return;
        }
        const {id, name} = item[0]
        const account_id = id;
        let acct = _.find(accounts, {account_id})
        setAccount(acct);
        setSelected([{
            id: acct.account_id,
            label: acct.name
        }]);
    };

    const UI = () => {
        if(!lang) return <Fragment></Fragment>
        return (
            <Fragment>
                <div className="d-flex flex-row">
                    <Typeahead
                        id="account-select"
                        labelKey="label"
                        onChange={handleSelectChange}
                        options={options}
                        placeholder={ (accounts.length)? l('components.widgets.accountSwitcher.placeholder1', 'Choose an account...'):  l('components.widgets.accountSwitcher.placeholder2', 'Create an account ->->->')}
                        selected={selected}
                    />
                    <button className={`ms-2 btn btn-primary btn-sm`} onClick={() => creatAccountModal('standard') }><i className={`fa fa-solid fa-plus ${ (!accounts.length)?'fa-beat-fade':'' }`} ></i></button>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default AccountSwitcher;