import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { LocaleContext } from '../../context/Locale/Locale';
import { ContactContext } from '../../context/Contact/ContactProvider';

import { BucketContext } from '../../context/Bucket/BucketProvider';
import { SensorContext } from '../../context/Sensor/SensorProvider';
import { GatewayContext } from '../../context/Gateway/GatewayProvider';
import { TenantContext } from '../../context/Tenant/TenantProvider';

import withReactContent from "sweetalert2-react-content";
import _ from 'lodash'
import Gmap from '../Maps/gmap';
import Modal from 'react-modal';
import Iconv from '../iconv';
import { Link, useNavigate } from "react-router-dom";
import AddressForm from '../Forms/frmAddress';
import Swal from '@nmesys/sweetalert2'
import { createRoot } from "react-dom/client";

const AccountCard = (props) => {

    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    const { lang, locale } = useContext(LocaleContext);
    const [account, setAccount] = useState(props.account);

    const {buckets} = useContext(BucketContext);
    const {sensors} = useContext(SensorContext);
    const {gateways} = useContext(GatewayContext);
    const {tenants} = useContext(TenantContext);
    
    const accountChanged = useCompare(account);
    const [mapIsOpen, setMapIsOpen] = useState(false);

    const [frmIsOpen, setFrmIsOpen] = useState(false);
    const [frmAddressIsOpen, setFrmAddressIsOpen] = useState(false);

    const [showDataIcons, setShowIcons] = useState(true);
    const [showOverflow, setShowOverflow] = useState(true);
    const { contacts } = useContext(ContactContext);

    const [contact, setContact] = useState(props.contact);
    const [address, setAddress] = useState(props.address);

    useEffect(  () => {

        if(props.showIcons !== undefined) setShowIcons(props.showIcons);
        if(props.account !== undefined) setAccount(props.account);
        if(props.showOverflow !== undefined) setShowOverflow(props.showOverflow);

    }, [props, account, showDataIcons, contact, address]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const showMap = () => setMapIsOpen(true)
    
    const closeMap = () => setMapIsOpen(false)
    
    const showFrm = () => setFrmIsOpen(true)
    
    const closeFrm = () => setFrmIsOpen(false)
    
    const showMapModal = () => {
        if(!account?.contact?.addresses?.[0]?.geolocation) return (<Fragment></Fragment>)
        // Create a container element for the React component
        const mapContainer = document.createElement("div");
        // Render the Gmap component into the container
        const root = createRoot(mapContainer);
        root.render(
          <Gmap
            geolocation={
              account?.contact?.addresses?.[0]?.geolocation
                ? account.contact.addresses[0].geolocation
                : ""
            }
          />
        );
      
        // Use SweetAlert2 with the rendered React component
        MySwal.fire({
          title: "Map Location",
          html: mapContainer, // Embed the container into SweetAlert2
          showCancelButton: false,
          cancelButtonText: "Close",
          width: "50vw",
          heightAuto: false,
          didClose: () => {
            closeMap(); // Call the closeMap function when the modal is closed
          }
        });
    };

    const viewDetails = () => {
        if(!account.account_id) return
        navigate('/details/account')
    }

    const icons = () => {
        if(!showDataIcons) return (<Fragment></Fragment>)
        return (
            <ul className="list-inline mb-0">
                {
                    (account?.contact?.addresses?.[0]?.geolocation) ?
                    (
                        <li className="list-inline-item avatar-sm">
                            <a className="avatar-title bg-soft-primary text-primary fs-15 rounded" onClick={() => showMapModal()}>
                                <Iconv name="bucket.location" size="2" classList="text-primary"/>
                            </a>
                        </li>
                    ) : ''
                }
                <li className="list-inline-item avatar-sm">
                    <a className="avatar-title bg-soft-primary text-primary fs-15 rounded" onClick={() => navigate('/apps/buckets/list')} >
                        <Iconv name="bucket.multi" size="2" classList="text-primary"/>
                        <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-primary">{ (buckets && buckets.length)? buckets.length : '0' }</span>
                    </a>
                </li>
                <li className="list-inline-item avatar-sm">
                    <a className="avatar-title bg-soft-success text-success fs-15 rounded" onClick={() => navigate('/apps/sensors/list')} >
                        <Iconv name="apps.sensor" size="2" classList="text-success"/>
                        <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-primary">{ (sensors && sensors.length)? sensors.length : '0' }</span>
                    </a>
                </li>
                <li className="list-inline-item avatar-sm">
                    <a className="avatar-title bg-soft-success text-success fs-15 rounded" onClick={() => navigate('/apps/gateways/list')} >
                        <Iconv name="apps.gateway" size="2" classList="text-success"/>
                        <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-primary">{ (gateways && gateways.length)? gateways.length : '0' }</span>
                    </a>
                </li>
            </ul>
        )
    }

    const toggleCollapse = (e) => {
        e.preventDefault()
        document.getElementById(e.currentTarget.dataset.target).classList.toggle('show')
    }

    const UI = () => {
        if(!account?.name) return (<Fragment></Fragment>)
        return (
            <Fragment>
                <div className="card">
                    <div className={`d-flex flex-row-reverse ${!showOverflow ? 'd-none':''}`} >
                        <div className="hstack text-nowrap gap-2 float-right">
                            <button type="button" id="dropdownMenuLink1" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-soft-info"><i className="ri-more-2-fill"></i></button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                <li className="ps-3 dropdown-item text-primary" onClick={() => viewDetails()}>Details</li>
                                <li className="ps-3 dropdown-item text-warning" onClick={() => showFrm()} >Edit</li>
                                <li className="ps-3 dropdown-item text-danger" title="Destroy this Bucket, but keep sensors and gateways within" onClick={() => showFrm()} ><a>Delete</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="card-body text-center">
                        <div className="position-relative d-inline-block">
                            
                            <a onClick={() => viewDetails()} >
                                <Iconv name="apps.organization" size="5" className="text-primary " />
                            </a>
                          
                        </div>
                        <h5 className="mt-4 mb-1">{ account.name }     </h5>
                        <p className="text-muted"></p>
                        {icons()}
                    </div>

                    <div className="card-body">

                        <div className="card">
                            <div className="card-header" >
                                <div className="card-title d-flex justify-content-between">
                                    <span>
                                        <i className="fa-solid fa-location me-2"></i> <b>Address</b>
                                    </span>
                                    <button className="btn btn-sm btn-light" onClick={() => setFrmAddressIsOpen(true)} ><i className="fa fa-solid fa-pencil"></i></button>
                                </div>
                            </div>

                            <div className="card-body">
                                <table className="table table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>{account?.contact?.addresses?.[0] ? account.contact.addresses[0].street+` `+account.contact.addresses[0].house_number+` `+`${account.contact.addresses[0].street1 ? ' , ME '+account.contact.addresses[0].street1 : ''}`     :``}</div>
                                                <div>{account?.contact?.addresses?.[0] ? account.contact.addresses[0].post_code+` `+ account.contact.addresses[0].city : ''}</div>
                                                <div>{account?.contact?.addresses?.[0] ? account.contact.addresses[0].country : ''}</div>
                                            </td>
                                            <td><button className="btn btn-sm btn-light"><i className="fa fa-solid fa-location-dot" onClick={() => showMap()} ></i></button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {
                            (account.contact !== undefined) ?
                                (
                                    <div className="card">
                                        <div className="card-header" >
                                            <div className="card-title"><i className="fa-solid fa-address-book me-2"></i> <b>{account.contact.first_name} {account.contact.last_name}</b></div>
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-borderless mb-0">
                                                <tbody>
                                                    
                                                    <tr>
                                                        <td className="fw-medium" scope="row">
                                                            <span className="me-1"><i className="fa-solid fa-envelope"></i></span> Email
                                                        </td>
                                                        <td>
                                                            { 
                                                                (account.contact.emails) ? 
                                                                _.map(account.contact.emails, email => {
                                                                    return (<div key={email.address}><a href={`mailto:${email.address}`}>{email.address}</a></div>)
                                                                })
                                                                : ''
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-medium" scope="row">
                                                            <span className="me-1"><i className="fa-solid fa-phone"></i></span> Phone
                                                        </td>
                                                        <td>{
                                                                (account.contact.phones) ? 
                                                                _.map(account.contact.phones, phone => {
                                                                    return (<div key={phone.number}>{phone.number}</div>)
                                                                })
                                                                : ''
                                                            }
                                                        </td>
                                                    </tr>
                                                    {/** 
                                                    <tr>
                                                        <td className="fw-medium" scope="row">
                                                            <span className="me-1"><i className="fa-solid fa-location"></i></span> Address
                                                        </td>
                                                        <td>
                                                            <div>{props?.address ? props.address.street+` `+props.address.house_number+` `+`${props.address.street1 ? ' , ME '+props.address.street1 : ''}`     :``}</div>
                                                            <div>{props?.address ? props.address.post_code+` `+ props.address.city : ''}</div>
                                                            <div>{props?.address ? props.address.country : ''}</div>
                                                        </td>
                                                    </tr>
                                                    **/}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                                :''
                        }
{/** 
                        {
                            (organization.contacts && organization.contacts.length) ?
                            (
                                _.map(organization.contacts, contact_id => {
                                    let orgContact = _.filter(contacts, c => c.contact_id === contact_id)[0];
                                    if(!!orgContact)  
                                        return (
                                            <div className="card">
                                                
                                                <div className="card-header" data-target={`collapse${orgContact.contact_id}`} onClick={(e) => toggleCollapse(e)}>
                                                    <div className="card-title d-flex justify-content-between">
                                                        <span>
                                                            <i className="fa-solid fa-address-book me-2"></i> <b>{orgContact.first_name} {orgContact.last_name}</b>
                                                        </span>
                                                        <button className="btn btn-sm btn-light"><i className="fa fa-solid fa-pencil"></i></button>
                                                    </div>
                                                </div>
                                                
                                                <div className="collapse" id={`collapse${orgContact.contact_id}`}>
                                                    <div className="card-body">
                                                        <table className="table table-borderless mb-0">
                                                            <tbody>
                                                                
                                                                <tr>
                                                                    <td className="fw-medium" scope="row">
                                                                        <span className="me-1"><i className="fa-solid fa-envelope"></i></span> Email
                                                                    </td>
                                                                    <td>
                                                                        { 
                                                                            (orgContact.email) ? 
                                                                            _.map(orgContact.email, email => {
                                                                                return (<div><a href={`mailto:${email.address}`}>{email.address}</a></div>)
                                                                            })
                                                                            : ''
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-medium" scope="row">
                                                                        <span className="me-1"><i className="fa-solid fa-phone"></i></span> Phone
                                                                    </td>
                                                                    <td>{
                                                                            (orgContact.phone) ? 
                                                                            _.map(orgContact.phone, phone => {
                                                                                return (<div>{phone.number}</div>)
                                                                            })
                                                                            : ''
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-medium" scope="row">
                                                                        <span className="me-1"><i className="fa-solid fa-location"></i></span> Address
                                                                    </td>
                                                                    <td>
                                                                        <div>{orgContact?.address?.[0] ? orgContact.address[0].street+` `+orgContact.address[0].house_number+` `+`${orgContact.address[0].street1 ? ' , ME '+orgContact.address[0].street1 : ''}`     :``}</div>
                                                                        <div>{orgContact?.address?.[0] ? orgContact.address[0].post_code+` `+ orgContact.address[0].city : ''}</div>
                                                                        <div>{orgContact?.address?.[0] ? orgContact.address[0].country : ''}</div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    
                                })
                            ) : ''
                        }
*/}
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Details</div>
                            </div>
                            <div className="card-body">
                                <table className="table table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <td className="fw-medium ps-2" scope="row">
                                                <span className="me-1"><i className="fa-solid fa-euro"></i></span> VAT
                                            </td>
                                            <td>{ (account?.contact?.vat) ? account.contact.vat : '' }</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-medium ps-2" scope="row">
                                                <span className="me-1"><i className="fa-solid fa-globe"></i></span> Website
                                            </td>
                                            <td>{ (account?.contact?.website) ? account.contact.website : '' }</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
               
{/** 
                <Modal
                    isOpen={frmIsOpen}
                    onRequestClose={closeFrm}
                    style={{
                        content: {
                            top: '40%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: "65vw",
                            height: "55vh"
                        }
                    }}
                >
                    <OrganizationForm 
                        organization={ organization }
                        mode={'edit'}
                    />
                </Modal>

                <Modal
                    isOpen={frmAddressIsOpen}
                    onRequestClose={() => setFrmAddressIsOpen(false)}
                    style={{
                        content: {
                            top: '40%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: "65vw",
                            height: "50vh"
                        }
                    }}
                >
                    <AddressForm
                        contact_id={organization.contact_id}
                        address={ account.contact.address }
                        mode={'edit'}
                    />
                </Modal>
        */}
            </Fragment>
        )  
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default AccountCard;