import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { LocaleContext } from '../context/Locale/Locale';
import { AuthContext } from '../context/Auth/AuthProvider';
import { Link } from "react-router-dom";
import { useRouter } from '../components/Router/Router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';

import Loading from '../components/Loaders/Loading'
import HeroSlider from '../components/Home/HeroSlider';
//import TrustSlider from '../components/Home/TrustSlider';
import Services from '../components/Home/Services';
import CallToActionBanner from '../components/Home/CallToActionBanner';
import DesignFeatures from '../components/Home/DesignFeatures';
import MembershipPlans from '../components/Home/MembershipPlans';
import Faq from '../components/Home/Faq';
import WorkProcess from '../components/Home/WorkProcess';
//import OurTeam from '../components/Home/OurTeam';
import ContactUs from '../components/Home/ContactUs';

const HomePage = (props) => {

    const { lang, setLang, locale, localeAvailable } = useContext(LocaleContext);
    const { user, login } = useContext(AuthContext);
    const router = useRouter();

    useEffect(  () => {

    }, [lang]);


    useEffect(  () => {

    }, [user]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        return (
            <Fragment>

                <HeroSlider /> 
            {/**     <TrustSlider /> */}
                <Services />
                <CallToActionBanner />
                <DesignFeatures />
                <MembershipPlans />
                <Faq />
                <CallToActionBanner />
                <WorkProcess /> 
            {/**    <OurTeam /> */} 
               
                
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

HomePage.requiredScope = [];
HomePage.requireAuth = false;
export default HomePage;