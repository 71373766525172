import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/Auth/AuthProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import { AccountContext } from '../../context/Account/AccountProvider';
import { ContactContext } from '../../context/Contact/ContactProvider';

import {useRouter} from "../../components/Router/Router";
import Loading from '../../components/Loaders/Loading';
import Iconv from '../../components/iconv';
import Modal from 'react-modal';
import _ from 'lodash'

import { 
    capitalizeFirst,
    errorToast,
    successToast,
    PROXY_CLIENT
 } from '../../libraries/utils';
import Swal from '@nmesys/sweetalert2'


const AccountWizard = (props) => {
    
    const router = useRouter();
    const navigate = useNavigate();
    const { lang, locale } = useContext(LocaleContext);
    const {user} = useContext(AuthContext);
    const {accounts, setAccounts, createAccount} = useContext(AccountContext);
    const {createContact} = useContext(ContactContext);

    useEffect(  () => {

        
    }, [lang]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const creatAccountModal = (type) => {
        const html = `
            <form id="frmAccount">
                <div class="overflow-hidden">
                    <div class="form-group mb-4">
                        <input class="form-control mb-1" type"text" name="account.name" placeholder="Account Name"/>
                        <small>You should add a name to this account to make it easier to identify. (ex: Lucky Immobilien GmbH)</small>
                    </div> 
                    
                    <section id="frmContact" class={} >
                        <div class="mb-4">
                            <label htmlFor="name" class="form-label">Contact</label>
                            <div class="row mb-4">
                                <div class="col-6">
                                    <input type="text" class="form-control" name="contact.first_name" placeholder='First Name'required/>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-control" name="contact.last_name" placeholder='Last Name'required/>
                                </div>
                            </div>
                            <div class="mb-4">
                                <input type="text" class="form-control" name="contact.company_name" placeholder="Company Name"/>
                            </div>
                            <div class="mb-4">
                                <input type="text" class="form-control" name="contact.job_position" placeholder="Job Position"/>
                            </div>
                        </div>
                    </section>

                    <div class="mb-4">
                        <section id="frmAddress" >
                            <label htmlFor="name" class="form-label">Address</label>
                            <div class="row mb-4">
                                <div class="col-6">
                                    <input type="text" class="form-control" name="address.street" placeholder='Street'required/>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" name="address.house_number" placeholder='Number'required/>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" name="address.street1" placeholder='Apt/Office' />
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-4">
                                    <input type="text" class="form-control" name="address.post_code" placeholder='PostCode'required/>
                                </div>
                                <div class="col-8">
                                    <input type="text" class="form-control" name="address.city" placeholder='City'required/>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-12">
                                    <label htmlFor="country" class="form-label">Country</label>
                                    <select class="form-select" name="address.country" aria-label="country select" defaultValue={'de'}>
                                        <option value="at" >Austria</option>
                                        <option value="dk" >Danmark</option>
                                        <option value="de" selected="true">Deutschland</option>
                                        <option value="fr" >France</option>
                                        <option value="it" >Italia</option>
                                    </select>
                                </div>
                            </div>
                        </section>

                        <hr />

                        <section class="" >
                            <div class="row mb-4" >
                                <div class="col-12">
                                    <input type="text" class="form-control" name="contact.phones.0.number" placeholder='Phone (Work)'/>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-12">
                                    <input type="text" class="form-control" name="contact.emails.0.address" placeholder='Email (Primary)'/>
                                </div>
                            </div>
                        </section>
                        
                        <hr />

                        <div class="row mb-4">
                            <div class="col-6">
                                <input type="text" class="form-control" name="contact.vat" placeholder='VAT Nmr'/>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-12">
                                <input type="text" class="form-control" name="contact.website" placeholder='Website'/>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        
        `;

        Swal.fire({
            title: `Add a new ${capitalizeFirst(type)} Account`,
            icon: 'info',
            width: '60%',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Create this account',
            html,
            preConfirm: (inputValue) => {
                const form = Swal.getPopup().querySelector('#frmAccount');
                const formData = new FormData(form);
                const params = Array.from(formData.entries()).reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {});

                return params;
            }
        }).then(async results => {
            if (results.isConfirmed) {
                console.log('RESULTS', results)

                const nestedObject = {};
                _.forEach(results.value, (value, key) => {
                  _.set(nestedObject, key, value);
                });
                
                let {address, contact, account} = nestedObject;
                const frmAccount = nestedObject.account;

                console.log(nestedObject)

                //const newContact = await createContact( {...contact, address} );

                //const newContact = await PROXY_CLIENT().post(`${process.env.REACT_APP_API}/contact`, {...contact, address})
               //if(!!newContact?.data?.contact_id) frmAccount.contact_id = newContact.data.contact_id
                const accountCreated = await createAccount({...account, ...contact, address: nestedObject.address})
                Swal.close();
                navigate('/dashboard');
            }
        }).catch(err => {
            console.log(err)
            errorToast('Something went wrong with creating your account. Please try again or contact your service admin.');
        })
    }

    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        
        return (
            <Fragment>

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Account Wizard</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">Apps</li>
                                    <li className="breadcrumb-item"><Link to="/apps/accounts/list">Account</Link></li>
                                    <li className="breadcrumb-item active"><b>Wizard</b></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center mt-5">
                    <div className="col-lg-6">
                        <div className="text-center mb-4 ">
                            <h4 className="fw-semibold fs-23">{lang.pages.accountWizard.title}</h4>
                            <p className="text-muted mb-4 fs-15" dangerouslySetInnerHTML={{ __html: lang.pages.accountWizard.subtitle }}></p>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-start">
                    <div className="col-lg-6 mx-auto">
                        <div className="mb-4 pb-2">
                            <div className="">
                                <p className="">How It Works:</p>
                                <ul className="" dangerouslySetInnerHTML={{ __html: lang.pages.accountWizard.howItWorks_ul }}></ul>
                            </div>
                        
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-xl-9">
                        <div className="row">

                            <div className="col-lg-4 mx-auto d-none">
                                <div className="card pricing-box">
                                    <div className="card-body p-4 m-2">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h5 className="mb-1 fw-semibold">Standard Plan</h5>
                                                <p className="text-muted mb-0">Small Business</p>
                                            </div>
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-light rounded-circle text-primary">
                                                    <i className="ri-book-mark-line fs-20"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pt-4">
                                            <h2><sup><small>€</small></sup>30<span className="fs-13 text-muted">/Month/Customer</span></h2>
                                        </div>
                                        <hr className="my-4 text-muted" />
                                        <div>
                                            <ul className="list-unstyled text-muted vstack gap-3">
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>Unlimited</b> Customers
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>Unlimited</b> Gateways
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>Unlimited</b> Sensors
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>Unlimited</b> Email
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>Unlimited</b> Sensor Alerts
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>Unlimited</b> User Seats
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Full Data Export
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-danger me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Cusomtizable Reports
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-danger me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            White Label Domain
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-danger me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            White Label Logo
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-danger me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            White Label Meta Data
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="mt-4">
                                                <button className="btn btn-primary w-100 waves-effect waves-light" onClick={() => creatAccountModal('standard')}>Create a NEW Account</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 mx-auto">
                                <div className="card pricing-box ribbon-box right">
                                    <div className="card-body p-4 m-2">
                                        <div className="ribbon-two ribbon-two-danger"><span>Popular</span></div>
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <h5 className="mb-1 fw-semibold">Pro Business</h5>
                                                    <p className="text-muted mb-0">Professional plans</p>
                                                </div>
                                                <div className="avatar-sm">
                                                    <div className="avatar-title bg-light rounded-circle text-primary">
                                                        <i className="ri-medal-line fs-20"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="pt-4">
                                                <h2><sup><small>€</small></sup>30<span className="fs-13 text-muted">/Month</span></h2>
                                            </div>
                                        </div>
                                        <hr className="my-4 text-muted" />
                                        <div>
                                            <ul className="list-unstyled text-muted vstack gap-3">
                                                
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>{lang.pages.accountWizard.unlimited}</b> Gateways
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>{lang.pages.accountWizard.unlimited}</b> Sensors
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>{lang.pages.accountWizard.unlimited}</b> Email
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>{lang.pages.accountWizard.unlimited}</b> Sensor Alerts
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <b>{lang.pages.accountWizard.unlimited}</b> User Seats
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Full Data Export
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            Cusomtizable Reports
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            White Label Domain
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            White Label Logo
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 text-success me-1">
                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            White Label Meta Data
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="mt-4">
                                                <button className="btn btn-info w-100 waves-effect waves-light" onClick={() => creatAccountModal('pro')}>Create a NEW Pro Account</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

AccountWizard.requiredScope = ['account.create.own', 'account.create.any'];
AccountWizard.requireAuth = true;
export default AccountWizard;