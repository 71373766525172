import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { BucketContext } from '../../context/Bucket/BucketProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import { SensorContext } from '../../context/Sensor/SensorProvider';
import Iconv from '../../components/iconv';
import SensorConsumptionChart from '../../components/Charts/SensorConsumptionChart';
import _ from 'lodash';

const SensorCard = (props) => {

    const navigate = useNavigate();
    const { lang, locale } = useContext(LocaleContext);
    const {buckets, setBucket} = useContext(BucketContext);
    const {sensor, sensors, signalStrength} = useContext(SensorContext);
    const [year, setYear] = useState(new Date().getFullYear());
    const [sensorBucket, setSensorBucket] = useState(null)
    const sensorChanged = useCompare(sensor);
    const sensorsChanged = useCompare(sensors);


    useEffect(  () => {}, [sensors])

    useEffect(  () => {
        if(sensorChanged && sensor?.serial_number){
            if(sensor?.bucket_id) {
                let b = _.filter(buckets, b => b.bucket_id === sensor.bucket_id);
                if(b[0]) setSensorBucket(b[0])
            }
        }
    }, [sensor, buckets])

    useEffect(  () => {}, [year])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    
    const addressBuilder = (obj, formatted = false) => {
        if(obj?.address){
            if(!formatted) return `${obj.address.street} ${obj.address.house_number} ${ obj.address?.street1 ? ', ME '+ obj.address.street1 : ''  }, ${obj.address.post_code} ${obj.address.city}, ${obj.address.country.toUpperCase()}`
            else {
                return (
                    `${obj.address.street} ${obj.address.house_number} ${ obj.address?.street1 ? ', ME '+ obj.address.street1 : ''  }\n ${obj.address.post_code} ${obj.address.city}\n ${obj.address.country.toUpperCase()}`
                    )
                }
        }
        return ''
    }

    const gotoBucket = () => {
        let objArr = _.filter(buckets, pr => pr.bucket_id === sensor.bucket_id)
        if(objArr?.[0]){
            setBucket(objArr[0]);
            setTimeout(() => {
                navigate('/details/bucket')
            }, 500)
        }
        return;
    }

    const UI = () => {
        return (
            <div className="card" id="contact-view-detail">

                <div className="d-flex flex-row-reverse" >
                    <div className="hstack text-nowrap gap-2 float-right">
                        <button type="button" id="dropdownMenuLinkSensor" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-soft-info"><i className="ri-more-2-fill"></i></button>
                        <ul className="dropdown-menu" aria-labelledby="k">
                            
                            <li className="ps-3 dropdown-item text-warning" >Edit</li>
                            <li className="ps-3 dropdown-item text-danger" title="Destroy this Bucket, but keep sensors and gateways within" ><a>Delete</a></li>
                        </ul>
                    </div>
                </div>

                <div className="card-body text-center">
                    <div className="position-relative d-inline-block">
                        <a onClick={ () => navigate(`/details/sensor/`) }>
                        {
                            (!!sensor && !!sensor?.attributes) ?
                            <Iconv name={`sensors.${sensor.medium_code}.${ (sensor.attributes.includes('wsr'))? 'wsr' : (sensor.attributes.includes('hzg')? 'hzg':'standard') }`} size="5" className="text-primary "/> 
                            :
                            <Iconv name={`default`} size="5" className="text-primary "/>
                        }
                        </a>
                    </div>
                    
                    <h5 className="mt-4 mb-1" title="Sensor serial number">{ (sensor?.serial_number)? sensor.serial_number : '' }</h5>
                    <h5 className="mt-1 mb-1 text-primary">{ sensor?.state?.values?.[sensor.unit_of_measure] ? `${sensor.state.values[sensor.unit_of_measure]} ${sensor.unit_of_measure}` : ""  }</h5>

                    <p className="text-muted">{(sensor?.bucket_id)? addressBuilder(sensorBucket): ''}</p>
                    <ul className="list-inline mb-0">

                        <li className="list-inline-item avatar-sm">
                            <a className="avatar-title bg-soft-primary text-primary fs-15 rounded" onClick={() => gotoBucket()} >
                                <Iconv name="bucket.single" size="2" classList="text-primary" title={`Goto to Premise Details`}/>
                            </a>
                        </li>

                        <li className="list-inline-item avatar-sm" title="Sensor transmission method (wmbus, mbus, mioty, lora, nbiot)">
                            <a className="avatar-title bg-soft-primary text-primary fs-15 rounded" >
                                {
                                    (!!sensor && sensor?.method)?
                                        <Iconv name={`sensors.method.${sensor.method}`} size="2" classList="text-primary"/>
                                        : <Iconv name="default" size="2"/>
                                }
                            </a>
                        </li>

                        <li className="list-inline-item avatar-sm" title='Sensor signal strength to its Gateway'>
                            <a className="avatar-title bg-soft-primary fs-15 rounded">
                                {
                                    
                                    (!!sensor && sensor?.state?.rssi)?
                                    <Fragment>
                                        <Iconv name={`sensors.rssi.${signalStrength( sensor.state.rssi )}`} size="2" title={signalStrength( sensor.state.rssi )} />
                                        <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-primary">{sensor.state.rssi}</span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <Iconv name={`sensors.rssi.${signalStrength(null)}`} size="2" title="Signal strength is not available or is NONE"/>
                                        <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-primary">0</span>
                                    </Fragment>
                                }
                            </a>
                        </li>


                    </ul>
                </div>

                <div className="card-body">
                    <ul className="list-inline mb-4">
                        {
                            (!!sensor && !!sensor?.attributes)?
                            sensor.attributes.map( t => {
                                return <li className="list-inline-item" key={t}><span className="badge bg-primary" key={`att-${t}`}>{t}</span></li>
                            }): <Fragment></Fragment>
                        }
                    </ul>
                    
                
                    <SensorConsumptionChart />


                </div>
            </div>
        )
    }


    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default SensorCard;