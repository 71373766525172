import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/Auth/AuthProvider';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import { LocaleContext } from '../../context/Locale/Locale';
import { GatewayContext } from '../../context/Gateway/GatewayProvider';
import { SensorContext } from '../../context/Sensor/SensorProvider';
import GatewayCard from '../../components/Cards/gatewayCard';
import {useRouter} from "../../components/Router/Router";
import Loading from '../../components/Loaders/Loading';
import Iconv from '../../components/iconv';
import TblGateways from '../../components/DataTables/gateways';
import _ from 'lodash';

 
import Swal from '@nmesys/sweetalert2'
import TblInsight from '../../components/DataTables/insght';

const $ = require('jquery')
$.DataTable = require('datatables.net');

const Gateways = (props) => {
    
    const router = useRouter();
    const navigate = useNavigate();
    const { lang, locale } = useContext(LocaleContext);
    const {user} = useContext(AuthContext);
    const {bucket, buckets, setBucket} = useContext(BucketContext);
    const {sensors, sensor_ids} = useContext(SensorContext);
    const [isOpen, setIsOpen] = useState(false)

    const {
        gateway, 
        gateways, 
        setGateway,
        bucketGrpFilter, 
        bucketFilter, 
        setBucketGrpFilter,
        setBucketFilter
    } = useContext(GatewayContext);

    useEffect(() => {}, [bucket, buckets, gateways]);
    useEffect(() => {}, [bucketGrpFilter, bucketFilter])


    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const handleBucketGrpFilter = (e) => {
        setBucketGrpFilter(e.target.checked)
    }

    const handleBucketFilter = (e) => {
        setBucketFilter(e.target.checked)
    }

    const UI = () => {
        if(!lang) return (<Fragment><Loading/></Fragment>)
        const isProcessing = () => {
            if (!gateways || gateways.length === 0) {
                return (<Fragment><Loading/></Fragment>)
            }
        }

        return (
            <Fragment>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex align-items-center flex-wrap gap-2">
                                    <div className="flex-grow-1">
                                        {/**<button className="btn btn-primary add-btn" ><i className="ri-add-fill me-1 align-bottom"></i> Add Gateway</button>**/}
                                        <div className="form-check form-switch form-switch-right form-switch-md me-4">
                                            <label htmlFor="popover-showcode" className="form-label " title="Filter this Sensor table by the bucket group selected"><Iconv name="bucket.city" classList="text-primary"/> </label>
                                            <input className="form-check-input code-switcher" type="checkbox" id="popover-bucketGrpFilter" onChange={handleBucketGrpFilter} checked={bucketGrpFilter} />
                                        </div>
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            <label htmlFor="popover-showcode" className="form-label " title="Show only sensors relevant to the bucket selected"><Iconv name="bucket.single" classList="text-success"/></label>
                                            <input className="form-check-input code-switcher" type="checkbox" id="popover-bucketFilter" onChange={handleBucketFilter} checked={bucketFilter} />
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            (!bucketGrpFilter && !bucketFilter)? 'Account Level' : 
                                                (!!bucketGrpFilter) ? "Building Level" : "Premise Level"
                                                
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`col-xxl-${(!!gateways && gateways.length > 0)? '8':'12'}`}>
                        <div className="card" id="contactList">
                            <div className="card-body">
                                <div className="table-card mb-3 ps-2 pe-2">
                                    <TblGateways />
                                </div>
                                {isProcessing()}
                            </div>
                        </div>
                    </div>

                    <div className={`${(!!gateways && gateways.length)? 'col-xxl-4':'hidden'}`}>
                        <GatewayCard />
                    </div>
                </div>

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

Gateways.requiredScope = ['gateway.view.own','gateway.view.any'];
Gateways.requireAuth = true;
export default Gateways;