import React, {Fragment, useEffect } from 'react';

const Footer = (props) => {

    useEffect(  () => {

    }, []);

    const UI = () => {

        return (
            <Fragment>
                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                { `${new Date().getFullYear()} © NMESYS.IO` }
                            </div>
                            <div className="col-sm-6">
                                <div className="text-sm-end d-none d-sm-block">
                                    Architecture by <a href="https://nmesys.io" target='_blank'>NMESYS</a> @DevOps
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default Footer;