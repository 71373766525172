import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { Link, Navigate, useLocation, useParams, useNavigate } from "react-router-dom";
import { LocaleContext } from '../../context/Locale/Locale';
import { SensorContext } from '../../context/Sensor/SensorProvider';
import { GatewayContext } from '../../context/Gateway/GatewayProvider';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import {useRouter} from "../../components/Router/Router";
import Loading from '../../components/Loaders/Loading';
import Iconv from '../../components/iconv';
import _ from 'lodash';
import uniqid from 'uniqid';

//import SensorAlarmModal from '../../components/Modals/sensorAlarmModal';

import {
    errorToast,
    successToast, 
    PROXY_CLIENT,
    capitalizeFirst
 } from '../../libraries/utils';

import Swal from '@nmesys/sweetalert2'

import de from '../../components/DataTables/datatable.de.json';
const $ = require('jquery')
$.DataTable = require('datatables.net');

const Chart = React.lazy(() => import('react-apexcharts'), { ssr: false });


const SensorDetails = (props) => {

    const navigate = useNavigate();
    const router = useRouter();
    const {serial_number} = useParams();
    const { lang, locale } = useContext(LocaleContext);
    const {
        sensor,
        sensors, 
        consumption,
        measurements,
        getTelegrams,
        telegrams,
        sensorEvents,
        sensorAggregate
    } = useContext(SensorContext)

    const {gateways, setGateway} = useContext(GatewayContext);

    const {getBucket, bucket, buckets, setBucket} = useContext(BucketContext)

    const [mdlAlmOpen, setMdlAlmOpen]  = useState(false); // Controls alarm modal

    const [iconType, setIconType] = useState('standard');

    const [charts, setCharts] = useState([]);
    const [hbChart, setHBChart] = useState({});
    const [year, setYear] = useState(new Date().getFullYear());
    const [years, setYears] = useState([new Date().getFullYear()]);
    const [endYear, setEndYear] = useState(null);
    const [hbSlice, setHbSlice] = useState(60); // Slice heartbeat data to optimize chart

    const wmzFactorSensors = [`0x4`,'0x16','0x8','0x6','0xc','0xd'] // Only show/get wmz_factors for these sensor medium codes

    const serialNumberChanged = useCompare(serial_number);
    const sensorChanged = useCompare(sensor);
    const yearChanged = useCompare(year);
    const measurementsChanged = useCompare(sensorEvents);
    const consumptionChanged = useCompare(consumption);
    const hbSliceChanged = useCompare(hbSlice);
    const telegramsChanged = useCompare(telegrams);

    useEffect( () => {
        if(sensorChanged === true && sensor?.serial_number) resolveData();
    }, [sensor]);

    useEffect( () => {
        console.log('SENSOR AGG', sensorAggregate);
        if(sensorAggregate && sensorAggregate?.monthlyAggregate) initializeConsumptionDT()
    }, [sensorAggregate]);

    useEffect( () => {
        console.log('SENSOR EVENTS', sensorEvents)
        if(sensorEvents && sensorEvents.length > 0){
            let yearsAvailable = []
            for(const obj of sensorEvents){
                let y = new Date(obj.created_at).getFullYear();
                if(!yearsAvailable.includes(y)) yearsAvailable.push(Number(y))
            }
            yearsAvailable.sort().reverse()
            setYears(yearsAvailable)
            setYear(yearsAvailable[0])
        }
    }, [sensorEvents]);

    useEffect( () => {
        if(yearChanged === true) {
            if(sensorAggregate && sensorAggregate?.annualAggregate){
                let data = _.filter(sensorAggregate.annualAggregate, blk => new Date(blk.date).getUTCFullYear() === year -1)
                if(data[0]) setEndYear(data[0].value)
                else setEndYear(null)
            }

            resolveCharts();
            initializeMDT();
            initializeConsumptionDT();
            buildHeartbeatChart();
        }
       
        if(hbSliceChanged === true){
            buildHeartbeatChart();
        }

    }, [year, hbSlice]);

    useEffect( () => {}, [endYear]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    } 
    /**
     * Show the sensor alarm builder modal
     */
    const toggleMdlAlm = () => {
        if(mdlAlmOpen === true) setMdlAlmOpen(false)
        else setMdlAlmOpen(true)
    }
   
    const resolveData = async () => {
        if(!sensor?.serial_number) return
        if(sensor?.attributes && sensor.attributes.includes('wsr')) setIconType('wsr');
        if(sensor?.attributes && sensor.attributes.includes('hzg')) setIconType('hzg');
    }

    const resolveCharts = () => {
        let chartsArray = [];
        if(sensorAggregate && sensorAggregate?.monthlyAggregate){
            let data = _.filter(sensorAggregate.monthlyAggregate, blk => new Date(blk.date).getUTCFullYear() === Number(year));
            let uomData = _.map(data, 'consumptionR');
            let chart = buildChart({id:'kwhChart', data: uomData, title: `Sensor Consumption ${sensor.unit_of_measure.toUpperCase()} ${year}`});
            chartsArray.push(chart)
        }
        setCharts(chartsArray)
        return 
/** 
        if(sensor?.serial_number && consumption?.[year]){
            let chartsArray = [];
            const uomData = _.map(consumption[year]['m'], (obj) => _.get(obj, sensor.unit_of_measure, 0))
            if(uomData && uomData.length > 0) {
                let chart = buildChart({id:'uomChart', data: uomData, title: `Sensor Consumption ${sensor.unit_of_measure.toUpperCase()} ${year}`});
                chartsArray.push(chart)
            }
            const kwhData = _.map(consumption[year]['m'], (obj) => _.get(obj, 'kwh', 0));
            if(
                wmzFactorSensors.includes(sensor.medium_code) && 
                kwhData && kwhData.length > 0
            ) {
                let chart = buildChart({id: 'kwhChart', data: kwhData, title: `Sensor Consumption KWH ${year}`});
                chartsArray.push(chart)
            }
            setCharts(chartsArray)
        }
**/
    }

    const gotoGatewayDetails = (gateway_serial_number) => {
        let gw = _.filter(gateways, gw => gw.serial_number === gateway_serial_number)
        if(gw?.[0]?.serial_number){
            setGateway(gw[0])
            navigate(`/details/gateway/`);
        }else{
            return;
        }
    }

    const gotoBucketDetails = (bucket_id) => {
        let bkts = _.filter(buckets, obj => obj.bucket_id === bucket_id)
        if(bkts?.[0]?.bucket_id){
            setBucket(bkts[0])
            navigate(`/details/bucket/`);
        }else{
            return;
        }
    }

    const initializeMDT = async () => {
        if(sensorEvents.length < 1) return <Fragment></Fragment>;
        let data = _.filter(sensorEvents, block => new Date(block.created_at).getFullYear() === Number(year)) 
        
        let table;
        if ($.fn.dataTable.isDataTable(`#tblMeasurements`)) {
            try{
                $(`#tblMeasurements`).DataTable().destroy();
                // remove the eventhandler to prevent multiple events 
                $(`#tblMeasurements tbody`).off('click')
                $(`#tblMeasurements tbody`).off('mouseenter')
                $(`#tblMeasurements tbody`).off('mouseleave')
                initializeMDT()
            }catch(err){console.log(err)}
        } else  {
            table = $(`#tblMeasurements`).DataTable({
                language: locale,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data,
                rowId: uniqid.time(),
                pageLength: 25,
                orderCellsTop: false,
                fixedHeader: true,
                order: [
                    [2, "desc"]
                ],
                columns: [
                    
                    {
                        data: null,
                        searchable: true,
                        visible: true,
                        render: function (data, type, row) {
                            return '';
                        }
                    },
                    {
                        data: null,
                        searchable: true,
                        visible: false,
                        render: function (data, type, row, meta) {
                            return meta.row
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row, meta) {
                            return (`<span><span class="d-none">${data.created_at}</span>${new Date(data.created_at).toLocaleDateString('de-DE', { year:"numeric", month:"short", day:"numeric"})}</span>`)
                        }
                    },
                    {
                        "data": null,
                        "searchable": false,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.data?.value) return data.data.value.toFixed(2)
                            else return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.metaData?.source) return data.metaData.source
                            else return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.metaData?.telegram?.gateway_serial_number) return (`<a class="gatewayUID">${data.metaData.telegram.gateway_serial_number}</a>`)
                            else return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": true,
                        render: function (data, type, row) {
                            if(data?.metaData?.telegram?.R) {
                                let rssi = data.metaData.telegram.R;
                                if(rssi > 0) rssi = rssi * -1;
                                return rssi
                            }
                            else return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": false,
                        "visible": true,
                        "ordering": false,
                        render: function (data, type, row) {
                            if(data?.metaData?.telegram?.F){
                                return (
                                    `
                                    <button class="btn btn-sm btn-primary btn-tg me-1">
                                        <i class="fa-regular fa-binary-lock"></i>
                                    </button>
                                    `
                                )
                            }else return ''
                        }
                    },
                ],
                createdRow: function (row, data, dataIndex) {},
                "fnDrawCallback": function( oSettings ) { }
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $('.btnQuickFilter').on('click', function () {
                table.columns(2).search($(this.data())).draw();
            });

            $(`#tblMeasurements tbody tr`).off('mouseenter').on('mouseenter', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });

            $(`#tblMeasurements tbody tr`).off('mouseleave').on('mouseleave', function () {
                $(this).css('background-color', 'transparent');
            });

            $('#tblMeasurements tbody').on( 'click', 'button.btn-tg', function (e) {
                var row = $(this).closest('tr');
                const content = table.row( row ).data().metaData.telegram.F;
                readTelegram({content, serial_number:sensor.serial_number})
            });

            $('#tblMeasurements tbody').on( 'click', 'a.gatewayUID', function (e) {
                var row = $(this).closest('tr');
                const content = table.row( row ).data().metaData.telegram.gateway_serial_number;
                gotoGatewayDetails(content)
            });

        }
    }

    const initializeConsumptionDT = () => {
        if(!sensorAggregate?.monthlyAggregate || sensorAggregate.monthlyAggregate.length < 1) return <Fragment></Fragment>;
        let data = _.filter(sensorAggregate.monthlyAggregate, block => new Date(block.date).getFullYear() === Number(year)) 
        
        let table;
        if ($.fn.dataTable.isDataTable(`#tblConsumption`)) {
            $(`#tblConsumption`).DataTable().destroy();
            $(`#tblConsumption tbody`).off('click')
            $(`#tblConsumption tbody`).off('mouseenter')
            $(`#tblConsumption tbody`).off('mouseleave')
            initializeConsumptionDT()
        } else {
            table = $(`#tblConsumption`).DataTable({
                language: locale,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data,
                pageLength: 25,
                orderCellsTop: false,
                fixedHeader: true,
                order: [
                    [2, "desc"]
                ],
                columns: [
                    
                    {
                        data: null,
                        searchable: true,
                        visible: true,
                        render: function (data, type, row) {
                            return '';
                        }
                    },
                    {
                        data: null,
                        searchable: true,
                        visible: false,
                        render: function (data, type, row, meta) {
                            return meta.row
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "visible": true,
                        "ordering": false,
                        render: function (data, type, row, meta) {
                            let date = new Date(data.dateISO)
                            if(data?.date) return (`<span><span class="d-none">${data.date}</span>${new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()).toLocaleDateString('de-DE', { year:"numeric", month:"short", day:"numeric"})}</span>`)
                            else return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": false,
                        "visible": true,
                        "ordering": false,
                        render: function (data, type, row) {
                            if(data?.value) return data.value.toFixed(2)
                            else return ''
                        }
                    },
                    {
                        "data": null,
                        "searchable": false,
                        "visible": true,
                        "ordering": false,
                        render: function (data, type, row) {
                            if(data?.consumptionR) return data.consumptionR.toFixed(2)
                            else return ''
                        }
                    },
                    
                ],
                createdRow: function (row, data, dataIndex) {},
                "fnDrawCallback": function( oSettings ) { }
            });

            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $('.btnQuickFilter').on('click', function () {
                table.columns(2).search($(this.data())).draw();
            });

            $(`#tblConsumption tbody tr`).off('mouseenter').on('mouseenter', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });

            $(`#tblConsumption tbody tr`).off('mouseleave').on('mouseleave', function () {
                $(this).css('background-color', 'transparent');
            });

        }
    }

    const getDayOfYear = (date) => Math.floor((date - new Date(date.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24));
    
    const getWeekOfYear = (date) => {
        const startOfYear = new Date(date.getFullYear(), 0, 1);
        const diffInMilliseconds = date - startOfYear + (startOfYear.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
        const dayOfYear = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)) + 1;
        const weekNumber = Math.ceil(dayOfYear / 7);
        return weekNumber - 1;
    };

    const generateHeartbeatData = () => {

        function getStartDate() {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            if (year === currentYear) {
                return currentDate;
            } else if (year < currentYear) {
                return new Date(year, 11, 31);
            } else {
                return new Date(year, 0, 1);
            }
        }

        const results = [];
        for (let i = hbSlice - 1; i >= 0; i--) {
            const date = getStartDate()
            date.setDate(date.getDate() - i);
            const dateString = date.toISOString().slice(0, 10);
            const hasEvent = _.some(sensorEvents, (event) => {
                const eventDate = new Date(event.created_at);
                return eventDate.toISOString().slice(0, 10) === dateString;
            });
            results.push(hasEvent ? 1 : 0);
            results.push(0);
        }
        return results;
    }

    const buildHeartbeatChart = () => {
        if(!sensorEvents || !sensorEvents.length) return
        let data = generateHeartbeatData();
        const chartId = "heartbeatChart"
        const series = [
            {
                name: "Contact",
                type: "line",
                data
            }
        ]
        const options = {
            chart: {
                type: 'line',
                sparkline: {
                    show: true
                },
                zoom: {
                    enabled: true,
                    type: 'x',
                    autoScaleYaxis: false
                },
                toolbar: {
                    show: false
                },
                plotOptions: {
                    dataLabels: {
                      enabled: false,
                    },
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            colors: ['#ff0000'],
            title: {
                text:`Sensor Heartbeat ${year} | Range: ${hbSlice} days`,
                align: 'left'
            },
            xaxis: {
                labels: {
                    show: false
                }
            },
            yaxis: {
                labels: {
                    show: false
                }
            },
        }

        setHBChart({chartId, series, options});
    }

    const readTelegram = async ({serial_number, content}) => {
        if(!serial_number ) return;
        PROXY_CLIENT().post(`${process.env.REACT_APP_API}/sensor/telegram/decrypt`, {serial_number, content})
        .then( response => {
            let html = `
                <div class="telegramContainer d-flex justify-content-start h-100 w-100 align-content-start overflow-auto">
                    <pre class="d-flex justify-content-start w-100">${ JSON.stringify(response.data, null, 2) }</pre>
                </div>
            `

            Swal.fire({
                title: 'Telegram',
                width: "50vw",
                icon: 'question',
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'Close',
                html,
                customClass: "telegramContainer"
            })

        })
        .catch( err => {
            console.log('TELEGRAM DECRYPT ERROR 727', err)
            errorToast(err)
        })
    }

    const chartColor = () => {
        let colors;
        switch(sensor.medium_code){
            case'0x8':
                colors = ['#dc3545'];
                break;
            case'0x6':
                colors = ['#ffc107'];
                break;
            case'0x7':
                colors = ['#007bff'];
                break;
            case'0x16':
                colors = ['#007bff'];
                break;
            case'0x4':
                colors = ['#dc3545'];
                break;
            case'0x3':
                colors = ['#dc3545'];
                break;
            case'0xa':
                colors = ['#03c5fe'];
                break;
            case'0xb':
                colors = ['#03c5fe'];
                break;
            case'0xd':
                colors = ['#800080'];
                break;
                
            default :
                break;
        }
        return colors;
    }

    const buildChart = ({id, data, title = 'Generic Title', colors = ['#dc3545']}) => {
        if(!data) return;
        let consumption = _.map(data, d => d.toFixed(2))
        let series = [
            {
                name: "Energie",
                type: "line",
                data: consumption
            }
        ]
        let options = {
            chart: {
                type: 'line',
                sparkline: {
                    show: true
                },
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            colors: chartColor(),
            title: {
                text:title,
                align: 'left'
            },
            xaxis: {
                categories: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            }
        }

        return {id, series, options}
    }

    const translateTimeFrame = (tf) => {
        let tfs = {
            d: 'DAILY',
            w: 'WEEKLY',
            m: 'MONTHLY',
            y: 'YEAR'
        }
        return tfs[tf].toUpperCase();
    }

    const createSensorAlarm = (e) => {
        console.log('ALARM SENSOR', sensor)
        e.preventDefault();
        const condition = e.target.dataset.condition
        
        Swal.fire({
            title: 'Create Sensor Alarm',
            width: "50vw",
            iconHtml: '<i class="fa-solid fa-bell"></i>',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Save',
            html: 
            `
            <div class="overflow-hidden">
                <p class="">Current Sensor Stand: ${ (sensor?.last_event?.payload?.data?.value)? Number(sensor.last_event.payload.data.value).toFixed(2) : 'UNK' }</p>
                <div class="row mb-4 ${ (condition !== 'standGte')? 'hidden':'' }">
                    <div class="col-3"><label htmlFor="upperlimit">Upper Limit*</label></div>
                    <div class="col-9">
                        <input class="form-control" type"text" id="upperlimit" placeholder="${ (sensor && sensor.state)? sensor.state.stand[ sensor.unit_of_measure ] : 0 }" required/>
                        <small class="text-muted">You must set a value greater than the current stand.</small>
                    </div>
                </div>
                <div class="row mb-4 ${ (condition !== 'standLte')? 'hidden':'' }">
                    <div class="col-3"><label htmlFor="upperlimit">Lower Limit*</label></div>
                    <div class="col-9">
                        <input class="form-control" type"text" id="lowerlimit" placeholder="${ (sensor && sensor.state)? sensor.state.stand[ sensor.unit_of_measure ] : 0 }" required/>
                        <small class="text-muted">You must set a value less than the current stand.</small>
                    </div>
                </div>

                <p class="mt-4">When this alarm is triggered, a message will be sent. Messages are sent to Telegram and/or Email addresses. You may enter as many as you like</p>
                <div class="row mb-2">
                    <div class="col-1"><label htmlFor="telegram"><i class="fa-brands fa-telegram fa-2x"></i></label></div>
                    <div class="col-11">
                        <input class="form-control" type"text" id="telegram"/>
                        <small class="text-muted">Enter a comma delimited list of Telegram chat_ids</small>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-1"><label htmlFor="email"><i class="fa-solid fa-envelope fa-2x"></i></label></div>
                    <div class="col-11">
                        <input class="form-control" type"text" id="email"/>
                        <small class="text-muted">Enter a comma delimited list of email addresses</small>
                    </div>
                </div>

            </div>
            `,
            preConfirm: (inputValue) => {}
        }).then( results => {
            if (results.isConfirmed) {


                let alarm = {
                    type: "sensor",
                    topic_id: sensor.serial_number,
                    notify: {
                        emails: [],
                        telegram_ids: []
                    },
                    conditions: [
                        {[condition]: 16.73}
                    ],
                    message: [],
                    frequency: "daily"
                }

                console.log('ALARM', alarm)

/** 

                PROXY_CLIENT().post(`${process.env.REACT_APP_PROXY}/alarm/`, results.value)
                .then( response => {

                    successToast('Your new Sensor Alarm has been created!')
                    return;
                })
                .catch( err => {
                    console.log('ALARM CREATION ERROR 70', err)
                    errorToast(err)
                })


**/


            }
        })
    }

    const addressBuilder = (obj, formatted = false) => {
        if(obj?.address){
            if(!formatted) return `${obj.address.street} ${obj.address.house_number} ${ obj.address?.street1 ? ', ME '+ obj.address.street1 : ''  }, ${obj.address.post_code} ${obj.address.city}, ${obj.address.country.toUpperCase()}`
            else {
                return (
                    `${obj.address.street} ${obj.address.house_number} ${ obj.address?.street1 ? ', ME '+ obj.address.street1 : ''  }\n ${obj.address.post_code} ${obj.address.city}\n ${obj.address.country.toUpperCase()}`
                    )
                }
        }
        return ''
    }

    const UI = () => {
        if(!lang || !sensor?.serial_number) return (<Fragment><Loading/></Fragment>)
        return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">SENSOR DETAILS { (sensor.serial_number) ? `(${sensor.serial_number})`:'' }</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item">Apps</li>
                                    <li className="breadcrumb-item active"><b>Details</b></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                {/** BEGIN HEADER SECTION */}
                                <section className="header">
                                    <div className="row gx-lg-5">
                                        <div className="col-xl-3 col-md-8 mx-auto ">
                                            <div className="d-flex justify-content-center mt-4">
                                                {
                                                    (sensor?.medium_code)?
                                                        <Iconv name={`sensors.${sensor.medium_code}.${iconType}`} classList="text-info" size="10"/>
                                                        :
                                                        <Iconv name="default" classList="text-danger" size="10"/>
                                                }
                                                
                                            </div>
                                        </div>

                                        <div className="col-xl-9">
                                            <div className="mt-xl-0 mt-5">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h4 >
                                                            {
                                                                (!!sensor.type )? `${sensor.type} Sensor (${sensor.manufacturer_shortcode})`: ''
                                                            }
                                                        </h4>
                                                    
                                                        <div className="hstack gap-3 flex-wrap">
                                                            <div className="" onClick={() => gotoBucketDetails(bucket.bucket_id)} >Bucket :
                                                            {
                                                                (sensor.bucket_id && bucket && bucket.address)?
                                                                <span className="text-primary fw-medium "> <a>{addressBuilder((bucket?.address)? bucket : '')}</a> </span>
                                                                : 
                                                                ''
                                                            } 
                                                            </div>
                                                            <div className="vr"></div>
                                                            <div className="text-muted">Published : <span className="text-body fw-medium">{(sensor)? new Date(sensor.created_at).toLocaleDateString('de-DE', {day:'numeric', month:'short', year:'numeric'}) : ''  }</span>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="flex-shrink-0 border p-2">
                                                        <div>
                                                            <button className="btn btn-light btn-border waves-effect waves-light dropdown" title="Update Sensor" data-bs-toggle="dropdown" ><i className="fa-solid fa-pen-to-square"></i></button>
                                                            <div className="dropdown-menu">
                                                                <a className='dropdown-item'><i className="me-2 fa-solid fa-repeat text-danger" />Replace Sensor</a>
                                                                <a className='dropdown-item'><i className="me-2 fa-solid fa-location text-primary" />Change Address</a>
                                                                <a className='dropdown-item'><i className="me-2 fa-solid fa-tag text-success" />Update Tags</a>
                                                                <a className='dropdown-item'><i className="me-2 fa-solid fa-sliders text-primary" />Update Attributes</a>
                                                                <a className='dropdown-item'><i className="me-2 fa-solid fa-key text-danger" title="Update / View Symetric Key"/>Symetric Key</a>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button className="mt-2 btn btn-light waves-effect waves-light btn-border" title="Sensor Alerts" onClick={(e) => {toggleMdlAlm(e)}}><i className="fa-solid fa-bell-plus"></i></button>
                                                        </div>
                                                        <div>
                                                            <button className="mt-2 btn btn-light waves-effect waves-light btn-border dropdown" title="Sensor Reports" data-bs-toggle="dropdown" ><i className="fa-regular fa-file-chart-pie"></i></button>
                                                            <div className="dropdown-menu">
                                                                <a className='dropdown-item'><i className="me-2 fa-regular fa-download text-primary" />Export Sensor</a>
                                                                <a className='dropdown-item'><i className="me-2 fa-regular fa-download text-secondary" />Export History</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-xl-6">
                                                        <div className=" mt-4 d-flex justify-content-lg-start">
                                                            <h5 className="fs-15">Tags :</h5>
                                                            {
                                                                (sensor.tags)?
                                                                    sensor.tags.map(t => {
                                                                        return (<span className="badge bg-primary ms-2" key={`tag-${t}`}>{t}</span>)
                                                                    }) : <span className="badge bg-danger ms-2" >None</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className=" mt-4 d-flex justify-content-lg-start">
                                                            <h5 className="fs-15">Attributes :</h5>
                                                            {
                                                                (sensor.attributes)?
                                                                    sensor.attributes.map(att => {
                                                                        return (<span className="badge bg-primary ms-2" key={`att-${att}`}>{att}</span>)
                                                                    }) : <span className="badge bg-danger ms-2">None</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="row mt-4">
                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="p-2 border border-dashed rounded">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar-sm me-2">
                                                                    <div className="avatar-title rounded bg-transparent text-success fs-24">
                                                                        <Iconv name={ (sensor?.medium_code)? `sensors.${sensor.medium_code}.${iconType}`:'default' } classList="text-primary" size="1"/>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <p className="text-muted mb-1">Stand</p>
                                                                    <h5 className="mb-0">
                                                                        {
                                                                            (sensor?.state?.values) ?
                                                                            _.map(sensor.state.values, (val,uom) => {
                                                                                return <div class={uom !== sensor.unit_of_measure ? "text-secondary":""}>{Number(val.toFixed(2))} {uom}</div>;
                                                                            })
                                                                            : ''
                                                                        }
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="p-2 border border-dashed rounded">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar-sm me-2">
                                                                    <div className="avatar-title rounded bg-transparent text-success fs-24">
                                                                        <Iconv name="apps.gateway" classList="text-primary" size="1"/>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <p className="text-muted mb-1">Last Contact </p>
                                                                    <h5 className="mb-0">{  (sensor?.state?.date)? new Date(sensor.state.date).toLocaleDateString('de-DE', { year:"numeric", month:"short", day:"numeric"}) : '?' }</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="p-2 border border-dashed rounded">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar-sm me-2">
                                                                    <div className="avatar-title rounded bg-transparent text-success fs-24">
                                                                        <Iconv name="apps.sensor" classList="text-primary" size="1"/>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <p className="text-muted mb-1">End Year {year - 1} </p>
                                                                    <h5 className="mb-0">{(!!endYear)? endYear : 'NA'}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/** END HEADER SECTION */}
                                {/** BEGIN SENSOR DATA SECTION */}
                                <section id="sensorData">
                                    <div className="card card-body mt-4" id="sensorHistoricalData">
                                        <div className="row mb-2">
                                            <div className="col-12 d-flex justify-content-end">
                                                <select className="form-select mb-3 w-25" onChange={(e) => { setYear(e.target.value) }}>
                                                    <option selected="">Select Year...</option>
                                                    {
                                                        years.map(k => {
                                                            return (<option value={k} key={k} selected={k === year}>{k}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {/** BEGIN CHART DATA SECTION */}
                                        <section id="chartData">
                                            <div className="row">
                                                {
                                                    (charts.length > 0)?
                                                    charts.map(obj => {
                                                        return (
                                                            <div className="col-6  ">
                                                                <div className="card " key={uniqid()}>
                                                                    <div className="card-body">
                                                                        <div id={`${obj.id}`}>
                                                                            <Chart 
                                                                                options={obj.options} 
                                                                                series={obj.series}
                                                                                type={'line'}
                                                                                width={'100%'} 
                                                                                height={200} 
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <Fragment></Fragment>
                                                }
                                            </div>

                                            {
                                                (!!hbChart['series'])?
                                                <div className='row'>
                                                    
                                                    <div className="col-12  ">
                                                        <div className="card " key={uniqid()}>
                                                            <div className="card-body">
                                                                <div className="col-12 d-flex justify-content-end">
                                                                    <select className="form-select mb-3 w-25" defaultValue={hbSlice} onChange={(e) => { setHbSlice(e.target.value) }} >
                                                                        <option selected="" >Select range...</option>
                                                                        {
                                                                            [180,90,60,30].map(range => {
                                                                                return (<option value={range} selected={(hbSlice === range)?true:false}>{`Last ${range} Days`}</option>)
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>

                                                                <div id={`${hbChart.chartId}`}>
                                                                    <Chart 
                                                                        options={hbChart.options} 
                                                                        series={hbChart.series}
                                                                        type={'line'}
                                                                        width={'100%'} 
                                                                        height={120} 
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                            
                                                    </div>
                                                </div>
                                                : <Fragment></Fragment>
                                            }


                                        </section>
                                        {/** END CHART DATA SECTION */}
                                        {/** BEGIN TAB DATA SECTION */}
                                        <section id="tabData">
                                            <div className="row">
                                                <div className="col-12 mx-auto ">

                                                    <div className="product-content mt-5">
                                                        <nav>
                                                            <ul className="nav nav-tabs nav-tabs-custom nav-success" id="nav-tab" role="tablist">
                                                                <li className="nav-item">
                                                                    <a className="nav-link active" id="tabMeasurements" data-bs-toggle="tab" href="#tabContentMeasurements" role="tab" aria-controls="tabMeasurements" aria-selected="false">Chain</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link " id="tabConsumption" data-bs-toggle="tab" href="#tabContentConsumption" role="tab" aria-controls="tabConsumption" aria-selected="false">Consumption</a>
                                                                </li>

                                                            </ul>
                                                        </nav>
                                                        <div className="tab-content border border-top-0 p-4 pb-5" id="nav-tabContent">

                                                            <div className="tab-pane fade show active" id="tabContentMeasurements" role="tabpanel" aria-labelledby="tabMeasurements">
                                                                <table id="tblMeasurements" className="table mb-0 row-border hover order-column w-100 table-hover" role="grid">
                                                                    <thead>
                                                                        <tr>
                                                                            <th ></th>
                                                                            <th ></th>
                                                                            <th >Date</th>
                                                                            <th >Value</th>
                                                                            <th >Source</th>
                                                                            <th >Gateway</th>
                                                                            <th >RSSI</th>
                                                                            <th ></th>
                                                                        </tr>
                                                                    </thead>
                                                                </table>
                                                            </div>

                                                            <div className="tab-pane fade" id="tabContentConsumption" role="tabpanel" aria-labelledby="tabConsumption">
                                                                <table id={`tblConsumption`} className="table mb-0 row-border hover order-column w-100 table-hover" role="grid">
                                                                    <thead>
                                                                        <tr>
                                                                            <th ></th>
                                                                            <th ></th>
                                                                            <th >Date</th>
                                                                            <th >Value</th>
                                                                            <th >Consumption</th>
                                                                        </tr>
                                                                    </thead>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </section>
                                        {/** END TAB DATA SECTION */}
                                    </div>
                                </section>
                                {/** END SENSOR DATA SECTION */}
                            </div>
                        </div>
                    </div>
                </div>


{/** 
                <SensorAlarmModal
                    sensor={sensor}
                    showModal={mdlAlmOpen}
                    closeModal={() => setMdlAlmOpen(false)}
                    openModal={() => setMdlAlmOpen(true)}
                />

*/}


            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )


}

SensorDetails.requiredScope = ['sensor.view.any', 'sensor.view.own'];
SensorDetails.requireAuth = true;
export default SensorDetails;