import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/Auth/AuthProvider';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import { SensorContext } from '../../context/Sensor/SensorProvider';
import { Link, useLocation, useParams } from "react-router-dom";
import { GatewayContext } from '../../context/Gateway/GatewayProvider';
import { AccountContext } from '../../context/Account/AccountProvider';
import { LocaleContext } from '../../context/Locale/Locale';

import Loading from '../../components/Loaders/Loading';
import Iconv from '../../components/iconv';
import Modal from 'react-modal';
import _ from 'lodash'
import uniqid from 'uniqid';

import { 
    PROXY_CLIENT,
    capitalizeFirst
 } from '../../libraries/utils';
import Swal from '@nmesys/sweetalert2'

import TblInsight from '../../components/DataTables/insght';
const $ = require('jquery')
$.DataTable = require('datatables.net');

const Chart = React.lazy(() => import('react-apexcharts'), { ssr: false });

const GatewayDetails = (props) => {

    const navigate = useNavigate()
    const { lang, locale } = useContext(LocaleContext);
    const { buckets, setBucket } = useContext(BucketContext);
    const { sensors } = useContext(SensorContext);
    const { gateways, gateway } = useContext(GatewayContext);

    const [hbChart, setHBChart] = useState({});
    const [hbSlice, setHbSlice] = useState(60); // Slice heartbeat data to optimize chart

    const gatewayChanged = useCompare(gateway);
    const hbSliceChanged = useCompare(hbSlice);

    useEffect( () => {}, [gateways] );

    useEffect( () => {
        if(gatewayChanged && gateway?.gateway_id){
            buildHeartbeatChart();
        }
    }, [gateway] );


    useEffect(  () => {
        
    }, [sensors]);

    useEffect(  () => {
        if(hbSliceChanged === true){ 
            buildHeartbeatChart();
        }
    }, [hbSlice]);
    

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    /**
     * Create fake gateway contact data
     */
    const generateDataForLast90Days = () => {
        const dataObject = {};
        const ONE_DAY = 24 * 60 * 60 * 1000; // Milliseconds in one day
        let date = new Date();
        date.setDate(date.getDate() - 90);
      
        for (let i = 0; i < 90; i++) {
            const arrayLength = Math.floor(Math.random() * 4);
            const arrayOfOnes = Array(arrayLength).fill(1);
            const epochTime = date.getTime();
            dataObject[epochTime] = arrayOfOnes;
            date = new Date(date.getTime() + ONE_DAY);
        }
      
        return dataObject;
    }

    const buildHeartbeatChart = () => {
        if(!gateway?.serial_number) return
        let gwdata = generateDataForLast90Days();
        let data = []
        const entries = _.toPairs(gwdata);
        const sortedEntries = _.orderBy(entries, [0], ['asc']);
        const sortedObject = _.fromPairs(sortedEntries);
        const unSlicedEntries = Object.entries(sortedObject);
        
        const sliceData = unSlicedEntries.slice( hbSlice * -1 )
        sliceData.forEach(entry => {
            data.push(entry[1].length);
            data.push(0);
        });

        const chartId = "gwHeartbeatChart"
        const series = [
            {
                name: "Contact",
                type: "line",
                data
            }
        ]
        const options = {
            chart: {
                type: 'line',
                sparkline: {
                    show: true
                },
                zoom: {
                    enabled: true,
                    type: 'x',
                    autoScaleYaxis: false
                },
                toolbar: {
                    show: false
                },
                plotOptions: {
                    dataLabels: {
                      enabled: false,
                    },
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            colors: ['#ff0000'],
            title: {
                text:`Gateway Heartbeat | Range: ${hbSlice} days`,
                align: 'left'
            },
            xaxis: {
                labels: {
                    show: false
                }
            },
            yaxis: {
                labels: {
                    show: false
                }
            },
        }

        setHBChart({chartId, series, options});
    }

    const gotoBucketDetails = () => {
        let bucketObj = _.filter(buckets, p => p.bucket_id === gateway.bucket_id);
        if(bucketObj?.[0]) {
            setBucket(bucketObj[0]);
            navigate('/details/bucket')
        }else{ return }
    }

    const addressBuilder = (obj, formatted = false) => {
        if(obj?.address){
            if(!formatted) return `${obj.address.street} ${obj.address.house_number} ${ obj.address?.street1 ? ', ME '+ obj.address.street1 : ''  }, ${obj.address.post_code} ${obj.address.city}, ${obj.address.country.toUpperCase()}`
            else {
                return (
                    `${obj.address.street} ${obj.address.house_number} ${ obj.address?.street1 ? ', ME '+ obj.address.street1 : ''  }\n ${obj.address.post_code} ${obj.address.city}\n ${obj.address.country.toUpperCase()}`
                    )
                }
        }
        return ''
    }

    const UI = () => {
        if(!lang || !gateway) return (<Fragment><Loading/></Fragment>)
        let bucket = {}
        if(gateway?.bucket_id){
            bucket = _.find(buckets, b => b.bucket_id === gateway.bucket_id);
        }

        return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">GATEWAY DETAILS { (gateway?.serial_number) ? `(${gateway.serial_number})`:'' }</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/dashboards/overview">Dashboard</Link></li>
                                    <li className="breadcrumb-item active"><b>Gateway Details</b></li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                {/** BEGIN HEADER SECTION */}
                                <section className="header">
                                    <div className="row gx-lg-5">
                                        <div className="col-xl-4 col-md-8 mx-auto ">
                                            <div className="d-flex justify-content-center mt-4 ">
                                                <Iconv name={`apps.gateway`} classList="text-info" size="10"/>
                                                
                                            </div>
                                            <div className="d-flex justify-content-center mt-2 ">
                                                <span className="">{gateway?.serial_number ? gateway.serial_number : '#'}</span>
                                            </div>
                                            
                                        </div>

                                        <div className="col-xl-8">
                                            <div className="mt-xl-0 mt-5">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        
                                                    
                                                        <div className="hstack gap-3 flex-wrap">
                                                            <div className="">Liegenschaft :
                                                            {
                                                                (bucket?.address)?
                                                                <span className="text-primary fw-medium " > <a onClick={() => gotoBucketDetails()} >{ (bucket?.address)? addressBuilder(bucket): '' }</a> </span>
                                                                : 
                                                                ''
                                                            } 
                                                            </div>
                                                            <div className="vr"></div>
                                                            <div className="text-muted">Published : <span className="text-body fw-medium">{(gateway)? new Date(gateway.created_at).toLocaleDateString('de-DE', {day:'numeric', month:'short', year:'numeric'}) : ''  }</span>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="flex-shrink-0 border p-2">
                                                        <div>
                                                            <button className="btn btn-light btn-border waves-effect waves-light dropdown" title="Update Sensor" data-bs-toggle="dropdown" ><i className="fa-solid fa-pen-to-square"></i></button>
                                                            <div className="dropdown-menu">
                                                                <a className='dropdown-item'><i className="me-2 fa-solid fa-repeat text-danger" />Replace Sensor</a>
                                                                <a className='dropdown-item'><i className="me-2 fa-solid fa-location text-primary" />Change Address</a>
                                                                <a className='dropdown-item'><i className="me-2 fa-solid fa-tag text-success" />Update Tags</a>
                                                                <a className='dropdown-item'><i className="me-2 fa-solid fa-sliders text-primary" />Update Attributes</a>
                                                                <a className='dropdown-item'><i className="me-2 fa-solid fa-key text-danger" title="Update / View Symetric Key"/>Symetric Key</a>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button className="mt-2 btn btn-light waves-effect waves-light btn-border" title="Sensor Alerts" ><i className="fa-solid fa-bell-plus"></i></button>
                                                        </div>
                                                        <div>
                                                            <button className="mt-2 btn btn-light waves-effect waves-light btn-border dropdown" title="Sensor Reports" data-bs-toggle="dropdown" ><i className="fa-regular fa-file-chart-pie"></i></button>
                                                            <div className="dropdown-menu">
                                                                <a className='dropdown-item'><i className="me-2 fa-regular fa-download text-primary" />Export Sensor</a>
                                                                <a className='dropdown-item'><i className="me-2 fa-regular fa-download text-secondary" />Export History</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-xl-6">
                                                        <div className=" mt-4 d-flex justify-content-lg-start">
                                                            <h5 className="fs-15">Tags : {gateway?.tags ? gateway.tags : ''}</h5>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className=" mt-4 d-flex justify-content-lg-start">
                                                            <h5 className="fs-15">Attributes : {gateway?.attributes ? gateway.atteibutes : ''}</h5>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="row mt-4">
                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="p-2 border border-dashed rounded">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar-sm me-2">
                                                                    <div className="avatar-title rounded bg-transparent text-success fs-24">

                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <p className="text-muted mb-1">Stand</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="p-2 border border-dashed rounded">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar-sm me-2">
                                                                    <div className="avatar-title rounded bg-transparent text-success fs-24">

                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <p className="text-muted mb-1">Last Contact </p>
                                                                    <h5 className="mb-0">{  (gateway?.last_transmission)? new Date(gateway.last_transmission).toLocaleDateString('de-DE', {day:'numeric', month: 'short', year: 'numeric'}): '?' }</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                   

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/** END HEADER SECTION */}
                            </div>
                        </div>
                    </div>
                </div>

                {
                    (!!hbChart['series'])?
                    <div className='row'>
                        <div className="col-12  ">
                            <div className="card " key={uniqid()}>
                                <div className="card-body">
                                    <div className="card-title">Gateway Heartbeat</div>
                                    <p className="small w-50">A gateway heartbeat reveals the communication frequency of this gateway for the last X number of days.The higher the peaks, then the higher the number of contacts from this gatewy on the given day.</p>
                                    <div className="col-12 d-flex justify-content-end">
                                        <select className="form-select mb-3 w-25" defaultValue={hbSlice} onChange={(e) => { setHbSlice(e.target.value) }} >
                                            <option selected="" >Select range...</option>
                                            {
                                                [180,90,60,30].map(range => {
                                                    return (<option value={range} selected={(hbSlice === range)?true:false}>{`Last ${range} Days`}</option>)
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div id={`${hbChart.chartId}`}>
                                        <Chart 
                                            options={hbChart.options} 
                                            series={hbChart.series}
                                            type={'line'}
                                            width={'100%'} 
                                            height={120} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <Fragment></Fragment>
                }

                <div className="row">
                    <div className="col-12  ">
                        <TblInsight />
                    </div>
                </div>

                
            </Fragment>
            
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

GatewayDetails.requiredScope = ['gateway.view.any','gateway.view.own'];
GatewayDetails.requireAuth = true;
export default GatewayDetails;