import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import { LocaleContext } from '../../context/Locale/Locale';
import { BucketContext } from '../../context/Bucket/BucketProvider';
import { SensorContext } from '../../context/Sensor/SensorProvider';
import { GatewayContext } from '../../context/Gateway/GatewayProvider';
import { TenantContext } from '../../context/Tenant/TenantProvider';
import { AddressContext } from '../../context/Address/AddressProvider';

import BucketMap from '../Maps/bucket';
import ConsumptionChart from '../Charts/consumptionChart';

import _ from 'lodash'
import Modal from 'react-modal';
import Iconv from '../iconv';
import { Link, useNavigate } from "react-router-dom";


const BucketCard = (props) => {

    const navigate = useNavigate();
    const { lang, locale } = useContext(LocaleContext);
    const {
        buckets,
        bucket,
        setBucket, 
        addNewBucketModal, 
        addApartmentsModal,
        deleteBucketModal,
        addressBuilder
    } = useContext(BucketContext);
    const {addBulkSensorsModal, sensors} = useContext(SensorContext);
    const {addBulkGatewaysModal, gateways} = useContext(GatewayContext);
    const {
        tenants,
        createTenantModal,
        curTenants, 
        setCurTenants
    } = useContext(TenantContext);
    const { addresses } = useContext(AddressContext);

    //const [iconSensors, setIconSensors] = useState(0);
    //const [iconGateways, setIconGateways] = useState(0);

    useEffect(  () => {
        if(bucket && bucket.bucket_id){
            bucket.address = _.find(addresses, {address_id:bucket.address_id});
            getCurTenants();
       //    setIconGateways(gateways.filter(g => g.bucket_id === bucket.bucket_id).length || 0)
       //     setIconSensors(sensors.filter(s => s.bucket_id === bucket.bucket_id).length || 0)
        }
    }, [bucket])


    useEffect(  () => {
        getCurTenants();
    }, [tenants])
   

    const getCurTenants = async () => {
        if(!bucket || !tenants.length === 0) return;
        const date = new Date().getTime();
        let objs = []
        const filterTenants = (bucket_id) => {
            let current = _.chain(tenants)
                .filter(obj => (obj.bucket_id === bucket_id ))
                .filter(obj => (!!obj.date_move_in && obj.date_move_in < date) )
                .filter(obj => (!obj.date_move_out || obj.date_move_out > date))
                .value();
            objs = objs.concat(current);
        }
       _.chain(buckets)
        .filter(obj => (obj.bucket_id === bucket.bucket_id || obj.parent_id === bucket.bucket_id))
        .map(obj => filterTenants(obj.bucket_id))
        .value();
        setCurTenants(objs)
    }

    const UI = () => {

        let iconGateways = (gateways && gateways.length)?gateways.filter(g => g.bucket_id === bucket.bucket_id).length : 0
        let iconSensors = (sensors && sensors.length)? sensors.filter(s => s.bucket_id === bucket.bucket_id).length : 0

        return (
            <div className="card" id="contact-view-detail">
                <div className="d-flex flex-row-reverse" >
                    <div className="hstack text-nowrap gap-2 float-right">
                        <button type="button" id="dropdownMenuLink1" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-soft-info"><i className="ri-more-2-fill"></i></button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                            <li className="ps-3 dropdown-item" title="Add a new Tenant to this address" onClick={() => navigate('/details/bucket')} ><i className="fa fa-solid fa-home"></i> View</li>
                            {
                                (bucket?.type && bucket.type === 'structural' && (!bucket?.parent_id || bucket.parent_id.length === 0) ) ?
                                <li className="ps-3 dropdown-item" title="Add a new Tenant to this address" onClick={() => addApartmentsModal()} ><i className="fa fa-solid fa-home-user"></i> Add Apartment(s)</li>
                                :
                                ''
                            }
                            {
                                (bucket?.type && bucket.type === 'structural' && !!bucket?.parent_id && bucket.parent_id.length > 0 ) ?
                                <li className="ps-3 dropdown-item" title="Add a new Tenant to this address" onClick={() => createTenantModal(bucket)} ><i className="fa fa-solid fa-home-user"></i> Add Tenant</li>
                                :
                                ''
                            }
                            <li className="ps-3 dropdown-item" title="Add new Sensors to this address" onClick={() => addBulkSensorsModal(bucket)} ><i className="fa fa-solid fa-microchip"></i> Add Sensor(s)</li>
                            <li className="ps-3 dropdown-item" title="Add new Gateways to this address" onClick={() => addBulkGatewaysModal(bucket)} ><i className="fa fa-solid fa-router"></i> Add Gateway(s)</li>
                            <li className="ps-3 dropdown-item" title="Transfer ownership of all assets to another Account"><i className="fa fa-solid fa-right-left-large"></i> Transfer</li>
                            <li className="ps-3 dropdown-item" title="Show bucket map location" onClick={() => document.getElementById('btnShowMapModal').click()} ><i className="fa fa-solid fa-location-dot"></i> Map</li>
                            <hr />
                            <li className="ps-3 dropdown-item text-warning"><i className="fa fa-solid fa-pencil"></i> Edit</li>
                            <li className="ps-3 dropdown-item text-danger" title="Destroy this Bucket, but keep sensors and gateways within" onClick={() => deleteBucketModal()}><i className="fa fa-solid fa-trash text-danger"></i> Delete</li>
                        </ul>
                    </div>
                </div>

                <div className="card-body text-center">
                    <div className="position-relative d-inline-block">
                        <Link to={`/details/bucket`}>
                            <a>
                                {
                                    (!bucket?.parent_id || bucket.parent_id.length === 0) ? 
                                     <Iconv name={`bucket.multi`} size="5" className="text-primary " />
                                     :
                                    <Iconv name={`bucket.single`} size="5" className="text-primary " />
                                }
                                
                            </a>
                        </Link> 
                    </div>
                    <p className="text-muted mt-2">{(bucket?.bucket_id)? bucket.bucket_id: ''}</p>
                    
                    <h5 className="mt-4 mb-1">{(!!bucket && bucket?.address)? addressBuilder(bucket) : `` } </h5>
                    <p className="text-muted"></p>
                    <ul className="list-inline mb-0">

                        <li className="list-inline-item avatar-sm">
                            <a className="avatar-title bg-soft-primary text-primary fs-15 rounded">
                                <Iconv name={`bucket.children`} size="2" classList="text-primary" />
                                <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-primary">{buckets.filter(p => p.parent_id === bucket.bucket_id).length}</span>
                            </a>
                        </li>
                            
                        <li className="list-inline-item avatar-sm">
                            <BucketMap />
                        </li>
                        <li className="list-inline-item avatar-sm">
                            <a className={`avatar-title bg-soft-${curTenants.length > 0 ? 'success':'primary'} text-success fs-15 rounded`} >
                                <Iconv name="bucket.tenant" size="2" classList={`text-${curTenants.length > 0 ? 'success':'primary'}`}/>
                                <span className={`position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-${curTenants.length > 0 ? 'success':'primary'}`} >{ curTenants.length }</span>
                            </a>
                        </li>
                        <li className="list-inline-item avatar-sm">
                            <a className="avatar-title bg-soft-primary text-success fs-15 rounded">
                                <Iconv name="apps.sensor" size="2" classList={`text-${(iconSensors > 0)?'success':'primary'}`}/>
                                <span className={`position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-${(iconSensors > 0)?'success':'primary'}`}>{iconSensors}</span>
                            </a>
                        </li>
                        <li className="list-inline-item avatar-sm">
                            <a className={`avatar-title bg-soft-primary text-primary fs-15 rounded`}>
                                <Iconv name="apps.gateway" size="2" classList={`text-${(iconGateways > 0)?'success':'primary'}`}/>
                                <span className={`position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-${(iconGateways > 0)?'success':'primary'}`}>{iconGateways}</span>
                            </a>
                        </li>

                    </ul>
                </div>

                <div className="card-body">
                    { (!!curTenants && curTenants.length > 0 && bucket.type !== 'multi')?
                        curTenants.map(t => {
                            return (
                                <div className="card" key={t.tenant_id}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-2">
                                                <a  className={`avatar-title bg-soft-success text-success fs-15 rounded`}>
                                                    <Iconv name="bucket.tenant" size="1" classList={`text-success`}/>
                                                </a>
                                            </div>
                                            <div className="col-10">
                                                <div className="d-flex flex-column">
                                                    <div className=""><a className="text-body fw-medium">{t.contact.first_name} {t.contact.last_name}</a></div>
                                                    <div className="">Move In: {(!!t.date_move_in )? new Date(t.date_move_in).toLocaleDateString('de-DE', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) : '' }</div> 
                                                    <div className="">Move Out: {(!!t.date_move_out )? new Date(t.date_move_out).toLocaleDateString('de-DE', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) : '' }</div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : <Fragment></Fragment>
                    }

                      <ConsumptionChart height="200"/> 
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default BucketCard;